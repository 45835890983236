/* eslint-disable no-nested-ternary */
/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
import { useMediaQuery } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import {
  BLUE_COLOR_SHADES,
  CIRCULAR_CHART_COLORS
  // CHART_COLORS_FOR_AGENCY,
  // NEW_CHART_COLOR_ARRAY
} from '../../constants';
import {
  currancyFormatter,
  currencySymbol,
  // currencySymbol,
  currencySymbolWithDecimal,
  numberFormatter,
  numberRounder
} from '../../utils/helper';
// import { colors } from '../../theme';
// import { currancyFormatter } from '../../utils/helper';

// const DonateChart = function ({
const DonuteChart = function ({
  height = '250px',
  series = [44, 55, 41, 17, 15],
  labels = ['Team A', 'Team B', 'Team C', 'Team D', 'Team E'],
  legend,
  showTotal = true,
  valueType = '',
  otherColors = []
}) {
  const [isSmallerThan1601] = useMediaQuery('(max-width: 1601px)');
  const [colors] = useState(otherColors.length > 0 ? otherColors : BLUE_COLOR_SHADES);

  const [options, setOptions] = useState({
    chart: {
      type: 'donut'
    },
    legend: {
      show: legend,
      width: 110
    },
    colors,
    labels,
    dataLabels: {
      enabled: false
    },
    tooltip: {
      followCursor: false,
      custom({ series, seriesIndex }) {
        const value = series.reduce((prev, curr) => prev + curr, 0);
        // return `${labels[seriesIndex]} - ${series[seriesIndex]}`;
        return `<div class="tooltip">  <div class="tooltip-inside"><span class="tooltip-circle" style="background-color: ${
          colors[seriesIndex]
        }"></span> <p style="max-width: 110px; word-break: break-word;
        white-space: normal;">${labels[seriesIndex]}</p></div> <div><span>${
          valueType === '£'
            ? Math.round(series[seriesIndex]).toString().length > 6
              ? currancyFormatter(series[seriesIndex])
              : currencySymbolWithDecimal(series[seriesIndex])
            : Math.round(series[seriesIndex]).toString().length > 6
            ? numberFormatter(series[seriesIndex])
            : series[seriesIndex].toFixed(2)
        } & </span> <p style="display: inline-block">${numberFormatter(
          (series[seriesIndex] / value) * 100
        )}%</p></div> </div>`;
      }
    },
    responsive: [
      {
        breakpoint: 1601,
        options: {
          legend: {
            position: 'bottom',
            width: 180,
            height: 50
          }
        }
      }
    ],
    plotOptions: {
      pie: {
        donut: {
          size: '83px',
          // background: colors.main.blueBackground,
          labels: {
            show: true,
            name: {
              show: false
            },
            value: {
              show: true,
              color: CIRCULAR_CHART_COLORS.TEXT_COLOR,
              fontSize: '2rem',
              formatter(value) {
                const total = series.reduce((prev, curr) => prev + curr, 0);
                return `${numberFormatter((value / total) * 100)}%`;
                // return `${value} ${valueType}`;
              }
            },
            total: {
              show: showTotal,
              color: CIRCULAR_CHART_COLORS.TEXT_COLOR,
              fontSize: '2rem',
              formatter() {
                const value = series.reduce(
                  (previousValue, currentValue) => previousValue + currentValue,
                  0
                );
                return valueType === '£'
                  ? Math.round(value).toString().length > 6
                    ? currancyFormatter(value)
                    : currencySymbol(value)
                  : Math.round(value).toString().length > 6
                  ? numberFormatter(value)
                  : numberRounder(value);
              }
            }
          }
        }
      }
    }
  });

  useEffect(() => {
    setOptions({
      chart: {
        type: 'donut'
      },
      legend: {
        show: legend,
        width: 110
      },
      colors,
      labels,
      dataLabels: {
        enabled: false
      },
      tooltip: {
        followCursor: false,
        custom({ series, seriesIndex }) {
          const value = series.reduce((prev, curr) => prev + curr, 0);
          // return `${labels[seriesIndex]} - ${series[seriesIndex]}`;
          return `<div class="tooltip">  <div class="tooltip-inside"><span class="tooltip-circle" style="background-color: ${
            colors[seriesIndex]
          }"></span> <p style="max-width: 110px; word-break: break-word;
          white-space: normal;">${labels[seriesIndex]}</p></div> <div><span>${
            valueType === '£'
              ? Math.round(series[seriesIndex]).toString().length > 6
                ? currancyFormatter(series[seriesIndex])
                : currencySymbolWithDecimal(series[seriesIndex])
              : Math.round(series[seriesIndex]).toString().length > 6
              ? numberFormatter(series[seriesIndex])
              : series[seriesIndex].toFixed(2)
          } & </span> <p style="display: inline-block">${numberFormatter(
            (series[seriesIndex] / value) * 100
          )}%</p></div> </div>`;
        }
      },
      responsive: [
        {
          breakpoint: 1601,
          options: {
            legend: {
              position: 'bottom',
              width: 180,
              height: 50
            }
          }
        }
      ],
      plotOptions: {
        pie: {
          donut: {
            size: '83px',
            // background: colors.main.blueBackground,
            labels: {
              show: true,
              name: {
                show: false
              },
              value: {
                show: true,
                color: CIRCULAR_CHART_COLORS.TEXT_COLOR,
                fontSize: '2rem',
                formatter(value) {
                  const total = series.reduce((prev, curr) => prev + curr, 0);
                  return `${numberFormatter((value / total) * 100)}%`;
                  // return `${value} ${valueType}`;
                }
              },
              total: {
                show: showTotal,
                color: CIRCULAR_CHART_COLORS.TEXT_COLOR,
                fontSize: '2rem',
                formatter() {
                  const value = series.reduce(
                    (previousValue, currentValue) => previousValue + currentValue,
                    0
                  );
                  return valueType === '£'
                    ? Math.round(value).toString().length > 6
                      ? currancyFormatter(value)
                      : currencySymbol(value)
                    : Math.round(value).toString().length > 6
                    ? numberFormatter(value)
                    : numberRounder(value);
                }
              }
            }
          }
        }
      }
    });
  }, [series, labels]);

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="donut"
      height={isSmallerThan1601 ? '210px' : height}
    />
  );
};

export default DonuteChart;
