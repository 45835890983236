import { applyMiddleware, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
// import { composeWithDevTools } from 'redux-devtools-extension';

import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import rootReducer from '../redux/reducers';

const persistConfig = {
  key: 'root',
  storage
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const storeConfig = createStore(
  persistedReducer,
  applyMiddleware(thunkMiddleware)
  // composeWithDevTools(applyMiddleware(thunkMiddleware))
);
const persistor = persistStore(storeConfig);

export default {
  storeConfig,
  persistor
};
