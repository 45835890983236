import { Button } from '@chakra-ui/react';
import styled from 'styled-components';

export const StyledCarouselContainer = styled.section`
  position: relative;
`;

export const StyledCarouselItemContainer = styled.div`
  width: 100%;
`;

export const StyledCarouselBtnContainer = styled.div`
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  display: flex;
  justify-content: space-between;
  width: 100%;
  z-index: 1000;
`;

export const StyledCarouselNextBtn = styled(Button)`
  right: 0rem;
  top: -1rem;
  @media (max-width: 1024px) {
    right: -26rem;
  }
`;

export const StyledCarouselPreviousBtn = styled(Button)`
  left: -1rem;
  top: -1rem;
`;
