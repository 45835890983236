/* eslint-disable import/named */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { Button, useToast } from '@chakra-ui/react';
import { getModulePermissions } from '../../utils/hooks';
import {
  addAgency,
  getAgency,
  getAgencyAdminUser,
  updateAgency
} from '../../redux/action/agency.action';
import ServerSideDatatable from '../../components/ServerSideDatatable';
import constants, {
  ADD_AGENCY_VALIDATIONS,
  ADD_USER_VALIDATIONS,
  CUSTOM_TOAST_DURATION,
  PAGE_TITLE,
  PATH,
  TOAST_SETTINGS,
  USER_TYPE
} from '../../constants';
import {
  deepClone,
  validatePostCode,
  validateForm
  // validatePostCode
} from '../../utils/helper';
import ModalComponent from '../../components/Modal';
import { addUser, reInviteAdminUser } from '../../redux/action/user.action';
import AddAgency from '../../components/Forms/AddAgency';
import Card from '../../components/Card';
import Loader from '../../components/Loader';
import StyledAgencyContainer from './Agency.styled';
import UserDetails from '../../components/Forms/UserDetails';
import MultiUser from '../../components/Forms/MultiUser';

const Agency = function () {
  useEffect(() => {
    document.title = PAGE_TITLE.AGENCY;
  }, []);

  const defaultAdminData = {
    name: '',
    email: '',
    label: '',
    country_code: '+44',
    mobile: ''
  };

  const toast = useToast();
  const permission = getModulePermissions(PATH.AGENCY.CODE);
  const [refresh, setRefresh] = useState(false);
  const [inModalOpan, setModalOpen] = useState(false);
  const [isAdminModal, setAdminModalOpen] = useState(false);
  const [agencyId, setAgencyId] = useState(null);
  const [isAgencyEdit, setIsAgencyEdit] = useState(false);
  const [reload, setReload] = useState(false);
  const [country, setCountry] = useState({});
  const [agencyData, setAgencyData] = useState({
    name: '',
    address_line_1: '',
    address_line_2: '',
    address_line_3: '',
    city: '',
    country: '',
    postCode: ''
  });
  const [adminData, setAdminData] = useState([defaultAdminData]);
  const [isPageLoader, setIsPageLoader] = useState(false);
  const [validations, setValidations] = useState(deepClone(ADD_AGENCY_VALIDATIONS));
  const [, setUserValidations] = useState(deepClone(ADD_USER_VALIDATIONS));
  const [addClick, setAddClick] = useState(false);
  const [saveClick, setSaveClick] = useState(false);
  const [adminUserDetailsModal, setAdminUserDetailsModal] = useState(false);

  const onAgencyModalClose = () => {
    setModalOpen(false);
    setIsAgencyEdit(false);
    setAgencyData({
      name: '',
      address_line_1: '',
      address_line_2: '',
      address_line_3: '',
      city: '',
      country: '',
      postCode: ''
    });
    setCountry(null);
    setValidations(deepClone(ADD_AGENCY_VALIDATIONS));
  };

  const addAdminInExistingCompany = () => {
    setAdminUserDetailsModal(false);
    setAdminModalOpen(true);
  };

  const saveAgency = async () => {
    // eslint-disable-next-line prefer-const
    let [validationObject, isFormValid] = validateForm(agencyData, validations);
    const isValidPostCode = validatePostCode(agencyData.postCode);
    if (!isValidPostCode) {
      validationObject.postCode.isvalid = false;
      isFormValid = false;
    } else {
      // agencyData.postCode = agencyData.postCode.replaceAll(' ', '');
      setAgencyData(agencyData);
    }
    setValidations(validationObject);
    if (isFormValid) {
      let result = null;
      if (isAgencyEdit) {
        result = await updateAgency(agencyId, agencyData);
        if (result && result.ok) {
          toast({
            ...TOAST_SETTINGS.SETTINGS,
            duration: CUSTOM_TOAST_DURATION.duration,
            status: TOAST_SETTINGS.SUCCESS,
            title: result.message
          });
          setIsAgencyEdit(false);
          onAgencyModalClose();
        } else {
          toast({
            ...TOAST_SETTINGS.SETTINGS,
            status: TOAST_SETTINGS.ERROR,
            title: result.error
          });
        }
      } else {
        result = await addAgency(agencyData);
        if (result && result.ok) {
          setAgencyId(result.data?.agency_id);
          toast({
            ...TOAST_SETTINGS.SETTINGS,
            duration: CUSTOM_TOAST_DURATION.duration,
            status: TOAST_SETTINGS.SUCCESS,
            title: result.message
          });
          setAdminModalOpen(true);
          onAgencyModalClose();
          setIsAgencyEdit(false);
        } else {
          toast({
            ...TOAST_SETTINGS.SETTINGS,
            status: TOAST_SETTINGS.ERROR,
            title: result.error
          });
        }
      }
      setReload(!reload);
    } else {
      setRefresh(!refresh);
      setReload(!reload);
    }
  };

  const onCloseAdminModal = () => {
    setCountry(null);
    setAdminModalOpen(false);
    setAdminData([
      {
        name: '',
        email: '',
        label: '',
        country_code: '+44',
        mobile: ''
      }
    ]);
    setUserValidations(deepClone(ADD_USER_VALIDATIONS));
  };

  const saveAdminData = async (data) => {
    // const [validationObject, isFormValid] = validateForm(adminData, userValidations);
    // setUserValidations(validationObject);
    setAdminData(data);
    const payloadUsers = data.map((item) => {
      return {
        id: agencyId,
        name: item.name,
        email: item.email,
        phone: item.mobile,
        country_code: item.country_code
      };
    });
    const result = await addUser({
      user_type: USER_TYPE.AGENCY_ADMIN,
      users: payloadUsers
    });
    if (result && result.ok) {
      toast({
        ...TOAST_SETTINGS.SETTINGS,
        duration: CUSTOM_TOAST_DURATION.duration,
        status: TOAST_SETTINGS.SUCCESS,
        title: result.message
      });
      onCloseAdminModal();
      setAdminUserDetailsModal(false);
    } else {
      toast({
        ...TOAST_SETTINGS.SETTINGS,
        status: TOAST_SETTINGS.ERROR,
        title: result.error
      });
    }
    // if (isFormValid) {
    //   const result = await addUser({
    //     user_type: USER_TYPE.AGENCY_ADMIN,
    //     users: [
    //       {
    //         name: adminData.name,
    //         id: agencyId,
    //         email: adminData.email,
    //         phone: adminData.mobile,
    //         country_code: adminData.country_code
    //       }
    //     ]
    //   });
    //   if (result && result.ok) {
    //     toast({
    //       ...TOAST_SETTINGS.SETTINGS,
    //       duration: CUSTOM_TOAST_DURATION.duration,
    //       status: TOAST_SETTINGS.SUCCESS,
    //       title: result.message
    //     });
    //     onCloseAdminModal();
    //   } else {
    //     toast({
    //       ...TOAST_SETTINGS.SETTINGS,
    //       status: TOAST_SETTINGS.ERROR,
    //       title: result.error
    //     });
    //   }
    // } else {
    //   setRefresh(!refresh);
    // }
  };

  const editAgency = (id, data) => {
    setCountry({ label: data.country, value: data.country });
    const { address } = data;
    setIsAgencyEdit(true);
    setAgencyData({
      name: data.agency_name,
      address_line_1: address?.address_line_1,
      address_line_2: address?.address_line_2,
      address_line_3: address?.address_line_3,
      city: data.city,
      country: data.country,
      postCode: data.post_code
    });
    setModalOpen(true);
    setAgencyId(id);
  };

  useEffect(() => {
    setIsPageLoader(true);
  }, []);

  const [adminUserData, setAdminUserData] = useState({});
  const [selectedAgency, setSelectedAgency] = useState('');

  const viewUserData = async (id, name) => {
    setSelectedAgency(name);
    const result = await getAgencyAdminUser(id);
    setAgencyId(id);
    setAdminUserDetailsModal(true);
    if (result && result.ok) {
      setAdminUserData(result.data?.users);
    } else {
      toast({
        ...TOAST_SETTINGS.SETTINGS,
        status: TOAST_SETTINGS.ERROR,
        title: result.error
      });
    }
  };

  const [column, setColumn] = useState([
    {
      label: 'Name',
      field: 'agency_name',
      sort: true
    },
    {
      label: 'City',
      field: 'city',
      sort: true
    },
    {
      label: 'Nationality',
      field: 'country',
      sort: true
    }
  ]);

  const setCountryData = (e) => {
    setCountry(e);
    setAgencyData({ ...agencyData, country: e.value });
  };

  const reInviteUser = async (userId) => {
    const result = await reInviteAdminUser(userId);
    if (result && result.ok) {
      toast({
        ...TOAST_SETTINGS.SETTINGS,
        duration: CUSTOM_TOAST_DURATION.duration,
        status: TOAST_SETTINGS.SUCCESS,
        title: result.message
      });
    } else {
      toast({
        ...TOAST_SETTINGS.SETTINGS,
        status: TOAST_SETTINGS.ERROR,
        title: result.error
      });
    }
    setReload(!reload);
  };

  useEffect(() => {
    if (permission && permission.includes('edit')) {
      setColumn([
        ...column,
        {
          label: 'Action',
          field: 'agency_id',
          sort: false,
          customRow: (cell, row) => {
            return (
              <>
                <Button
                  bg="main.semiPrimary"
                  color="main.white"
                  _hover={{ bg: 'main.primary' }}
                  _active={{
                    bg: 'main.semiPrimary',
                    borderColor: 'main.primary'
                  }}
                  onClick={() => editAgency(cell, row)}>
                  {constants.AGENCY.EDIT_BUTTON_TEXT}
                </Button>
                &nbsp;
                <Button
                  bg="main.semiPrimary"
                  color="main.white"
                  _hover={{ bg: 'main.primary' }}
                  _active={{
                    bg: 'main.semiPrimary',
                    borderColor: 'main.primary'
                  }}
                  onClick={() => viewUserData(cell, row.agency_name)}>
                  {constants.AGENCY.VIEW_USER_BUTTON_TEXT}
                </Button>
              </>
            );
          }
        }
      ]);
    }
  }, [permission]);

  return (
    <StyledAgencyContainer>
      {isPageLoader ? <Loader /> : null}
      <Card>
        {permission && permission.includes('create') && (
          <Button
            bg="main.semiPrimary"
            color="main.white"
            _hover={{ bg: 'main.primary' }}
            _active={{
              bg: 'main.semiPrimary',
              borderColor: 'main.primary'
            }}
            onClick={() => setModalOpen(true)}>
            Add Agency
          </Button>
        )}
        <ModalComponent
          isOpen={inModalOpan}
          onClose={onAgencyModalClose}
          modalTitle={
            isAgencyEdit ? constants.AGENCY.EDIT_HEADER_TEXT : constants.AGENCY.HEADER_TEXT
          }
          modalContent={
            <AddAgency
              countryValue={country}
              setCountryData={setCountryData}
              setData={setAgencyData}
              data={agencyData}
              validations={validations}
              refresh={refresh}
            />
          }
          negative={
            <Button
              onClick={() => onAgencyModalClose()}
              bg="main.secondary"
              mr={3}
              _hover={{ bg: 'main.secondary' }}
              color="main.black">
              {constants.AGENCY.CANCEL_BUTTON_TEXT}
            </Button>
          }
          positive={
            <Button
              bg="main.semiPrimary"
              color="main.white"
              _hover={{ bg: 'main.primary' }}
              _active={{
                bg: 'main.semiPrimary',
                borderColor: 'main.primary'
              }}
              onClick={() => {
                saveAgency();
              }}>
              {isAgencyEdit
                ? constants.AGENCY.EDIT_UPDATE_BUTTON_TEXT
                : constants.AGENCY.SAVE_BUTTON_TEXT}
            </Button>
          }
        />
        <ModalComponent
          isOpen={isAdminModal}
          onClose={() => setAdminModalOpen(false)}
          modalTitle="Add User"
          modalContent={
            <MultiUser
              adminData={adminData}
              setAdminData={setAdminData}
              refresh={refresh}
              addClick={addClick}
              saveClick={saveClick}
              setSaveClick={setSaveClick}
              setRefresh={setRefresh}
              setAddClick={setAddClick}
              saveAdminData={saveAdminData}
              onCloseAdminModal={onCloseAdminModal}
              setAdminUserDetailsModal={setAdminUserDetailsModal}
              Id={agencyId}
              ADMIN={USER_TYPE.AGENCY_ADMIN}
            />
          }
          positive={
            <>
              <Button
                bg="main.semiPrimary"
                color="main.white"
                _hover={{ bg: 'main.primary' }}
                mr={3}
                _active={{
                  bg: 'main.semiPrimary',
                  borderColor: 'main.primary'
                }}
                onClick={() => setAddClick(true)}>
                Add
              </Button>
              <Button
                bg="main.semiPrimary"
                color="main.white"
                _hover={{ bg: 'main.primary' }}
                _active={{
                  bg: 'main.semiPrimary',
                  borderColor: 'main.primary'
                }}
                disabled={saveClick}
                onClick={() => setSaveClick(true)}>
                Save
              </Button>
            </>
          }
        />
        <ModalComponent
          isOpen={adminUserDetailsModal}
          onClose={() => {
            setAdminUserDetailsModal(false);
            setAdminUserData({});
          }}
          modalTitle={`${selectedAgency} - Users`}
          modalContent={
            <UserDetails
              data={adminUserData}
              setData={setAdminUserData}
              reInviteUser={reInviteUser}
            />
          }
          positive={
            <>
              <Button
                bg="main.semiPrimary"
                color="main.white"
                _hover={{ bg: 'main.primary' }}
                mr={3}
                _active={{
                  bg: 'main.semiPrimary',
                  borderColor: 'main.primary'
                }}
                onClick={() => {
                  addAdminInExistingCompany();
                }}>
                Add
              </Button>
              <Button
                bg="main.semiPrimary"
                color="main.white"
                _hover={{ bg: 'main.primary' }}
                _active={{
                  bg: 'main.semiPrimary',
                  borderColor: 'main.primary'
                }}
                onClick={() => {
                  setAdminUserDetailsModal(false);
                  setAdminUserData({});
                }}>
                Ok
              </Button>
            </>
          }
        />
        <ServerSideDatatable
          pageSize={5}
          initialSortKey="agency_name"
          column={column}
          onPaginate={getAgency}
          afterPaginateData={() => setIsPageLoader(false)}
          pagination
          pages={[5, 10, 15]}
          dataKey="agency_list"
          refresh={reload}
        />
      </Card>
    </StyledAgencyContainer>
  );
};

Agency.propTypes = {};

export default Agency;
