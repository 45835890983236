/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Box } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import InputComponent from '../Input';
import StyledInputWrapper from './Forms.styled';
import { allowOnlyAlphabate } from '../../utils/helper';
import constants, { USER_TYPE, CLIENT_USER_TYPE, AGENCY_USER_TYPE } from '../../constants';
import MobileInput from '../MobileInput';
import SiteDropdown from './SiteDropdown';
import RegionDropdown from './RegionDropdown';
import ClientUserTypeDropdown from './ClientUserTypeDropdown';

const AddClientUser = function ({
  data,
  setData,
  validations,
  refresh,
  isEdit,
  userTypeData,
  regionData,
  siteData
}) {
  const { user_details: userDetails } = useSelector((state) => state.authentication);
  const [region, setRegion] = useState(regionData);
  const [site, setSite] = useState(siteData);
  const [userType, setUserType] = useState(userTypeData);

  useEffect(() => {
    if (userType.value === 4 && userDetails.user_type_id === USER_TYPE.AGENCY_ADMIN) {
      setData({
        ...data,
        client_role: 8
      });
    } else if (userType.value === 5 && userDetails.user_type_id === USER_TYPE.AGENCY_ADMIN) {
      setData({
        ...data,
        client_role: 7
      });
    } else if (userType.value === 4 && userDetails.user_type_id === USER_TYPE.CLIENT_ADMIN) {
      setData({
        ...data,
        client_role: 4
      });
    } else if (userType.value === 5 && userDetails.user_type_id === USER_TYPE.CLIENT_ADMIN) {
      setData({
        ...data,
        client_role: 5
      });
    } else if (userType.value === 9 && userDetails.user_type_id === USER_TYPE.CLIENT_ADMIN) {
      setData({
        ...data,
        client_role: 9
      });
    }
  }, [userType]);

  const selectUserType = (e) => {
    setUserType(e);
  };

  const selectRegion = (e) => {
    setRegion(e);
    setData({ ...data, id: e.value });
  };

  const selectSite = (e) => {
    setSite(e);
    setData({ ...data, id: e.value });
  };

  return (
    <Box p="10px">
      <StyledInputWrapper>
        <InputComponent
          isRequired
          lable={constants.NAME}
          placeholder={constants.NAME}
          onChange={(e) => setData({ ...data, name: allowOnlyAlphabate(e) })}
          value={data.name}
          validationObj={validations.name}
          refresh={refresh}
        />
      </StyledInputWrapper>
      <StyledInputWrapper>
        <MobileInput data={data} setData={setData} validationObj={validations} refresh={refresh} />
      </StyledInputWrapper>
      <StyledInputWrapper>
        <InputComponent
          isRequired
          type="email"
          lable={constants.EMAIL}
          placeholder={constants.EMAIL}
          onChange={(e) => {
            setData({ ...data, email: e });
          }}
          value={data.email}
          validationObj={validations.email}
          refresh={refresh}
          disabled={isEdit}
        />
      </StyledInputWrapper>
      <StyledInputWrapper>
        {USER_TYPE.CLIENT_ADMIN === userDetails.user_type_id ? (
          <ClientUserTypeDropdown
            userType={userType}
            setUserType={selectUserType}
            disabled={false}
            multiple={false}
            validationObj={validations.client_role}
            refresh={refresh}
            userTypesData={CLIENT_USER_TYPE}
          />
        ) : (
          <ClientUserTypeDropdown
            userType={userType}
            setUserType={selectUserType}
            disabled={false}
            multiple={false}
            validationObj={validations.client_role}
            refresh={refresh}
            userTypesData={AGENCY_USER_TYPE}
          />
        )}
      </StyledInputWrapper>
      {userType &&
        (userType.value === USER_TYPE.REGION_ADMIN ||
          userType.value === USER_TYPE.AGENCY_REGION_ADMIN) && (
          <StyledInputWrapper>
            <RegionDropdown
              region={region}
              setRegion={selectRegion}
              disabled={false}
              multiple={false}
              validationObj={validations.id}
              refresh={refresh}
            />
          </StyledInputWrapper>
        )}
      {userType &&
        (userType.value === USER_TYPE.SITE_ADMIN ||
          userType.value === USER_TYPE.AGENCY_SITE_ADMIN) && (
          <StyledInputWrapper>
            <SiteDropdown
              site={site}
              setSite={selectSite}
              disabled={false}
              multiple={false}
              validationObj={validations.id}
              refresh={refresh}
            />
          </StyledInputWrapper>
        )}
    </Box>
  );
};

export default AddClientUser;
