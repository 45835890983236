/* eslint-disable default-param-last */
import { API_ENDPOINT, REDUX_SELECTOR, USER_TYPE } from '../../constants';

import axiosInstance from '../../config/axios.config';
import { prepareErrorResponse, prepareSuccessReponse } from '../../utils/responseHandler';

export const getAgency = async (limit = 1, page = 1, sortKey, sortType) => {
  const result = await axiosInstance.get(
    `${API_ENDPOINT.GET_AGENCY}?page=${page}&limit=${limit}&sort_by=${sortKey}&sort_type=${sortType}`
  );
  // const result = await axiosInstance.get(`${API_ENDPOINT.GET_AGENCY}?page=${page}&limit=${limit}`);
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

export const addAgency = async (payload) => {
  const result = await axiosInstance.post(API_ENDPOINT.ADD_AGENCY, payload);
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

export const updateAgency = async (id, payload) => {
  const result = await axiosInstance.put(`${API_ENDPOINT.ADD_AGENCY}/${id}`, payload);
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

export const getAgencyAdminUser = async (id) => {
  const result = await axiosInstance.get(
    `${API_ENDPOINT.GET_AGENCY_USER}?user_type=${USER_TYPE.AGENCY_ADMIN}&id=${id}`
  );
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

export const getAgencyData = (id) => async (dispatch) => {
  const result = await axiosInstance.get(`${API_ENDPOINT.GET_AGENCY}/${id}`);
  if (result && result.ok) {
    dispatch({
      type: REDUX_SELECTOR.PROFILE_DETAIL,
      payload: result
    });
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

export const getAgencyUsers = async (clientId, agencyId) => {
  const result = await axiosInstance.get(
    `${API_ENDPOINT.GET_AGENCY_USERS}?client_id=${clientId}${
      agencyId ? `&agency_id=${agencyId}` : ''
    }`
  );
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

export const addAgencyUser = async (payload) => {
  const result = await axiosInstance.post(API_ENDPOINT.ADD_AGENCY_USERS, payload);
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

export const updateAgencyUser = async (id, payload) => {
  const result = await axiosInstance.put(`${API_ENDPOINT.UPDATE_AGENCY_USERS}/${id}`, payload);
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};
