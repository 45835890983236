/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';
import { Text } from '@chakra-ui/react';
import Dropdown from '../Dropdown';
// const countryList = require('country-list');

const NationalityDropdown = function ({
  label,
  country,
  setCountry,
  validationObj,
  refresh,
  dropdownData
}) {
  const [errorMsg, setErrorMsg] = useState(null);
  const [isValid, setIsValid] = useState(validationObj?.isvalid || true);

  //   useEffect(() => {
  //     setCountries(countryList.getData());
  //   }, []);

  useEffect(() => {
    if (validationObj && !validationObj?.isvalid) {
      setErrorMsg(validationObj?.errorMessage);
      setIsValid(validationObj?.isvalid);
    }
  }, [refresh]);

  const setCountryData = (e) => {
    setErrorMsg(null);
    setCountry(e);
    setIsValid(true);
  };

  return (
    <>
      <Dropdown
        label={`${label}${validationObj?.required ? '*' : ''}`}
        placeholder="Select"
        options={dropdownData.map((item) => {
          return { label: item.name, value: item.name };
        })}
        value={country}
        onSelect={(e) => {
          setCountryData(e);
        }}
        disabled={false}
        isError={!isValid}
      />
      <Text visibility={isValid ? 'hidden' : 'visible'} color="main.error" mb="2px" fontSize="11px">
        {errorMsg || 'Error!'}
      </Text>
    </>
  );
};

export default NationalityDropdown;
