/* eslint-disable react/prop-types */
import React from 'react';

import { StyledFileUploadWrapper, StyledImageWrapper } from './ProfileImageUploader.styled';

const ProfileImageUploader = function ({ src, onChange, width, altLabel = 'ProfilePicture' }) {
  return (
    <StyledFileUploadWrapper>
      <StyledImageWrapper width={width || 80}>
        <img
          alt={altLabel}
          htmlFor="photo-upload"
          src={src}
          style={{ width: '100%', height: '100%', objectFit: 'cover' }}
        />
      </StyledImageWrapper>
      <input
        id="photo-upload-input"
        type="file"
        onChange={onChange}
        accept="image/png, image/gif, image/jpeg"
      />
    </StyledFileUploadWrapper>
  );
};

export default ProfileImageUploader;
