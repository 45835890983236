/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Button, color, Flex } from '@chakra-ui/react';
import DatePicker from 'react-multi-date-picker';
import moment from 'moment';
import constants, { DATE_SAVE_TO_DB, DATE_TO_SHOW, USER_TYPE } from '../../constants';
import InputComponent from '../Input';
import StyledInputWrapper from './Forms.styled';
import { allowAlphaNumeric, currentData, replaceEmojiInInputField } from '../../utils/helper';
import JobDropdown from './JobDropdown';
import SiteDropdown from './SiteDropdown';
import NationalityDropdown from './NationalityDropdown';
import GenderDropdown from './GenderDropdown';
import SelectionDropdown from './SelectionDropdown';
import NationalityDropDownData from '../../utils/nationalityData.json';
import SupervisorStatusDropdown from './SupervisorStatusDropdown';

const AddWorker = function ({
  data,
  setData,
  refresh,
  validations,
  roleTypeValue,
  CompanyValue,
  agencyValue,
  genderValue,
  countryValue,
  userType,
  edit,
  passportFile,
  utilityBillFile,
  identityCardFile,
  // rightToWorkFile,
  drivingLicenseFile,
  disableSupervisorStatusDropdown
}) {
  const dobDateRef = useRef();
  const startDateRef = useRef();
  const assignmentDateRef = useRef();
  const [startDate, setStartDate] = useState();
  const [assignmentDate, setAssignmentDate] = useState();
  const [dob, setDob] = useState({});
  const [isValidStartDate, setIsValidStartDate] = useState(true);
  const [isValidAssignmentDate, setIsValidAssignmentDate] = useState(true);
  const [isValidDob, setIsValidDob] = useState(true);
  const { selected_site_id } = useSelector((state) => state.agency);
  const [roleType, setRoleType] = useState({});
  const [site, setSite] = useState({});
  const { user_details: userDetails } = useSelector((state) => state.authentication);

  const setRoleTypeData = (e) => {
    setRoleType(e);
    setData({ ...data, job_name: e.label, job_id: e.value });
  };

  const setSiteData = (e) => {
    setSite(e);
    setData({ ...data, site_id: e.value, site_name: e.label, job_name: '', job_id: '' });
    setRoleType({});
  };

  const [company, setCompany] = useState(CompanyValue);
  const setCompanyData = (e) => {
    setCompany(e);
    setData({ ...data, client_id: e.value });
  };

  const [agency, setAgency] = useState(agencyValue);
  const setAgencyData = (e) => {
    setAgency(e);
    setData({ ...data, agency_id: e.value });
  };

  const [country, setCountry] = useState({});
  const setCountryData = (e) => {
    setCountry(e);
    setData({ ...data, nationality: e.value });
  };

  const [gender, setGender] = useState({});
  const setGenderData = (e) => {
    setGender(e);
    setData({ ...data, orientation: e.value });
  };

  const [transport, setTrasport] = useState(
    edit
      ? { label: data.transport ? 'YES' : 'NO', value: data.transport }
      : { label: 'NO', value: 'NO' }
  );

  const [other_assignment, setOtherAssignment] = useState(
    edit
      ? { label: data.other_assignment ? 'YES' : 'NO', value: data.other_assignment }
      : { label: 'NO', value: 'NO' }
  );

  const [pension_opt_out, setPension] = useState(
    edit
      ? { label: data.pension_opt_out ? 'YES' : 'NO', value: data.pension_opt_out }
      : { label: 'YES', value: 'YES' }
  );

  const [student_visa, setStudentVisa] = useState(
    edit
      ? { label: data.student_visa ? 'YES' : 'NO', value: data.student_visa }
      : { label: 'NO', value: 'NO' }
  );
  const [supervisorStatus, setSupervisorStatus] = useState(() => {
    if (edit) {
      if (data.workers_supervisor_status === 'FRONT_OFFICE') {
        return { label: 'Front Office', value: 'FRONT_OFFICE' };
        // eslint-disable-next-line no-else-return
      } else if (data.workers_supervisor_status === 'BACK_OFFICE') {
        return { label: 'Back Office', value: 'BACK_OFFICE' };
      }
      // If not FRONT_OFFICE or BACK_OFFICE
      return { label: 'None', value: null };
    }
    // If not in edit mode
    return { label: 'None', value: null };
  });

  const setTrasportData = (e) => {
    setTrasport(e);
    setData({ ...data, transport: e.value === 'YES' });
  };

  const setOtherAssignmentData = (e) => {
    setOtherAssignment(e);
    setData({ ...data, other_assignment: e.value === 'YES' });
  };

  const setPensionData = (e) => {
    setPension(e);
    setData({ ...data, pension_opt_out: e.value === 'YES' });
  };

  const setStudentVisaData = (e) => {
    setStudentVisa(e);
    setData({ ...data, student_visa: e.value === 'YES' });
  };
  const handleSupervisorStatusChange = (status) => {
    setSupervisorStatus(status);
    setData((prev) => {
      return { ...prev, workers_supervisor_status: status.value };
    });
    //  setData({ ...data, workers_supervisor_status: status.value });
  };
  const setHouseNumberData = (e) => {
    const houseNumber = replaceEmojiInInputField(e);
    setData({ ...data, house_number: houseNumber });
  };

  const handelDateChange = (event, field) => {
    let selectedDate = '';
    if (event) {
      try {
        selectedDate = moment(`${event.day}/${event.month}/${event.year}`, 'DD/MM/YYYY').format(
          'DD/MM/YYYY'
        );
      } catch (error) {
        selectedDate = '';
      }
    }
    switch (field) {
      case 'startDate':
        setStartDate(event);
        if (event) {
          setIsValidStartDate(true);
          startDateRef.current.closeCalendar();
          const startDateToSave = DATE_SAVE_TO_DB(selectedDate);
          setData({ ...data, start_date: startDateToSave });
        } else {
          const startDateToSave = DATE_SAVE_TO_DB(selectedDate);
          setData({ ...data, start_date: startDateToSave });
        }
        break;
      case 'assignmentDate':
        setAssignmentDate(event);
        if (event) {
          setIsValidAssignmentDate(true);
          assignmentDateRef.current.closeCalendar();
          const assignmentDateToSave = DATE_SAVE_TO_DB(selectedDate);
          setData({ ...data, assignment_date: assignmentDateToSave });
        } else {
          const assignmentDateToSave = DATE_SAVE_TO_DB(selectedDate);
          setData({ ...data, assignment_date: assignmentDateToSave });
        }
        break;
      case 'dob':
        setDob(event);
        if (event) {
          setIsValidDob(true);
          dobDateRef.current.closeCalendar();
          const dobToSave = DATE_SAVE_TO_DB(selectedDate);
          setData({ ...data, date_of_birth: dobToSave });
        } else {
          const dobToSave = DATE_SAVE_TO_DB(selectedDate);
          setData({ ...data, date_of_birth: dobToSave });
        }
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    setIsValidDob(validations.date_of_birth.isvalid);
  }, [validations.date_of_birth.isvalid]);

  useEffect(() => {
    setIsValidStartDate(validations.start_date.isvalid);
  }, [validations.start_date.isvalid]);

  useEffect(() => {
    setIsValidAssignmentDate(validations.assignment_date.isvalid);
  }, [validations.assignment_date.isvalid]);

  useEffect(() => {
    if (data) {
      setSite({ label: data.site_name, value: data.site_id });
      setRoleType({ label: data.job_name, value: data.job_id });
      setCountry({ label: data.nationality, value: data.nationalilty });
      setGender({ label: data.orientation, value: data.orientation });
      const dobToShow = data.date_of_birth ? DATE_TO_SHOW(data.date_of_birth) : '';
      setDob(dobToShow);
      const startDateToShow = data.start_date ? DATE_TO_SHOW(data.start_date) : '';
      const startAssignmentDateToShow = data.assignment_date
        ? DATE_TO_SHOW(data.assignment_date)
        : '';
      setStartDate(startDateToShow);
      setAssignmentDate(startAssignmentDateToShow);
    }
  }, [data]);

  return (
    <Box p="10px">
      <Flex>
        <StyledInputWrapper style={{ marginRight: '8px' }}>
          <InputComponent
            lable={constants.FIRST_NAME}
            placeholder={constants.FIRST_NAME}
            onChange={(e) => setData({ ...data, first_name: allowAlphaNumeric(e) })}
            value={data.first_name}
            validationObj={validations.first_name}
            refresh={refresh}
            maxLength="50"
          />
        </StyledInputWrapper>
        <StyledInputWrapper>
          <InputComponent
            lable={constants.LAST_NAME}
            placeholder={constants.LAST_NAME}
            onChange={(e) => setData({ ...data, last_name: allowAlphaNumeric(e) })}
            value={data.last_name}
            validationObj={validations.last_name}
            refresh={refresh}
            maxLength="50"
          />
        </StyledInputWrapper>
      </Flex>
      <Flex>
        <StyledInputWrapper style={{ marginRight: '8px' }}>
          <p
            className={`input-label ${
              !isValidDob ? 'input-label-error' : ''
            }`}>{`${constants.DOB}*`}</p>
          <DatePicker
            format="DD/MM/YYYY"
            inputClass={`date-picker ${!isValidDob ? 'input-error' : ''}`}
            onChange={(event) => handelDateChange(event, 'dob')}
            value={dob}
            editable={false}
            ref={dobDateRef}
            maxDate={new Date()}
            className="booking-datepicker">
            <Button
              bg="main.semiPrimary"
              color="main.white"
              _hover={{ bg: 'main.primary' }}
              _active={{
                bg: 'main.semiPrimary',
                borderColor: 'main.primary'
              }}
              onClick={() => dobDateRef.current.closeCalendar()}
              size="xs"
              mb={4}>
              {constants.WORKER.CANCEL_BUTTON_TEXT}
            </Button>
          </DatePicker>
          <p className="input-error-msg" style={{ visibility: isValidDob ? 'hidden' : 'visible' }}>
            {validations.date_of_birth.errorMessage}
          </p>
        </StyledInputWrapper>
        <StyledInputWrapper style={{ marginRight: '8px' }}>
          <InputComponent
            lable={constants.PAYROLL_REF}
            placeholder={constants.PAYROLL_REF}
            onChange={(e) => setData({ ...data, payroll_ref: e })}
            value={data.payroll_ref}
            validationObj={validations.payroll_ref}
            refresh={refresh}
            maxLength="50"
          />
        </StyledInputWrapper>
      </Flex>
      <Flex>
        <StyledInputWrapper style={{ marginRight: '8px' }}>
          <InputComponent
            lable={constants.NATIONAL_INSURANCE_NUMBER}
            placeholder={constants.NATIONAL_INSURANCE_NUMBER}
            onChange={(e) => setData({ ...data, national_insurance_number: e })}
            value={data.national_insurance_number}
            validationObj={validations.national_insurance_number}
            refresh={refresh}
            maxLength="50"
            // disabled={edit && true}
          />
        </StyledInputWrapper>
        <StyledInputWrapper>
          <InputComponent
            lable={constants.EMPLOYEE_ID}
            placeholder={constants.EMPLOYEE_ID}
            onChange={(e) => setData({ ...data, employee_id: e })}
            value={data.employee_id}
            validationObj={validations.employee_id}
            refresh={refresh}
            maxLength="50"
          />
        </StyledInputWrapper>
      </Flex>
      <Flex>
        <StyledInputWrapper style={{ marginRight: '8px' }}>
          <p
            className={`input-label ${
              !isValidStartDate ? 'input-label-error' : ''
            }`}>{`${constants.START_DATE}*`}</p>
          <DatePicker
            format="DD/MM/YYYY"
            inputClass={`date-picker ${!isValidStartDate ? 'input-error' : ''}`}
            onChange={(event) => handelDateChange(event, 'startDate')}
            value={startDate}
            ref={startDateRef}
            // maxDate={new Date()}
            editable={false}
            className="booking-datepicker">
            <Button
              bg="main.semiPrimary"
              color="main.white"
              _hover={{ bg: 'main.primary' }}
              _active={{
                bg: 'main.semiPrimary',
                borderColor: 'main.primary'
              }}
              onClick={() => startDateRef.current.closeCalendar()}
              size="xs"
              mb={4}>
              {constants.WORKER.CANCEL_BUTTON_TEXT}
            </Button>
          </DatePicker>
          <p
            className="input-error-msg"
            style={{ visibility: isValidStartDate ? 'hidden' : 'visible' }}>
            {validations.start_date.errorMessage}
          </p>
        </StyledInputWrapper>
        <StyledInputWrapper style={{ marginRight: '8px' }}>
          <p
            className={`input-label ${
              !isValidAssignmentDate ? 'input-label-error' : ''
            }`}>{`${constants.ASSIGNMENT_DATE}*`}</p>
          <DatePicker
            format="DD/MM/YYYY"
            inputClass={`date-picker ${!isValidAssignmentDate ? 'input-error' : ''}`}
            onChange={(event) => handelDateChange(event, 'assignmentDate')}
            value={assignmentDate}
            ref={assignmentDateRef}
            // maxDate={new Date()}
            editable={false}
            className="booking-datepicker">
            <Button
              bg="main.semiPrimary"
              color="main.white"
              _hover={{ bg: 'main.primary' }}
              _active={{
                bg: 'main.semiPrimary',
                borderColor: 'main.primary'
              }}
              onClick={() => assignmentDateRef.current.closeCalendar()}
              size="xs"
              mb={4}>
              {constants.WORKER.CANCEL_BUTTON_TEXT}
            </Button>
          </DatePicker>
          <p
            className="input-error-msg"
            style={{ visibility: isValidAssignmentDate ? 'hidden' : 'visible' }}>
            {validations.assignment_date.errorMessage}
          </p>
        </StyledInputWrapper>
      </Flex>
      <Flex>
        <StyledInputWrapper style={{ marginRight: '8px' }}>
          <InputComponent
            lable={constants.POST_CODE}
            placeholder={constants.POST_CODE}
            onChange={(e) => setData({ ...data, post_code: e })}
            value={data.post_code}
            validationObj={validations.post_code}
            refresh={refresh}
            maxLength="20"
          />
        </StyledInputWrapper>
        <StyledInputWrapper>
          <GenderDropdown
            validationObj={validations.orientation}
            refresh={refresh}
            gender={gender}
            setGender={setGenderData}
          />
        </StyledInputWrapper>
      </Flex>
      <Flex>
        <StyledInputWrapper style={{ marginRight: '8px' }}>
          <NationalityDropdown
            label="Nationality"
            refresh={refresh}
            country={country}
            setCountry={setCountryData}
            validationObj={validations.nationality}
            dropdownData={NationalityDropDownData}
          />
        </StyledInputWrapper>
        {(userDetails.user_type_id === USER_TYPE.AGENCY_ADMIN ||
          userDetails.user_type_id === USER_TYPE.AGENCY_REGION_ADMIN ||
          userDetails.user_type_id === USER_TYPE.AGENCY_SITE_ADMIN) && (
          <StyledInputWrapper style={{ marginRight: '8px' }}>
            <SelectionDropdown
              validationObj={validations.other_assignment}
              lable={constants.OTHER_ASSIGNMENT}
              refresh={refresh}
              Dropdownvalue={other_assignment}
              setDropdownvalue={setOtherAssignmentData}
            />
          </StyledInputWrapper>
        )}
      </Flex>
      <Flex>
        {(userDetails.user_type_id === USER_TYPE.AGENCY_ADMIN ||
          userDetails.user_type_id === USER_TYPE.AGENCY_REGION_ADMIN ||
          userDetails.user_type_id === USER_TYPE.AGENCY_SITE_ADMIN) && (
          <StyledInputWrapper style={{ marginRight: '8px' }}>
            <SelectionDropdown
              validationObj={validations.transport}
              lable={constants.TRANSPORT}
              refresh={refresh}
              Dropdownvalue={transport}
              setDropdownvalue={setTrasportData}
            />
          </StyledInputWrapper>
        )}
        {(userDetails.user_type_id === USER_TYPE.AGENCY_ADMIN ||
          userDetails.user_type_id === USER_TYPE.AGENCY_REGION_ADMIN ||
          userDetails.user_type_id === USER_TYPE.AGENCY_SITE_ADMIN) && (
          <StyledInputWrapper>
            <SelectionDropdown
              lable={constants.PENSION_OPT_OUT_STATUS}
              validationObj={validations.pension}
              refresh={refresh}
              Dropdownvalue={pension_opt_out}
              setDropdownvalue={setPensionData}
            />
          </StyledInputWrapper>
        )}
      </Flex>
      <Flex>
        <StyledInputWrapper style={{ marginRight: '8px' }}>
          <InputComponent
            lable={constants.HOUSE_NUMBER}
            placeholder={constants.HOUSE_NUMBER}
            onChange={setHouseNumberData}
            value={data.house_number}
            validationObj={validations.house_number}
            refresh={refresh}
            maxLength="50"
          />
        </StyledInputWrapper>
        <StyledInputWrapper>
          <SelectionDropdown
            lable={constants.STUDENT_VISA}
            validationObj={validations.student_visa}
            refresh={refresh}
            Dropdownvalue={student_visa}
            setDropdownvalue={setStudentVisaData}
          />
        </StyledInputWrapper>
      </Flex>
      <Flex>
        <StyledInputWrapper>
          <InputComponent
            lable={constants.EMAIL}
            placeholder={constants.EMAIL}
            onChange={(e) => setData({ ...data, email: e })}
            value={data.email}
            validationObj={validations.email}
            refresh={refresh}
            maxLength="50"
            // disabled={edit && true}
          />
        </StyledInputWrapper>
      </Flex>
      <Flex>
        <StyledInputWrapper>
          {(userType === 'client_admin' ||
            edit ||
            userDetails.user_type_id === USER_TYPE.MESSAGE_ADMIN) && (
            <SiteDropdown
              site={site}
              setSite={setSiteData}
              multiple={false}
              validationObj={validations.site_id}
              refresh={refresh}
            />
          )}
        </StyledInputWrapper>
      </Flex>
      <Flex>
        <StyledInputWrapper>
          {userType === 'client_admin' ||
          edit ||
          userDetails.user_type_id === USER_TYPE.MESSAGE_ADMIN ? (
            site.value && (
              <JobDropdown
                roleType={roleType}
                setRoleType={setRoleTypeData}
                disabled={false}
                multiple={false}
                validationObj={validations.job_id}
                refresh={refresh}
                siteId={site?.value}
              />
            )
          ) : (
            <JobDropdown
              roleType={roleType}
              setRoleType={setRoleTypeData}
              disabled={false}
              multiple={false}
              validationObj={validations.job_id}
              refresh={refresh}
              siteId={selected_site_id || site?.value}
            />
          )}
        </StyledInputWrapper>
      </Flex>
      {(userDetails.user_type_id === USER_TYPE.AGENCY_ADMIN ||
        userDetails.user_type_id === USER_TYPE.AGENCY_REGION_ADMIN ||
        userDetails.user_type_id === USER_TYPE.AGENCY_SITE_ADMIN) && (
        <Flex>
          <StyledInputWrapper>
            <SupervisorStatusDropdown
              label={constants.WORKERS_SUPERVISOR_STATUS}
              validationObj={validations.workers_supervisor_status}
              refresh={refresh}
              supervisorStatusValue={supervisorStatus}
              setSupervisorStatusData={handleSupervisorStatusChange}
              supervisorStatusList={[
                { label: 'Front Office', value: 'FRONT_OFFICE' },
                { label: 'Back Office', value: 'BACK_OFFICE' },
                { label: 'None', value: null }
              ]}
              disabled={disableSupervisorStatusDropdown}
              edit={edit}
            />
          </StyledInputWrapper>
        </Flex>
      )}

      {edit ? (
        <>
          <Box color="rgb(66 107 157)" fontSize="lg">
            Right To Work Documents:
          </Box>
          <Flex>
            <StyledInputWrapper>
              <label
                htmlFor="passport"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  margin: '20px 20px 20px 0px'
                }}>
                Passport:
                {data?.documents?.passport ? (
                  <Flex alignItems="center">
                    <input
                      id="passport"
                      type="file"
                      onChange={(e) => {
                        passportFile(e);
                      }}
                      title="Add Passport"
                      style={{ width: '222px' }}
                    />
                    <a href={data.documents.passport} target="_blank" rel="noreferrer" download>
                      <Button
                        alignSelf="center"
                        backgroundColor="rgb(66 107 157)"
                        color="white"
                        _hover={{ backgroundColor: '#124684' }}>
                        Download
                      </Button>
                    </a>
                  </Flex>
                ) : (
                  <input
                    id="passport"
                    type="file"
                    onChange={(e) => {
                      passportFile(e);
                    }}
                    title="Add Passport"
                    style={{ alignSelf: 'flex-end', width: '331px' }}
                  />
                )}
              </label>
              <label
                htmlFor="utility-bill"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  margin: '20px 20px 20px 0px'
                }}>
                Utility Bill:
                {data?.documents?.utility_bill ? (
                  <Flex alignItems="center">
                    <input
                      id="utility-bill"
                      type="file"
                      onChange={utilityBillFile}
                      title="Add Utility Bill"
                      style={{ width: '222px' }}
                    />
                    <a href={data.documents.utility_bill} target="_blank" rel="noreferrer" download>
                      <Button
                        alignSelf="center"
                        backgroundColor="rgb(66 107 157)"
                        color="white"
                        _hover={{ backgroundColor: '#124684' }}>
                        Download
                      </Button>
                    </a>
                  </Flex>
                ) : (
                  <input
                    id="utility-bill"
                    type="file"
                    onChange={utilityBillFile}
                    title="Add Utility Bill"
                    style={{ alignSelf: 'flex-end', width: '331px' }}
                  />
                )}
              </label>
              <label
                htmlFor="identity-card"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  margin: '20px 20px 20px 0px'
                }}>
                Identity Card:
                {data?.documents?.identity_card ? (
                  <Flex alignItems="center">
                    <input
                      id="identity-card"
                      type="file"
                      onChange={identityCardFile}
                      title="Add Identity Card"
                      style={{ width: '222px' }}
                    />
                    <a
                      href={data.documents.identity_card}
                      rel="noreferrer"
                      target="_blank"
                      download>
                      <Button
                        alignSelf="center"
                        backgroundColor="rgb(66 107 157)"
                        color="white"
                        _hover={{ backgroundColor: '#124684' }}>
                        Download
                      </Button>
                    </a>
                  </Flex>
                ) : (
                  <input
                    id="identity-card"
                    type="file"
                    onChange={identityCardFile}
                    title="Add Identity Card"
                    style={{ alignSelf: 'flex-end', width: '331px' }}
                  />
                )}
              </label>
              <label
                htmlFor="driving-license"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  margin: '20px 20px 20px 0px'
                }}>
                Driving License:
                {data?.documents?.driving_license ? (
                  <Flex alignItems="center">
                    <input
                      id="driving-license"
                      type="file"
                      onChange={drivingLicenseFile}
                      title="Add Driving License"
                      style={{ width: '222px' }}
                    />
                    <a
                      href={data.documents.driving_license}
                      rel="noreferrer"
                      target="_blank"
                      download>
                      <Button
                        alignSelf="center"
                        backgroundColor="rgb(66 107 157)"
                        color="white"
                        _hover={{ backgroundColor: '#124684' }}>
                        Download
                      </Button>
                    </a>
                  </Flex>
                ) : (
                  <input
                    id="driving-license"
                    type="file"
                    onChange={drivingLicenseFile}
                    title="Add Driving License"
                    style={{ alignSelf: 'flex-end', width: '331px' }}
                  />
                )}
              </label>
            </StyledInputWrapper>
          </Flex>
        </>
      ) : null}
    </Box>
  );
};

export default AddWorker;
