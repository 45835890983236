/* eslint-disable react/prop-types */
/* eslint-disable  no-unneeded-ternary */
/* eslint-disable  no-shadow */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import { Box, Button, Switch, Text, useToast } from '@chakra-ui/react';
import constants, { CUSTOM_TOAST_DURATION, TOAST_SETTINGS } from '../../constants';
import { StyledInfoBlock, StyledInfoLabel, StyledInfoValue, StyledRow } from './Forms.styled';
import { StyledDivider } from '../Modal/Modal.styled';
import { InActiveUser } from '../../redux/action/user.action';

const UserDetails = function ({ data, reInviteUser }) {
  const [isActiveObj, setIsActiveObj] = useState([]);
  const toast = useToast();
  useEffect(() => {
    if (data && data.length > 0) {
      data.map((data) => {
        setIsActiveObj((prev) => [...prev, { id: data.user_id, is_active: data?.is_active }]);
      });
    }
  }, [data]);
  const InActiveUserData = async (userId, payload) => {
    const result = await InActiveUser(userId, payload);
    if (result && result.ok) {
      toast({
        ...TOAST_SETTINGS.SETTINGS,
        duration: CUSTOM_TOAST_DURATION.duration,
        status: TOAST_SETTINGS.SUCCESS,
        title: result.message
      });
    } else {
      toast({
        ...TOAST_SETTINGS.SETTINGS,
        status: TOAST_SETTINGS.ERROR,
        title: result.error
      });
    }
    // setReload(!reload);
  };
  const onToggleChanged = (e, d, index) => {
    // let objIndex = data.findIndex((newdata) => newdata.user_id === d.user_id);
    // let data = isActiveObj;
    // data[index].is_active = e.target.checked ? 1 : 0;
    // let newData = isActiveObj;
    // newData.splice(index, 1);
    // console.log(data);
    // setIsActiveObj([]);
    // setTimeout(() => setIsActiveObj(data));
    InActiveUserData(isActiveObj[index].id, { is_active: e.target.checked ? true : false });
    const newdata = [...isActiveObj];
    newdata[index].is_active = e.target.checked ? 1 : 0;
    setIsActiveObj(newdata);
  };

  return (
    <Box>
      {data && data.length > 0
        ? data.map((d, index) => {
            return (
              <>
                <Box key={d.user_id} id={`box-${index}`}>
                  <StyledRow>
                    <StyledInfoBlock>
                      <StyledInfoLabel>Name</StyledInfoLabel>
                      <StyledInfoValue>{d.user_name}</StyledInfoValue>
                    </StyledInfoBlock>
                    <StyledInfoBlock>
                      <StyledInfoLabel>Email</StyledInfoLabel>
                      <StyledInfoValue>{d.email}</StyledInfoValue>
                    </StyledInfoBlock>
                  </StyledRow>
                  <StyledRow>
                    <StyledInfoBlock>
                      <StyledInfoLabel>Mobile</StyledInfoLabel>
                      <StyledInfoValue>
                        {d.country_code} {d.mobile}
                      </StyledInfoValue>
                    </StyledInfoBlock>
                    <StyledInfoBlock>
                      <StyledInfoLabel>Action</StyledInfoLabel>
                      {/* <StyledRow> */}
                      <Text
                        id={`details-${index}`}
                        color="main.primary"
                        fontSize="15px"
                        w="130px"
                        m="5px "
                        ml="0px"
                        fontWeight="600">
                        {isActiveObj[index]?.is_active ? 'Active' : 'InActive'}
                        <Switch
                          id={`details-switch-${index}`}
                          float="right"
                          isChecked={isActiveObj[index]?.is_active ? true : false}
                          onChange={(e) => onToggleChanged(e, d, index)}
                        />
                      </Text>
                      <Button
                        bg="main.semiPrimary"
                        color="main.white"
                        _hover={{ bg: 'main.primary' }}
                        _active={{
                          bg: 'main.semiPrimary',
                          borderColor: 'main.primary'
                        }}
                        onClick={() => reInviteUser(d.user_id)}>
                        {constants.CLIENT.RE_INVITE}
                      </Button>
                      {/* </StyledRow> */}
                    </StyledInfoBlock>
                    {/* ) : (
                      <StyledInfoBlock></StyledInfoBlock>
                    )} */}
                  </StyledRow>
                </Box>
                {data.length > 1 && index !== data.length - 1 && (
                  <>
                    &nbsp; <StyledDivider /> &nbsp;
                  </>
                )}
              </>
            );
          })
        : 'No user found'}
    </Box>
  );
};

export default UserDetails;
