import React from 'react';

const TrendIcon = function () {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      version="1"
      viewBox="0 0 225 225">
      <path
        fill="currentColor"
        d="M1510 1683c0-4 46-54 102-110l103-103-228-228-227-227-188 188-187 187-348-348-347-347 65-65 65-65 280 280 280 280 188-188 187-187 295 295 295 295 107-107 108-108v565h-275c-151 0-275-3-275-7z"
        transform="matrix(.1 0 0 -.1 0 225)"
      />
    </svg>
  );
};

export default TrendIcon;
