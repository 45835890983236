/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Text, useToast } from '@chakra-ui/react';
import { TOAST_SETTINGS } from '../../constants';
import Dropdown from '../Dropdown';
import { getTemplateList } from '../../redux/action/messaging.action';

const TemplateDropdown = function ({
  template,
  setTemplate,
  validationObj,
  refresh,
  disabled = false,
  messageType,
  label,
  labelFontSize
}) {
  const toast = useToast();
  const [templates, setTemplates] = useState([]);
  const [errorMsg, setErrorMsg] = useState(null);
  const [isValid, setValid] = useState(validationObj?.isvalid || true);

  const retrieveTemplates = async () => {
    const result = await getTemplateList(messageType);
    if (result && result.ok) {
      setTemplates(result.data.templates);
    } else {
      toast({
        ...TOAST_SETTINGS.SETTINGS,
        status: TOAST_SETTINGS.ERROR,
        title: result.error
      });
    }
  };

  useEffect(() => {
    if (validationObj && !validationObj.isvalid) {
      setErrorMsg(validationObj.errorMessage);
      setValid(validationObj.isvalid);
    }
  }, [refresh]);

  useEffect(() => {
    retrieveTemplates();
  }, [messageType, template]);

  const setTemplateData = (e) => {
    setErrorMsg(null);
    setValid(true);
    setTemplate(e);
  };

  return (
    <>
      <Dropdown
        label={label}
        onSelect={setTemplateData}
        options={templates.map((temp) => {
          return { label: temp.name, value: temp.id };
        })}
        value={template}
        placeholder={label}
        isError={!isValid}
        disabled={disabled}
        labelFontSize={labelFontSize}
      />
      <Text visibility={isValid ? 'hidden' : 'visible'} color="main.error" mb="2px" fontSize="11px">
        {errorMsg || 'Error !'}
      </Text>
    </>
  );
};

export default TemplateDropdown;
