export const prepareSuccessReponse = (result) => {
  return { ok: true, data: result, message: result.message || 'Success!' };
};

export const prepareErrorResponse = (result) => {
  let error = result;

  if (result?.message) {
    if (result.message[0]?.message) {
      error = result.message[0].message;
    } else {
      error = result.message;
    }
  }

  return {
    ok: false,
    error
  };
};
