import { Box } from '@chakra-ui/react';
import styled from 'styled-components';
import { colors } from '../../theme';

export const StyledLoginRoot = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

export const StyledLoginForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  max-width: 400px;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: ${colors.main.white};
`;

export const StyledFormBodyContainer = styled(Box)`
  width: 100%;
`;
