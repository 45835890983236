import React, { useEffect, useState } from 'react';
import { Button } from '@chakra-ui/react';
// import { PATH } from '../../constants';
// import { useQuery } from '../../utils/hooks';
import { NavLink } from 'react-router-dom';
import Card from '../../components/Card';
import ServerSideDatatable from '../../components/ServerSideDatatable';
import Loader from '../../components/Loader';
import { getAttendanceProcessData } from '../../redux/action/attendance.action';
import StyledAttendanceDetailsContainer from './ProcessedAttendance.styled';
import { PATH, PAGE_TITLE } from '../../constants';
import { currancyFormatter } from '../../utils/helper';

const ProcessedAttendance = function () {
  //   const permission = getModulePermissions(PATH.AGENCY.CODE);
  // const query = useQuery();
  useEffect(() => {
    document.title = PAGE_TITLE.PROCESSED_ATTENDANCE;
  }, []);

  const [isPageLoader, setIsPageLoader] = useState(false);
  const [id, setId] = useState(null);
  const [reload] = useState(false);
  const [column] = useState([
    {
      label: 'Worker Name',
      field: 'worker_first_name',
      sort: true,
      customRow: (cell, row) => {
        return `${cell} ${row.worker_last_name}`;
      }
    },
    {
      label: 'Agency',
      field: 'agency_name',
      sort: true
    },
    {
      label: 'Client',
      field: 'client_name',
      sort: true
    },
    {
      label: 'Holiday Dynamic',
      field: 'holiday_dynamic',
      sort: true
    },
    {
      label: 'Margin',
      field: 'margin',
      customRow: (cell) => {
        return `${currancyFormatter(cell)}`;
      },
      sort: true
    },
    {
      label: 'Pay Per Hour Static',
      field: 'pay_per_hour',
      customRow: (cell) => {
        return `${currancyFormatter(cell)}`;
      },
      sort: true
    },
    {
      label: 'Pay Per Hour Dynamic',
      field: 'pay_per_hour_dynamic',
      customRow: (cell) => {
        return `${currancyFormatter(cell)}`;
      },
      sort: true
    },
    {
      label: 'Payroll Static Total',
      field: 'payroll_static_total',
      customRow: (cell) => {
        return `${currancyFormatter(cell)}`;
      },
      sort: true
    },
    {
      label: 'Clearvue Savings',
      field: 'clearvue_savings',
      customRow: (cell) => {
        return `${currancyFormatter(cell)}`;
      },
      sort: true
    }
  ]);

  useEffect(() => {
    const url = window.location.href;
    const currentId = url.split('/')[url.split('/').length - 1];
    setId(currentId);
  }, []);

  //   const permission = getModulePermissions(PATH.TIME_AND_ATTENDANCE.CODE);
  return (
    <StyledAttendanceDetailsContainer bg="main.secondary">
      {isPageLoader ? <Loader /> : null}
      <Card>
        <NavLink to={PATH.TIME_AND_ATTENDANCE.TO}>
          <Button
            bg="main.semiPrimary"
            color="main.white"
            _hover={{ bg: 'main.primary' }}
            _active={{
              bg: 'main.semiPrimary',
              borderColor: 'main.primary'
            }}>
            Back
          </Button>
        </NavLink>
        {id && (
          <ServerSideDatatable
            pageSize={5}
            initialSortKey="worker_first_name"
            column={column}
            onPaginate={getAttendanceProcessData}
            afterPaginateData={() => setIsPageLoader(false)}
            pagination
            pages={[5, 10, 15]}
            dataKey="payroll_list"
            refresh={reload}
            id={id}
          />
        )}
      </Card>
    </StyledAttendanceDetailsContainer>
  );
};

ProcessedAttendance.propTypes = {};

export default ProcessedAttendance;
