/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable prefer-const */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable no-alert */
/* eslint-disable no-return-await */
/* eslint-disable camelcase */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-constant-condition */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/self-closing-comp */
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Image,
  SimpleGrid,
  Spinner,
  Switch,
  Text,
  Tooltip,
  useMediaQuery,
  useToast
} from '@chakra-ui/react';
// import { NavLink } from 'react-router-dom';
import Card from '../../components/Card';
import ModalComponent from '../../components/Modal';
import AddWorker from '../../components/Forms/AddWorker';
import PdfDownloadIcon from '../../icons/PdfDownloadIcon';
// import ClientSideDatatable from '../../components/ClientSideDatatable';

import constants, {
  TOAST_SETTINGS,
  ADD_WORKER_VALIDATIONS,
  PATH,
  USER_TYPE,
  PAGE_TITLE,
  DATE_TO_SHOW,
  CUSTOM_TOAST_DURATION,
  SEARCH_WORKER_VALIDATIONS,
  CUSTOM_TOAST_DURATION_NULL
} from '../../constants';
import {
  deepClone,
  formatDate,
  validateForm,
  // validatePostCode
  validateAlphaNumericPostCode,
  validateAlphaNumericPostCodeForWorker,
  filterErrorData
} from '../../utils/helper';
import {
  addWorker,
  addWorkerDocs,
  downloadFilteredDataOfWorker,
  downloadUpdateWorkerSample,
  // addWorkerDocs,
  downloadWorkerSample,
  getSearchWorkers,
  getWorkerDetails,
  getWorkers,
  updateBulkWorkers,
  updateWorker,
  uploadBulkWorker,
  uploadUpdateBulkWorker
} from '../../redux/action/worker.action';

import './worker.css';
import StyledWorkerContainer, {
  StyledAddWorkerCardHeaderContainer,
  StyledDetailLabel,
  StyledDetailValue
} from './Worker.styled';
import Loader from '../../components/Loader';
import { getModulePermissions } from '../../utils/hooks';
import AgencyDropdown from '../../components/Forms/AgencyDropdown';
import ServerSideDatatable from '../../components/ServerSideDatatable';
// import ServerSideDatatable from '../../components/ServerSideDatatable';
import AlertDialogComponent from '../../components/AlertModalComponent';
import SiteDropdown from '../../components/Forms/SiteDropdown';
import SearchWorker from '../../components/Forms/SearchWorker';
import DownloadIcon from '../../icons/DownloadIcon';
import ClearFilterIcon from '../../assets/images/Clearfilter.png';

const INITIAL_WORKER = {
  first_name: '',
  last_name: '',
  date_of_birth: '',
  national_insurance_number: '',
  employee_id: '',
  tax_code: 'Test',
  post_code: '',
  nationality: '',
  orientation: '',
  email: '',
  country_code: '+44',
  mobile: '',
  current_worker_id: null,
  start_date: '',
  department_id: '',
  agency_id: '',
  client_id: '',
  payroll_ref: '',
  roleType: '',
  passportUrl: '',
  utilityBillUrl: '',
  identityCardUrl: '',
  // rightToWorkUrl: '',
  drivingLicenseUrl: '',
  site_id: '',
  site_name: '',
  transport: false,
  pension_opt_out: true,
  assignment_date: '',
  other_assignment: false,
  student_visa: false,
  workers_supervisor_status: null
};
const INITIAL_SEARCH_WORKER = {
  employee_id: '',
  payroll_ref: '',
  national_insurance_number: '',
  email: '',
  first_name: '',
  last_name: '',
  under_twentyone: false,
  under_twentytwo: false,
  within_twelveweeks: false,
  all_workers: '',
  other_assignment: { label: '', value: '' },
  pension_opt_out: { label: '', value: '' },
  app_downloaded: { label: '', value: '' }
};

const INITIAL_COLUMNS = [
  {
    label: 'First Name',
    field: 'first_name',
    sort: true
  },
  {
    label: 'Last Name',
    field: 'last_name',
    sort: true
  },
  {
    label: 'Email',
    field: 'email',
    sort: true
  },
  // {
  //   label: 'Mobile',
  //   field: 'mobile',
  //   sort: true
  // },
  {
    label: 'Agency',
    field: 'agency_name',
    sort: true
  },
  {
    label: 'NI Number',
    field: 'national_insurance_number',
    sort: true
  },
  {
    label: 'Employee ID',
    field: 'employee_id',
    sort: true
  },
  {
    label: 'DOB',
    field: 'date_of_birth',
    sort: true,
    customRow: (cell) => {
      return formatDate(cell);
    }
  },
  {
    label: 'Post Code',
    field: 'post_code',
    sort: true
  },
  {
    label: 'Nationality',
    field: 'nationality',
    sort: true
  },
  {
    label: 'Pension Opt Out',
    field: 'pension_opt_out',
    sort: true,
    customRow: (cell) => {
      return cell === 0 ? (
        <span style={{ color: 'red' }}>No</span>
      ) : (
        <span style={{ color: 'green' }}>Yes</span>
      );
    }
  },
  {
    label: 'App Downloaded',
    field: 'is_app_downloaded',
    sort: true,
    customRow: (cell) => {
      return cell === 0 || cell === '0' ? (
        <span style={{ color: 'red' }}>No</span>
      ) : (
        <span style={{ color: 'green' }}>Yes</span>
      );
    }
  },
  {
    label: 'Status',
    field: 'is_active',
    sort: true,
    customRow: (cell) => {
      return cell === 0 ? (
        <span style={{ color: 'red' }}>InActive</span>
      ) : (
        <span style={{ color: 'green' }}>Active</span>
      );
    }
  }
];

const Worker = function () {
  useEffect(() => {
    document.title = PAGE_TITLE.WORKERS;
  }, []);

  const { user_details: userDetails } = useSelector((state) => state.authentication);
  const { selected_client_id, selected_site_id } = useSelector((state) => state.agency);
  const permission = getModulePermissions(PATH.WORKER.CODE);
  const toast = useToast();
  // const [workersData, setWorkersData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [isWorkerModalOpen, setIsWorkerModalOpen] = useState(false);
  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);
  const [isWorkerDetailModalOpen, setIsWorkerDetailModalOpen] = useState(false);
  const [isWorkerEditModalOpen, setIsWorkerEditModalOpen] = useState(false);
  const [isBulkUploadWorkerModalOpen, setIsBulkUploadWorkerModalOpen] = useState(false);
  const [isUpdateBulkUploadWorkerModalOpen, setIsUpdateBulkUploadWorkerModalOpen] = useState(false);
  const [isActivateWorkerModalOpen, setIsActivateWorkerModalOpen] = useState(false);
  const [activeRow, setActiveRow] = useState({});
  const [workerData, setWorkerData] = useState({ ...INITIAL_WORKER });
  const [searchWorkerData, setsearchWorkerData] = useState({ ...INITIAL_SEARCH_WORKER });
  const [isPageLoader, setIsPageLoader] = useState(false);
  const [validations, setValidations] = useState(deepClone(ADD_WORKER_VALIDATIONS));
  const [searchvalidations, setSearchValidations] = useState(deepClone(SEARCH_WORKER_VALIDATIONS));
  const [edit] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [reload, setReload] = useState(false);

  const [selectedPassport, setSelectedPassport] = useState(null);
  const [selectedUtilityBill, setSelectedUtilityBill] = useState(null);
  const [selectedidentityCard, setSelectedIdentityCard] = useState(null);
  // const [selectedRightToWork, setSelectedRightToWork] = useState(null);
  const [selectedDrivingLicense, setSelectedDrivingLicense] = useState(null);
  const [site, setSite] = useState({});
  const [searchData, setsearchData] = useState(0);
  const [agency, setAgency] = useState({});
  const [workerType, setWorkerType] = useState(
    userDetails.user_type_id === USER_TYPE.AGENCY_ADMIN ||
      userDetails.user_type_id === USER_TYPE.AGENCY_SITE_ADMIN ||
      userDetails.user_type_id === USER_TYPE.AGENCY_REGION_ADMIN
      ? 'TEMPORARY'
      : 'PERMANENT'
  );
  const [searchBtnClicked, setsearchBtnClicked] = useState(false);
  const firstUpdate = useRef(true);
  const [isSmallerThan1850] = useMediaQuery('(max-width: 1850px)');
  const [loader, setloader] = useState(false);
  const [paramsData, setparamsData] = useState([]);
  const [countFilterData, setcountFilterData] = useState();
  const [disableSupervisorStatusDropdown, setDisableSupervisorStatusDropdown] = useState(false);

  const setWorkerColumns = () => {
    return INITIAL_COLUMNS.filter((column) => {
      if (column.label === 'Email' || column.label === 'Insurance Number') {
        if (userDetails.user_type_id === USER_TYPE.AGENCY_ADMIN) {
          return true;
        }
        return false;
      }
      // if (column.label === 'App Downloaded') {
      //   if (
      //     userDetails.user_type_id === USER_TYPE.AGENCY_ADMIN ||
      //     userDetails.user_type_id === USER_TYPE.CLIENT_ADMIN
      //   ) {
      //     return true;
      //   }
      //   return false;
      // }

      return true;
    });
  };

  const [columns, setColumns] = useState([...setWorkerColumns()]);
  const [selectedWorker, setSelectedWorker] = useState();

  const retrieveWorkerData = async (...params) => {
    // return await getWorkers(
    //   selected_client_id || userDetails.client_id,
    //   userDetails.agency_id,
    //   selected_site_id || -1,
    //   ...params
    // );
    setsearchBtnClicked(false);
    const result = await getWorkers(
      userDetails.user_type_id === USER_TYPE.CLIENT_ADMIN ||
        userDetails.user_type_id === USER_TYPE.MESSAGE_ADMIN ||
        userDetails.user_type_id === USER_TYPE.SITE_ADMIN
        ? workerType
        : 'TEMPORARY',
      selected_client_id || userDetails.client_id,
      workerType === 'PERMANENT'
        ? ''
        : userDetails.user_type_id === USER_TYPE.AGENCY_ADMIN ||
          userDetails.user_type_id === USER_TYPE.AGENCY_REGION_ADMIN ||
          userDetails.user_type_id === USER_TYPE.AGENCY_SITE_ADMIN
        ? userDetails.agency_id
        : agency.value,
      userDetails.user_type_id === USER_TYPE.AGENCY_ADMIN ||
        userDetails.user_type_id === USER_TYPE.AGENCY_REGION_ADMIN
        ? selected_site_id || -1
        : userDetails.user_type_id === USER_TYPE.SITE_ADMIN ||
          userDetails.user_type_id === USER_TYPE.AGENCY_SITE_ADMIN
        ? userDetails.site_id
        : undefined,
      '',
      // userDetails.user_type_id === USER_TYPE.AGENCY_REGION_ADMIN && userDetails.region_id,
      ...params
    );
    if (result.ok) {
      return result;
    }
    return {
      data: []
    };

    // setIsPageLoader(false);
    // if (result && result.ok) {
    //   setWorkersData(result.data.workers);
    // } else {
    //   toast({
    //     ...TOAST_SETTINGS.SETTINGS,
    //     status: TOAST_SETTINGS.ERROR,
    //     title: result.error
    //   });
    // }
  };

  const setAgencyData = (e) => {
    setAgency(e);
    // retrieveWorkerData(e.value);
    setReload(!reload);
  };

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    setReload(!reload);
  }, [workerType]);

  useEffect(() => {
    // const cols = [];
    // INITIAL_COLUMNS.forEach((column) => {
    //   if (column.label === 'Email' || column.label === 'Insurance Number') {
    //     if (userDetails.user_type_id === USER_TYPE.AGENCY_ADMIN) {
    //       cols.push({ ...column });
    //     }
    //   } else {
    //     cols.push({ ...column });
    //   }
    // });
    // setColumns([...cols]);
    setIsPageLoader(true);
    if (userDetails.user_type_id !== USER_TYPE.AGENCY_ADMIN)
      // retrieveWorkerData(userDetails.agency_id);
      setReload(!reload);
  }, []);

  useEffect(() => {
    if (isWorkerModalOpen) {
      setWorkerData((currentState) => ({
        ...currentState,
        client_id: selected_client_id || userDetails.client_id,
        agency_id: userDetails.agency_id
      }));
    }
  }, [isWorkerModalOpen]);
  const resetWorkerData = () => {
    setWorkerData({ ...INITIAL_WORKER });
  };

  const closeActivateWorkerModal = () => {
    setActiveRow(null);
    setIsActivateWorkerModalOpen(false);
  };

  const closeAddWorkerModal = () => {
    resetWorkerData();
    setIsWorkerModalOpen(false);
    setValidations(deepClone(ADD_WORKER_VALIDATIONS));
    setSelectedPassport(null);
    setSelectedUtilityBill(null);
    setSelectedIdentityCard(null);
    // setSelectedRightToWork(null);
    setSelectedDrivingLicense(null);
  };

  const cancelBtnClickHandler = () => {
    closeAddWorkerModal();
  };
  const searchWorkerBtnClickHandler = async () => {
    if (JSON.stringify(searchWorkerData) === JSON.stringify(INITIAL_SEARCH_WORKER)) {
      setsearchData(0);
      setIsSearchModalOpen(false);
    } else {
      const count = searchData + 1;
      setsearchData(count);
      setsearchBtnClicked(true);
    }
  };

  const retrieveSearchWorkerData = async (...params) => {
    setparamsData(params);
    const payload = { ...searchWorkerData };
    payload.other_assignment = payload.other_assignment.value ? payload.other_assignment.value : '';
    payload.pension_opt_out = payload.pension_opt_out.value ? payload.pension_opt_out.value : '';
    payload.app_downloaded = payload.app_downloaded.value ? payload.app_downloaded.value : '';
    const result = await getSearchWorkers(
      userDetails.user_type_id === USER_TYPE.CLIENT_ADMIN ||
        userDetails.user_type_id === USER_TYPE.MESSAGE_ADMIN ||
        userDetails.user_type_id === USER_TYPE.SITE_ADMIN
        ? workerType
        : 'TEMPORARY',
      selected_client_id || userDetails.client_id,
      workerType === 'PERMANENT'
        ? ''
        : userDetails.user_type_id === USER_TYPE.AGENCY_ADMIN ||
          userDetails.user_type_id === USER_TYPE.AGENCY_REGION_ADMIN ||
          userDetails.user_type_id === USER_TYPE.AGENCY_SITE_ADMIN
        ? userDetails.agency_id
        : agency.value,
      userDetails.user_type_id === USER_TYPE.AGENCY_ADMIN ||
        userDetails.user_type_id === USER_TYPE.AGENCY_REGION_ADMIN
        ? selected_site_id || -1
        : userDetails.user_type_id === USER_TYPE.SITE_ADMIN ||
          userDetails.user_type_id === USER_TYPE.AGENCY_SITE_ADMIN
        ? userDetails.site_id
        : undefined,
      '',
      payload,
      ...params
    );
    if (result.ok) {
      setcountFilterData(result.data.count);
      setIsSearchModalOpen(false);

      return result;
    }
    return {
      data: []
    };
  };

  const addWorkerBtnClickHandler = async () => {
    if (
      userDetails.user_type_id === USER_TYPE.CLIENT_ADMIN ||
      userDetails.user_type_id === USER_TYPE.MESSAGE_ADMIN
    ) {
      delete workerData.agency_id;
      delete validations.agency_id;
      workerData.type = 'PERMANENT';
    }
    if (userDetails.user_type_id === USER_TYPE.AGENCY_ADMIN) {
      workerData.type = 'TEMPORARY';
      delete validations.site_id;
    }
    if (userDetails.user_type_id === USER_TYPE.AGENCY_SITE_ADMIN) {
      workerData.site_id = `${userDetails.site_id}`;
      workerData.type = 'TEMPORARY';
      workerData.agency_id = `${userDetails.agency_id}`;
    }
    if (userDetails.user_type_id === USER_TYPE.AGENCY_REGION_ADMIN) {
      workerData.type = 'TEMPORARY';
      workerData.agency_id = `${userDetails.agency_id}`;
      delete validations.site_id;
      delete workerData.site_id;
    }

    let [validationObject, isFormValid] = validateForm(workerData, validations);
    const isValidPostCode = validateAlphaNumericPostCodeForWorker(workerData.post_code);
    if (!isValidPostCode && workerData.post_code.length !== 0) {
      validationObject.post_code.isvalid = false;
      isFormValid = false;
    }
    setValidations(validationObject);
    if (isFormValid) {
      // const result2 = await addWorkerDocs({
      //   passport: selectedPassport,
      //   utility_bill: selectedUtilityBill,
      //   identity_card: selectedidentityCard,
      //   right_to_work: selectedRightToWork,
      //   driving_license: selectedDrivingLicense
      // });
      // setWorkerData({
      //   ...workerData,
      //   passportUrl: result2.data.documents.passport.url,
      //   utilityBillUrl: result2.data.documents.utility_bill.url,
      //   identityCardUrl: result2.data.documents.identity_card.url,
      //   rightToWorkUrl: result2.data.documents.right_to_work.url,
      //   drivingLicenseUrl: result2.data.documents.driving_license.url
      // });
      const payload = { ...workerData, post_code: workerData.post_code.toUpperCase() };
      const result = await addWorker({ ...payload, current_worker_id: null });
      if (result && result.ok) {
        toast({
          ...TOAST_SETTINGS.SETTINGS,
          duration: CUSTOM_TOAST_DURATION.duration,
          status: TOAST_SETTINGS.SUCCESS,
          title: result.message
        });
        // retrieveWorkerData(userDetails.agency_id, selected_site_id);
        setReload(!reload);
        closeAddWorkerModal();
      } else {
        toast({
          ...TOAST_SETTINGS.SETTINGS,
          status: TOAST_SETTINGS.ERROR,
          title: result.error
        });
      }
    } else {
      setRefresh(!refresh);
    }
  };

  const closeWorkerDetailModal = () => {
    setIsWorkerDetailModalOpen(false);
    setSelectedWorker(null);
  };

  const openWorkerDetailModal = async (rowData) => {
    const result = await getWorkerDetails(rowData.id);
    if (result && result.ok) {
      setIsWorkerDetailModalOpen(true);
      setSelectedWorker({ ...result.data.data });
    } else {
      toast({
        ...TOAST_SETTINGS.SETTINGS,
        status: TOAST_SETTINGS.ERROR,
        title: result.error
      });
    }
  };

  const closeWorkerEditModal = () => {
    setDisableSupervisorStatusDropdown(false);
    setSelectedWorker(null);
    setValidations(deepClone(ADD_WORKER_VALIDATIONS));
    // resetWorkerData();
    setIsWorkerEditModalOpen(false);
    setValidations(deepClone(ADD_WORKER_VALIDATIONS));
    setSelectedPassport(null);
    setSelectedUtilityBill(null);
    setSelectedIdentityCard(null);
    // setSelectedRightToWork(null);
    setSelectedDrivingLicense(null);
  };

  const closeEditModalHandler = () => {
    closeWorkerEditModal();
  };

  const cancelSearchBtnClickHandler = () => {
    if (JSON.stringify(searchWorkerData) === JSON.stringify(INITIAL_SEARCH_WORKER)) {
      setIsSearchModalOpen(false);
      setsearchWorkerData(INITIAL_SEARCH_WORKER);
    } else {
      !searchBtnClicked && setsearchWorkerData(INITIAL_SEARCH_WORKER);
      setIsSearchModalOpen(false);
    }
  };
  const createFilterBtnClickHandler = () => {
    setsearchWorkerData(INITIAL_SEARCH_WORKER);
    setsearchData(0);
  };

  const openWorkerEditModal = async (rowData) => {
    setIsWorkerEditModalOpen(true);
    const result = await getWorkerDetails(rowData.id);
    if (result && result.ok) {
      if (
        result.data.data.workers_supervisor_status === 'FRONT_OFFICE' ||
        result.data.data.workers_supervisor_status === 'BACK_OFFICE'
      ) {
        setDisableSupervisorStatusDropdown(true);
      }
      setSelectedWorker(result.data.data);
    } else {
      toast({
        ...TOAST_SETTINGS.SETTINGS,
        status: TOAST_SETTINGS.ERROR,
        title: result.error
      });
    }
  };

  const updateSelectedWorker = async (id, payload) => {
    let data = { ...payload };
    if (
      selectedPassport ||
      selectedUtilityBill ||
      selectedidentityCard ||
      // selectedRightToWork ||
      selectedDrivingLicense
    ) {
      const result2 = await addWorkerDocs({
        passport: selectedPassport,
        utility_bill: selectedUtilityBill,
        identity_card: selectedidentityCard,
        // right_to_work: selectedRightToWork,
        driving_license: selectedDrivingLicense
      });
      if (result2 && result2.ok) {
        data = {
          ...data,
          documents: {
            passport: result2?.data?.documents?.passport?.url || data?.documents?.passport || '',
            utility_bill:
              result2?.data?.documents?.utility_bill?.url || data?.documents?.utility_bill || '',
            identity_card:
              result2?.data?.documents?.identity_card?.url || data?.documents?.identity_card || '',
            driving_license:
              result2?.data?.documents?.driving_license?.url ||
              data?.documents?.driving_license ||
              ''
          }
        };
      }
    }

    if (data.is_active === 1) {
      data.is_active = true;
    } else {
      data.is_active = false;
    }

    const result = await updateWorker(id, data);
    if (result && result.ok) {
      setReload(!reload);
      toast({
        ...TOAST_SETTINGS.SETTINGS,
        duration: CUSTOM_TOAST_DURATION.duration,
        status: TOAST_SETTINGS.SUCCESS,
        title: result.message
      });
      closeWorkerEditModal();
    } else {
      toast({ ...TOAST_SETTINGS.SETTINGS, status: TOAST_SETTINGS.ERROR, title: result.error });
    }
  };

  const editWorkerHandler = () => {
    if (
      userDetails.user_type_id === USER_TYPE.CLIENT_ADMIN ||
      userDetails.user_type_id === USER_TYPE.MESSAGE_ADMIN
    ) {
      delete workerData.agency_id;
      delete validations.agency_id;
      workerData.type = 'PERMANENT';
    }
    if (userDetails.user_type_id === USER_TYPE.AGENCY_ADMIN) {
      workerData.type = 'TEMPORARY';
      delete validations.site_id;
    }
    let [validationObject, isFormValid] = validateForm(selectedWorker, validations);
    const isValidPostCode = validateAlphaNumericPostCodeForWorker(selectedWorker.post_code);
    if (!isValidPostCode && selectedWorker.post_code.length !== 0) {
      validationObject.post_code.isvalid = false;
      isFormValid = false;
    }
    setValidations(validationObject);
    if (isFormValid) {
      const id = selectedWorker.id.toString();
      const payload = { ...selectedWorker, post_code: selectedWorker.post_code.toUpperCase() };
      updateSelectedWorker(id, payload);
    } else {
      setRefresh(!refresh);
    }
  };

  const setClientIdToWorkerData = () => {
    INITIAL_WORKER.client_id = selected_client_id;
    resetWorkerData();
  };

  useEffect(() => {
    setClientIdToWorkerData();
    if (
      userDetails.user_type_id === USER_TYPE.CLIENT_ADMIN ||
      userDetails.user_type_id === USER_TYPE.MESSAGE_ADMIN
    ) {
      setReload(!reload);
    } else if (userDetails.user_type_id === USER_TYPE.AGENCY_ADMIN && selected_site_id)
      setReload(!reload);
    else {
      setIsPageLoader(false);
      setReload(!reload);
    }
  }, [selected_site_id]); // selected_client_id,  // Add if we need to add client and agency filter together.

  const selectFile = async (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const selectPassportFile = async (event) => {
    setSelectedPassport(event.target.files[0]);
  };

  const selectUtilityBill = async (event) => {
    setSelectedUtilityBill(event.target.files[0]);
  };

  const selectIdentityCard = async (event) => {
    setSelectedIdentityCard(event.target.files[0]);
  };

  // const selectRightToWork = async (event) => {
  //   setSelectedRightToWork(event.target.files[0]);
  // };

  const selectDrivingLicense = async (event) => {
    setSelectedDrivingLicense(event.target.files[0]);
  };

  const onCloseBulkWorkerModal = () => {
    if (!loader) {
      setIsBulkUploadWorkerModalOpen(false);
      setSite({});
      setSelectedFile(null);
    }
  };
  const onCloseUpdateBulkWorkerModal = () => {
    setIsUpdateBulkUploadWorkerModalOpen(false);
    setSite({});
    setSelectedFile(null);
  };

  const saveBulkWorkerData = async () => {
    setloader(true);
    const result = await uploadBulkWorker(
      selected_client_id || userDetails.client_id,
      userDetails.agency_id,
      userDetails.user_type_id === USER_TYPE.CLIENT_ADMIN ||
        userDetails.user_type_id === USER_TYPE.MESSAGE_ADMIN
        ? site.value
        : selected_site_id,
      userDetails.user_type_id === USER_TYPE.CLIENT_ADMIN ||
        userDetails.user_type_id === USER_TYPE.MESSAGE_ADMIN
        ? 'PERMANENT'
        : 'TEMPORARY',
      { workers: selectedFile }
    );
    if (result && result.ok) {
      setloader(false);
      // retrieveWorkerData(userDetails.agency_id, selected_site_id);
      setReload(!reload);
      onCloseBulkWorkerModal();
      toast({
        ...TOAST_SETTINGS.SETTINGS,
        duration: CUSTOM_TOAST_DURATION.duration,
        status: TOAST_SETTINGS.SUCCESS,
        title: result.message
      });
    } else {
      setloader(false);
      toast({
        ...TOAST_SETTINGS.SETTINGS,
        duration: CUSTOM_TOAST_DURATION_NULL.duration,
        status: TOAST_SETTINGS.ERROR,
        title: filterErrorData(result.error)
      });
    }
  };

  const UpdateBulkWorkerData = async () => {
    setloader(true);
    const result = await uploadUpdateBulkWorker(
      selected_client_id || userDetails.client_id,
      userDetails.agency_id,
      userDetails.user_type_id === USER_TYPE.CLIENT_ADMIN ||
        userDetails.user_type_id === USER_TYPE.MESSAGE_ADMIN
        ? 'PERMANENT'
        : 'TEMPORARY',
      { workers: selectedFile }
    );
    if (result && result.ok) {
      setloader(false);
      setReload(!reload);
      onCloseUpdateBulkWorkerModal();
      toast({
        ...TOAST_SETTINGS.SETTINGS,
        duration: CUSTOM_TOAST_DURATION.duration,
        status: TOAST_SETTINGS.SUCCESS,
        title: result.message
      });
    } else {
      setloader(false);
      toast({
        ...TOAST_SETTINGS.SETTINGS,
        duration: CUSTOM_TOAST_DURATION_NULL.duration,
        status: TOAST_SETTINGS.ERROR,
        title: filterErrorData(result.error)
      });
    }
  };

  const downloadBtnClickHandler = async () => {
    const result = await downloadWorkerSample();
    if (result.ok) {
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.style = 'display: none';
      a.setAttribute('href', result.data.resource_url);
      a.setAttribute('download', 'Worker-CSV-Sample');
      a.click();
    }
  };

  const downloadFilteredDataClickHandler = async () => {
    let params = paramsData;
    params[0] = countFilterData;
    params[1] = 1;
    const payload = { ...searchWorkerData };
    payload.other_assignment = payload.other_assignment.value ? payload.other_assignment.value : '';
    payload.pension_opt_out = payload.pension_opt_out.value ? payload.pension_opt_out.value : '';
    payload.app_downloaded = payload.app_downloaded.value ? payload.app_downloaded.value : '';

    const result = await downloadFilteredDataOfWorker(
      userDetails.user_type_id === USER_TYPE.CLIENT_ADMIN ||
        userDetails.user_type_id === USER_TYPE.MESSAGE_ADMIN ||
        userDetails.user_type_id === USER_TYPE.SITE_ADMIN
        ? workerType
        : 'TEMPORARY',
      selected_client_id || userDetails.client_id,
      workerType === 'PERMANENT'
        ? ''
        : userDetails.user_type_id === USER_TYPE.AGENCY_ADMIN ||
          userDetails.user_type_id === USER_TYPE.AGENCY_REGION_ADMIN ||
          userDetails.user_type_id === USER_TYPE.AGENCY_SITE_ADMIN
        ? userDetails.agency_id
        : agency.value,
      userDetails.user_type_id === USER_TYPE.AGENCY_ADMIN ||
        userDetails.user_type_id === USER_TYPE.AGENCY_REGION_ADMIN
        ? selected_site_id || -1
        : userDetails.user_type_id === USER_TYPE.SITE_ADMIN ||
          userDetails.user_type_id === USER_TYPE.AGENCY_SITE_ADMIN
        ? userDetails.site_id
        : undefined,
      '',
      payload,
      ...params
    );
    if (result && result.ok) {
      let line = `First Name,Last Name,Email,Agency,NI Number,Employee ID,DOB,Post Code,Nationality,Pention Opt Out ,App Downloaded,Status,Other Assignment${'\r\n'}`;
      result.data.workers.forEach((row) => {
        line += `${row.first_name},${row.last_name},${row.email},${row.agency_name},${
          row.national_insurance_number
        },${row.employee_id},${row.date_of_birth},${row.post_code},${row.nationality},${
          row.pension_opt_out ? 'Yes' : 'No'
        },${row.is_app_downloaded ? 'Yes' : 'No'},${row.is_active ? 'Active' : 'InActive'},${
          row.other_assignment ? 'Yes' : 'No'
        },${'\r\n'}`;
      });
      const csvContent = `data:text/csv;charset=utf-8,${line}`;
      const encodedUri = encodeURI(csvContent);
      const link = document.createElement('a');
      link.setAttribute('href', encodedUri);
      link.setAttribute('download', `Filtered Data Of Workers.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      toast({
        ...TOAST_SETTINGS.SETTINGS,
        status: TOAST_SETTINGS.ERROR,
        title: result.error
      });
    }
  };

  const updateBulkdownloadBtnClickHandler = async () => {
    const result = await downloadUpdateWorkerSample();
    if (result.ok) {
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.style = 'display: none';
      a.setAttribute('href', result.data.resource_url);
      a.setAttribute('download', 'Update-Worker-CSV-Sample');
      a.click();
    }
  };

  // const getWorkerData = () => {
  //   getWorkers(
  //     selected_client_id || userDetails.client_id,
  //     userDetails.agency_id,
  //     selected_site_id
  //   );
  //   setIsPageLoader(false);
  // };

  const toggleWorkerStatus = async () => {
    const payload = {
      workers: [activeRow.id],
      is_active: !activeRow.is_active
    };
    const result = await updateBulkWorkers(payload);
    if (result && result.ok) {
      setReload((currentState) => !currentState);
      toast({
        ...TOAST_SETTINGS.SETTINGS,
        duration: CUSTOM_TOAST_DURATION.duration,
        status: TOAST_SETTINGS.SUCCESS,
        title: result.message
      });
    } else {
      toast({
        ...TOAST_SETTINGS.SETTINGS,
        status: TOAST_SETTINGS.ERROR,
        title: result.error
      });
    }
    closeActivateWorkerModal();
  };

  useEffect(() => {
    // if (permission && permission.includes('edit')) {
    const newCols = columns.filter((column) => {
      return column.label !== 'Actions';
    });
    setColumns([
      ...newCols,
      {
        label: 'Actions',
        field: 'id',
        sort: false,
        // eslint-disable-next-line react/no-unstable-nested-components
        customRow: (_cell, row) => {
          // eslint-disable-next-line no-plusplus
          return (
            <div style={{ display: 'flex' }}>
              <Button
                bg="main.semiPrimary"
                color="main.white"
                _hover={{ bg: 'main.primary' }}
                _active={{
                  bg: 'main.semiPrimary',
                  borderColor: 'main.primary'
                }}
                onClick={() => openWorkerDetailModal(row)}>
                {constants.WORKER.DETAIL}
              </Button>
              {(userDetails.user_type_id === USER_TYPE.CLIENT_ADMIN ||
                userDetails.user_type_id === USER_TYPE.MESSAGE_ADMIN) &&
              workerType === 'TEMPORARY' ? (
                ''
              ) : (
                <>
                  {permission && permission.includes('edit') ? (
                    <Button
                      ml={2}
                      bg="main.semiPrimary"
                      color="main.white"
                      _hover={{ bg: 'main.primary' }}
                      _active={{
                        bg: 'main.semiPrimary',
                        borderColor: 'main.primary'
                      }}
                      // size="xs"
                      onClick={() => {
                        openWorkerEditModal(row);
                      }}>
                      {constants.WORKER.EDIT_BUTTON_TEXT}
                    </Button>
                  ) : null}
                  {permission && permission.includes('edit') ? (
                    <Button
                      bg="main.semiPrimary"
                      color="main.white"
                      _hover={{ bg: 'main.primary' }}
                      // toggleWorkerStatus(row)
                      onClick={() => {
                        setActiveRow(row);
                        setIsActivateWorkerModalOpen(true);
                      }}
                      ml={2}
                      disabled={row.status === '2'}>
                      {row.is_active
                        ? constants.WORKER.INACTIVE_BUTTON_LABEL
                        : constants.WORKER.ACTIVE_BUTTON_LABEL}
                    </Button>
                  ) : null}
                </>
              )}
            </div>
          );
        }
      }
    ]);
    // }
  }, [permission, workerType]);

  function checkTempWorker(field, type) {
    if (
      type === 'TEMPORARY' &&
      (userDetails.user_type_id === USER_TYPE.CLIENT_ADMIN ||
        userDetails.user_type_id === USER_TYPE.SITE_ADMIN ||
        userDetails.user_type_id === USER_TYPE.MESSAGE_ADMIN ||
        userDetails.user_type_id === USER_TYPE.REGION_ADMIN)
    ) {
      return '-';
    }
    return field;
  }

  return (
    <StyledWorkerContainer>
      {isPageLoader ? <Loader /> : null}
      <Card className="add-worker-card">
        <StyledAddWorkerCardHeaderContainer>
          <Flex alignItems="flex-end" justifyContent="center">
            {permission && permission.includes('create') && (
              <Button
                mt={
                  (userDetails.user_type_id === USER_TYPE.CLIENT_ADMIN ||
                    userDetails.user_type_id === USER_TYPE.MESSAGE_ADMIN) &&
                  '32px'
                }
                mr="8px"
                bg="main.semiPrimary"
                color="main.white"
                _hover={{ bg: 'main.primary' }}
                _active={{
                  bg: 'main.semiPrimary',
                  borderColor: 'main.primary'
                }}
                onClick={() => setIsWorkerModalOpen(true)}
                disabled={
                  userDetails.user_type_id === USER_TYPE.AGENCY_ADMIN ? !selected_site_id : false
                }>
                {(userDetails.user_type_id === USER_TYPE.CLIENT_ADMIN ||
                  userDetails.user_type_id === USER_TYPE.MESSAGE_ADMIN) &&
                workerType === 'PERMANENT'
                  ? constants.WORKER.CREATE_PERMANENT_BUTTON_TEXT
                  : constants.WORKER.CREATE_BUTTON_TEXT}
              </Button>
            )}
            <Button
              mt={
                (userDetails.user_type_id === USER_TYPE.CLIENT_ADMIN ||
                  userDetails.user_type_id === USER_TYPE.MESSAGE_ADMIN) &&
                '32px'
              }
              mr={workerType === 'TEMPORARY' && searchData === 0 ? '8px' : '0px'}
              bg={searchData !== 0 ? 'main.white' : 'main.semiPrimary'}
              color={searchData !== 0 ? 'main.semiPrimary' : 'main.white'}
              _hover={{ bg: searchData !== 0 ? 'main.white' : 'main.primary' }}
              // _active={{
              //   bg: 'main.white',
              //   borderColor: 'main.semiPrimary'
              // }}
              borderColor={searchData !== 0 && 'main.semiPrimary'}
              borderWidth={searchData !== 0 && '1px'}
              onClick={() => {
                setIsSearchModalOpen(true);
              }}
              _focus={{ outline: 'none' }}
              borderBottomRightRadius={searchData !== 0 && '0px'}
              borderTopRightRadius={searchData !== 0 && '0px'}>
              {constants.WORKER.SEARCH_BUTTON}
            </Button>
            {searchData !== 0 && (
              <>
                <Tooltip label="Clear Filter">
                  <Button
                    mt={
                      (userDetails.user_type_id === USER_TYPE.CLIENT_ADMIN ||
                        userDetails.user_type_id === USER_TYPE.MESSAGE_ADMIN) &&
                      '32px'
                    }
                    mr="8px"
                    _focus={{ outline: 'none' }}
                    p="0px"
                    bg="main.white"
                    color="main.semiPrimary"
                    _hover={{ bg: 'main.white' }}
                    // _active={{
                    //   bg: 'main.semiPrimary',
                    //   borderColor: 'main.primary'
                    // }}
                    borderColor="main.semiPrimary"
                    borderWidth="1px"
                    borderLeftWidth="0px"
                    style={{ borderBottomLeftRadius: '0px', borderTopLeftRadius: '0px' }}
                    onClick={createFilterBtnClickHandler}>
                    <Image src={ClearFilterIcon} height={6} />
                  </Button>
                </Tooltip>
                <Button
                  title="Download Filtered Data"
                  mt={
                    userDetails.user_type_id === USER_TYPE.CLIENT_ADMIN ||
                    userDetails.user_type_id === USER_TYPE.MESSAGE_ADMIN
                      ? isSmallerThan1850
                        ? '10px'
                        : '32px'
                      : ''
                  }
                  mr="8px"
                  bg="main.white"
                  color="main.semiPrimary"
                  _hover={{ bg: 'main.white' }}
                  borderColor="main.semiPrimary"
                  borderWidth="1px"
                  onClick={downloadFilteredDataClickHandler}>
                  <DownloadIcon />
                </Button>
              </>
            )}
            {(userDetails.user_type_id === USER_TYPE.CLIENT_ADMIN ||
              userDetails.user_type_id === USER_TYPE.MESSAGE_ADMIN) &&
              workerType === 'TEMPORARY' && (
                <Box width="250px">
                  <AgencyDropdown
                    agency={agency}
                    refresh={refresh}
                    setAgency={setAgencyData}
                    removeErrors="true"
                  />
                </Box>
              )}
          </Flex>
          <Flex
            alignItems="flex-end"
            flexDirection={isSmallerThan1850 ? 'column' : 'row'}
            flexWrap={isSmallerThan1850 ? 'wrap' : ''}>
            {(userDetails.user_type_id === USER_TYPE.CLIENT_ADMIN ||
              userDetails.user_type_id === USER_TYPE.MESSAGE_ADMIN ||
              userDetails.user_type_id === USER_TYPE.SITE_ADMIN) && (
              <Box
                display="flex"
                mt={
                  userDetails.user_type_id === USER_TYPE.CLIENT_ADMIN ||
                  userDetails.user_type_id === USER_TYPE.MESSAGE_ADMIN
                    ? isSmallerThan1850
                      ? '10px'
                      : ''
                    : ''
                }
                mb={
                  userDetails.user_type_id === USER_TYPE.CLIENT_ADMIN ||
                  userDetails.user_type_id === USER_TYPE.MESSAGE_ADMIN
                    ? isSmallerThan1850
                      ? ''
                      : '5px'
                    : ''
                }>
                <FormControl display="flex" alignItems="center" mr={isSmallerThan1850 ? '' : '8px'}>
                  <FormLabel htmlFor="email-alerts" mb="0">
                    Temporary/Permanent Workers:
                  </FormLabel>
                  <Switch
                    isChecked={workerType === 'PERMANENT'}
                    onChange={() => {
                      setWorkerType(workerType === 'PERMANENT' ? 'TEMPORARY' : 'PERMANENT');
                    }}
                    id="worker-activity"
                  />
                </FormControl>
              </Box>
            )}
            <Flex>
              {permission && permission.includes('create') && (
                <Button
                  title="Download Sample Sheet"
                  mt={
                    userDetails.user_type_id === USER_TYPE.CLIENT_ADMIN ||
                    userDetails.user_type_id === USER_TYPE.MESSAGE_ADMIN
                      ? isSmallerThan1850
                        ? '10px'
                        : '32px'
                      : ''
                  }
                  bg="main.semiPrimary"
                  color="main.white"
                  _hover={{ bg: 'main.primary' }}
                  _active={{
                    bg: 'main.semiPrimary',
                    borderColor: 'main.primary'
                  }}
                  onClick={downloadBtnClickHandler}>
                  <DownloadIcon />
                </Button>
              )}
              {permission && permission.includes('create') && (
                <Button
                  w="180px"
                  mt={
                    userDetails.user_type_id === USER_TYPE.CLIENT_ADMIN ||
                    userDetails.user_type_id === USER_TYPE.MESSAGE_ADMIN
                      ? isSmallerThan1850
                        ? '10px'
                        : '32px'
                      : ''
                  }
                  bg="main.semiPrimary"
                  color="main.white"
                  _hover={{ bg: 'main.primary' }}
                  _active={{
                    bg: 'main.semiPrimary',
                    borderColor: 'main.primary'
                  }}
                  onClick={() => setIsBulkUploadWorkerModalOpen(true)}
                  ml={2}
                  disabled={
                    userDetails.user_type_id === USER_TYPE.AGENCY_ADMIN ? !selected_site_id : false
                  }>
                  {constants.WORKER.BULK_UPLOAD_BUTTON_TEXT}
                </Button>
              )}
            </Flex>
          </Flex>
        </StyledAddWorkerCardHeaderContainer>
        {/* <StyledAddWorkerCardHeaderContainer> */}
        <Flex
          justifyContent="flex-end"
          marginTop="10px"
          alignItems="center"
          flexWrap={isSmallerThan1850 ? 'wrap' : ''}>
          {permission && permission.includes('create') && (
            <Button
              title="Download Sample Sheet"
              bg="main.semiPrimary"
              color="main.white"
              _hover={{ bg: 'main.primary' }}
              _active={{
                bg: 'main.semiPrimary',
                borderColor: 'main.primary'
              }}
              onClick={updateBulkdownloadBtnClickHandler}>
              <DownloadIcon />
            </Button>
          )}
          {permission && permission.includes('create') && (
            <Button
              // mr="8px"
              w="180px"
              bg="main.semiPrimary"
              color="main.white"
              _hover={{ bg: 'main.primary' }}
              _active={{
                bg: 'main.semiPrimary',
                borderColor: 'main.primary'
              }}
              onClick={() => setIsUpdateBulkUploadWorkerModalOpen(true)}
              ml={2}
              disabled={
                userDetails.user_type_id === USER_TYPE.AGENCY_ADMIN ? !selected_site_id : false
              }>
              {constants.WORKER.UPDATE_UPLOAD_BUTTON_TEXT}
            </Button>
          )}
        </Flex>
        <AlertDialogComponent
          isOpen={isActivateWorkerModalOpen}
          onClose={closeActivateWorkerModal}
          title={constants.WORKER.CHANGE_WORKER_STATUS}
          body={
            <Box>
              Are you sure you want to set{' '}
              {activeRow && <span style={{ fontWeight: 'bold' }}>{activeRow.first_name}</span>}{' '}
              {activeRow && <span style={{ fontWeight: 'bold' }}>{activeRow.last_name}</span>} as{' '}
              {activeRow && activeRow.is_active ? 'InActive' : 'Active'} ?
            </Box>
          }
          negative={
            <Button
              bg="main.secondary"
              mr={3}
              _hover={{ bg: 'main.secondary' }}
              color="main.black"
              onClick={closeActivateWorkerModal}>
              {constants.WORKER.CANCEL_BUTTON_TEXT}
            </Button>
          }
          positive={
            <Button
              bg="main.semiPrimary"
              color="main.white"
              _hover={{ bg: 'main.primary' }}
              _active={{
                bg: 'main.semiPrimary',
                borderColor: 'main.primary'
              }}
              onClick={toggleWorkerStatus}>
              {constants.WORKER.CONFIRM_BUTTON_TEXT}
            </Button>
          }
        />
        <ModalComponent
          isOpen={isWorkerDetailModalOpen}
          onClose={closeWorkerDetailModal}
          modalTitle={
            (userDetails.user_type_id === USER_TYPE.CLIENT_ADMIN ||
              userDetails.user_type_id === USER_TYPE.MESSAGE_ADMIN) &&
            workerType === 'PERMANENT'
              ? constants.WORKER.PERMANENT_WORKER_INFORMATION_TEXT
              : constants.WORKER.WORKER_INFORMATION_TEXT
          }
          modalContent={
            selectedWorker ? (
              <>
                <SimpleGrid columns={2} spacingX="40px" spacingY="20px">
                  <Box>
                    <StyledDetailLabel>First Name</StyledDetailLabel>
                    <StyledDetailValue>
                      {selectedWorker.first_name ? selectedWorker.first_name : '-'}
                    </StyledDetailValue>
                  </Box>
                  <Box>
                    <StyledDetailLabel>Last Name</StyledDetailLabel>
                    <StyledDetailValue>
                      {selectedWorker.last_name ? selectedWorker.last_name : '-'}
                    </StyledDetailValue>
                  </Box>

                  <Box>
                    <StyledDetailLabel>DOB</StyledDetailLabel>
                    <StyledDetailValue>
                      {selectedWorker?.date_of_birth
                        ? checkTempWorker(
                            DATE_TO_SHOW(selectedWorker.date_of_birth),
                            selectedWorker.type
                          )
                        : '-'}
                    </StyledDetailValue>
                  </Box>
                  {userDetails.user_type_id === USER_TYPE.AGENCY_ADMIN ||
                  userDetails.user_type_id === USER_TYPE.AGENCY_REGION_ADMIN ||
                  userDetails.user_type_id === USER_TYPE.AGENCY_SITE_ADMIN ? (
                    <Box>
                      <StyledDetailLabel>Email</StyledDetailLabel>
                      <StyledDetailValue>
                        {selectedWorker.email ? selectedWorker.email : '-'}
                      </StyledDetailValue>
                    </Box>
                  ) : null}

                  {/* <Box>
      <StyledDetailLabel>Phone Number</StyledDetailLabel>
      <StyledDetailValue>
        {selectedWorker.country_code ? selectedWorker.country_code : '-'}
        {selectedWorker.mobile ? selectedWorker.mobile : '-'}
      </StyledDetailValue>
    </Box> */}
                  <Box>
                    <StyledDetailLabel>Post Code</StyledDetailLabel>
                    <StyledDetailValue>
                      {selectedWorker.post_code ? selectedWorker.post_code : '-'}
                    </StyledDetailValue>
                  </Box>

                  <Box>
                    <StyledDetailLabel>Nationality</StyledDetailLabel>
                    <StyledDetailValue>
                      {selectedWorker.nationality
                        ? checkTempWorker(selectedWorker.nationality, selectedWorker.type)
                        : '-'}
                    </StyledDetailValue>
                  </Box>
                  <Box>
                    <StyledDetailLabel>Sex</StyledDetailLabel>
                    <StyledDetailValue>
                      {selectedWorker.orientation
                        ? checkTempWorker(selectedWorker.orientation, selectedWorker.type)
                        : '-'}
                    </StyledDetailValue>
                  </Box>
                  {userDetails.user_type_id === USER_TYPE.AGENCY_ADMIN ||
                  userDetails.user_type_id === USER_TYPE.AGENCY_REGION_ADMIN ||
                  userDetails.user_type_id === USER_TYPE.AGENCY_SITE_ADMIN ? (
                    <Box>
                      <StyledDetailLabel>National Insurance Number</StyledDetailLabel>
                      <StyledDetailValue>
                        {selectedWorker.national_insurance_number
                          ? checkTempWorker(
                              selectedWorker.national_insurance_number,
                              selectedWorker.type
                            )
                          : '-'}
                      </StyledDetailValue>
                    </Box>
                  ) : null}
                  <Box>
                    <StyledDetailLabel>Employee Id</StyledDetailLabel>
                    <StyledDetailValue>
                      {selectedWorker.employee_id ? selectedWorker.employee_id : '-'}
                    </StyledDetailValue>
                  </Box>
                  <Box>
                    <StyledDetailLabel>Shift Completed</StyledDetailLabel>
                    <StyledDetailValue>
                      {selectedWorker.shift_completed ? selectedWorker.shift_completed : '-'}
                    </StyledDetailValue>
                  </Box>
                  <Box>
                    <StyledDetailLabel>Start Date</StyledDetailLabel>
                    <StyledDetailValue>
                      {selectedWorker.start_date ? DATE_TO_SHOW(selectedWorker.start_date) : '-'}
                    </StyledDetailValue>
                  </Box>
                  <Box>
                    <StyledDetailLabel>InActive Date</StyledDetailLabel>
                    <StyledDetailValue>
                      {selectedWorker.in_actived_at
                        ? DATE_TO_SHOW(selectedWorker.in_actived_at)
                        : '-'}
                    </StyledDetailValue>
                  </Box>
                  <Box>
                    <StyledDetailLabel>Assignment Date</StyledDetailLabel>
                    <StyledDetailValue>
                      {selectedWorker.assignment_date
                        ? DATE_TO_SHOW(selectedWorker.assignment_date)
                        : '-'}
                    </StyledDetailValue>
                  </Box>

                  <Box>
                    <StyledDetailLabel style={{ marginBottom: '10px' }}>
                      Achievements
                    </StyledDetailLabel>
                    <StyledDetailValue style={{ marginBottom: '10px' }}>
                      <span style={{ marginRight: '15px' }}>Awards:</span>
                      {selectedWorker?.appreciation?.awards
                        ? selectedWorker?.appreciation?.awards
                        : '-'}
                    </StyledDetailValue>
                    <StyledDetailValue style={{ marginBottom: '10px' }}>
                      <span style={{ marginRight: '15px' }}>Badges:</span>
                      {selectedWorker?.appreciation?.badge
                        ? selectedWorker?.appreciation?.badge
                        : '-'}
                    </StyledDetailValue>
                    <StyledDetailValue style={{ marginBottom: '10px' }}>
                      <span style={{ marginRight: '23px' }}>Recognition:</span>
                      {selectedWorker?.appreciation?.recognition
                        ? selectedWorker?.appreciation?.recognition
                        : '-'}
                    </StyledDetailValue>
                  </Box>
                  <Box>
                    <StyledDetailLabel style={{ marginBottom: '10px' }}>
                      Length Of Service
                    </StyledDetailLabel>
                    <StyledDetailValue style={{ marginBottom: '10px' }}>
                      <span style={{ marginRight: '25px' }}>Years: </span>
                      {selectedWorker?.length_of_service?.years
                        ? selectedWorker?.length_of_service?.years
                        : '-'}
                    </StyledDetailValue>
                    <StyledDetailValue style={{ marginBottom: '10px' }}>
                      <span style={{ marginRight: '10px' }}>Months: </span>
                      {selectedWorker?.length_of_service?.months
                        ? selectedWorker?.length_of_service?.months
                        : '-'}
                    </StyledDetailValue>
                    <StyledDetailValue style={{ marginBottom: '10px' }}>
                      <span style={{ marginRight: '16px' }}>Weeks: </span>
                      {selectedWorker?.length_of_service?.weeks
                        ? selectedWorker?.length_of_service?.weeks
                        : '-'}
                    </StyledDetailValue>
                  </Box>
                  <Box>
                    <StyledDetailLabel>Worker Type</StyledDetailLabel>
                    <StyledDetailValue>{selectedWorker.type || '-'}</StyledDetailValue>
                  </Box>
                  {selectedWorker.type === 'TEMPORARY' && (
                    <Box>
                      <StyledDetailLabel>Supervisor Status</StyledDetailLabel>
                      <StyledDetailValue>
                        {(() => {
                          switch (selectedWorker.workers_supervisor_status) {
                            case 'FRONT_OFFICE':
                              return 'Front Office';
                            case 'BACK_OFFICE':
                              return 'Back Office';
                            default:
                              return '-';
                          }
                        })()}
                      </StyledDetailValue>
                    </Box>
                  )}

                  <Box>
                    <StyledDetailLabel>Under 21 Years</StyledDetailLabel>
                    <StyledDetailValue>
                      {selectedWorker?.under_twentyone ? 'True' : 'False'}
                    </StyledDetailValue>
                  </Box>
                  {(userDetails.user_type_id === USER_TYPE.AGENCY_ADMIN ||
                    userDetails.user_type_id === USER_TYPE.AGENCY_REGION_ADMIN ||
                    userDetails.user_type_id === USER_TYPE.AGENCY_SITE_ADMIN) && (
                    <Box>
                      <StyledDetailLabel>Pension Opt Out Status</StyledDetailLabel>
                      <StyledDetailValue>
                        {selectedWorker?.pension_opt_out ? 'Yes' : 'No'}
                      </StyledDetailValue>
                    </Box>
                  )}
                  <Box>
                    <StyledDetailLabel>Under 22 Years</StyledDetailLabel>
                    <StyledDetailValue>
                      {selectedWorker?.under_twentytwo ? 'True' : 'False'}
                    </StyledDetailValue>
                  </Box>
                  {(userDetails.user_type_id === USER_TYPE.AGENCY_ADMIN ||
                    userDetails.user_type_id === USER_TYPE.AGENCY_REGION_ADMIN ||
                    userDetails.user_type_id === USER_TYPE.AGENCY_SITE_ADMIN) && (
                    <Box>
                      <StyledDetailLabel>Transport</StyledDetailLabel>
                      <StyledDetailValue>
                        {selectedWorker?.transport ? 'Yes' : 'No'}
                      </StyledDetailValue>
                    </Box>
                  )}

                  <Box>
                    <StyledDetailLabel>Within 12 Weeks Of The Start Date</StyledDetailLabel>
                    <StyledDetailValue>
                      {selectedWorker?.within_twelveweeks ? 'True' : 'False'}
                    </StyledDetailValue>
                  </Box>
                  <Box>
                    <StyledDetailLabel>Student Visa</StyledDetailLabel>
                    <StyledDetailValue>
                      {selectedWorker?.student_visa ? 'Yes' : 'No'}
                    </StyledDetailValue>
                  </Box>
                  <Box>
                    <StyledDetailLabel>House Number</StyledDetailLabel>
                    <StyledDetailValue>
                      {selectedWorker?.house_number ? selectedWorker.house_number : '-'}
                    </StyledDetailValue>
                  </Box>
                  <Box>
                    <StyledDetailLabel>Site Associated</StyledDetailLabel>
                    <StyledDetailValue>{selectedWorker?.site_name}</StyledDetailValue>
                  </Box>
                  {(userDetails.user_type_id === USER_TYPE.AGENCY_ADMIN ||
                    userDetails.user_type_id === USER_TYPE.AGENCY_REGION_ADMIN ||
                    userDetails.user_type_id === USER_TYPE.AGENCY_SITE_ADMIN) && (
                    <Box>
                      <StyledDetailLabel>Other Assignment</StyledDetailLabel>
                      <StyledDetailValue>
                        {selectedWorker?.other_assignment ? 'Yes' : 'No'}
                      </StyledDetailValue>
                    </Box>
                  )}
                  <Box>
                    <StyledDetailLabel>Role</StyledDetailLabel>
                    <StyledDetailValue>{selectedWorker?.job_name}</StyledDetailValue>
                  </Box>
                </SimpleGrid>
                <StyledDetailLabel style={{ marginBottom: '10px', marginTop: '25px' }}>
                  Right To Work Documents
                </StyledDetailLabel>
                {!selectedWorker?.documents?.passport &&
                  !selectedWorker?.documents?.utility_bill &&
                  !selectedWorker?.documents?.identity_card &&
                  !selectedWorker?.documents?.driving_license && (
                    <StyledDetailValue style={{ fontWeight: '500' }}>
                      None of the documents are uploaded
                    </StyledDetailValue>
                  )}
                <SimpleGrid columns={2} spacingX="40px" spacingY="20px">
                  {selectedWorker?.documents?.passport && (
                    <Box>
                      <StyledDetailValue>
                        <span style={{ marginRight: '20px' }}>Passport:</span>
                        {selectedWorker?.documents?.passport.substring(
                          selectedWorker?.documents?.passport?.lastIndexOf('.') + 1
                        ) === 'pdf' ? (
                          <a
                            style={{ fontWeight: '500' }}
                            href={selectedWorker?.documents?.passport}
                            target="_blank"
                            rel="noreferrer">
                            <Box w="200px" h="150px">
                              <PdfDownloadIcon />
                            </Box>
                          </a>
                        ) : (
                          <img
                            style={{ height: '150px', width: '200px', borderRadius: '10px' }}
                            src={selectedWorker?.documents?.passport}
                            alt="Passport"
                          />
                        )}
                      </StyledDetailValue>
                    </Box>
                  )}
                  {selectedWorker?.documents?.utility_bill && (
                    <Box>
                      <StyledDetailValue>
                        <span style={{ marginRight: '20px' }}>Utility Bill: </span>
                        {selectedWorker?.documents?.utility_bill.substring(
                          selectedWorker?.documents?.utility_bill.lastIndexOf('.') + 1
                        ) === 'pdf' ? (
                          <a
                            style={{ fontWeight: '500' }}
                            href={selectedWorker?.documents?.utility_bill}
                            target="_blank"
                            rel="noreferrer">
                            <Box w="200px" h="150px">
                              <PdfDownloadIcon />
                            </Box>
                          </a>
                        ) : (
                          <img
                            src={selectedWorker?.documents?.utility_bill}
                            alt="Utility Bill"
                            style={{ height: '150px', width: '200px', borderRadius: '10px' }}
                          />
                        )}
                      </StyledDetailValue>
                    </Box>
                  )}
                  {selectedWorker?.documents?.identity_card && (
                    <Box>
                      <StyledDetailValue>
                        <span style={{ marginRight: '20px' }}>Identity Card: </span>
                        {selectedWorker?.documents?.identity_card.substring(
                          selectedWorker?.documents?.identity_card?.lastIndexOf('.') + 1
                        ) === 'pdf' ? (
                          <a
                            style={{ fontWeight: '500' }}
                            href={selectedWorker?.documents?.identity_card}
                            target="_blank"
                            rel="noreferrer">
                            <Box w="200px" h="150px">
                              <PdfDownloadIcon />
                            </Box>
                          </a>
                        ) : (
                          <img
                            src={selectedWorker?.documents?.identity_card}
                            alt="Identity card"
                            style={{ height: '150px', width: '200px', borderRadius: '10px' }}
                          />
                        )}
                      </StyledDetailValue>
                    </Box>
                  )}
                  {selectedWorker?.documents?.driving_license && (
                    <Box>
                      <StyledDetailValue>
                        <span style={{ marginRight: '20px' }}>Driving License: </span>
                        {selectedWorker?.documents?.driving_license.substring(
                          selectedWorker?.documents?.driving_license?.lastIndexOf('.') + 1
                        ) === 'pdf' ? (
                          <a
                            style={{ fontWeight: '500' }}
                            href={selectedWorker?.documents?.driving_license}
                            target="_blank"
                            rel="noreferrer"
                            download={false}>
                            <Box w="200px" h="150px">
                              <PdfDownloadIcon />
                            </Box>
                          </a>
                        ) : (
                          <img
                            src={selectedWorker?.documents?.driving_license}
                            alt="Driving License"
                            style={{ height: '150px', width: '200px', borderRadius: '10px' }}
                          />
                        )}
                      </StyledDetailValue>
                    </Box>
                  )}
                </SimpleGrid>
              </>
            ) : (
              <div />
            )
          }
        />
        <ModalComponent
          isOpen={isWorkerEditModalOpen}
          onClose={closeEditModalHandler}
          modalTitle={
            userDetails.user_type_id === USER_TYPE.CLIENT_ADMIN ||
            userDetails.user_type_id === USER_TYPE.MESSAGE_ADMIN
              ? constants.WORKER.PERMANENT_WORKER_EDIT_TEXT
              : constants.WORKER.WORKER_EDIT_TEXT
          }
          modalContent={
            selectedWorker ? (
              <AddWorker
                edit
                data={selectedWorker}
                setData={setSelectedWorker}
                refresh={refresh}
                validations={validations}
                departmentValue={workerData.department_id}
                CompanyValue={workerData.client_id}
                agencyValue={workerData.agency_id}
                userType={userDetails.user_type}
                passportFile={selectPassportFile}
                utilityBillFile={selectUtilityBill}
                identityCardFile={selectIdentityCard}
                // rightToWorkFile={selectRightToWork}
                drivingLicenseFile={selectDrivingLicense}
                disableSupervisorStatusDropdown={disableSupervisorStatusDropdown}
              />
            ) : (
              <div />
            )
          }
          negative={
            <Button
              bg="main.secondary"
              mr={3}
              _hover={{ bg: 'main.secondary' }}
              color="main.black"
              onClick={closeEditModalHandler}>
              {constants.WORKER.CANCEL_BUTTON_TEXT}
            </Button>
          }
          positive={
            <Button
              bg="main.semiPrimary"
              color="main.white"
              _hover={{ bg: 'main.primary' }}
              _active={{
                bg: 'main.semiPrimary',
                borderColor: 'main.primary'
              }}
              onClick={editWorkerHandler}>
              {constants.WORKER.EDIT_UPDATE_BUTTON_TEXT}
            </Button>
          }
        />
        <ModalComponent
          isOpen={isBulkUploadWorkerModalOpen}
          onClose={() => onCloseBulkWorkerModal()}
          modalTitle={
            userDetails.user_type_id === USER_TYPE.AGENCY_ADMIN ||
            userDetails.user_type_id === USER_TYPE.AGENCY_SITE_ADMIN ||
            userDetails.user_type_id === USER_TYPE.AGENCY_REGION_ADMIN
              ? edit
                ? constants.WORKER.EDIT_HEADER_TEXT
                : constants.WORKER.STATIC_HEADER_TEXT
              : edit
              ? constants.WORKER.EDIT_PERMANENT_WORKER_TEXT
              : constants.WORKER.CREATE_PERMANENT_BUTTON_TEXT
          }
          modalContent={
            <Flex flexDir="column" alignItems="center" justifyContent="center">
              {(userDetails.user_type_id === USER_TYPE.CLIENT_ADMIN ||
                userDetails.user_type_id === USER_TYPE.MESSAGE_ADMIN) && (
                <Box width="250px">
                  <SiteDropdown
                    label="Site"
                    site={site}
                    setSite={setSite}
                    disabled={false}
                    multiple={false}
                    clientId={userDetails.client_id}
                  />
                </Box>
              )}
              {userDetails.user_type_id === USER_TYPE.CLIENT_ADMIN ||
              userDetails.user_type_id === USER_TYPE.MESSAGE_ADMIN ? (
                site.value ? (
                  <>
                    {!loader ? (
                      <input
                        style={{ width: '250px' }}
                        type="file"
                        onChange={selectFile}
                        onClick={(e) => {
                          e.target.value = null;
                        }}
                      />
                    ) : (
                      <Flex alignItems="center">
                        <Text mr="10px">We are uploading the file, so please stay tuned</Text>
                        <Spinner size="lg" color="main.primary"></Spinner>
                      </Flex>
                    )}
                  </>
                ) : (
                  ''
                )
              ) : (
                <>
                  {!loader ? (
                    <input
                      type="file"
                      onChange={selectFile}
                      onClick={(e) => {
                        e.target.value = null;
                      }}
                    />
                  ) : (
                    <Flex alignItems="center">
                      <Text mr="10px">We are uploading the file, so please stay tuned</Text>
                      <Spinner size="lg" color="main.primary"></Spinner>
                    </Flex>
                  )}
                </>
              )}
            </Flex>
          }
          negative={
            <Button
              bg="main.secondary"
              mr={3}
              _hover={{ bg: 'main.secondary' }}
              color="main.black"
              disabled={loader}
              onClick={() => onCloseBulkWorkerModal()}>
              {constants.WORKER.CANCEL_BUTTON_TEXT}
            </Button>
          }
          positive={
            <Button
              bg="main.semiPrimary"
              color="main.white"
              _hover={{ bg: 'main.primary' }}
              _active={{
                bg: 'main.semiPrimary',
                borderColor: 'main.primary'
              }}
              disabled={!selectedFile || loader}
              onClick={() => saveBulkWorkerData()}>
              {edit ? constants.WORKER.EDIT_UPDATE_BUTTON_TEXT : constants.WORKER.SAVE_BUTTON_TEXT}
            </Button>
          }
        />
        <ModalComponent
          isOpen={isUpdateBulkUploadWorkerModalOpen}
          onClose={() => onCloseUpdateBulkWorkerModal()}
          modalTitle={constants.WORKER.STATIC_UPDATE_HEADER_TEXT}
          modalContent={
            <>
              {!loader ? (
                <Flex flexDir="column" alignItems="center" justifyContent="center">
                  <input
                    type="file"
                    onChange={selectFile}
                    onClick={(e) => {
                      e.target.value = null;
                    }}
                  />
                </Flex>
              ) : (
                <Flex alignItems="center" justifyContent="center">
                  <Text mr="10px">We are uploading the file, so please stay tuned</Text>
                  <Spinner size="md" color="main.primary"></Spinner>
                </Flex>
              )}
            </>
          }
          negative={
            <Button
              bg="main.secondary"
              mr={3}
              _hover={{ bg: 'main.secondary' }}
              color="main.black"
              disabled={loader}
              onClick={() => onCloseUpdateBulkWorkerModal()}>
              {constants.WORKER.CANCEL_BUTTON_TEXT}
            </Button>
          }
          positive={
            <Button
              bg="main.semiPrimary"
              color="main.white"
              _hover={{ bg: 'main.primary' }}
              _active={{
                bg: 'main.semiPrimary',
                borderColor: 'main.primary'
              }}
              disabled={!selectedFile || loader}
              onClick={() => UpdateBulkWorkerData()}>
              {edit ? constants.WORKER.EDIT_UPDATE_BUTTON_TEXT : constants.WORKER.SAVE_BUTTON_TEXT}
            </Button>
          }
        />
        <ModalComponent
          isOpen={isSearchModalOpen}
          onClose={cancelSearchBtnClickHandler}
          modalTitle={constants.WORKER.SEARCH_WORKER}
          modalContent={
            <SearchWorker
              data={searchWorkerData}
              setData={setsearchWorkerData}
              validations={searchvalidations}
            />
          }
          negative={
            <Button
              bg="main.secondary"
              mr={3}
              _hover={{ bg: 'main.secondary' }}
              color="main.black"
              onClick={createFilterBtnClickHandler}>
              {constants.WORKER.CLEAR_FILTER_BUTTON_TEXT}
            </Button>
          }
          positive={
            <Button
              bg="main.semiPrimary"
              color="main.white"
              _hover={{ bg: 'main.primary' }}
              _active={{
                bg: 'main.semiPrimary',
                borderColor: 'main.primary'
              }}
              onClick={searchWorkerBtnClickHandler}>
              {constants.WORKER.SEARCH_BUTTON}
            </Button>
          }
        />
        <ModalComponent
          isOpen={isWorkerModalOpen}
          onClose={cancelBtnClickHandler}
          modalTitle={
            userDetails.user_type_id === USER_TYPE.AGENCY_ADMIN ||
            userDetails.user_type_id === USER_TYPE.AGENCY_SITE_ADMIN ||
            userDetails.user_type_id === USER_TYPE.AGENCY_REGION_ADMIN
              ? edit
                ? constants.WORKER.EDIT_HEADER_TEXT
                : constants.WORKER.STATIC_HEADER_TEXT
              : edit
              ? constants.WORKER.EDIT_PERMANENT_WORKER_TEXT
              : workerType === 'PERMANENT'
              ? constants.WORKER.CREATE_PERMANENT_BUTTON_TEXT
              : constants.WORKER.STATIC_HEADER_TEXT
          }
          modalContent={
            <AddWorker
              setData={setWorkerData}
              data={workerData}
              departmentValue={workerData.department_id}
              CompanyValue={workerData.client_id}
              agencyValue={workerData.agency_id}
              validations={validations}
              refresh={refresh}
              userType={userDetails.user_type}
              // passportFile={selectPassportFile}
              // utilityBillFile={selectUtilityBill}
              // identityCardFile={selectIdentityCard}
              // rightToWorkFile={selectRightToWork}
              // drivingLicenseFile={selectDrivingLicense}
            />
          }
          negative={
            <Button
              bg="main.secondary"
              mr={3}
              _hover={{ bg: 'main.secondary' }}
              color="main.black"
              onClick={cancelBtnClickHandler}>
              {constants.WORKER.CANCEL_BUTTON_TEXT}
            </Button>
          }
          positive={
            <Button
              bg="main.semiPrimary"
              color="main.white"
              _hover={{ bg: 'main.primary' }}
              _active={{
                bg: 'main.semiPrimary',
                borderColor: 'main.primary'
              }}
              onClick={addWorkerBtnClickHandler}>
              {edit ? constants.WORKER.EDIT_UPDATE_BUTTON_TEXT : constants.WORKER.SAVE_BUTTON_TEXT}
            </Button>
          }
        />

        {/* {workersData.length > 0 && ( */}
        <ServerSideDatatable
          pageSize={10}
          initialSortKey="first_name"
          column={columns}
          onPaginate={searchData > 0 ? retrieveSearchWorkerData : retrieveWorkerData}
          workerType={workerType}
          userType={userDetails.user_type_id}
          // getWorkers(
          //   selected_client_id || userDetails.client_id,
          //   userDetails.agency_id,
          //   selected_site_id,
          //   ...params
          // );
          afterPaginateData={() => setIsPageLoader(false)}
          pagination
          pages={[10, 15]}
          moveToFirstPage={searchData}
          dataKey="workers"
          refresh={reload}
        />
        {/* <ClientSideDatatable
          pageSize={10}
          initialSortKey="id"
          column={columns}
          row={workersData}
          pagination
          pages={[2, 5, 10, 15]}
          refresh={reload}
        /> */}
        {/* )} */}
      </Card>
    </StyledWorkerContainer>
  );
};

Worker.propTypes = {};

export default Worker;
