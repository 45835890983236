/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
import React from 'react';
import { Center, Tooltip } from '@chakra-ui/react';

const ChartCardTitle = ({ title, label }) => {
  return (
    <Tooltip borderRadius="7px" textAlign="center" label={label}>
      <Center mt={2} fontWeight="semibold">
        {title}
      </Center>
    </Tooltip>
  );
};

export default ChartCardTitle;
