/* eslint-disable react/prop-types */
import React from 'react';

import Rating from 'react-rating';
import { RATING_STAR_COLOR } from '../../constants';
import StarIcon from '../../icons/StarIcon';

const RatingComponent = function ({ value }) {
  return (
    <Rating
      start={0}
      stop={5}
      initialRating={value}
      readonly
      emptySymbol={<StarIcon fillColor="none" />}
      fullSymbol={<StarIcon fillColor={RATING_STAR_COLOR} />}
      fractions={10}
      size={20}
    />
  );
};

export default RatingComponent;
