/* eslint-disable radix */
import { API_ENDPOINT } from '../../constants';
import axiosInstance from '../../config/axios.config';
import { prepareErrorResponse, prepareSuccessReponse } from '../../utils/responseHandler';

const getCompliances = async (
  clientId,
  agencyId,
  regionId,
  siteId,
  Id,
  startDate,
  endDate,
  limit = 10,
  page = 1,
  sortKey = 'first_name',
  sortType = 'DESC'
) => {
  let query = '?';
  query += agencyId ? `agency_id=${parseInt(agencyId)}` : '';
  query += siteId && agencyId ? '&' : '';
  query += siteId ? `site_id=${parseInt(siteId)}` : '';
  query += regionId && agencyId ? '&' : '';
  query += regionId ? `region_id=${parseInt(regionId)}` : '';
  const result = await axiosInstance.get(
    `${API_ENDPOINT.GET_COMPLIANCE_DATA}${clientId}${API_ENDPOINT.COMPLIANCE}${Id}${query}&page=${page}&limit=${limit}&sort_by=${sortKey}&sort_type=${sortType}&start_date=${startDate}&end_date=${endDate}`
  );
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const getCompliancesCountData = async (
  clientId,
  agencyId,
  regionId,
  siteId,
  startDate,
  endDate
) => {
  let query = '?';
  query += agencyId ? `agency_id=${parseInt(agencyId)}` : '';
  query += siteId && agencyId ? '&' : '';
  query += siteId ? `site_id=${parseInt(siteId)}` : '';
  query += regionId && agencyId ? '&' : '';
  query += regionId ? `region_id=${parseInt(regionId)}` : '';
  const date =
    clientId && !agencyId && !siteId && !regionId
      ? `start_date=${startDate}`
      : `&start_date=${startDate}`;

  const result = await axiosInstance.get(
    `${API_ENDPOINT.GET_COMPLIANCE_DATA}${clientId}${API_ENDPOINT.COMPLIANCES}${query}${date}&end_date=${endDate}`
  );
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

export { getCompliances, getCompliancesCountData };
