/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-undef */
/* eslint-disable camelcase */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable radix */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React, { useEffect, useState } from 'react';
import { Box } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import '../../components/Forms/formStyles.css';
import { USER_TYPE, PAGE_TITLE, DATE_TO_SHOW } from '../../constants';
import Card from '../../components/Card';
import ServerSideDatatable from '../../components/ServerSideDatatable';
import AgencyDropdown from '../../components/Forms/AgencyDropdown';
import { getSites } from '../../redux/action/site.action';
import { getSupervisorsData } from '../../redux/action/agencyManagement.action';
import SupervisorDataModal from '../SupervisorDataModal';

const INITIAL_COLUMNS_TEMPLATE = (handleWeekClick) => [
  {
    label: 'Week',
    field: 'week',
    sort: true,
    customRow: (cell, row) => (
      <span
        style={{ cursor: 'pointer', color: 'blue' }}
        onClick={() => handleWeekClick(row.payroll_meta_id)}
        role="button"
        tabIndex="0">
        {cell}
      </span>
    )
  },
  {
    label: 'Date',
    field: 'start_date',
    sort: true,
    customRow: (cell) => {
      return `${DATE_TO_SHOW(cell)}`;
    }
  },
  { label: 'Agency', field: 'agency_name', sort: true },
  { label: 'Site', field: 'site_name', sort: true },
  {
    label: 'Supervisor Hours',
    field: 'supervisor_hours',
    sort: true
  },
  {
    label: 'Supervisor Charges',
    field: 'supervisor_charges',
    sort: true,
    customRow: (cell) => {
      if (cell !== null && cell !== undefined) {
        return `£${cell}`;
      }
      return `-`;
    }
  },
  {
    label: 'Identified Supervisors',
    field: 'identified_supervisors',
    sort: true,
    customRow: (cell) => <span style={{ color: 'darkblue' }}>{cell}</span>
  },
  {
    label: 'Non Supervisor Hours',
    field: 'non_supervisor_hours',
    sort: true
  },
  {
    label: 'Non Supervisor Charges',
    field: 'non_supervisor_charges',
    sort: true,
    customRow: (cell) => {
      if (cell !== null && cell !== undefined) {
        return `£${cell}`;
      }
      return `-`;
    }
  },
  {
    label: 'Total Charges',
    field: 'total_charge',
    sort: true,
    customRow: (cell) => {
      if (cell !== null && cell !== undefined) {
        return `£${cell}`;
      }
      return `-`;
    }
  }
];

const AgencyManagement = function () {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPayrollMetaId, setSelectedPayrollMetaId] = useState(null);

  useEffect(() => {
    document.title = PAGE_TITLE.AGENCY_MANAGEMENT;
  }, []);

  const handleWeekClick = (payrollMetaId) => {
    setSelectedPayrollMetaId(payrollMetaId);
    setIsModalOpen(true);
  };

  const { selected_client_id } = useSelector((state) => state.agency);
  const { user_details } = useSelector((state) => state.authentication);
  const { selected_site_id, selected_start_date, selected_end_date } = useSelector(
    (state) => state.agency
  );
  /* eslint-disable-next-line no-unused-vars */
  const [isPageLoader, setIsPageLoader] = useState(false);
  /* eslint-disable-next-line no-unused-vars */
  const [columns, setColumns] = useState(INITIAL_COLUMNS_TEMPLATE(handleWeekClick));
  const [reload, setReload] = useState(false);
  const [agency, setAgency] = useState({});
  const [sitesList, setSitesList] = useState([]);

  const getSitesFunction = async () => {
    const sites = await getSites(user_details.client_id);
    if (sites && sites.ok) {
      setSitesList(sites.data.sites);
    }
  };

  if (user_details.user_type_id === USER_TYPE.AGENCY_SITE_ADMIN) {
    useEffect(() => {
      getSitesFunction();
    }, []);
  }

  useEffect(() => {
    setSitesList(
      sitesList.filter((value) => parseInt(value.id) === parseInt(user_details.site_id))
    );
  }, [sitesList.length]);

  const retrieveSupervisorsData = async (size, pageNumber, sortKey, sorting, id) => {
    const result = await getSupervisorsData(
      selected_client_id || user_details.client_id,
      agency.value,
      selected_start_date,
      selected_end_date,
      size,
      pageNumber,
      sortKey,
      sorting,
      id
    );
    if (result.ok && result.data && result.data.ok) {
      return {
        data: {
          supervisors_data: result.data.supervisors_data,
          count: result.data.count
        }
      };
    }
    return {
      data: {
        supervisors_data: [],
        count: 0
      }
    };
  };

  const setAgencyData = (e) => {
    setAgency(e);
    setReload(!reload);
  };

  useEffect(() => {
    setReload(!reload);
  }, [selected_client_id, selected_site_id, selected_start_date, selected_end_date]);

  return (
    <>
      <Card>
        {user_details.user_type_id === USER_TYPE.CLIENT_ADMIN && (
          <Box width="300px" mr="15px">
            <AgencyDropdown agency={agency} refresh={reload} setAgency={setAgencyData} />
          </Box>
        )}

        <ServerSideDatatable
          pageSize={10}
          initialSortKey="agency_id"
          column={columns}
          onPaginate={retrieveSupervisorsData}
          afterPaginateData={() => setIsPageLoader(false)}
          pagination
          pages={[5, 10, 15]}
          dataKey="supervisors_data"
          refresh={reload}
        />
      </Card>
      <SupervisorDataModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        payrollMetaId={selectedPayrollMetaId}
      />
    </>
  );
};

export default AgencyManagement;
