/* eslint-disable react/prop-types */
import React from 'react';
import { Box, Flex, Image, Tooltip } from '@chakra-ui/react';
import { buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import greenArrowUp from '../../assets/images/greenArrowUp.png';
import redArrowDown from '../../assets/images/redArrowDown.png';
import { colors } from '../../theme';
import { CIRCULAR_CHART_COLORS } from '../../constants';
import { currancyFormatter } from '../../utils/helper';

const CircularChart = function ({
  value = 0,
  valueType = '',
  max,
  styles,
  positive,
  difference,
  maxW,
  displayValue = 0,
  isIndicatorsVisible = false,
  fontSize = '4xl',
  label,
  count,
  extraLabel,
  extraCount,
  ...props
}) {
  return (
    <Box margin="auto" maxW={maxW} w="100%" mt={2} p={2}>
      <CircularProgressbarWithChildren
        maxValue={max}
        value={value}
        styles={styles ? buildStyles(styles) : ''}
        {...props}>
        <Box color={CIRCULAR_CHART_COLORS.TEXT_COLOR} fontSize={fontSize}>
          <Tooltip
            backgroundColor="#e9f4ff"
            w="170px"
            color="black"
            border="none"
            fontWeight="500"
            p="8px"
            borderRadius="7px"
            label={
              label ? (
                <Flex flexDirection="column">
                  <Box textAlign="center">{`${label} : ${count}`}</Box>
                  {extraLabel && <Box textAlign="center">{`${extraLabel} : ${extraCount}`}</Box>}
                </Flex>
              ) : (
                ''
              )
            }>
            {valueType === '£' ? currancyFormatter(displayValue) : `${displayValue}${valueType}`}
          </Tooltip>
        </Box>
        {isIndicatorsVisible &&
          (positive ? (
            <Box>
              <Image src={greenArrowUp} height={8} />
            </Box>
          ) : (
            <Box>
              <Image src={redArrowDown} height={8} />
            </Box>
          ))}
        {isIndicatorsVisible && (
          <Box
            mt={1}
            fontSize="2xl"
            color={positive ? colors.main.positiveGreen : colors.main.negativeRed}>
            {difference}%
          </Box>
        )}
      </CircularProgressbarWithChildren>
      {/* <CircularProgressbar
        value={value}
        maxValue={max}
        text={`${value}${valueType}`}
        styles={styles ? buildStyles(styles) : ''}
        {...props}
      /> */}
    </Box>
  );
};

export default CircularChart;
