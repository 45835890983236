import React from 'react';

const HomeIcon = function () {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none" viewBox="0 0 22 21">
      <path
        fill="currentColor"
        d="M10.708 5.448l-7.041 7.119v7.683c0 .199.064.39.179.53.115.141.27.22.432.22l4.28-.014a.561.561 0 00.43-.22.845.845 0 00.178-.53V15.75c0-.199.064-.39.179-.53a.56.56 0 01.432-.22h2.444a.56.56 0 01.432.22c.115.14.18.332.18.53v4.484a.9.9 0 00.045.288.78.78 0 00.133.244.62.62 0 00.198.163.514.514 0 00.234.057l4.278.015a.56.56 0 00.433-.22.844.844 0 00.178-.53v-7.688l-7.04-7.114a.416.416 0 00-.291-.127.416.416 0 00-.293.127zm11.123 4.838l-3.193-3.23V.563a.634.634 0 00-.134-.398A.42.42 0 0018.18 0H16.04a.42.42 0 00-.324.165.634.634 0 00-.134.398v3.404L12.163.513C11.835.182 11.423.001 11 .001c-.425 0-.837.18-1.165.512L.166 10.287a.554.554 0 00-.113.17.66.66 0 00-.033.428c.018.071.047.137.085.194l.974 1.453a.49.49 0 00.139.14.377.377 0 00.35.041.44.44 0 00.157-.105l8.983-9.082a.416.416 0 01.293-.127c.106 0 .209.045.292.127l8.983 9.082c.047.048.1.083.158.105a.377.377 0 00.35-.04.492.492 0 00.138-.14l.974-1.453a.61.61 0 00.084-.194.686.686 0 00-.035-.43.552.552 0 00-.114-.17z"
      />
    </svg>
  );
};

export default HomeIcon;
