/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Flex, Link, Spacer } from '@chakra-ui/react';
import InputComponent from '../Input';
import { StyledLoginForm, StyledFormBodyContainer, StyledLoginRoot } from './LoginBox.styled';
import StyledInputWrapper from '../Forms/Forms.styled';
import constants from '../../constants';
import PasswordField from '../Forms/PasswordField';
import CompanyLogoImage from '../../assets/images/New_Logo.png';

const LoginBox = function (props) {
  const {
    leftButtonText,
    leftButtonClick,
    rightButtonText,
    rightLinkTo,
    type,
    email,
    setEmail,
    password,
    setPassword,
    confirmPassword,
    setConfirmPassword,
    validations,
    refresh
  } = props;

  const renderForm = () => {
    switch (type) {
      case 'LOGIN':
        return (
          <Box>
            <Flex>
              <StyledInputWrapper>
                <InputComponent
                  lable={constants.EMAIL}
                  placeholder={constants.EMAIL}
                  type="email"
                  onChange={setEmail}
                  value={email}
                  validationObj={validations.email}
                  refresh={refresh}
                  maxLength="50"
                />
              </StyledInputWrapper>
            </Flex>
            <Flex>
              <StyledInputWrapper>
                <PasswordField
                  lable={constants.PASSWORD}
                  placeholder={constants.PASSWORD}
                  type="password"
                  value={password}
                  onChange={setPassword}
                  validationObj={validations.password}
                  refresh={refresh}
                  maxLength="15"
                />
              </StyledInputWrapper>
            </Flex>
          </Box>
        );
      case 'FORGOT-PASSWORD':
        return (
          <Flex>
            <StyledInputWrapper>
              <InputComponent
                lable={constants.EMAIL}
                placeholder={constants.EMAIL}
                type="email"
                onChange={setEmail}
                value={email}
                validationObj={validations.email}
                refresh={refresh}
                maxLength="50"
              />
            </StyledInputWrapper>
          </Flex>
        );
      case 'RESET-PASSWORD':
        return (
          <>
            <Flex>
              <StyledInputWrapper>
                <PasswordField
                  lable={constants.NEW_PASSWORD}
                  placeholder={constants.NEW_PASSWORD}
                  type="password"
                  value={password}
                  onChange={setPassword}
                  validationObj={validations.password}
                  refresh={refresh}
                  maxLength="15"
                  tooltip="true"
                />
              </StyledInputWrapper>
            </Flex>
            <Flex>
              <StyledInputWrapper>
                <PasswordField
                  lable={constants.CONFIRM_PASSWORD}
                  placeholder={constants.CONFIRM_PASSWORD}
                  type="password"
                  value={confirmPassword}
                  onChange={setConfirmPassword}
                  validationObj={validations.confirmPassword}
                  refresh={refresh}
                  maxLength="15"
                  tooltip="true"
                />
              </StyledInputWrapper>
            </Flex>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <StyledLoginRoot>
      <StyledLoginForm bg="main.white">
        <Flex w="100%" align="center" justify="center">
          {/* <CompanyLogo /> */}
          <img alt="ClearVue" src={CompanyLogoImage} style={{ margin: '30px' }} />
        </Flex>
        <StyledFormBodyContainer>{renderForm()}</StyledFormBodyContainer>

        <Flex w="100%" mt="5" align="center">
          {leftButtonText && (
            <Box>
              <Button
                bg="main.semiPrimary"
                color="main.white"
                _hover={{ bg: 'main.primary' }}
                _active={{
                  bg: 'main.semiPrimary',
                  borderColor: 'main.primary'
                }}
                onClick={leftButtonClick}>
                {leftButtonText}
              </Button>
            </Box>
          )}
          <Spacer />
          {rightButtonText && (
            <Link color="main.primary" href={rightLinkTo || '/'} fontSize="sm">
              {rightButtonText}
            </Link>
          )}
        </Flex>
      </StyledLoginForm>
    </StyledLoginRoot>
  );
};

LoginBox.propTypes = {
  confirmPassword: PropTypes.string,
  email: PropTypes.string,
  leftButtonClick: PropTypes.func,
  leftButtonText: PropTypes.string,
  password: PropTypes.string,
  rightButtonText: PropTypes.string,
  rightLinkTo: PropTypes.string,
  setConfirmPassword: PropTypes.func,
  setEmail: PropTypes.func,
  setPassword: PropTypes.func,
  type: PropTypes.string,
  validations: PropTypes.object,
  refresh: PropTypes.bool
};

LoginBox.defaultProps = {
  confirmPassword: '',
  email: '',
  leftButtonClick: () => {},
  leftButtonText: '',
  password: '',
  rightButtonText: '',
  rightLinkTo: '',
  setConfirmPassword: () => {},
  setEmail: () => {},
  setPassword: () => {},
  type: '',
  validations: {},
  refresh: false
};

export default LoginBox;
