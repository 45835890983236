/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-children-prop */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/prop-types */

import React from 'react';
import { CircularProgressLabel, CircularProgress, Flex, Box, Tooltip } from '@chakra-ui/react';
import { CIRCULAR_CHART_COLORS } from '../../constants';

const CircularProgressTile = function ({
  HoursRatio,
  workerHours,
  supervisorHours,
  shiftFulfillmentPer,
  requested,
  fulfilled,
  supervisorsPer,
  supervisroRequested,
  supervisroFullfilled
}) {
  return (
    <Flex>
      <CircularProgress
        value={HoursRatio}
        size="70px"
        color="red.400"
        thickness="5px"
        alignSelf="flex-start">
        <CircularProgressLabel>
          <Tooltip
            backgroundColor="#e9f4ff"
            w="170px"
            color="black"
            border="none"
            fontWeight="500"
            p="8px"
            borderRadius="7px"
            placement="right"
            label={
              <Flex flexDirection="column">
                <Box textAlign="center">Worker Hours: {workerHours}</Box>
                <Box textAlign="center">Supervisor Hours: {supervisorHours}</Box>
              </Flex>
            }>
            <Box color={CIRCULAR_CHART_COLORS.TEXT_COLOR}>{HoursRatio}</Box>
          </Tooltip>
        </CircularProgressLabel>
      </CircularProgress>
      <CircularProgress value={shiftFulfillmentPer} size="140px" color="blue.500" thickness="5px">
        <CircularProgressLabel>
          <Tooltip
            backgroundColor="#e9f4ff"
            w="130px"
            color="black"
            border="none"
            fontWeight="500"
            p="8px"
            borderRadius="7px"
            placement="bottom"
            label={
              <Flex flexDirection="column">
                <Box textAlign="center">Requested : {requested}</Box>
                <Box textAlign="center">Fulfilled : {fulfilled}</Box>
              </Flex>
            }>
            <Box color={CIRCULAR_CHART_COLORS.TEXT_COLOR}>{Math.round(shiftFulfillmentPer)}%</Box>
          </Tooltip>
        </CircularProgressLabel>
      </CircularProgress>
      <CircularProgress
        value={supervisorsPer}
        size="75px"
        color="blue.500"
        thickness="5px"
        alignSelf="flex-end">
        <CircularProgressLabel>
          <Tooltip
            backgroundColor="#e9f4ff"
            w="130px"
            color="black"
            border="none"
            fontWeight="500"
            p="8px"
            borderRadius="7px"
            placement="left"
            label={
              <Flex flexDirection="column">
                <Box textAlign="center">Requested : {supervisroRequested}</Box>
                <Box textAlign="center">Fulfilled : {supervisroFullfilled}</Box>
              </Flex>
            }>
            <Box fontSize={14} color={CIRCULAR_CHART_COLORS.TEXT_COLOR}>
              {supervisorsPer}%
            </Box>
          </Tooltip>
        </CircularProgressLabel>
      </CircularProgress>
    </Flex>
  );
};

export default CircularProgressTile;
