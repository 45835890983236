/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable no-empty */
/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
/* eslint-disable one-var */
/* eslint-disable no-shadow */

import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Button, Flex, useToast } from '@chakra-ui/react';

import DatePicker, { DateObject } from 'react-multi-date-picker';
import moment from 'moment';
import DepartmentDropdown from './DepartmentDropdown';
import StyledInputWrapper from './Forms.styled';
import ShiftDropdown from './ShiftDropdown';
import InputComponent from '../Input';
import constants, {
  REPEAT_SHIFT_BOOKIING_TYPE,
  TOAST_SETTINGS,
  WEEK_DAYS,
  DAYS_VALIDATION,
  DaySelectionList,
  BookingFormat
} from '../../constants';
import './formStyles.css';
import Dropdown from '../Dropdown';
import { allowNaturalNumber, allowTwoDecimalNumbers } from '../../utils/helper';
import { getClientById } from '../../redux/action/client.action';
import RequirementPerDayForm from '../RequirementPerDayForm';

const AddBooking = function ({ data, setData, validations, refresh, isValidDate }) {
  const toast = useToast();
  const calendarRef = useRef();
  const { weekday_start } = useSelector((state) => state.agency);
  const { user_details: userDetails } = useSelector((state) => state.authentication);

  const [dateRange, setDateRange] = useState();
  const [workersForEachDay, setWorkersForEachDay] = useState({ ...data.required_workers_heads });
  const [supervisorsForEachDay, setSupervisorsForEachDay] = useState({
    ...data.required_supervisors_heads
  });
  const [workerValidationObject, setWorkerValidationObject] = useState({});
  const [supervisorValidationObject, setSupervisorValidationObject] = useState({});
  const [selectweekDay, setselectweekDay] = useState();
  const [bookingFormat, setBookingFormat] = useState();
  const dayIndex = DaySelectionList.indexOf(weekday_start);

  const [department, setDepartment] = useState({
    label: data.department_name,
    value: data.department_id
  });
  const [shift, setShift] = useState({ label: data.shift_name, value: data.shift_id });

  const setDepartmentData = (e) => {
    setDepartment(e);
    setData((prevData) => ({ ...prevData, department_id: e.value, department_name: e.label }));
  };

  const setShiftData = (e) => {
    setShift(e);
    setData((prevData) => ({ ...prevData, shift_id: e.value, shift_name: e.label }));
  };
  const handelDateChange = (event) => {
    // setDateRange(event);
    // if (event.length === 2) {
    const client_index = typeof selectweekDay === 'number' ? selectweekDay : dayIndex;
    const current_index = event[0].weekDay.index;
    let momentStartDate, formattedStartDate, momentEndDate, formattedEndDate;
    if (client_index > current_index) {
      const forward_index = client_index - current_index - 1;
      const index = 7 - (client_index - current_index);
      momentStartDate = moment(event[0].toDate(), 'YYYY-MM-DD').subtract(index, 'days');
      formattedStartDate = momentStartDate.format('YYYY-MM-DD');
      momentEndDate = moment(event[0].toDate(), 'YYYY-MM-DD').add(forward_index, 'days');
      formattedEndDate = momentEndDate.format('YYYY-MM-DD');
    } else {
      const index = current_index - client_index;
      const forward_index = 7 - index - 1;
      momentStartDate = moment(event[0].toDate(), 'YYYY-MM-DD').subtract(index, 'days');
      formattedStartDate = momentStartDate.format('YYYY-MM-DD');
      momentEndDate = moment(event[0].toDate(), 'YYYY-MM-DD').add(forward_index, 'days');
      formattedEndDate = momentEndDate.format('YYYY-MM-DD');
    }

    setDateRange([new DateObject(formattedStartDate), new DateObject(formattedEndDate)]);
    setData((prevData) => ({
      ...prevData,
      start_date: formattedStartDate,
      end_date: formattedEndDate
    }));

    const numberOfDays = Math.abs(
      moment.duration(momentStartDate.diff(momentEndDate)).asDays() - 1
    );
    if (numberOfDays < 8) {
      const numberOfInputsValue = {};
      const workerValidationData = {};
      const supervisorValidationData = {};
      // eslint-disable-next-line no-plusplus
      for (let index = 1; index <= numberOfDays; index++) {
        numberOfInputsValue[index] = '';
        workerValidationData[index] = {
          required: true,
          errorMessage: '*Please Enter Valid Number',
          isvalid: true
        };
        supervisorValidationData[index] = {
          required: true,
          errorMessage: '*Please Enter Valid Number',
          isvalid: true
        };
      }
      calendarRef.current.closeCalendar();
      setWorkersForEachDay({ ...numberOfInputsValue });
      setSupervisorsForEachDay({ ...numberOfInputsValue });
      setWorkerValidationObject({ ...workerValidationData });
      setSupervisorValidationObject({ ...supervisorValidationData });
    } else {
      toast({
        ...TOAST_SETTINGS.SETTINGS,
        status: TOAST_SETTINGS.ERROR,
        title: 'Maximum select 7 days.'
      });
    }
  };

  const getClientByIdFunction = async () => {
    const result = await getClientById(userDetails.client_id);
    if (result && result.ok) {
      const weekDayIndex = DaySelectionList.indexOf(result.data.client_details.weekday_start);
      setselectweekDay(weekDayIndex);
      setBookingFormat(result?.data?.client_details?.booking_format);
    }
  };

  useEffect(() => {
    getClientByIdFunction();
  }, []);

  const setNumberOfWorker = (e, dayIndex, booking_Format) => {
    const requiredHeadData = { ...workersForEachDay };
    const validationData = { ...workerValidationObject };
    // eslint-disable-next-line radix
    if (e === '' || e == '0' || parseInt(e) < 10000) {
      const value = booking_Format !== 'HEADS' ? allowTwoDecimalNumbers(e) : allowNaturalNumber(e);
      requiredHeadData[dayIndex] = value;
      validationData[dayIndex] = {
        required: true,
        errorMessage: '*Please Enter Valid Number',
        isvalid: value !== ''
      };
      setWorkersForEachDay({ ...requiredHeadData });
      setWorkerValidationObject({ ...validationData });
    }
  };

  const setNumberOfSupervisor = (e, dayIndex, booking_Format) => {
    const requiredHeadData = { ...supervisorsForEachDay };
    const validationData = { ...supervisorValidationObject };
    // eslint-disable-next-line radix
    if (e === '' || e == '0' || parseInt(e) < 10000) {
      const value = booking_Format !== 'HEADS' ? allowTwoDecimalNumbers(e) : allowNaturalNumber(e);
      requiredHeadData[dayIndex] = value;
      validationData[dayIndex] = {
        required: true,
        errorMessage: '*Please Enter Valid Number',
        isvalid: value !== ''
      };
      setSupervisorsForEachDay({ ...requiredHeadData });
      setSupervisorValidationObject({ ...validationData });
    }
  };

  const checkIsPreviousDay = (index) => {
    // TODO : Un comment once validation needed
    const isDisabled = false;
    // const current = moment(new Date());
    // const dayCount = index - 1;
    // const todayDate = moment(data.start_date).add(dayCount, 'days');
    // if (current.isSame(todayDate) || todayDate.isBefore(current)) {
    //   isDisabled = true;
    // }
    return isDisabled;
  };

  useEffect(() => {
    const keys = Object.keys(workersForEachDay);
    let totalSum = 0;

    keys.forEach((key) => {
      // eslint-disable-next-line radix
      const value = parseFloat(workersForEachDay[key]);
      if (value) {
        totalSum += value;
      }
    });

    setData((prevData) => ({
      ...prevData,
      required_workers_total: totalSum,
      required_workers_heads: { ...workersForEachDay },
      worker_validation_data: { ...workerValidationObject }
    }));
  }, [workersForEachDay]);

  useEffect(() => {
    const keys = Object.keys(supervisorsForEachDay);
    let totalSum = 0;

    keys.forEach((key) => {
      const value = parseFloat(supervisorsForEachDay[key]);
      if (value) {
        totalSum += value;
      }
    });

    setData((prevData) => ({
      ...prevData,
      required_supervisors_total: totalSum,
      required_supervisors_heads: { ...supervisorsForEachDay },
      supervisor_validation_data: { ...supervisorValidationObject }
    }));
  }, [supervisorsForEachDay]);

  useEffect(() => {
    let startDate = '';
    let endDate = '';

    try {
      startDate = data.start_date ? new DateObject(data.start_date) : '';
    } catch (error) {}

    try {
      endDate = data.end_date ? new DateObject(data.end_date) : '';
    } catch (error) {}

    if (startDate && endDate) {
      setDateRange([startDate, endDate]);
      // Initialize validation objects based on the date range
      const numberOfDays = Math.abs(
        moment.duration(moment(startDate.toDate()).diff(moment(endDate.toDate()))).asDays() - 1
      );
      const workerValidationData = {};
      const supervisorValidationData = {};
      // eslint-disable-next-line no-plusplus
      for (let index = 1; index <= numberOfDays; index++) {
        workerValidationData[index] = {
          required: true,
          errorMessage: '*Please Enter Valid Number',
          isvalid: true
        };
        supervisorValidationData[index] = {
          required: true,
          errorMessage: '*Please Enter Valid Number',
          isvalid: true
        };
      }

      setWorkerValidationObject({ ...workerValidationData });
      setSupervisorValidationObject({ ...supervisorValidationData });
    }
  }, [data.start_date, data.end_date]);

  return (
    <Box p="10px">
      <Flex>
        <StyledInputWrapper>
          <p className={`input-label ${!isValidDate ? 'input-label-error' : ''}`}>Select Date*</p>
          <DatePicker
            format="DD/MM/YYYY"
            inputClass={`date-picker ${!isValidDate ? 'input-error' : ''}`}
            range
            // weekPicker
            onChange={handelDateChange}
            value={dateRange}
            ref={calendarRef}
            editable={false}
            // minDate={new Date()}
            weekStartDayIndex={typeof selectweekDay === 'number' ? selectweekDay : dayIndex}
            className="booking-datepicker">
            <Button
              bg="main.semiPrimary"
              color="main.white"
              _hover={{ bg: 'main.primary' }}
              _active={{
                bg: 'main.semiPrimary',
                borderColor: 'main.primary'
              }}
              onClick={() => calendarRef.current.closeCalendar()}
              size="xs"
              mb={4}>
              {constants.SHIFT_BOOKING.CANCEL_BUTTON_TEXT}
            </Button>
          </DatePicker>
          <p className="input-error-msg" style={{ visibility: isValidDate ? 'hidden' : 'visible' }}>
            *Please Select Date
          </p>
        </StyledInputWrapper>
      </Flex>
      <Flex>
        <StyledInputWrapper>
          <DepartmentDropdown
            department={department}
            refresh={refresh}
            setDepartment={setDepartmentData}
            validationObj={validations.department_id}
          />
        </StyledInputWrapper>
      </Flex>
      <Flex>
        <StyledInputWrapper>
          <ShiftDropdown
            shift={shift}
            setShift={setShiftData}
            multiple={false}
            refresh={refresh}
            validationObj={validations.shift_id}
          />
        </StyledInputWrapper>
      </Flex>
      <Flex>
        <StyledInputWrapper>
          <Dropdown
            label="Repeat"
            onSelect={(e) => setData((prevData) => ({ ...prevData, repeat: e.value }))}
            options={REPEAT_SHIFT_BOOKIING_TYPE.map((item) => {
              return { label: item, value: item };
            })}
            value={{ label: data.repeat, value: data.repeat }}
            placeholder=""
          />
        </StyledInputWrapper>
      </Flex>

      {workersForEachDay && Object.keys(workersForEachDay).length > 0 && (
        <>
          <Flex>
            <p className="input-label">Requirement Each Day Worker</p>
          </Flex>
          <Flex>
            <RequirementPerDayForm
              workersForEachDay={workersForEachDay}
              setNumberOfWorker={setNumberOfWorker}
              bookingFormat={bookingFormat}
              validationObject={workerValidationObject}
              refresh={refresh}
              checkIsPreviousDay={checkIsPreviousDay}
            />
          </Flex>
          <Flex>
            <h6>Total Workers: {data?.required_workers_total?.toFixed(2)}</h6>
          </Flex>
        </>
      )}
      {supervisorsForEachDay && Object.keys(supervisorsForEachDay).length > 0 && (
        <>
          <Flex>
            <p className="input-label">Requirement Each Day Supervisor</p>
          </Flex>
          <Flex>
            <RequirementPerDayForm
              workersForEachDay={supervisorsForEachDay}
              setNumberOfWorker={setNumberOfSupervisor}
              bookingFormat={bookingFormat}
              validationObject={supervisorValidationObject}
              refresh={refresh}
              checkIsPreviousDay={checkIsPreviousDay}
            />
          </Flex>
          <Flex>
            <h6>Total Supervisors: {data?.required_supervisors_total?.toFixed(2)}</h6>
          </Flex>
        </>
      )}
    </Box>
  );
};

export default AddBooking;
