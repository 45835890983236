/* eslint-disable import/prefer-default-export */
import { Flex } from '@chakra-ui/react';
import styled from 'styled-components';

const StyledPayrollContainer = styled.div`
  background-color: 'main.secondary';
`;

export const StyledPayrollHeaderContainer = styled(Flex)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export default StyledPayrollContainer;
