/* eslint-disable no-nested-ternary */
/* eslint-disable radix */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-vars */
/* eslint-disable no-alert */
/* eslint-disable no-return-await */
/* eslint-disable camelcase */
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  Box,
  Button,
  Center,
  Flex,
  SimpleGrid,
  Text,
  useToast,
  useMediaQuery
} from '@chakra-ui/react';
import DatePicker from 'react-multi-date-picker';
import ReactApexChart from 'react-apexcharts';
import { NavLink } from 'react-router-dom';
import moment from 'moment';
import Card from '../../components/Card';

import constants, {
  PATH,
  USER_TYPE,
  PAGE_TITLE,
  // CHART_COLORS_FOR_AGENCY,
  TOAST_SETTINGS,
  BLUE_COLOR_SHADES
} from '../../constants';

import Loader from '../../components/Loader';
import { getModulePermissions } from '../../utils/hooks';
import MessageTypeDropdown from '../../components/Forms/MessageTypeDropDown';
import MessagingView from '../../components/MessagingView';
import { StyledSurveysContainer } from './Surveys.styled';
import { colors } from '../../theme';
import { currancyFormatter, formatDate } from '../../utils/helper';
import SurveysDropdown from '../../components/Forms/SurveysDropdown';
import { getSurveyAnalysis, getSurveyAnalysisCSV } from '../../redux/action/survey.action';
import AgencyDropdownWithDefault from '../../components/Forms/AgencyDropdownWithDefault';
import CompanyDropdown from '../../components/Forms/CompanyDropdown';
import SiteDropdown from '../../components/Forms/SiteDropdown';
import DownloadIcon from '../../icons/DownloadIcon';
import NoData from '../../components/NoData';
import HeaderCard from '../../components/HeaderCard';
import WorkerTypeDropdown from '../../components/Forms/WorkerTypeDropdown';
import AgencyDropdown from '../../components/Forms/AgencyDropdown';
import SiteDropdownWithDefault from '../../components/Forms/SiteDropdownWithDefault';

const Surveys = function () {
  useEffect(() => {
    document.title = PAGE_TITLE.SURVEYS;
  }, []);

  const { userData, user_details: userDetails } = useSelector((state) => state.authentication);
  const { selected_client_id, selected_site_id } = useSelector((state) => state.agency);
  const [refresh, setRefresh] = useState(false);
  const [isPageLoader, setIsPageLoader] = useState(false);
  const [selectedSurvey, setSelectedSurvey] = useState(null);
  const [surveys, setSurveys] = useState([]);
  const toast = useToast();
  const calendarRef = useRef();
  const [startDate, setStartDate] = useState(
    moment(new Date(), 'YYYY-MM-DD').subtract(7, 'd').format('YYYY-MM-DD')
  );
  const [endDate, setEndDate] = useState(moment(new Date(), 'YYYY-MM-DD').format('YYYY-MM-DD'));
  const [dateRange, setDateRangeData] = useState([
    moment(new Date(), 'DD/MM/YYYY').subtract(7, 'd').format('DD/MM/YYYY'),
    moment(new Date(), 'DD/MM/YYYY').format('DD/MM/YYYY')
  ]);
  const [agency, setAgency] = useState({});
  const [client, setClient] = useState({});
  const [site, setSite] = useState({});

  const [isSmallerThan1420] = useMediaQuery('(max-width: 1420px)');
  const [isSmallerThan1870] = useMediaQuery('(max-width: 1870px)');
  const [workerType, setWorkerType] = useState({ label: 'Temporary Workers', value: 'TEMPORARY' });
  const [firstUpdate, setFirstUpdate] = useState(true);
  const [defaultSite, setDefaultSite] = useState({});

  const getSurveyListing = async () => {
    if (
      selectedSurvey &&
      selectedSurvey.value &&
      (client.value || userData.client_id || userDetails.client_id || selected_client_id)
    ) {
      const result = await getSurveyAnalysis(
        selectedSurvey.value,
        client.value ||
          userData.client_id ||
          userDetails.client_id ||
          selected_client_id ||
          undefined,
        agency.value
          ? agency.value
          : userDetails.user_type_id === USER_TYPE.AGENCY_REGION_ADMIN ||
            userDetails.user_type_id === USER_TYPE.AGENCY_SITE_ADMIN ||
            userDetails.user_type_id === USER_TYPE.AGENCY_ADMIN
          ? userDetails.agency_id
          : undefined,
        site.value || defaultSite.value
          ? site.value || defaultSite.value
          : userDetails.user_type_id === USER_TYPE.AGENCY_SITE_ADMIN ||
            userDetails.user_type_id === USER_TYPE.SITE_ADMIN
          ? userDetails.site_id
          : undefined,
        userDetails.user_type_id === USER_TYPE.AGENCY_REGION_ADMIN ||
          userDetails.user_type_id === USER_TYPE.REGION_ADMIN
          ? userDetails.region_id
          : '',
        workerType.value,
        startDate,
        endDate
      );
      if (result && result.ok) {
        const chartData = result.data.questions.map((item) => {
          const labels = [];
          const values = [];
          item.options.forEach((option) => {
            if (option.name && option.count) {
              labels.push(option.name);
              values.push(parseInt(option.count));
            }
          });

          return {
            label: item.label,
            question: item.question_text,
            data: {
              options: {
                chart: {
                  type: 'donut'
                },
                legend: {
                  show: {
                    show: false
                  },
                  position: 'bottom'
                },
                colors: BLUE_COLOR_SHADES,
                labels: [...labels],
                dataLabels: {
                  enabled: false
                },
                tooltip: {
                  followCursor: false,
                  custom({ series, seriesIndex }) {
                    // return `${labels[seriesIndex]} - ${series[seriesIndex]}`;
                    return `<div class="tooltip">  <div class="tooltip-inside"><span class="tooltip-circle" style="background-color: ${BLUE_COLOR_SHADES[seriesIndex]}"></span> <p style="text-overflow: ellipsis; overflow: hidden; whitespace: nowrap; max-width: 130px">${labels[seriesIndex]}</p></div> <p>${series[seriesIndex]}</p> </div>`;
                  }
                },
                plotOptions: {
                  pie: {
                    donut: {
                      size: '0%',
                      // background: colors.main.blueBackground,
                      labels: {
                        show: false,
                        name: {
                          show: false
                        },
                        value: {
                          show: false
                        }
                      }
                    }
                  }
                }
              },
              series: [...values]
            }
          };
        });
        setSurveys([...chartData]);
      } else {
        toast({
          ...TOAST_SETTINGS.SETTINGS,
          status: TOAST_SETTINGS.ERROR,
          title: result.error
        });
      }
    }
  };

  const setDateRange = (event) => {
    setDateRangeData([...event]);
    if (event.length === 2) {
      const start = moment(`${event[0].year}/${event[0].month}/${event[0].day}`).format(
        'YYYY-MM-DD'
      );
      const end = moment(`${event[1].year}/${event[1].month}/${event[1].day}`).format('YYYY-MM-DD');
      setStartDate(moment(start).format('YYYY-MM-DD'));
      setEndDate(moment(end).format('YYYY-MM-DD'));
      // calendarRef.current.closeCalendar();
    }
  };

  const downloadCSVData = async () => {
    const result = await getSurveyAnalysisCSV(
      selectedSurvey.value,
      client.value ||
        userData.client_id ||
        userDetails.client_id ||
        selected_client_id ||
        undefined,
      agency.value || userDetails.agency_id || undefined,
      site.value || defaultSite.value || userDetails.site_id || undefined,
      userDetails.region_id,
      workerType.value,

      startDate,
      endDate
    );
    if (result && result.ok) {
      let header = [];
      if (selectedSurvey.label === 'Pay Query Survey') {
        if (
          userDetails.user_type_id === USER_TYPE.CLIENT_ADMIN ||
          userDetails.user_type_id === USER_TYPE.MESSAGE_ADMIN ||
          userDetails.user_type_id === USER_TYPE.SITE_ADMIN ||
          userDetails.user_type_id === USER_TYPE.REGION_ADMIN
        ) {
          header = ['worker_employee_id', 'worker_first_name', 'worker_last_name', 'worker_type'];
        } else {
          header = ['worker_employee_id', 'worker_first_name', 'worker_last_name'];
        }
      } else if (selectedSurvey.label === 'No Hours Survey') {
        if (
          userDetails.user_type_id === USER_TYPE.CLIENT_ADMIN ||
          userDetails.user_type_id === USER_TYPE.MESSAGE_ADMIN ||
          userDetails.user_type_id === USER_TYPE.SITE_ADMIN ||
          userDetails.user_type_id === USER_TYPE.REGION_ADMIN
        ) {
          header = [
            'worker_employee_id',
            'worker_site',
            'worker_role',
            'worker_shift',
            'worker_department',
            'worker_type'
          ];
        } else {
          header = [
            'worker_employee_id',
            'worker_site',
            'worker_role',
            'worker_shift',
            'worker_department'
          ];
        }
      } else if (
        userDetails.user_type_id === USER_TYPE.CLIENT_ADMIN ||
        userDetails.user_type_id === USER_TYPE.MESSAGE_ADMIN ||
        userDetails.user_type_id === USER_TYPE.SITE_ADMIN ||
        userDetails.user_type_id === USER_TYPE.REGION_ADMIN
      ) {
        header = ['worker_site', 'worker_role', 'worker_shift', 'worker_department', 'worker_type'];
      } else {
        header = ['worker_site', 'worker_role', 'worker_shift', 'worker_department'];
      }

      const questionIdSequences = [];
      const rows = [];

      result.data.questions.forEach((question) => {
        header.push(question.question_text);
        questionIdSequences.push(question.id);
      });
      if (selectedSurvey.label === 'Pay Query Survey') {
        header.push('Date');
      }
      header.push('\r\n');

      result.data.surveys.forEach((survey) => {
        const row = [];
        if (selectedSurvey.label === 'Pay Query Survey') {
          row.push(survey.worker_employee_id);
          row.push(survey.worker_first_name);
          row.push(survey.worker_last_name);
          if (
            userDetails.user_type_id === USER_TYPE.CLIENT_ADMIN ||
            userDetails.user_type_id === USER_TYPE.MESSAGE_ADMIN ||
            userDetails.user_type_id === USER_TYPE.SITE_ADMIN ||
            userDetails.user_type_id === USER_TYPE.REGION_ADMIN
          ) {
            row.push(survey.worker_type);
          }
        } else if (selectedSurvey.label === 'No Hours Survey') {
          row.push(survey.worker_employee_id);
          row.push(survey.site_name);
          row.push(survey.worker_role);
          row.push(survey.shift_name);
          row.push(survey.department_name);
          if (
            userDetails.user_type_id === USER_TYPE.CLIENT_ADMIN ||
            userDetails.user_type_id === USER_TYPE.MESSAGE_ADMIN ||
            userDetails.user_type_id === USER_TYPE.SITE_ADMIN ||
            userDetails.user_type_id === USER_TYPE.REGION_ADMIN
          ) {
            row.push(survey.worker_type);
          }
        } else {
          row.push(survey.site_name);
          row.push(survey.worker_role);
          row.push(survey.shift_name);
          row.push(survey.department_name);
          if (
            userDetails.user_type_id === USER_TYPE.CLIENT_ADMIN ||
            userDetails.user_type_id === USER_TYPE.MESSAGE_ADMIN ||
            userDetails.user_type_id === USER_TYPE.SITE_ADMIN ||
            userDetails.user_type_id === USER_TYPE.REGION_ADMIN
          ) {
            row.push(survey.worker_type);
          }
        }
        questionIdSequences.forEach((id) => {
          if (survey.questions && survey.questions[id]) {
            const ans = survey.questions[id];
            if (ans.rating) {
              row.push(ans.rating);
            } else {
              let answerString = '';
              ans.answer.forEach((answer, index) => {
                answerString += `${index + 1}. ${answer} `;
              });
              row.push(answerString);
            }
          } else {
            row.push('');
          }
        });
        if (selectedSurvey.label === 'Pay Query Survey') {
          row.push(formatDate(survey.created_at));
        }
        row.push('\r\n');
        rows.push(row);
      });

      let csvData = header.join(',');
      rows.forEach((row) => {
        csvData += row.join(',');
      });
      const csvFile = new Blob([csvData], { type: 'text/csv' });
      const downloadLink = document.createElement('a');
      downloadLink.download = 'survey_analysis.csv';
      downloadLink.href = window.URL.createObjectURL(csvFile);
      downloadLink.style.display = 'none';
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } else {
      toast({
        ...TOAST_SETTINGS.SETTINGS,
        status: TOAST_SETTINGS.ERROR,
        title: result.error
      });
    }
  };

  const clearDateRange = () => {
    setDateRangeData([]);
    setStartDate(null);
    setEndDate(null);
  };

  useEffect(() => {
    setSite({});
  }, [client, userDetails.client_id]);

  useEffect(() => {
    if (firstUpdate) {
      setFirstUpdate(false);
    }
    if (selectedSurvey) {
      getSurveyListing();
    }
  }, [
    selectedSurvey,
    client,
    userDetails.client_id,
    selected_client_id,
    site,
    defaultSite,
    agency,
    endDate,
    startDate
  ]);

  useEffect(() => {
    if (!firstUpdate) {
      setSite({});
      setAgency({});
      if (selectedSurvey) {
        getSurveyListing();
      }
    }
  }, [workerType.value]);

  return (
    <StyledSurveysContainer>
      <Card>
        <Flex alignItems="center" justifyContent="space-between" m="8px 0">
          <Flex
            alignSelf={
              userDetails.user_type_id === USER_TYPE.REGION_ADMIN ||
              userDetails.user_type_id === USER_TYPE.SITE_ADMIN
                ? 'flex-start'
                : ''
            }
            flexDir={isSmallerThan1870 ? 'column' : 'row'}>
            <Flex>
              <Box width="210px" mr="8px">
                <SurveysDropdown
                  validationObj={{ isvalid: true }}
                  survey={selectedSurvey}
                  setSurvey={setSelectedSurvey}
                  refresh
                  setDefaultValue
                />
              </Box>
              <Box width="210px">
                <p className="input-label">Date Range</p>
                <DatePicker
                  format="DD/MM/YYYY"
                  inputClass="date-picker"
                  range
                  onChange={setDateRange}
                  onClose={(e) => {
                    if (dateRange.length < 2) {
                      clearDateRange();
                    }
                  }}
                  editable={false}
                  value={dateRange}
                  ref={calendarRef}
                  maxDate={new Date()}>
                  <Button
                    bg="main.semiPrimary"
                    color="main.white"
                    _hover={{ bg: 'main.primary' }}
                    _active={{
                      bg: 'main.semiPrimary',
                      borderColor: 'main.primary'
                    }}
                    onClick={() => {
                      setDateRangeData([]);
                    }}
                    size="xs"
                    mb={4}>
                    {constants.DASHBOARD_VIEW.CANCEL_BUTTON_TEXT}
                  </Button>
                </DatePicker>
              </Box>
            </Flex>
            {(userDetails.user_type_id === USER_TYPE.CLIENT_ADMIN ||
              userDetails.user_type_id === USER_TYPE.AGENCY_ADMIN) && (
              <Box w="214px" display="block" m={isSmallerThan1870 ? '25px 0px' : '25px 8px'}>
                <NavLink to={PATH.SURVEY_UPDATE.TO} color="main.primary" fontSize="sm">
                  <Button
                    bg="main.semiPrimary"
                    color="main.white"
                    _hover={{ bg: 'main.primary' }}
                    _active={{
                      bg: 'main.semiPrimary',
                      borderColor: 'main.primary'
                    }}>
                    Modify General Survey
                  </Button>
                </NavLink>
              </Box>
            )}
          </Flex>
          <Flex alignSelf={userDetails.user_type_id === USER_TYPE.AGENCY_ADMIN ? 'flex-start' : ''}>
            <Flex
              flexDir={
                isSmallerThan1870 &&
                (userDetails.user_type_id === USER_TYPE.CLIENT_ADMIN ||
                  userDetails.user_type_id === USER_TYPE.MESSAGE_ADMIN ||
                  userDetails.user_type_id === USER_TYPE.REGION_ADMIN) &&
                'column'
              }>
              {userDetails.user_type_id === USER_TYPE.AGENCY_ADMIN && (
                <Box width="210px" mr="8px">
                  <SiteDropdownWithDefault
                    label="Site"
                    site={defaultSite}
                    setSite={setDefaultSite}
                    disabled={false}
                    multiple={false}
                    clientId={selected_client_id}
                    setDefaultValue
                  />
                </Box>
              )}
              {(userDetails.user_type_id === USER_TYPE.REGION_ADMIN ||
                userDetails.user_type_id === USER_TYPE.CLIENT_ADMIN ||
                userDetails.user_type_id === USER_TYPE.MESSAGE_ADMIN ||
                userDetails.user_type_id === USER_TYPE.AGENCY_REGION_ADMIN) && (
                <Box width="210px" mr="8px">
                  <SiteDropdown
                    label="Site"
                    site={site}
                    setSite={setSite}
                    disabled={false}
                    multiple={false}
                    clientId={client.value}
                  />
                </Box>
              )}
              {userData.client_id &&
                (userDetails.user_type_id === USER_TYPE.CLIENT_ADMIN ||
                  userDetails.user_type_id === USER_TYPE.MESSAGE_ADMIN ||
                  userDetails.user_type_id === USER_TYPE.REGION_ADMIN ||
                  userDetails.user_type_id === USER_TYPE.SITE_ADMIN) && (
                  <Box width="210px" mr="10px">
                    <AgencyDropdown
                      disabled={workerType.value !== 'TEMPORARY'}
                      setDefaultValue
                      agency={agency}
                      setAgency={setAgency}
                      placeholder={workerType.value !== 'TEMPORARY' ? ' ' : 'All Agencies'}
                    />
                  </Box>
                )}
            </Flex>
            <Flex
              flexDir={
                isSmallerThan1870 &&
                (userDetails.user_type_id === USER_TYPE.AGENCY_SITE_ADMIN ||
                  userDetails.user_type_id === USER_TYPE.CLIENT_ADMIN ||
                  userDetails.user_type_id === USER_TYPE.MESSAGE_ADMIN ||
                  userDetails.user_type_id === USER_TYPE.REGION_ADMIN)
                  ? 'column'
                  : 'row'
              }
              justifyContent={isSmallerThan1870 ? 'space-between' : ''}>
              {/* {userDetails.user_type_id === USER_TYPE.AGENCY_ADMIN && (
                <Box width="210px">
                  <CompanyDropdown
                    setDefaultValue
                    company={client}
                    setCompany={setClient}
                    disabled={false}
                  />
                </Box>
              )} */}
              {(userDetails.user_type_id === USER_TYPE.CLIENT_ADMIN ||
                userDetails.user_type_id === USER_TYPE.MESSAGE_ADMIN ||
                userDetails.user_type_id === USER_TYPE.REGION_ADMIN ||
                userDetails.user_type_id === USER_TYPE.SITE_ADMIN) && (
                <Box w="210px">
                  <WorkerTypeDropdown
                    twoOptions
                    workerType={workerType}
                    setWorkerTypeDropdown={setWorkerType}
                    setDefaultValue
                    disabled={false}
                    multiple={false}
                  />
                </Box>
              )}
              <Box
                ml={2}
                alignSelf="center"
                mt={2}
                mb={
                  isSmallerThan1870 &&
                  (userDetails.user_type_id === USER_TYPE.CLIENT_ADMIN ||
                    userDetails.user_type_id === USER_TYPE.MESSAGE_ADMIN ||
                    userDetails.user_type_id === USER_TYPE.REGION_ADMIN) &&
                  '17px'
                }>
                <Button
                  bg="main.semiPrimary"
                  color="main.white"
                  _hover={{ bg: 'main.primary' }}
                  _active={{
                    bg: 'main.semiPrimary',
                    borderColor: 'main.primary'
                  }}
                  title="Download Survey Analysis"
                  onClick={downloadCSVData}>
                  <DownloadIcon />
                </Button>
              </Box>
            </Flex>
          </Flex>
        </Flex>
      </Card>
      <Box mt={4}>
        <SimpleGrid columns={3} spacingX={4} width={isSmallerThan1420 && '1183px'} spacingY={4}>
          {surveys.length > 0 &&
            surveys.map((item, index) => (
              <HeaderCard
                minWidth="390px"
                boxShadow="rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;"
                key={index}>
                <Box margin="8px 0">
                  <b>{item.label}</b>
                </Box>
                <Box margin="8px 0" h="64px">
                  <Text>{item.question}</Text>
                </Box>
                <ReactApexChart
                  options={item.data.options}
                  series={item.data.series}
                  type="donut"
                />
              </HeaderCard>
            ))}
        </SimpleGrid>
        {surveys.length === 0 && (
          <Card>
            {/* <Center>No Data Found!</Center> */}
            <NoData />
          </Card>
        )}
      </Box>
    </StyledSurveysContainer>
  );
};

Surveys.propTypes = {};

export default Surveys;
