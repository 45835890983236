/* eslint-disable array-callback-return */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-undef */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
import React from 'react';
import { Box, Center, Flex, Grid, GridItem, Skeleton, SkeletonCircle } from '@chakra-ui/react';
import DashboardContainer from '../DashboardContainer';
import DashboardCard from '../DashboardCard';
import ColumnChart from '../Charts/ColumnChart';
import ChartCardTitle from '../ChartCardTitle';
import NoData from '../NoData';
// import PieChart from '../Charts/PieChart';
import StackChart from '../Charts/StackChart';
import CircularChart from '../Charts/CircularChart';
import { colors } from '../../theme';
import {
  calculateLeverPercentage,
  calculatePercentage,
  calculatePercentageWithDecimalPoints
} from '../../utils/helper';
import DonuteChart from '../Charts/DonuteChart';
import { HEADER_COLORS, MIN_BARS_FOR_CHART, NEW_CHART_COLOR } from '../../constants';

const DashboardBottomCard = ({
  activityBottomCardShiftUtil,
  activityBottomCardSpend,
  activityBottomCardAverageHours,
  workforceLOS,
  leaverLOS,
  leaverNSR,
  leaverCount,
  activityBottomCardHeadCount,
  workforceShiftUti
  // isDataAvailable
}) => {
  const prepareSeries = (data) => {
    const newData = data.map((d) => {
      return d.data.length < 8
        ? { ...d, data: [...d.data, ...Array(MIN_BARS_FOR_CHART - d.data.length).fill(0)] }
        : d;
    });
    return newData;
  };

  const total = leaverNSR?.result?.rows?.reduce((prev, curr) => prev?.count + curr?.count, 0);

  return (
    <Flex mt={25}>
      <DashboardContainer header="" color={HEADER_COLORS.ACTIVITY_COLOR}>
        <Flex>
          {/* mb="5px" mr="5px" */}
          <DashboardCard
            m="5px"
            borderRadius="10px"
            boxShadow="rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px">
            <ChartCardTitle title="Shift Fulfilment" label="Requested vs Fulfilled %" />
            {activityBottomCardShiftUtil?.is_data_available ? (
              <>
                {activityBottomCardShiftUtil &&
                activityBottomCardShiftUtil?.result?.rows?.length > 0 ? (
                  <ColumnChart
                    // horizontal
                    series={
                      activityBottomCardShiftUtil?.result?.rows?.length < 8
                        ? [
                            ...activityBottomCardShiftUtil?.result?.rows?.map(
                              (d) => d.fulfilled_count
                            ),
                            ...Array(
                              MIN_BARS_FOR_CHART - activityBottomCardShiftUtil?.result?.rows?.length
                            ).fill(0)
                          ]
                        : activityBottomCardShiftUtil?.result?.rows?.map((d) => d.fulfilled_count)
                      // data: activityBottomCardShiftUtil.map((d) => d.fulfilled_count)
                    }
                    categories={
                      activityBottomCardShiftUtil?.result?.rows?.length < 8
                        ? [
                            ...activityBottomCardShiftUtil?.result?.rows?.map((d) => d.label),
                            ...Array(
                              MIN_BARS_FOR_CHART - activityBottomCardShiftUtil?.result?.rows?.length
                            ).fill(0)
                          ]
                        : activityBottomCardShiftUtil?.result?.rows?.map((d) => d.label)
                    }
                    percentage={[
                      {
                        data:
                          activityBottomCardShiftUtil?.result?.rows?.length < 8
                            ? [
                                ...activityBottomCardShiftUtil?.result?.rows?.map((d) => {
                                  return calculatePercentageWithDecimalPoints(
                                    d?.fulfilled_count,
                                    d?.lost_count
                                  );
                                }),
                                ...Array(
                                  MIN_BARS_FOR_CHART -
                                    activityBottomCardShiftUtil?.result?.rows?.length
                                ).fill(0)
                              ]
                            : activityBottomCardShiftUtil?.result?.rows?.map((d) => {
                                return calculatePercentageWithDecimalPoints(
                                  d?.fulfilled_count,
                                  d?.lost_count
                                );
                              })
                      }
                    ]}
                    // categories={activityBottomCardShiftUtil.map((d) => d.label)}
                    // styles={{
                    //   strokeLinecap: 'butt'
                    // }}
                    height="80%"
                    bottomDeck
                  />
                ) : (
                  <Skeleton h="200px" w="100%" />
                )}
              </>
            ) : (
              <NoData height="130px" width="130px" />
            )}
          </DashboardCard>
          {/* mb="5px"  */}
          <DashboardCard
            m="5px"
            borderRadius="10px"
            boxShadow="rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px">
            <ChartCardTitle
              title="Unfulfilled Shifts"
              label="Total Requested minus Total Fulfilled"
            />
            {activityBottomCardShiftUtil?.is_data_available ? (
              <>
                {activityBottomCardShiftUtil &&
                activityBottomCardShiftUtil?.result?.rows?.length > 0 ? (
                  <ColumnChart
                    // horizontal
                    series={
                      activityBottomCardShiftUtil?.result?.rows?.length < 8
                        ? [
                            ...activityBottomCardShiftUtil?.result?.rows?.map((d) => d.lost_count),
                            ...Array(
                              MIN_BARS_FOR_CHART - activityBottomCardShiftUtil?.result?.rows?.length
                            ).fill(0)
                          ]
                        : activityBottomCardShiftUtil?.result?.rows?.map((d) => d.lost_count)
                      // data: activityBottomCardShiftUtil.map((d) => d.lost_count)
                    }
                    categories={
                      activityBottomCardShiftUtil?.result?.rows?.length < 8
                        ? [
                            ...activityBottomCardShiftUtil?.result?.rows?.map((d) => d.label),
                            ...Array(
                              MIN_BARS_FOR_CHART - activityBottomCardShiftUtil?.result?.rows?.length
                            ).fill(0)
                          ]
                        : activityBottomCardShiftUtil?.result?.rows?.map((d) => d.label)
                    }
                    percentage={[
                      {
                        data:
                          activityBottomCardShiftUtil?.result?.rows?.length < 8
                            ? [
                                ...activityBottomCardShiftUtil?.result?.rows?.map((d) => {
                                  return calculatePercentageWithDecimalPoints(
                                    d?.lost_count,
                                    d?.fulfilled_count
                                  );
                                }),
                                ...Array(
                                  MIN_BARS_FOR_CHART -
                                    activityBottomCardShiftUtil?.result?.rows?.length
                                ).fill(0)
                              ]
                            : activityBottomCardShiftUtil?.result?.rows?.map((d) => {
                                return calculatePercentageWithDecimalPoints(
                                  d?.lost_count,
                                  d?.fulfilled_count
                                );
                              })
                      }
                    ]}
                    // categories={activityBottomCardShiftUtil.map((d) => d.label)}
                    // styles={{
                    //   strokeLinecap: 'butt'
                    // }}
                    height="80%"
                    bottomDeck
                  />
                ) : (
                  <Skeleton h="200px" w="100%" />
                )}
              </>
            ) : (
              <NoData height="130px" width="130px" />
            )}
          </DashboardCard>
        </Flex>
        <Flex>
          {/* mr="5px" */}
          <DashboardCard
            m="5px"
            height="315px"
            borderRadius="10px"
            boxShadow="rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px">
            <Box>
              <ChartCardTitle title="Spend" label=" Breakdown Of Spend By Agency" />
              {activityBottomCardSpend?.is_data_available ? (
                <>
                  <Center>
                    {activityBottomCardSpend && activityBottomCardSpend?.result?.rows.length > 0 ? (
                      <DonuteChart
                        series={activityBottomCardSpend?.result?.rows.map((d) => d.count)}
                        labels={activityBottomCardSpend?.result?.rows.map((d) => d.label)}
                        valueType="£"
                      />
                    ) : (
                      <SkeletonCircle size="40" margin="auto" />
                    )}
                  </Center>
                </>
              ) : (
                <NoData height="130px" width="130px" />
              )}
            </Box>
          </DashboardCard>
          <DashboardCard
            m="5px"
            height="315px"
            borderRadius="10px"
            boxShadow="rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px">
            <Box>
              <ChartCardTitle title="Hours" label="Breakdown Of Hours By Agency" />
              {activityBottomCardAverageHours?.is_data_available ? (
                <>
                  <Center>
                    {activityBottomCardAverageHours &&
                    activityBottomCardAverageHours?.result?.rows?.length > 0 ? (
                      <DonuteChart
                        series={activityBottomCardAverageHours?.result?.rows?.map((d) => d.count)}
                        labels={activityBottomCardAverageHours?.result?.rows?.map((d) => d.label)}
                      />
                    ) : (
                      <SkeletonCircle size="40" m="auto" />
                    )}
                  </Center>
                </>
              ) : (
                <NoData height="130px" width="130px" />
              )}
            </Box>
          </DashboardCard>
        </Flex>
      </DashboardContainer>
      <DashboardContainer header="" color={HEADER_COLORS.ACTIVITY_COLOR}>
        <Flex>
          {/* mb="5px" mr="5px" */}
          <DashboardCard
            m="5px"
            borderRadius="10px"
            boxShadow="rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px">
            <ChartCardTitle
              title="Inactive Workforce"
              label="Number Of Inactive Workers vs Declared Work Pool"
            />
            {activityBottomCardHeadCount?.is_data_available ? (
              <>
                {activityBottomCardHeadCount &&
                activityBottomCardHeadCount?.result?.rows.length > 0 ? (
                  <ColumnChart
                    // horizontal
                    series={
                      activityBottomCardHeadCount?.result?.rows.length < 8
                        ? [
                            ...activityBottomCardHeadCount?.result?.rows?.map((d) => d.inactive),
                            ...Array(
                              MIN_BARS_FOR_CHART - activityBottomCardHeadCount?.result?.rows?.length
                            ).fill(0)
                          ]
                        : activityBottomCardHeadCount?.result?.rows?.map((d) => d.inactive)
                    }
                    categories={
                      activityBottomCardHeadCount?.result?.rows?.length < 8
                        ? [
                            ...activityBottomCardHeadCount?.result?.rows?.map((d) => d.label),
                            ...Array(
                              MIN_BARS_FOR_CHART - activityBottomCardHeadCount?.result?.rows?.length
                            ).fill(0)
                          ]
                        : activityBottomCardHeadCount?.result?.rows.map((d) => d.label)
                    }
                    percentage={[
                      {
                        data:
                          activityBottomCardHeadCount?.result?.rows.length < 8
                            ? [
                                ...activityBottomCardHeadCount?.result?.rows?.map((d) => {
                                  return calculatePercentageWithDecimalPoints(
                                    d?.inactive,
                                    d?.active
                                  );
                                }),
                                ...Array(
                                  MIN_BARS_FOR_CHART -
                                    activityBottomCardHeadCount?.result?.rows?.length
                                ).fill(0)
                              ]
                            : activityBottomCardHeadCount?.result?.rows?.map((d) => {
                                return calculatePercentageWithDecimalPoints(d?.inactive, d?.active);
                              })
                      }
                    ]}
                    // categories={activityBottomCardHeadCount.map((d) => d.label)}
                    // styles={{
                    //   strokeLinecap: 'butt'
                    // }}
                    height="80%"
                    bottomDeck
                  />
                ) : (
                  <Skeleton h="200px" w="100%" />
                )}
              </>
            ) : (
              <NoData height="130px" width="130px" />
            )}
          </DashboardCard>
          {/* mb="5px" */}
          <DashboardCard
            m="5px"
            borderRadius="10px"
            boxShadow="rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px">
            <ChartCardTitle
              title="Shift Utilisation"
              label="Number Of Workers That Completed 3 Shifts Or Less"
            />
            {workforceShiftUti?.is_data_available ? (
              <>
                {workforceShiftUti && workforceShiftUti?.result?.rows?.length > 0 ? (
                  <ColumnChart
                    // horizontal
                    series={
                      workforceShiftUti?.result?.rows?.length < 8
                        ? [
                            ...workforceShiftUti?.result?.rows?.map((d) => d['1-3']),
                            ...Array(
                              MIN_BARS_FOR_CHART - workforceShiftUti?.result?.rows?.length
                            ).fill(0)
                          ]
                        : workforceShiftUti?.result?.rows?.map((d) => d['1-3'])
                    }
                    categories={
                      workforceShiftUti?.result?.rows?.length < 8
                        ? [
                            ...workforceShiftUti?.result?.rows?.map((d) => d.label),
                            ...Array(
                              MIN_BARS_FOR_CHART - workforceShiftUti?.result?.rows?.length
                            ).fill(0)
                          ]
                        : workforceShiftUti?.result?.rows?.map((d) => d.label)
                    }
                    percentage={[
                      {
                        data:
                          workforceShiftUti?.result?.rows?.length < 8
                            ? [
                                ...workforceShiftUti?.result?.rows?.map((d) => {
                                  return calculatePercentageWithDecimalPoints(d['1-3'], d['4+']);
                                }),
                                ...Array(
                                  MIN_BARS_FOR_CHART - workforceShiftUti?.result?.rows?.length
                                ).fill(0)
                              ]
                            : workforceShiftUti?.result?.rows?.map((d) => {
                                return calculatePercentageWithDecimalPoints(d['1-3'], d['4+']);
                              })
                      }
                    ]}
                    // categories={workforceShiftUti.map((d) => d.label)}
                    // styles={{
                    //   strokeLinecap: 'butt'
                    // }}
                    height="80%"
                    bottomDeck
                  />
                ) : (
                  <Skeleton h="200px" w="100%" />
                )}
              </>
            ) : (
              <NoData height="130px" width="130px" />
            )}
          </DashboardCard>
        </Flex>
        <Flex>
          <DashboardCard
            fullWidth
            m="5px"
            borderRadius="10px"
            height="315px"
            boxShadow="rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px">
            <ChartCardTitle title="Length of Service" label="Breakdown Of Tenure Per Agency" />
            <Box className="stack-chart">
              {workforceLOS?.is_data_available ? (
                <>
                  {workforceLOS &&
                  workforceLOS?.result?.rows &&
                  workforceLOS?.result?.rows.length > 0 ? (
                    ((workforceLOS.result.rows[0].data =
                      workforceLOS?.result?.rows[0]?.data?.length < 8
                        ? [
                            ...workforceLOS?.result?.rows[0].data,
                            ...Array(
                              MIN_BARS_FOR_CHART - workforceLOS?.result?.rows[0]?.data?.length
                            ).fill(0)
                          ]
                        : workforceLOS?.result?.rows[0].data),
                    (
                      <StackChart
                        header=""
                        height="235px"
                        width="100%"
                        horizontal
                        series={prepareSeries(workforceLOS?.result?.rows)}
                        // series={workforceLOS?.rows}
                        label={workforceLOS?.result?.agencies}
                      />
                    ))
                  ) : (
                    <Skeleton h="100px" w="100%" />
                  )}
                </>
              ) : (
                <NoData height="130px" width="130px" />
              )}
              {workforceLOS?.is_data_available && <Center fontSize="xl"># of weeks</Center>}
            </Box>
            {/* <Center fontSize={"xl"}>
              # of weeks
            </Center> */}
          </DashboardCard>
        </Flex>
      </DashboardContainer>
      <DashboardContainer header="" color={HEADER_COLORS.ACTIVITY_COLOR}>
        <Flex>
          {/* mb="5px" mr="5px"  */}
          <DashboardCard
            m="5px"
            borderRadius="10px"
            boxShadow="rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px">
            <ChartCardTitle
              title="New Starter Retention"
              label="Percentage Of New Starters Remaining After 2 Weeks"
            />
            {leaverNSR?.is_data_available ? (
              <>
                {leaverNSR && leaverNSR?.result?.rows?.length > 0 ? (
                  <ColumnChart
                    // horizontal
                    series={
                      leaverNSR?.result?.rows.length < 8
                        ? [
                            ...leaverNSR?.result?.rows.map((d) => d.active),
                            ...Array(MIN_BARS_FOR_CHART - leaverNSR?.result?.rows.length).fill(0)
                          ]
                        : leaverNSR?.result?.rows.map((d) => d.active)
                    }
                    categories={
                      leaverNSR?.result?.rows.length < 8
                        ? [
                            ...leaverNSR?.result?.rows.map((d) => d.label),
                            ...Array(MIN_BARS_FOR_CHART - leaverNSR?.result?.rows.length).fill(0)
                          ]
                        : leaverNSR?.result?.rows.map((d) => d.label)
                    }
                    percentage={[
                      {
                        data:
                          leaverNSR?.result?.rows.length < 8
                            ? [
                                ...leaverNSR?.result?.rows?.map((d) => {
                                  return calculatePercentageWithDecimalPoints(
                                    d?.active,
                                    d?.in_active
                                  );
                                }),
                                ...Array(MIN_BARS_FOR_CHART - leaverNSR?.result?.rows.length).fill(
                                  0
                                )
                              ]
                            : leaverNSR?.result?.rows?.map((d) => {
                                return calculatePercentageWithDecimalPoints(
                                  d?.active,
                                  d?.in_active
                                );
                              })
                      }
                    ]}
                    value={35}
                    max={100}
                    height="80%"
                    bottomDeck
                    // styles={{
                    //   strokeLinecap: 'butt'
                    // }}
                  />
                ) : (
                  <Skeleton h="200px" w="100%" />
                )}
              </>
            ) : (
              <NoData height="130px" width="130px" />
            )}
          </DashboardCard>
          {/* mb="5px"  */}
          <DashboardCard
            overflowY="auto"
            m="5px"
            borderRadius="10px"
            boxShadow="rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px">
            <Box w="100%">
              <ChartCardTitle
                title="Leavers"
                label="Breakdown Of Leavers & Percentage Per Agency"
              />
              {leaverCount?.is_data_available ? (
                <>
                  {leaverCount && leaverCount?.result?.rows.length > 0 ? (
                    <Grid templateColumns="repeat(2, 1fr)">
                      {leaverCount?.result?.rows.map((leaver, index) => {
                        return (
                          // eslint-disable-next-line react/no-array-index-key
                          <GridItem key={index} w="100%">
                            <CircularChart
                              maxW="100px"
                              value={calculateLeverPercentage(leaverCount?.total, leaver.inactive)}
                              valueType="%"
                              max={100}
                              styles={{
                                pathColor: NEW_CHART_COLOR.BLUE,
                                trailColor: colors.main.blueBackground
                              }}
                              displayValue={calculateLeverPercentage(
                                leaverCount?.total,
                                leaver.inactive
                              )}
                              fontSize="xl"
                              label={leaver?.agency_name}
                              count={leaver?.inactive}
                            />
                          </GridItem>
                        );
                      })}
                    </Grid>
                  ) : (
                    <SkeletonCircle size={40} m="auto" />
                  )}
                </>
              ) : (
                <NoData height="130px" width="130px" />
              )}
            </Box>
          </DashboardCard>
        </Flex>
        <Flex>
          <DashboardCard
            fullWidth
            m="5px"
            borderRadius="10px"
            height="315px"
            boxShadow="rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px">
            <ChartCardTitle title="Length of Service" label="Breakdown Of Tenure Per Agency" />
            <Box className="stack-chart">
              {leaverLOS?.is_data_available ? (
                <>
                  {leaverLOS && leaverLOS?.result.rows && leaverLOS?.result.rows.length > 0 ? (
                    <StackChart
                      header=""
                      height="235px"
                      width="100%"
                      horizontal
                      series={prepareSeries(leaverLOS?.result.rows)}
                      label={leaverLOS?.result.agencies}
                    />
                  ) : (
                    <Skeleton h="100px" w="100%" />
                  )}
                </>
              ) : (
                <NoData height="130px" width="130px" />
              )}
              {leaverLOS?.is_data_available && <Center fontSize="xl"># of weeks</Center>}
            </Box>
            {/* <Center fontSize={"xl"}>
              # of weeks
            </Center> */}
          </DashboardCard>
        </Flex>
      </DashboardContainer>
    </Flex>
  );
};

export default DashboardBottomCard;
