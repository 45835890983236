/* eslint-disable default-param-last */
import { API_ENDPOINT } from '../../constants';

import axiosInstance from '../../config/axios.config';
import { prepareErrorResponse, prepareSuccessReponse } from '../../utils/responseHandler';

const getAgencyAssociations = async (limit = 1, page = 1, sortKey, sortType, id) => {
  const result = await axiosInstance.get(
    `${
      API_ENDPOINT.GET_ASSOCIATIONS
    }?page=${page}&limit=${limit}&sort_by=${sortKey}&sort_type=${sortType}${
      id ? `&agency_id=${id}` : ''
    }`
  );
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const getClientAssociations = async (limit = 1, page = 1, sortKey, sortType, id) => {
  const result = await axiosInstance.get(
    `${API_ENDPOINT.GET_ASSOCIATIONS}?page=${page}&limit=${limit}&sort_by=${sortKey}&sort_type=${sortType}&client_id=${id}`
  );
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const getAssociations = async (limit = 1, page = 1, sortKey, sortType) => {
  const result = await axiosInstance.get(
    `${API_ENDPOINT.GET_ASSOCIATIONS}?page=${page}&limit=${limit}&sort_by=${sortKey}&sort_type=${sortType}`
  );
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const addAssociation = async (payload) => {
  const result = await axiosInstance.post(API_ENDPOINT.ADD_ASSOCIATIONS, payload);
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};
const updateSwitchDataInAssociation = async (id, payload) => {
  const result = await axiosInstance.put(
    `${API_ENDPOINT.ADD_ASSOCIATIONS}${API_ENDPOINT.RESRICT}/${id}`,
    payload
  );
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const updateTapDataInAssociation = async (id, payload) => {
  const result = await axiosInstance.put(
    `${API_ENDPOINT.ADD_ASSOCIATIONS}${API_ENDPOINT.TAP}/${id}`,
    payload
  );
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const updateRestrictCommentSwitch = async (id, payload) => {
  const result = await axiosInstance.put(
    `${API_ENDPOINT.ADD_ASSOCIATIONS}${API_ENDPOINT.RESRICT_COMMENTS}/${id}`,
    payload
  );
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const updateAssociation = async (id, payload) => {
  const result = await axiosInstance.put(`${API_ENDPOINT.UPDATE_ASSOCIATIONS}/${id}`, payload);
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

export {
  getAssociations,
  addAssociation,
  updateAssociation,
  getAgencyAssociations,
  getClientAssociations,
  updateSwitchDataInAssociation,
  updateRestrictCommentSwitch,
  updateTapDataInAssociation
};
