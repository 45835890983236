import styled from 'styled-components';
import { colors } from '../../theme';

export const StyledDropDownContent = styled.div`
  display: none;
  position: absolute;
  background-color: ${colors.main.white};
  border-radius: 10px;
  min-width: 160px;
  margin-top: 1px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 100;
  overflow: hidden;
`;

export const StyledDropDown = styled.div`
  position: relative;
  &:hover ${StyledDropDownContent} {
    display: block;
  }
`;
