import styled from 'styled-components';
import uploadIcon from '../../assets/icons/cloud-upload-signal-svgrepo-com.svg';

export const StyledFileUploadWrapper = styled.label`
  border-radius: 50%;
  display: inline-block;
  position: relative;
  padding: 4px;
  cursor: pointer;
  background: linear-gradient(135deg, #426b9d, #9f7aea);
  img {
    width: auto;
    height: 100%;
  }

  input[type='file'] {
    display: none;
  }
`;

export const StyledImageWrapper = styled.div`
  position: relative;
  width: ${(props) => `${props.width}px`};
  height: ${(props) => `${props.width}px`};
  overflow: hidden;
  border-radius: 50%;

  &::before {
    display: flex;
    justify-content: center;
    align-items: center;
    content: url(${uploadIcon});
    font-size: 10px;
    position: absolute;
    top: 0;
    left: 0;
    text-align: center;
    color: #63d3a6;
    width: ${(props) => `${props.width}px`};
    height: ${(props) => `${props.width}px`};
    border-radius: 50%;
    opacity: 0;
    transition: 0.5s ease;
    background-color: #ffffffd4;
  }

  &:hover::before {
    opacity: 1;
  }
`;
