/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Text, useToast } from '@chakra-ui/react';
import { TOAST_SETTINGS, USER_TYPE } from '../../constants';
import { getShifts } from '../../redux/action/shift.action';
import Dropdown from '../Dropdown';

const RoleShiftDropdown = function ({
  site,
  shift,
  setShift,
  validationObj,
  refresh,
  multiple,
  clientId = null,
  disabled = false
}) {
  const toast = useToast();
  const { userData, user_details } = useSelector((state) => state.authentication);
  const [shifts, setShifts] = useState([]);
  const [errorMsg, setErrorMsg] = useState(null);
  const [isValid, setValid] = useState(validationObj?.isvalid || true);
  const [isInitialRender, setIsInitialRender] = useState(true);
  const { selected_client_id, selected_site_id } = useSelector((state) => state.agency);

  useEffect(async () => {
    // Check if the site prop has a truthy value
    if (site.value) {
      const result = await getShifts(
        clientId || selected_client_id || userData.client_id || user_details.client_id || null,
        user_details.user_type_id === USER_TYPE.AGENCY_REGION_ADMIN
          ? ''
          : site.value || selected_site_id || userData.site_id || null,
        user_details.user_type_id === USER_TYPE.AGENCY_REGION_ADMIN && user_details.region_id
      );
      if (result && result.ok) {
        if (result.data.shifts.length !== 0) {
          setShifts(result.data.shifts);
        } else {
          // Set shift state to empty if shift list is empty
          // setShift({ label: '', value: '' });
          setShifts([]);
        }
      } else {
        toast({
          ...TOAST_SETTINGS.SETTINGS,
          status: TOAST_SETTINGS.ERROR,
          title: result.error
        });
      }
    }
  }, [clientId, site, selected_client_id, selected_site_id, userData.client_id, userData.site_id]);

  useEffect(() => {
    if (!isInitialRender) {
      setShift({ label: '', value: '' });
    } else {
      setIsInitialRender(false);
    }
  }, [site]);

  useEffect(() => {
    if (validationObj && !validationObj.isvalid) {
      setErrorMsg(validationObj.errorMessage);
      setValid(validationObj.isvalid);
    }
  }, [refresh]);

  const setShiftData = (e) => {
    setErrorMsg(null);
    setValid(true);
    setShift(e);
  };

  return (
    <>
      <Dropdown
        label={`Shift${validationObj?.required ? '*' : ''}`}
        onSelect={setShiftData}
        options={shifts.map((s) => {
          return { label: s.name, value: s.id };
        })}
        value={shift}
        placeholder=""
        isError={!isValid}
        multiple={multiple}
        disabled={disabled}
      />
      <Text visibility={isValid ? 'hidden' : 'visible'} color="main.error" mb="2px" fontSize="11px">
        {errorMsg || 'Error !'}
      </Text>
    </>
  );
};

export default RoleShiftDropdown;
