/* eslint-disable react/prop-types */
import { Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import Dropdown from '../Dropdown';

const SupervisorStatusDropdown = function ({
  label,
  supervisorStatusValue,
  setSupervisorStatusData,
  refresh,
  validationObj,
  supervisorStatusList,
  disabled
}) {
  const [errorMsg, setErrorMsg] = useState(null);
  const [isValid, setIsValid] = useState(validationObj?.isvalid || true);

  useEffect(() => {
    if (validationObj && !validationObj?.isvalid) {
      setErrorMsg(validationObj?.errorMessage);
      setIsValid(validationObj?.isvalid);
    }
  }, [refresh]);

  const setDropdownData = (e) => {
    setErrorMsg(null);
    setSupervisorStatusData(e);
    setIsValid(true);
  };
  return (
    <>
      <Dropdown
        label={label}
        placeholder="Select"
        options={supervisorStatusList.map((item) => {
          return { label: item.label, value: item.value };
        })}
        onSelect={(e) => {
          setDropdownData(e);
        }}
        value={supervisorStatusValue}
        isError={!isValid}
        disabled={disabled}
      />
      <Text visibility={isValid ? 'hidden' : 'visible'} color="main.error" mb="2px" fontSize="11px">
        {errorMsg || 'Error!'}
      </Text>
    </>
  );
};

export default SupervisorStatusDropdown;
