/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Text, useToast } from '@chakra-ui/react';
// eslint-disable-next-line import/named
import { TOAST_SETTINGS, USER_TYPE } from '../../constants';
// import { getSiteDropDownData } from '../../redux/action/worker.action';
import Dropdown from '../Dropdown';
import { getWorkersListing } from '../../redux/action/worker.action';

const WorkerDropdown = function ({
  setWorker,
  worker,
  validationObj,
  refresh,
  multiple,
  label = 'Worker',
  siteId = null,
  clientId = null,
  workerType,
  disabled = false
}) {
  const toast = useToast();
  const [workers, setWorkers] = useState([]);
  const { userData, user_details } = useSelector((state) => state.authentication);
  const { selected_client_id, selected_site_id } = useSelector((state) => state.agency);
  const [errorMsg, setErrorMsg] = useState(null);
  const [isValid, setValid] = useState(validationObj?.isvalid || true);

  useEffect(async () => {
    if (!!selected_client_id || !!userData.client_id || !!user_details.client_id) {
      const result = await getWorkersListing(
        clientId || selected_client_id || userData.client_id || user_details.client_id,
        userData.agency_id ? userData.agency_id : undefined,
        user_details.user_type_id === USER_TYPE.AGENCY_REGION_ADMIN
          ? selected_site_id
          : user_details.user_type_id === USER_TYPE.AGENCY_SITE_ADMIN
          ? user_details.site_id
          : siteId || selected_site_id || userData.site_id || undefined,
        workerType
      );
      if (result && result.ok) {
        setWorkers(result.data.workers);
      } else {
        toast({
          ...TOAST_SETTINGS.SETTINGS,
          status: TOAST_SETTINGS.ERROR,
          title: result.error
        });
      }
    }
  }, [
    clientId,
    siteId,
    selected_client_id,
    selected_site_id,
    userData.client_id,
    userData.agency_id,
    userData.site_id,
    workerType
  ]);

  useEffect(() => {
    if (validationObj && !validationObj.isvalid) {
      setErrorMsg(validationObj.errorMessage);
      setValid(validationObj.isvalid);
    }
  }, [refresh]);

  const setWorkerData = (e) => {
    setErrorMsg(null);
    setValid(true);
    setWorker(e);
  };

  return (
    <>
      <Dropdown
        label={label.length > 0 ? `${label}${validationObj?.required ? '*' : ''}` : ''}
        onSelect={(e) => {
          setWorkerData(e);
        }}
        options={workers.map((item) => {
          return { label: `${item.display_name}`, value: item.id };
        })}
        value={worker}
        placeholder=""
        multiple={multiple}
        isError={!isValid}
        disabled={disabled}
      />
      <Text visibility={isValid ? 'hidden' : 'visible'} color="main.error" mb="2px" fontSize="11px">
        {errorMsg || 'Error !'}
      </Text>
    </>
  );
};

export default WorkerDropdown;
