/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Box, Fade, Flex, FormControl, FormLabel, Switch, Text } from '@chakra-ui/react';
import Card from '../../components/Card';
import { BLUE_COLOR_SHADES, PAGE_TITLE, USER_TYPE } from '../../constants';
import {
  getProximityData,
  getGenderData,
  // getHeaderData,
  getWorkforceTopCardDemographicsData,
  getAgeData
} from '../../redux/action/demographics.action';
import HeaderCard from '../../components/HeaderCard';
import DonuteChart from '../../components/Charts/DonuteChart';
import NoData from '../../components/NoData';
import AgencyDropdownWithDefault from '../../components/Forms/AgencyDropdownWithDefault';
import CompanyDropdown from '../../components/Forms/CompanyDropdown';
// import RegionDropdown from '../../components/Forms/RegionDropdown';
import SiteDropdown from '../../components/Forms/SiteDropdown';
import WorkerTypeDropdown from '../../components/Forms/WorkerTypeDropdown';
import AgencyDropdown from '../../components/Forms/AgencyDropdown';

const Demographics = function () {
  useEffect(() => {
    document.title = PAGE_TITLE.DEMOGRAPHICS;
  }, []);

  // const [headerData, setHeaderData] = useState(null);
  const [proximityData, setProximityData] = useState(null);
  const [genderData, setGenderData] = useState(null);
  const [demographicsData, setDemographicsData] = useState(null);
  const [ageData, setAgeData] = useState(null);

  const [agency, setAgency] = useState({});
  const [client, setClient] = useState({});
  const [site, setSite] = useState({});
  // const [region, setRegion] = useState({});
  const [isWorkerActive, setIsWorkerActive] = useState(true);
  const firstUpdate = useRef(true);
  const [workerType, setWorkerType] = useState({ label: 'All Workers', value: '' });
  const { userData, user_details } = useSelector((state) => state.authentication);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    setSite({});
    setAgency({});
    getChartData(
      workerType.value !== 'TEMPORARY' ? '' : agency.value || user_details.agency_id,
      client.value || user_details.client_id,
      workerType ? '' : site.value || user_details.site_id,
      isWorkerActive,
      workerType.value
    );
  }, [isWorkerActive, workerType.value]);

  // const { selected_client_id, selected_start_date, selected_end_date } = useSelector(
  //   (state) => state.agency
  // );

  useEffect(async () => {
    if (user_details.user_type_id === USER_TYPE.AGENCY_REGION_ADMIN) {
      getChartData(
        user_details.agency_id,
        user_details.client_id,
        user_details.site_id,
        isWorkerActive
      );
    }
    if (user_details.user_type_id === USER_TYPE.AGENCY_SITE_ADMIN) {
      getChartData(user_details.agency_id, user_details.client_id, '', isWorkerActive);
    }
    if (
      user_details.user_type_id === USER_TYPE.CLIENT_ADMIN ||
      user_details.user_type_id === USER_TYPE.REGION_ADMIN ||
      user_details.user_type_id === USER_TYPE.SITE_ADMIN
    ) {
      getChartData('', user_details.client_id, '', isWorkerActive);
    }
  }, []);

  // useEffect(async () => {
  //   if (selected_client_id) {
  //     resetData();
  //     getChartData();
  //   }
  // }, [selected_client_id]);

  // useEffect(() => {
  //   resetData();
  //   getChartData();
  // }, [agency, client, site, region]);

  const resetData = () => {
    setProximityData(null);
    setGenderData(null);
    setDemographicsData(null);
    setAgeData(null);
  };

  const setWorkerTypeDropdown = (e) => {
    setWorkerType(e);
  };

  // const getHeaderDataFunction = async () => {
  //   const header = await getHeaderData(
  //     selected_client_id,
  //     userData.client_id,
  //     user_details.site_id,
  //     user_details.region_id,
  //     selected_start_date,
  //     selected_end_date
  //   );
  //   if (header && header.ok) {
  //     setHeaderData(header.data.result);
  //   }
  // };

  const getProximityDataFunction = async (agencyId, clientId, siteId, isActive) => {
    const proximity = await getProximityData(
      agencyId || userData.agency_id,
      clientId || userData.client_id,
      siteId || user_details.site_id,
      (user_details.user_type_id === USER_TYPE.AGENCY_REGION_ADMIN ||
        user_details.user_type_id === USER_TYPE.REGION_ADMIN) &&
        user_details.region_id,
      isActive,
      workerType.value
    );
    if (proximity && proximity.ok) {
      setProximityData(proximity.data.result);
    }
  };

  const getGenderDataFunction = async (agencyId, clientId, siteId, isActive) => {
    const gender = await getGenderData(
      agencyId || userData.agency_id,
      clientId || userData.client_id,
      siteId || user_details.site_id,
      (user_details.user_type_id === USER_TYPE.AGENCY_REGION_ADMIN ||
        user_details.user_type_id === USER_TYPE.REGION_ADMIN) &&
        user_details.region_id,
      isActive,
      workerType.value
    );
    if (gender && gender.ok) {
      setGenderData(gender.data.result);
    }
  };

  const getAgeDataFunction = async (agencyId, clientId, siteId, isActive) => {
    const age = await getAgeData(
      agencyId || userData.agency_id,
      clientId || userData.client_id,
      siteId || user_details.site_id,
      (user_details.user_type_id === USER_TYPE.AGENCY_REGION_ADMIN ||
        user_details.user_type_id === USER_TYPE.REGION_ADMIN) &&
        user_details.region_id,
      isActive,
      workerType.value
    );
    if (age && age.ok) {
      setAgeData(age.data.result);
    }
  };

  const getDemographicsDataFunction = async (agencyId, clientId, siteId, isActive) => {
    const demographics = await getWorkforceTopCardDemographicsData(
      agencyId || userData.agency_id,
      clientId || userData.client_id,
      siteId || user_details.site_id,
      (user_details.user_type_id === USER_TYPE.AGENCY_REGION_ADMIN ||
        user_details.user_type_id === USER_TYPE.REGION_ADMIN) &&
        user_details.region_id,
      isActive,
      workerType.value
    );
    if (demographics && demographics.ok) {
      setDemographicsData(demographics.data.result);
    }
  };

  const getChartData = (agencyId, clientId, siteId, isActive) => {
    Promise.all([
      // getHeaderDataFunction(
      //   selected_client_id,
      //   userData.client_id,
      //   user_details.site_id,
      //   selected_start_date,
      //   selected_end_date
      // ),
      getProximityDataFunction(agencyId, clientId, siteId, isActive),
      getGenderDataFunction(agencyId, clientId, siteId, isActive),
      getAgeDataFunction(agencyId, clientId, siteId, isActive),
      getDemographicsDataFunction(agencyId, clientId, siteId, isActive)
    ]);
  };

  const setAgencyData = (e) => {
    setSite({});
    setAgency(e);
    resetData();
    getChartData(workerType.value !== 'TEMPORARY' ? '' : e.value, client.value, '', isWorkerActive);
  };
  const setClientData = (clientData) => {
    setSite({});
    setClient(clientData);
    resetData();
    getChartData(
      workerType.value !== 'TEMPORARY' ? '' : agency.value,
      clientData.value,
      '',
      isWorkerActive
    );
  };
  const setSiteData = (e) => {
    setSite(e);
    resetData();
    getChartData(
      workerType.value !== 'TEMPORARY' ? '' : agency.value,
      client.value || user_details.client_id,
      e.value,
      isWorkerActive
    );
  };

  return (
    <>
      <Card margin="5px">
        <Flex
          p="8px"
          justifyContent={
            user_details.user_type_id === USER_TYPE.AGENCY_SITE_ADMIN ? 'flex-end' : 'space-between'
          }>
          {((userData.client_id && user_details.user_type_id === USER_TYPE.CLIENT_ADMIN) ||
            (userData.client_id && user_details.user_type_id === USER_TYPE.MESSAGE_ADMIN)) && (
            <Flex>
              <Box w="250px" mr="15px">
                <AgencyDropdown
                  disabled={workerType.value !== 'TEMPORARY'}
                  setDefaultValue
                  agency={agency}
                  setAgency={setAgencyData}
                  placeholder={workerType.value !== 'TEMPORARY' ? ' ' : 'All Agencies'}
                />
              </Box>
              <Box w="250px" mr="15px" zIndex="30">
                <SiteDropdown
                  label="Site"
                  site={site}
                  setSite={setSiteData}
                  disabled={false}
                  multiple={false}
                />
              </Box>
            </Flex>
          )}
          {user_details.user_type_id === USER_TYPE.AGENCY_REGION_ADMIN && (
            <Box w="250px" mr="15px">
              <SiteDropdown
                label="Site"
                site={site}
                setSite={setSiteData}
                disabled={false}
                multiple={false}
                clientId={client.value}
              />
            </Box>
          )}
          {user_details.user_type_id === USER_TYPE.AGENCY_ADMIN && (
            <Flex>
              <Box w="250px" mr="15px">
                <CompanyDropdown
                  setDefaultValue
                  company={client}
                  setCompany={setClientData}
                  disabled={false}
                />
              </Box>
              <Box w="250px" mr="15px">
                <SiteDropdown
                  label="Site"
                  site={site}
                  setSite={setSiteData}
                  disabled={false}
                  multiple={false}
                  clientId={client.value}
                />
              </Box>
            </Flex>
          )}
          {userData.client_id && user_details.user_type_id === USER_TYPE.REGION_ADMIN && (
            <Flex>
              <Box w="250px" mr="15px">
                <AgencyDropdown
                  disabled={workerType.value !== 'TEMPORARY'}
                  setDefaultValue
                  agency={agency}
                  setAgency={setAgencyData}
                  placeholder={workerType.value !== 'TEMPORARY' ? ' ' : 'All Agencies'}
                />
              </Box>
              <Box w="250px" mr="15px" zIndex="30">
                <SiteDropdown
                  label="Site"
                  site={site}
                  setSite={setSiteData}
                  disabled={false}
                  multiple={false}
                />
              </Box>
            </Flex>
          )}
          {userData.client_id && user_details.user_type_id === USER_TYPE.SITE_ADMIN && (
            <Box w="250px" mr="15px">
              <AgencyDropdown
                disabled={workerType.value !== 'TEMPORARY'}
                setDefaultValue
                agency={agency}
                setAgency={setAgencyData}
                placeholder={workerType.value !== 'TEMPORARY' ? ' ' : 'All Agencies'}
              />
            </Box>
          )}
          <Flex>
            {((userData.client_id && user_details.user_type_id === USER_TYPE.CLIENT_ADMIN) ||
              (userData.client_id && user_details.user_type_id === USER_TYPE.MESSAGE_ADMIN) ||
              (userData.client_id && user_details.user_type_id === USER_TYPE.SITE_ADMIN) ||
              (userData.client_id && user_details.user_type_id === USER_TYPE.REGION_ADMIN)) && (
              <Box w="250px" mr="15px">
                <WorkerTypeDropdown
                  workerType={workerType}
                  setWorkerTypeDropdown={setWorkerTypeDropdown}
                  disabled={false}
                  multiple={false}
                  setDefaultValue
                />
              </Box>
            )}
            <FormControl display="flex" alignItems="center" width="230px">
              <FormLabel htmlFor="email-alerts" mb="0">
                InActive/Active Workers:
              </FormLabel>
              <Switch
                isChecked={isWorkerActive}
                onChange={() => {
                  setIsWorkerActive(!isWorkerActive);
                }}
                id="worker-activity"
              />
            </FormControl>
          </Flex>
        </Flex>
      </Card>
      <Flex>
        <HeaderCard
          minWidth="289.3px"
          width="100%"
          m="5px"
          boxShadow="rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px"
          minH="350px">
          <Text textAlign="center" fontSize="xl" fontWeight="bold">
            Age
          </Text>
          <Box m="auto" w="100%">
            {ageData?.rows?.length > 0 ? (
              <DonuteChart
                height="275px"
                width="100%"
                otherColors={BLUE_COLOR_SHADES}
                series={ageData?.rows?.map((d) => d.value)}
                labels={ageData?.rows?.map((d) => d.label)}
              />
            ) : (
              <NoData />
            )}
          </Box>
        </HeaderCard>
        <HeaderCard
          boxShadow="rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px"
          minWidth="289.3px"
          width="100%"
          m="5px"
          minH="350px">
          <Text textAlign="center" fontSize="xl" fontWeight="bold">
            Sex
          </Text>
          <Box m="auto" w="100%">
            {genderData && genderData.rows.length > 0 ? (
              <DonuteChart
                height="275px"
                width="100%"
                otherColors={BLUE_COLOR_SHADES}
                series={genderData.rows.map((d) => d.value)}
                labels={genderData.rows.map((d) => d.label)}
              />
            ) : (
              <NoData />
            )}
          </Box>
        </HeaderCard>
        <HeaderCard
          minWidth="289.3px"
          width="100%"
          m="5px"
          boxShadow="rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px"
          minH="350px">
          <Text textAlign="center" fontSize="xl" fontWeight="bold">
            Nationality
          </Text>
          <Box m="auto" w="100%">
            {demographicsData && demographicsData.rows.length > 0 ? (
              <DonuteChart
                height="275px"
                width="100%"
                otherColors={BLUE_COLOR_SHADES}
                series={demographicsData.rows.map((d) => d.value)}
                labels={demographicsData.rows.map((d) => d.label)}
              />
            ) : (
              <NoData />
            )}
          </Box>
        </HeaderCard>
        <HeaderCard
          boxShadow="rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px"
          m="5px"
          minWidth="289.3px"
          width="100%"
          minH="350px">
          <Text textAlign="center" fontSize="xl" fontWeight="bold">
            Post Code
          </Text>
          <Box m="auto" width="100%">
            {proximityData && proximityData.rows.length > 0 ? (
              <DonuteChart
                height="275px"
                width="100%"
                otherColors={BLUE_COLOR_SHADES}
                series={proximityData.rows.map((d) => d.value)}
                labels={proximityData.rows.map((d) => d.label)}
              />
            ) : (
              <NoData />
            )}
          </Box>
        </HeaderCard>
      </Flex>
    </>
  );
};

export default Demographics;
