import { API_ENDPOINT } from '../../constants';

import axiosInstance from '../../config/axios.config';
import { prepareErrorResponse, prepareSuccessReponse } from '../../utils/responseHandler';

const getRatingData = async (clientId, shiftId, startDate, endDate) => {
  const result = await axiosInstance.get(
    `${API_ENDPOINT.GET_RATING_DATA}?client_id=${clientId}&shift_id=${shiftId}&start_date=${startDate}&end_date=${endDate}`
  );
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const getAgencyRatingData = async (clientId, agencyId) => {
  const result = await axiosInstance.get(
    `${API_ENDPOINT.GET_AGENCY_RATING_DATA}?client_id=${clientId}${
      agencyId ? `&agency_id=${agencyId}` : ''
    }`
  );
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const getHeaderData = async (clientId, shiftId, startDate, endDate, agencyId) => {
  const result = await axiosInstance.get(
    `${
      API_ENDPOINT.GET_HEADER_DATA
    }?client_id=${clientId}&shift_id=${shiftId}&start_date=${startDate}&end_date=${endDate}${
      agencyId ? `&agency_id=${agencyId}` : ''
    }`
  );
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const getClientRatingData = async (clientId) => {
  const result = await axiosInstance.get(
    `${API_ENDPOINT.GET_CLIENT_RATING_DATA}?client_id=${clientId}`
  );
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const getLeaverAnalysisData = async (clientId, shiftId, startDate, endDate, agencyId) => {
  const result = await axiosInstance.get(
    `${
      API_ENDPOINT.GET_LEAVER_ANALYSIS_DATA
    }?client_id=${clientId}&shift_id=${shiftId}&start_date=${startDate}&end_date=${endDate}${
      agencyId ? `&agency_id=${agencyId}` : ''
    }`
  );
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const getActivityTopCardData = async (clientId, shiftId, startDate, endDate, agencyId) => {
  const result = await axiosInstance.get(
    `${
      API_ENDPOINT.GET_ACTIVITY_TP_CARD_DATA
    }?client_id=${clientId}&shift_id=${shiftId}&start_date=${startDate}&end_date=${endDate}${
      agencyId ? `&agency_id=${agencyId}` : ''
    }`
  );
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

// const getWorkforceTopCardDemographicsData = async (clientId, shiftId) => {
//   const result = await axiosInstance.get(
//     `${API_ENDPOINT.GET_WORKFORCE_TOP_CARD_DEMOGRAPHICS}?client_id=${clientId}&shift_id=${shiftId}`
//   );
//   if (result && result.ok) {
//     return prepareSuccessReponse(result);
//   }
//   return prepareErrorResponse(result);
// };

const getWorkforceTopCardLOSData = async (clientId, shiftId, startDate, endDate, agencyId) => {
  const result = await axiosInstance.get(
    `${
      API_ENDPOINT.GET_WORKFORCE_TOP_CARD_LOS
    }?client_id=${clientId}&shift_id=${shiftId}&start_date=${startDate}&end_date=${endDate}${
      agencyId ? `&agency_id=${agencyId}` : ''
    }`
  );
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const getWorkforceTopCardShiftUtiData = async (clientId, shiftId, startDate, endDate, agencyId) => {
  const result = await axiosInstance.get(
    `${
      API_ENDPOINT.GET_WORKFORCE_TOP_CARD_SHIFT_UTI
    }?client_id=${clientId}&shift_id=${shiftId}&start_date=${startDate}&end_date=${endDate}${
      agencyId ? `&agency_id=${agencyId}` : ''
    }`
  );
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const getWorkforceTopCardPoolUtiData = async (clientId, shiftId, startDate, endDate, agencyId) => {
  const result = await axiosInstance.get(
    `${
      API_ENDPOINT.GET_WORKFORCE_TOP_CARD_POOL_UTI
    }?client_id=${clientId}&shift_id=${shiftId}&start_date=${startDate}&end_date=${endDate}${
      agencyId ? `&agency_id=${agencyId}` : ''
    }`
  );
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const getLeaverTopCardLOS = async (clientId, shiftId, startDate, endDate, agencyId) => {
  const result = await axiosInstance.get(
    `${
      API_ENDPOINT.GET_LEAVER_TOP_CARD_LOS
    }?client_id=${clientId}&shift_id=${shiftId}&start_date=${startDate}&end_date=${endDate}${
      agencyId ? `&agency_id=${agencyId}` : ''
    }`
  );
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const getLeaverTopCardCounts = async (clientId, shiftId, startDate, endDate, agencyId) => {
  const result = await axiosInstance.get(
    `${
      API_ENDPOINT.GET_LEAVER_TOP_CARD_COUNTS
    }?client_id=${clientId}&shift_id=${shiftId}&start_date=${startDate}&end_date=${endDate}${
      agencyId ? `&agency_id=${agencyId}` : ''
    }`
  );
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const getLeaverTopCardPoolUti = async (clientId, shiftId, startDate, endDate) => {
  const result = await axiosInstance.get(
    `${API_ENDPOINT.GET_LEAVER_TOP_CARD_POOL_UTI}?client_id=${clientId}&shift_id=${shiftId}&start_date=${startDate}&end_date=${endDate}`
  );
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const getActivityBottomCardShiftUti = async (clientId, shiftId, startDate, endDate) => {
  const result = await axiosInstance.get(
    `${API_ENDPOINT.GET_ACTIVITY_BOTTOM_CARD_SHIFT_UTI}?client_id=${clientId}&shift_id=${shiftId}&start_date=${startDate}&end_date=${endDate}`
  );
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

// const getActivityBottomCardLostShift = async (clientId, shiftId, startDate, endDate) => {
//   const result = await axiosInstance.get(
//     `${API_ENDPOINT.GET_ACTIVITY_BOTTOM_CARD_LOST_SHIFT}?client_id=${clientId}&shift_id=${shiftId}&start_date=${startDate}&end_date=${endDate}`
//   );
//   if (result && result.ok) {
//     return prepareSuccessReponse(result);
//   }
//   return prepareErrorResponse(result);
// };

const getActivityBottomCardHeadCount = async (clientId, shiftId, startDate, endDate) => {
  const result = await axiosInstance.get(
    `${API_ENDPOINT.GET_ACTIVITY_BOTTOM_CARD_HEAD_COUNT}?client_id=${clientId}&shift_id=${shiftId}&start_date=${startDate}&end_date=${endDate}`
  );
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const getActivityBottomCardSpend = async (clientId, shiftId, startDate, endDate) => {
  const result = await axiosInstance.get(
    `${API_ENDPOINT.GET_ACTIVITY_BOTTOM_CARD_SPEND}?client_id=${clientId}&shift_id=${shiftId}&start_date=${startDate}&end_date=${endDate}`
  );
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const getActivityBottomCardAverageHours = async (clientId, shiftId, startDate, endDate) => {
  const result = await axiosInstance.get(
    `${API_ENDPOINT.GET_ACTIVITY_BOTTOM_CARD_AVERAGE_HOURS}?client_id=${clientId}&shift_id=${shiftId}&start_date=${startDate}&end_date=${endDate}`
  );
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

// const getWorkforceDemo = async (clientId, shiftId) => {
//   const result = await axiosInstance.get(
//     `${API_ENDPOINT.GET_WORKFORCE_BOTTOM_CARD_DEMO}?client_id=${clientId}&shift_id=${shiftId}`
//   );
//   if (result && result.ok) {
//     return prepareSuccessReponse(result);
//   }
//   return prepareErrorResponse(result);
// };

const getWorkforceShiftUti = async (clientId, shiftId, startDate, endDate) => {
  const result = await axiosInstance.get(
    `${API_ENDPOINT.GET_WORKFORCE_BOTTOM_CARD_SHIFT_UTL}?client_id=${clientId}&shift_id=${shiftId}&start_date=${startDate}&end_date=${endDate}`
  );
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const getWorkforceLOS = async (clientId, shiftId, startDate, endDate) => {
  const result = await axiosInstance.get(
    `${API_ENDPOINT.GET_WORKFORCE_BOTTOM_CARD_LOS}?client_id=${clientId}&shift_id=${shiftId}&start_date=${startDate}&end_date=${endDate}`
  );
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const getLeaverNSR = async (clientId, shiftId, startDate, endDate) => {
  const result = await axiosInstance.get(
    `${API_ENDPOINT.GET_LEAVER_BOTTOM_CARD_NSR}?client_id=${clientId}&shift_id=${shiftId}&start_date=${startDate}&end_date=${endDate}`
  );
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const getLeaverCount = async (clientId, shiftId, startDate, endDate) => {
  const result = await axiosInstance.get(
    `${API_ENDPOINT.GET_LEAVER_BOTTOM_CARD_COUNT}?client_id=${clientId}&shift_id=${shiftId}&start_date=${startDate}&end_date=${endDate}`
  );
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

// const getLeaverShiftUtl = async (clientId, shiftId, startDate, endDate) => {
//   const result = await axiosInstance.get(
//     `${API_ENDPOINT.GET_LEAVER_BOTTOM_CARD_SHIFT_UTL}?client_id=${clientId}&shift_id=${shiftId}&start_date=${startDate}&end_date=${endDate}`
//   );
//   if (result && result.ok) {
//     return prepareSuccessReponse(result);
//   }
//   return prepareErrorResponse(result);
// };
const getSiteRatingData = async (clientId, shiftId) => {
  const result = await axiosInstance.get(
    `${API_ENDPOINT.GET_SITE_RATING_DATA}?client_id=${clientId}&shift_id=${shiftId}`
  );
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const getLeaverLOS = async (clientId, shiftId, startDate, endDate) => {
  const result = await axiosInstance.get(
    `${API_ENDPOINT.GET_LEAVER_BOTTOM_CARD_LOS}?client_id=${clientId}&shift_id=${shiftId}&start_date=${startDate}&end_date=${endDate}`
  );
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

export {
  getRatingData,
  getHeaderData,
  getLeaverAnalysisData,
  getActivityTopCardData,
  // getWorkforceTopCardDemographicsData,
  getWorkforceTopCardLOSData,
  getWorkforceTopCardShiftUtiData,
  getWorkforceTopCardPoolUtiData,
  getLeaverTopCardLOS,
  getLeaverTopCardCounts,
  // getLeaverTopCardShiftUti,
  getActivityBottomCardShiftUti,
  // getActivityBottomCardLostShift,
  getActivityBottomCardHeadCount,
  getActivityBottomCardSpend,
  getActivityBottomCardAverageHours,
  // getWorkforceDemo,
  getWorkforceShiftUti,
  getWorkforceLOS,
  getLeaverNSR,
  getLeaverCount,
  // getLeaverShiftUtl,
  getLeaverLOS,
  getLeaverTopCardPoolUti,
  getSiteRatingData,
  getAgencyRatingData,
  getClientRatingData
};
