/* eslint-disable camelcase */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable prefer-const */
/* eslint-disable react/jsx-no-useless-fragment */
import { Box, Button, Center, Flex, useToast } from '@chakra-ui/react';
import { DragControls } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Card from '../../components/Card';
import ClientSideDatatable from '../../components/ClientSideDatatable';
import AddClientUser from '../../components/Forms/AddClientUser';
import StyledInputWrapper from '../../components/Forms/Forms.styled';
import Loader from '../../components/Loader';
import ModalComponent from '../../components/Modal';
import constants, {
  ADD_CLIENT_USER_VALIDATION,
  PATH,
  TOAST_SETTINGS,
  USER_TYPE,
  PAGE_TITLE,
  CUSTOM_TOAST_DURATION
} from '../../constants';
import { addAgencyUser, getAgencyUsers, updateAgencyUser } from '../../redux/action/agency.action';
import { addClientUser, getClientUsers, updateClientUser } from '../../redux/action/client.action';
import { reInviteAdminUser, revokeUser } from '../../redux/action/user.action';
import { deepClone, validateForm } from '../../utils/helper';
import { getModulePermissions } from '../../utils/hooks';
import { StyledRightPanel } from '../Dashboard/Dashboard.styled';
import StyledSitesContainer from '../Sites/Sites.styled';

const Users = function () {
  const toast = useToast();
  const { user_details: userDetails } = useSelector((state) => state.authentication);
  const { selected_client_id } = useSelector((state) => state.agency);
  const permission = getModulePermissions(PATH.CLIENT_USERS.CODE);

  const [inModalOpan, setModalOpen] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [reload, setReload] = useState(false);
  const [clientUsers, setClientUsers] = useState([]);
  const [agencyUsers, setAgencyUsers] = useState([]);

  const [validations, setValidations] = useState(deepClone(ADD_CLIENT_USER_VALIDATION));
  const [isPageLoader, setIsPageLoader] = useState(false);

  const [regionData, setRegionData] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState({});
  const [selectedSite, setSelectedSite] = useState({});
  const [selectedUserType, setSelectedUserType] = useState({});
  const [edit, setEdit] = useState(false);
  const [id, setId] = useState(null);
  const [data, setData] = useState({
    name: '',
    email: '',
    country_code: '+44',
    mobile: '',
    client_role: 0,
    id: 0
  });
  const [previousState, setPreviousState] = useState(null);

  const getClientUserData = async () => {
    const sites = await getClientUsers();
    setIsPageLoader(false);
    if (sites && sites.ok) {
      setClientUsers(sites.data.users);
    } else {
      toast({
        ...TOAST_SETTINGS.SETTINGS,
        status: TOAST_SETTINGS.ERROR,
        title: sites.error
      });
    }
  };

  const getAgencyUserData = async () => {
    const users = await getAgencyUsers(selected_client_id);
    setIsPageLoader(false);
    if (users && users.ok) {
      setAgencyUsers(users.data.users);
    } else {
      toast({
        ...TOAST_SETTINGS.SETTINGS,
        status: TOAST_SETTINGS.ERROR,
        title: users.error
      });
    }
  };

  useEffect(() => {
    document.title = PAGE_TITLE.SYSTEM_USERS;
    if (userDetails.user_type_id === USER_TYPE.CLIENT_ADMIN) {
      getClientUserData();
    } else if (userDetails.user_type_id === USER_TYPE.AGENCY_ADMIN) {
      getAgencyUserData();
    }
  }, [selected_client_id]);

  const onModalClose = () => {
    setEdit(false);
    setModalOpen(false);
    setRefresh(!refresh);
    setData({
      name: '',
      email: '',
      country_code: '+44',
      mobile: '',
      client_role: 0,
      id: 0
    });
    setSelectedUserType({});
    setSelectedRegion({});
    setSelectedSite({});
    setId(null);
    setValidations(deepClone(ADD_CLIENT_USER_VALIDATION));
  };

  const saveClientUser = async () => {
    let [validationObject, isFormValid] = validateForm(data, validations);
    if (
      data.client_role === 9 &&
      validationObject.name.isvalid === true &&
      validationObject.mobile.isvalid === true &&
      validationObject.email.isvalid === true
    ) {
      isFormValid = true;
    }
    setValidations(validationObject);
    if (isFormValid) {
      let result = null;
      if (edit) {
        const payload = {
          name: data.name,
          phone: data.mobile,
          country_code: data.country_code
        };
        if (previousState.client_role !== data.client_role || previousState.id !== data.id) {
          payload.user_type_id = data.client_role;
          payload.id = data.id;
        }
        if (userDetails.user_type_id === USER_TYPE.CLIENT_ADMIN) {
          result = await updateClientUser(id, {
            ...payload
          });
        } else {
          result = await updateAgencyUser(id, {
            ...payload
          });
        }
        if (result && result.ok) {
          toast({
            ...TOAST_SETTINGS.SETTINGS,
            duration: CUSTOM_TOAST_DURATION.duration,
            status: TOAST_SETTINGS.SUCCESS,
            title: result.message
          });
          setEdit(false);
          if (userDetails.user_type_id === USER_TYPE.CLIENT_ADMIN) {
            getClientUserData();
          } else {
            getAgencyUserData();
          }
          onModalClose();
        } else {
          toast({
            ...TOAST_SETTINGS.SETTINGS,
            status: TOAST_SETTINGS.ERROR,
            title: result.error
          });
        }
      } else {
        if (userDetails.user_type_id === USER_TYPE.CLIENT_ADMIN) {
          result = await addClientUser(
            data.client_role === 9
              ? {
                  name: data.name,
                  email: data.email,
                  country_code: data.country_code,
                  phone: data.mobile,
                  client_role: data.client_role,
                  client_id: userDetails.client_id
                }
              : {
                  name: data.name,
                  email: data.email,
                  country_code: data.country_code,
                  phone: data.mobile,
                  client_role: data.client_role,
                  id: data.id,
                  client_id: userDetails.client_id
                }
          );
        } else {
          result = await addAgencyUser({
            name: data.name,
            email: data.email,
            country_code: data.country_code,
            phone: data.mobile,
            client_role: data.client_role,
            client_id: selected_client_id,
            id: data.id
          });
        }
        if (result && result.ok) {
          setId(result.data?.site_id);
          toast({
            ...TOAST_SETTINGS.SETTINGS,
            duration: CUSTOM_TOAST_DURATION.duration,
            status: TOAST_SETTINGS.SUCCESS,
            title: result.message
          });
          onModalClose();
          setEdit(false);
          if (userDetails.user_type_id === USER_TYPE.CLIENT_ADMIN) {
            getClientUserData();
          } else {
            getAgencyUserData();
          }
        } else {
          toast({
            ...TOAST_SETTINGS.SETTINGS,
            status: TOAST_SETTINGS.ERROR,
            title: result.error
          });
        }
      }
      setReload(!reload);
    } else {
      setRefresh(!refresh);
      setReload(!reload);
    }
  };

  const editData = (row, rowId) => {
    setEdit(true);
    setId(rowId);
    // eslint-disable-next-line radix
    const userId = parseInt(row.user_type_id);
    setData({
      name: row.user_name,
      email: row.email,
      country_code: row.country_code || '+44',
      mobile: row.mobile,
      client_role: userId,
      id: row.verbose_id,
      client_id: selected_client_id
    });

    setPreviousState({
      client_role: userId,
      id: row.verbose_id
    });

    setSelectedUserType({ value: userId, label: row.user_type_name });
    if (userId === USER_TYPE.REGION_ADMIN || userId === USER_TYPE.AGENCY_REGION_ADMIN) {
      setSelectedRegion({ label: row.name, value: row.verbose_id });
    } else if (userId === USER_TYPE.SITE_ADMIN || userId === USER_TYPE.AGENCY_SITE_ADMIN) {
      setSelectedSite({ label: row.name, value: row.verbose_id });
    }
    setModalOpen(true);
    setId(rowId);
  };

  const reInviteUser = async (userId) => {
    const result = await reInviteAdminUser(userId);
    if (result && result.ok) {
      toast({
        ...TOAST_SETTINGS.SETTINGS,
        duration: CUSTOM_TOAST_DURATION.duration,
        status: TOAST_SETTINGS.SUCCESS,
        title: result.message
      });
    } else {
      toast({
        ...TOAST_SETTINGS.SETTINGS,
        status: TOAST_SETTINGS.ERROR,
        title: result.error
      });
    }
    setReload(!reload);
  };

  const revokeUserBtnClickHandler = async (userId) => {
    const result = await revokeUser(userId);
    if (result && result.ok) {
      toast({
        ...TOAST_SETTINGS.SETTINGS,
        duration: CUSTOM_TOAST_DURATION.duration,
        status: TOAST_SETTINGS.SUCCESS,
        title: result.message
      });
      if (userDetails.user_type_id === USER_TYPE.CLIENT_ADMIN) {
        getClientUserData();
      } else {
        getAgencyUserData();
      }
    } else {
      toast({
        ...TOAST_SETTINGS.SETTINGS,
        status: TOAST_SETTINGS.ERROR,
        title: result.error
      });
    }
    setReload(!reload);
  };
  const [column, setColumn] = useState([
    {
      label: 'Name',
      field: 'user_name',
      sort: true
    },
    {
      label: 'Email',
      field: 'email',
      sort: true
    },
    {
      label: 'Mobile',
      field: 'mobile',
      customRow: (cell, row) => {
        if (row.country_code && row.country_code.length > 0 && cell && cell.length) {
          return `${row.country_code}${cell}`;
        }
        return '';
      }
    },
    {
      label: 'User Type',
      field: 'user_type_name',
      sort: true
    },
    {
      label: 'Region / Site Name',
      field: 'name',
      sort: true
    },

    {
      label: 'Action',
      field: 'id',
      sort: false,
      customRow: (cell, row) => {
        return (
          <div style={{ display: 'flex' }}>
            {permission && permission.includes('edit') && (
              <Button
                bg="main.semiPrimary"
                color="main.white"
                _hover={{ bg: 'main.primary' }}
                _active={{
                  bg: 'main.semiPrimary',
                  borderColor: 'main.primary'
                }}
                onClick={() => editData(row, cell)}>
                {constants.CLIENT_USERS.EDIT_BUTTON_TEXT}
              </Button>
            )}
            <Button
              bg="main.semiPrimary"
              color="main.white"
              _hover={{ bg: 'main.primary' }}
              _active={{
                bg: 'main.semiPrimary',
                borderColor: 'main.primary'
              }}
              onClick={() => reInviteUser(cell)}
              ml={permission && permission.includes('edit') ? 2 : 0}>
              {constants.CLIENT_USERS.RE_INVITE}
            </Button>
            <Button
              bg="main.semiPrimary"
              color="main.white"
              _hover={{ bg: 'main.primary' }}
              _active={{
                bg: 'main.semiPrimary',
                borderColor: 'main.primary'
              }}
              onClick={() => revokeUserBtnClickHandler(cell)}
              ml={2}
              disabled={row.is_able_access === '0'}>
              {constants.CLIENT_USERS.REVOKE}
            </Button>
          </div>
        );
      }
    }
  ]);
  useEffect(() => {
    // if (permission && permission.includes('edit')) {
    //   setColumn([
    //     ...column,
    //     {
    //       label: 'Action',
    //       field: 'id',
    //       sort: false,
    //       customRow: (cell, row) => {
    //         return (
    //           <Button onClick={() => editData(cell, row)}>
    //             {constants.CLIENT_USERS.EDIT_BUTTON_TEXT}
    //           </Button>
    //         );
    //       }
    //     }
    //   ]);
    // }
  }, [permission]);

  return (
    <>
      <StyledSitesContainer>
        {isPageLoader ? <Loader /> : null}
        <Card>
          {permission && permission.includes('create') && (
            <>
              <Button
                bg="main.semiPrimary"
                color="main.white"
                _hover={{ bg: 'main.primary' }}
                _active={{
                  bg: 'main.semiPrimary',
                  borderColor: 'main.primary'
                }}
                onClick={() => setModalOpen(true)}>
                Add User
              </Button>
              <ModalComponent
                isOpen={inModalOpan}
                onClose={() => onModalClose()}
                modalTitle={
                  edit
                    ? constants.CLIENT_USERS.EDIT_HEADER_TEXT
                    : constants.CLIENT_USERS.HEADER_TEXT
                }
                modalContent={
                  <Box p="10px">
                    <Flex>
                      <StyledInputWrapper>
                        <AddClientUser
                          data={data}
                          setData={setData}
                          validations={validations}
                          refresh={refresh}
                          isEdit={edit}
                          userTypeData={selectedUserType}
                          regionData={selectedRegion}
                          siteData={selectedSite}
                        />
                      </StyledInputWrapper>
                    </Flex>
                  </Box>
                }
                negative={
                  <Button
                    onClick={() => onModalClose()}
                    bg="main.secondary"
                    mr={3}
                    _hover={{ bg: 'main.secondary' }}
                    color="main.black">
                    {constants.CLIENT_USERS.CANCEL_BUTTON_TEXT}
                  </Button>
                }
                positive={
                  <Button
                    onClick={() => saveClientUser()}
                    bg="main.semiPrimary"
                    color="main.white"
                    _hover={{ bg: 'main.primary' }}
                    _active={{
                      bg: 'main.semiPrimary',
                      borderColor: 'main.primary'
                    }}>
                    {edit
                      ? constants.CLIENT_USERS.EDIT_UPDATE_BUTTON_TEXT
                      : constants.CLIENT_USERS.SAVE_BUTTON_TEXT}
                  </Button>
                }
              />
            </>
          )}
          <ClientSideDatatable
            pageSize={10}
            initialSortKey="id"
            column={column}
            row={userDetails.user_type_id === USER_TYPE.CLIENT_ADMIN ? clientUsers : agencyUsers}
            pagination
            pages={[2, 5, 10, 15]}
            refresh={reload}
          />
        </Card>
      </StyledSitesContainer>{' '}
    </>
  );
};

Users.propTypes = {};

export default Users;
