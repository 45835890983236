/* eslint-disable react/prop-types */
import React from 'react';
import { Box, Center } from '@chakra-ui/react';
import { colors } from '../../theme';
import NoDataIcon from '../../icons/NoDataIcon';

const NoData = function ({ height, width }) {
  return (
    <Box height={height} width={width} margin="auto" color={colors.main.gray} p={2} pt={4} pb={4}>
      <Box height="40px" width="40px" ml="auto" mr="auto">
        <NoDataIcon />
      </Box>
      <Center>No Data Found!</Center>
    </Box>
  );
};

export default NoData;
