import React from 'react';

const ComplianceIcon = function () {
  return (
    <svg
      fill="currentColor"
      height="18"
      width="18"
      version="1.1"
      id="XMLID_138_"
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 24 24"
      space="preserve">
      <g id="compliance">
        <g>
          <path
            d="M22,24H2V2h5V0h10v2h5V24z M4,22h16V4h-3v3H7V4H4V22z M9,5h6V2H9V5z M11,18.4l-3.7-3.7l1.4-1.4l2.3,2.3l5.3-5.3l1.4,1.4
       L11,18.4z"
          />
        </g>
      </g>
    </svg>
  );
};

export default ComplianceIcon;
