/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Input, Text, Tooltip } from '@chakra-ui/react';
import Label from '../Label';

const InputComponent = function ({
  lable,
  placeholder,
  value,
  onChange,
  isRequired,
  type,
  style,
  error,
  validationObj,
  refresh,
  labelFontSize,
  // eslint-disable-next-line react/prop-types
  Disable,
  checkbox,
  templatevalidation,
  tooltip,
  isBodyChanged,
  ...props
}) {
  const [errorMsg, setErrorMsg] = useState(null);
  const [isValid, setValid] = useState(validationObj.isvalid || true);
  const changeValue = (event) => {
    setErrorMsg(null);
    setValid(true);
    onChange(event.target.value);
  };

  useEffect(() => {
    if (validationObj.greaterThanZero === false) {
      setValid(true);
    }
    if (validationObj && !validationObj.isvalid) {
      setErrorMsg(validationObj.errorMessage, () => {});
      setValid(validationObj.isvalid);
    }
    if (templatevalidation) {
      setValid(true);
    }
  }, [refresh, validationObj.greaterThanZero, templatevalidation]);

  return (
    <>
      {!checkbox && (
        <Label
          label={`${lable}${validationObj.required ? '*' : ''}`}
          isError={!isValid}
          fontSize={labelFontSize}
          color="main.primary"
        />
      )}
      {tooltip ? (
        <Tooltip
          minW="360px"
          mt="-7px"
          label="Password must have minimum 8 characters including lowercase, uppercase, special, and numeric characters.">
          <Input
            isRequired={isRequired}
            focusBorderColor={!isValid ? 'red.400' : 'main.semiPrimary'}
            borderColor={!isValid ? 'red.400' : 'main.semiPrimary'}
            _hover={!isValid ? 'red.400' : 'main.semiPrimary'}
            placeholder={placeholder}
            value={value}
            title=""
            color="main.black"
            onChange={changeValue}
            type={type || 'text'}
            fontSize="md"
            // disabled={Disable}
            {...style}
            {...props}
          />
        </Tooltip>
      ) : (
        <Input
          isRequired={isRequired}
          focusBorderColor={!isValid ? 'red.400' : 'main.semiPrimary'}
          borderColor={!isValid ? 'red.400' : 'main.semiPrimary'}
          backgroundColor={isBodyChanged ? 'main.lightPrimary' : ''}
          _hover={!isValid ? 'red.400' : 'main.semiPrimary'}
          placeholder={placeholder}
          value={value}
          title=""
          color="main.black"
          onChange={changeValue}
          type={type || 'text'}
          fontSize="md"
          // disabled={Disable}
          {...style}
          {...props}
        />
      )}
      {!checkbox && (
        <Text
          visibility={isValid ? 'hidden' : 'visible'}
          color="main.error"
          mb="2px"
          fontSize="11px">
          {errorMsg || 'Error !'}
        </Text>
      )}
    </>
  );
};

InputComponent.propTypes = {
  containerStyle: PropTypes.object,
  error: PropTypes.string,
  isRequired: PropTypes.bool,
  lable: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  style: PropTypes.object,
  type: PropTypes.string,
  value: PropTypes.string,
  validationObj: PropTypes.object,
  labelFontSize: PropTypes.string,
  refresh: PropTypes.bool,
  checkbox: PropTypes.bool
};

InputComponent.defaultProps = {
  containerStyle: {},
  error: '',
  isRequired: false,
  lable: 'Input',
  onChange: () => {},
  placeholder: 'Input placeholder',
  style: {},
  type: 'text',
  value: '',
  validationObj: {},
  labelFontSize: 'lg',
  refresh: false,
  checkbox: false
};

export default InputComponent;
