import React from 'react';

const AgencyIcon = function () {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none" viewBox="0 0 22 22">
      <path
        fill="currentColor"
        d="M19.938.974H1.813C.812.974 0 1.984 0 3.227v16.52C0 20.991.812 22 1.813 22h18.125c1 0 1.812-1.01 1.812-2.253V3.227c0-1.244-.812-2.253-1.813-2.253zM6.646 5.48c1.333 0 2.417 1.347 2.417 3.003 0 1.657-1.084 3.004-2.417 3.004-1.333 0-2.417-1.347-2.417-3.004 0-1.656 1.084-3.003 2.417-3.003zm4.229 11.113c0 .498-.378.901-.846.901H3.263c-.469 0-.846-.403-.846-.9v-.902c0-1.492 1.136-2.703 2.537-2.703h.189c.464.24.97.375 1.503.375a3.25 3.25 0 001.503-.375h.189c1.4 0 2.537 1.21 2.537 2.703v.901zm8.458-2.478c0 .207-.136.376-.302.376h-5.437c-.166 0-.302-.17-.302-.376v-.75c0-.207.136-.376.302-.376h5.437c.166 0 .302.169.302.375v.751zm0-3.004c0 .207-.136.376-.302.376h-5.437c-.166 0-.302-.169-.302-.376v-.75c0-.207.136-.376.302-.376h5.437c.166 0 .302.17.302.376v.75zm0-3.003c0 .206-.136.375-.302.375h-5.437c-.166 0-.302-.169-.302-.375v-.751c0-.207.136-.376.302-.376h5.437c.166 0 .302.17.302.376v.75z"
      />
    </svg>
  );
};

export default AgencyIcon;
