/* eslint-disable default-param-last */
import { API_ENDPOINT } from '../../constants';

import axiosInstance from '../../config/axios.config';
import { prepareErrorResponse, prepareSuccessReponse } from '../../utils/responseHandler';
import { toFormData } from '../../utils/helper';

const getRoleType = async (
  page = 1,
  limit = 10,
  sortKey = 'id',
  sortType = 'ASC',
  clientId,
  siteId,
  regionId
) => {
  const result = await axiosInstance.get(
    `${
      API_ENDPOINT.GET_ROLE_TYPE
    }?page=${page}&limit=${limit}&sort_by=${sortKey}&sort_type=${sortType}&client_id=${clientId}${
      siteId ? `&site_id=${siteId}` : ''
    }${regionId ? `&region_id=${regionId}` : ''}`
  );
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const addRoleType = async (payload) => {
  const result = await axiosInstance.post(API_ENDPOINT.ADD_ROLE_TYPE, payload);
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const uploadBulkRoleType = async (clientId, payload) => {
  const result = await axiosInstance.post(
    `${API_ENDPOINT.BULK_ROLE_TYPE_UPLOAD}?client_id=${clientId}`,
    await toFormData(payload)
  );
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const updateRoleType = async (id, payload) => {
  const result = await axiosInstance.put(`${API_ENDPOINT.UPDATE_ROLE_TYPE}/${id}`, payload);
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const getRoleTypeDropDownData = async (siteId) => {
  const result = await axiosInstance.get(`${API_ENDPOINT.GET_ROLE_TYPE_DROP_DOWN}/${siteId}`);
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const getRoleTypeDropDownDataForMessaging = async (siteId) => {
  const result = await axiosInstance.get(
    `${API_ENDPOINT.GET_ROLE_TYPE_DROP_DOWN_MESSAGING}/${siteId}`
  );
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

export {
  getRoleType,
  addRoleType,
  updateRoleType,
  getRoleTypeDropDownData,
  getRoleTypeDropDownDataForMessaging,
  uploadBulkRoleType
};
