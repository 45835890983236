/* eslint-disable react/prop-types */
import { Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import Dropdown from '../Dropdown';
import { genderList } from '../../constants';

const GenderDropdown = function ({ gender, validationObj, setGender, refresh }) {
  const [errorMsg, setErrorMsg] = useState(null);
  const [isValid, setIsValid] = useState(validationObj?.isvalid || true);

  useEffect(() => {
    if (validationObj && !validationObj?.isvalid) {
      setErrorMsg(validationObj?.errorMessage);
      setIsValid(validationObj?.isvalid);
    }
  }, [refresh]);

  const setGenderData = (e) => {
    setErrorMsg(null);
    setGender(e);
    setIsValid(true);
  };

  return (
    <>
      <Dropdown
        label={`Sex${validationObj?.required ? '*' : ''}`}
        placeholder="Select"
        options={genderList.map((item) => {
          return { label: item, value: item };
        })}
        onSelect={(e) => {
          setGenderData(e);
        }}
        value={gender}
        isError={!isValid}
        disabled={false}
      />
      <Text visibility={isValid ? 'hidden' : 'visible'} color="main.error" mb="2px" fontSize="11px">
        {errorMsg || 'Error!'}
      </Text>
    </>
  );
};

export default GenderDropdown;
