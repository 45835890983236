/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Text, useToast } from '@chakra-ui/react';
import { TOAST_SETTINGS } from '../../constants';
import { getRegionDropDownData } from '../../redux/action/region.action';
import Dropdown from '../Dropdown';

const RegionDropdown = function ({ setRegion, region, validationObj, refresh, multiple }) {
  const toast = useToast();
  const [regionData, setRegionData] = useState([]);
  const { userData } = useSelector((state) => state.authentication);
  const { selected_client_id } = useSelector((state) => state.agency);
  const [errorMsg, setErrorMsg] = useState(null);
  const [isValid, setValid] = useState(validationObj?.isvalid || true);

  useEffect(async () => {
    const result = await getRegionDropDownData(selected_client_id || userData.client_id);
    if (result && result.ok) {
      setRegionData(result.data.region_details);
    } else {
      toast({
        ...TOAST_SETTINGS.SETTINGS,
        status: TOAST_SETTINGS.ERROR,
        title: result.error
      });
    }
  }, []);

  useEffect(() => {
    if (validationObj && !validationObj.isvalid) {
      setErrorMsg(validationObj.errorMessage);
      setValid(validationObj.isvalid);
    }
  }, [refresh]);

  const setSectorData = (e) => {
    setErrorMsg(null);
    setValid(true);
    setRegion(e);
  };

  return (
    <>
      <Dropdown
        label={`Region${validationObj?.required ? '*' : ''}`}
        onSelect={(e) => {
          setSectorData(e);
        }}
        options={regionData.map((sec) => {
          return { label: sec.name, value: sec.id };
        })}
        value={region}
        placeholder=""
        multiple={multiple}
        isError={!isValid}
      />
      <Text visibility={isValid ? 'hidden' : 'visible'} color="main.error" mb="2px" fontSize="11px">
        {errorMsg || 'Error !'}
      </Text>
    </>
  );
};

export default RegionDropdown;
