import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '@chakra-ui/react';

const Label = function ({ label, isError, fontSize }) {
  return (
    <Text color={isError ? 'main.error' : 'main.semiPrimary'} fontSize={fontSize}>
      {label}
    </Text>
  );
};

Label.propTypes = {
  label: PropTypes.string,
  fontSize: PropTypes.string,
  isError: PropTypes.bool
};

Label.defaultProps = {
  label: 'Input',
  fontSize: 'lg',
  isError: false
};

export default Label;
