import { useEffect, useState } from 'react';
import Card from '../../components/Card';
import Loader from '../../components/Loader';
import ServerSideDatatable from '../../components/ServerSideDatatable';
import { DATE_TO_SHOW, PAGE_TITLE } from '../../constants';
import getPayrollListing from '../../redux/action/payrollData.action';
import StyledPayrollContainer from './Payroll.styled';

const INITIAL_COLUMNS = [
  {
    label: 'Date',
    field: 'start_date',
    sort: true,
    customRow: (cell) => {
      return `${DATE_TO_SHOW(cell)}`;
    }
  },
  {
    label: 'Week',
    field: 'week',
    sort: true,
    customRow: (cell) => {
      return `${cell}`;
    }
  },
  {
    label: 'Client',
    field: 'client',
    sort: true
  },
  {
    label: 'Agency',
    field: 'agency',
    sort: true
  },
  {
    label: 'Workers',
    field: 'workers',
    sort: true
  }
];

const Payroll = function () {
  useEffect(() => {
    document.title = PAGE_TITLE.PAYROLL;
  }, []);
  const [columns] = useState([...INITIAL_COLUMNS]);
  const [isPageLoader, setIsPageLoader] = useState(false);
  const retrievePayrollListing = async (...params) => {
    const result = await getPayrollListing(...params);
    if (result && result.ok) {
      return result;
    }
    return {
      data: []
    };
  };
  return (
    <StyledPayrollContainer>
      {isPageLoader ? <Loader /> : null}
      <Card>
        <ServerSideDatatable
          initialSortKey="start_date"
          column={columns}
          onPaginate={retrievePayrollListing}
          afterPaginateData={() => setIsPageLoader(false)}
          pagination
          pages={[5, 10, 15]}
          pageSize={5}
          dataKey="records"
        />
      </Card>
    </StyledPayrollContainer>
  );
};

export default Payroll;
