/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Text } from '@chakra-ui/react';
import Dropdown from '../Dropdown';

const ClientUserTypeDropdown = function ({
  setUserType,
  userType,
  validationObj,
  refresh,
  multiple,
  userTypesData
}) {
  const [clientUserTypes] = useState(userTypesData);
  const [errorMsg, setErrorMsg] = useState(null);
  const [isValid, setValid] = useState(validationObj?.isvalid || true);

  useEffect(() => {
    if (validationObj && !validationObj.isvalid) {
      setErrorMsg(validationObj.errorMessage);
      setValid(validationObj.isvalid);
    }
  }, [refresh]);

  const setUserTypeData = (e) => {
    setErrorMsg(null);
    setValid(true);
    setUserType(e);
  };

  return (
    <>
      <Dropdown
        label={`User Type${validationObj?.required ? '*' : ''}`}
        onSelect={(e) => {
          setUserTypeData(e);
        }}
        options={clientUserTypes.map((type) => {
          return { label: type.name, value: type.id };
        })}
        value={userType}
        placeholder=""
        multiple={multiple}
        isError={!isValid}
      />
      <Text visibility={isValid ? 'hidden' : 'visible'} color="main.error" mb="2px" fontSize="11px">
        {errorMsg || 'Error !'}
      </Text>
    </>
  );
};

export default ClientUserTypeDropdown;
