import React, { useEffect, useState } from 'react';
import { Button } from '@chakra-ui/react';
// import { PATH } from '../../constants';
// import { useQuery } from '../../utils/hooks';
import { NavLink } from 'react-router-dom';
import Card from '../../components/Card';
import ServerSideDatatable from '../../components/ServerSideDatatable';
import Loader from '../../components/Loader';
import { getAttendanceDetails } from '../../redux/action/attendance.action';
import StyledAttendanceDetailsContainer from './AttendanceDetails.styled';
import { PATH, PAGE_TITLE } from '../../constants';

const AttendanceDetails = function () {
  //   const permission = getModulePermissions(PATH.AGENCY.CODE);
  // const query = useQuery();
  useEffect(() => {
    document.title = PAGE_TITLE.ATTENDANCE_DETAILS;
  }, []);
  const [isPageLoader, setIsPageLoader] = useState(false);
  const [id, setId] = useState(null);
  const [reload] = useState(false);
  const [column] = useState([
    {
      label: 'Worker Name',
      field: 'worker_first_name',
      sort: true,
      customRow: (cell, row) => {
        return `${cell} ${row.worker_last_name}`;
      }
    },
    {
      label: 'Agency',
      field: 'agency_name',
      sort: true
    },
    {
      label: 'Client',
      field: 'client_name',
      sort: true
    },
    {
      label: 'Site',
      field: 'site_name',
      sort: true
    },
    {
      label: 'Job',
      field: 'job_name',
      sort: true
    },
    {
      label: 'Approved Hours',
      field: 'hour_approved',
      sort: true
    },
    {
      label: 'Payment Week',
      field: 'payment_week',
      sort: true
    }
  ]);

  useEffect(() => {
    const url = window.location.href;
    const currentId = url.split('/')[url.split('/').length - 1];
    setId(currentId);
  }, []);

  //   const permission = getModulePermissions(PATH.TIME_AND_ATTENDANCE.CODE);
  return (
    <StyledAttendanceDetailsContainer bg="main.secondary">
      {isPageLoader ? <Loader /> : null}
      <Card>
        <NavLink to={PATH.TIME_AND_ATTENDANCE.TO}>
          <Button
            bg="main.semiPrimary"
            color="main.white"
            _hover={{ bg: 'main.primary' }}
            _active={{
              bg: 'main.semiPrimary',
              borderColor: 'main.primary'
            }}>
            Back
          </Button>
        </NavLink>
        {id && (
          <ServerSideDatatable
            pageSize={5}
            initialSortKey="worker_first_name"
            column={column}
            onPaginate={getAttendanceDetails}
            afterPaginateData={() => setIsPageLoader(false)}
            pagination
            pages={[5, 10, 15]}
            dataKey="time_and_attendance_detail"
            refresh={reload}
            id={id}
          />
        )}
      </Card>
    </StyledAttendanceDetailsContainer>
  );
};

AttendanceDetails.propTypes = {};

export default AttendanceDetails;
