/* eslint-disable no-unused-vars */
/* eslint-disable no-alert */
/* eslint-disable no-return-await */
/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-lonely-if */

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Box, Button, Flex, Spacer, useMediaQuery, useToast } from '@chakra-ui/react';
import moment from 'moment';
import Card from '../../components/Card';

import constants, {
  TOAST_SETTINGS,
  ADD_WORKER_VALIDATIONS,
  PATH,
  USER_TYPE,
  PAGE_TITLE,
  MESSAGE_TYPES,
  MESSAGE_CREATE_VALIDATIONS,
  CUSTOM_TOAST_DURATION,
  MESSAGE_UPDATE_VALIDATIONS,
  TRANSLATE_CREATE_VALIDATIONS
} from '../../constants';
import { deepClone, formatDate, validateForm, validateUrl } from '../../utils/helper';

import StyledMessagingContainer, { StyledMessageCardHeaderContainer } from './Messaging.styled';
import Loader from '../../components/Loader';
import { getModulePermissions } from '../../utils/hooks';
import ServerSideDatatable from '../../components/ServerSideDatatable';
import MessageTypeDropdown from '../../components/Forms/MessageTypeDropDown';
import MessagingView from '../../components/MessagingView';
import {
  createTemplate,
  deleteTemplate,
  getAutomatedMessages,
  getMessages,
  getTemplate,
  getTemplateList,
  sendMessage,
  UpdateMessage,
  updateTemplate
} from '../../redux/action/messaging.action';
import CompanyDropdown from '../../components/Forms/CompanyDropdown';
import SiteDropdownWithDefault from '../../components/Forms/SiteDropdownWithDefault';
import TypeDropdown from '../../components/Forms/TypeDropdown';
// import ServerSideDatatable from '../../components/ServerSideDatatable';
const INITIAL_COLUMNS = [
  {
    label: 'From',
    field: 'from',
    sort: true
  },
  {
    label: 'Messages',
    field: 'title',
    sort: true
  },
  {
    label: 'Date',
    field: 'created_at',
    sort: true,
    customRow: (cell) => {
      const date = moment(new Date(cell)).format('DD/MM/YYYY');
      return date || '-';
    }
  }
];

const INITIAL_MESSAGE_DATA = {
  name: '',
  title: '',
  type: { value: 'GENERAL', label: 'General' },
  from: '',
  languages: { label: 'English', value: 'en' },
  body: [
    {
      text: '',
      media: '',
      link: '',
      isValid: true,
      isValidUrl: true
    }
  ]
};

const Messaging = function () {
  useEffect(() => {
    document.title = PAGE_TITLE.MESSAGING;
  }, []);
  // const { user_details: userDetails } = useSelector((state) => state.authentication);
  // const { selected_client_id, selected_site_id } = useSelector((state) => state.agency);
  // const permission = getModulePermissions(PATH.WORKER.CODE);
  // const toast = useToast();
  // const [workersData, setWorkersData] = useState([]);
  const toast = useToast();
  const [refresh, setRefresh] = useState(false);
  const [isPageLoader, setIsPageLoader] = useState(false);
  const [columns, setColumns] = useState([...INITIAL_COLUMNS]);

  const [selectedMessageType, setSelectedMessageType] = useState(null);
  const [selectedType, setSelectedType] = useState({ label: 'Sent', value: 'SENT' });
  const [currentPageIndex, setCurrentPageIndex] = useState(0);

  const [isNewTemplate, setIsNewTemplate] = useState(true);
  const [templateData, setTemplateData] = useState({
    ...INITIAL_MESSAGE_DATA,
    body: [...INITIAL_MESSAGE_DATA.body]
  });
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const { user_details } = useSelector((state) => state.authentication);
  const { selected_site_id } = useSelector((state) => state.agency);
  // const { selected_client_id, selected_site_id } = useSelector((state) => state.agency);
  const [shift, setShift] = useState([]);
  const [department, setDepartment] = useState([]);
  const [role, setRole] = useState([]);
  const [worker, setWorker] = useState([]);
  const [nationality, setNationality] = useState([]);
  const [validationObject, setValidationObject] = useState({
    ...deepClone(TRANSLATE_CREATE_VALIDATIONS)
  });
  // const [selectedSiteId, setSelectedSiteId] = useState(null);
  const [client, setClientData] = useState(null);
  const [site, setSite] = useState({});
  const [workerType, setWorkerType] = useState('');
  const [edit, setedit] = useState(false);
  const [isSmallerThan1980] = useMediaQuery('(max-width: 1980px)');
  const [loader, setloader] = useState(false);

  const changePageIndex = (index) => {
    setCurrentPageIndex(index);
  };

  const openMessageEditModal = async (row) => {
    setedit(true);
    setValidationObject({ ...deepClone(MESSAGE_UPDATE_VALIDATIONS) });
    const editdata = row;
    const BodyData = editdata.body[0];
    const TextTypeIndex = BodyData.findIndex((obj) => obj.type === 'text');
    const MediaTypeIndex = BodyData.findIndex((obj) => obj.type === 'media');
    const LinkTypeIndex = BodyData.findIndex((obj) => obj.type === 'link');
    const TranslateTypeIndex = BodyData.findIndex((obj) => obj.type === 'translate');

    const MessageBody = [
      {
        text: BodyData[TextTypeIndex]?.data,
        media: BodyData[MediaTypeIndex]?.data,
        link: BodyData[LinkTypeIndex]?.data,
        translate: BodyData[TranslateTypeIndex]?.data,
        isValid: true,
        isValidUrl: true
      }
    ];
    editdata.body = MessageBody;
    setTemplateData(editdata);
    changePageIndex(1);
  };

  useEffect(() => {
    if (selectedType && selectedType.value === 'AUTOMATED') {
      setColumns([
        {
          label: 'From',
          field: 'from',
          sort: true
        },
        {
          label: 'Messages',
          field: 'name',
          sort: true
        },
        {
          label: 'Date',
          field: 'created_at',
          sort: true,
          customRow: (cell) => {
            const date = moment(new Date(cell)).format('DD/MM/YYYY');
            return date || '-';
          }
        },
        {
          label: 'Action',
          field: 'id',
          sort: false,
          customRow: (_cell, row) => {
            return (
              <div style={{ display: 'flex' }}>
                <Button
                  bg="main.semiPrimary"
                  color="main.white"
                  _hover={{ bg: 'main.primary' }}
                  _active={{
                    bg: 'main.semiPrimary',
                    borderColor: 'main.primary'
                  }}
                  // size="xs"
                  onClick={() => {
                    openMessageEditModal(row, _cell);
                  }}>
                  {constants.MESSAGING.EDIT_BUTTON_TEXT}
                </Button>
              </div>
            );
          }
        }
      ]);
    } else {
      const SENT_MESSAGE_COLUMNS = [
        {
          label: 'Likes',
          field: 'likes',
          sort: true
        },
        {
          label: 'Dislikes',
          field: 'dislikes',
          sort: true
        },
        {
          label: 'Sent To',
          field: 'sent_to',
          sort: true
        }
      ];
      setColumns([...INITIAL_COLUMNS, ...SENT_MESSAGE_COLUMNS]);
    }
  }, [selectedType]);

  const getWorkerType = (e) => {
    setWorkerType(e.value);
  };
  const onEditTranslateClicked = async () => {
    const siteId = (site && site.value) || selected_site_id || user_details.site_id || null;
    let clientId = (client && client.value) || user_details.client_id || null;
    const agencyId = user_details.agency_id || null;
    const type =
      selectedMessageType && selectedMessageType.value ? selectedMessageType.value : null;
    const AutomatedMsgtype = 'SYSTEM_DEFAULT';
    clientId =
      user_details.user_type_id === USER_TYPE.AGENCY_REGION_ADMIN ||
      user_details.user_type_id === USER_TYPE.AGENCY_SITE_ADMIN ||
      user_details.user_type_id === USER_TYPE.AGENCY_ADMIN
        ? ''
        : (client && client.value) || user_details.client_id || null;
    const parameters = [10, 1, 'id', 'DESC', undefined];
    const result = await getAutomatedMessages(AutomatedMsgtype, clientId, agencyId, ...parameters);
    if (result.ok) {
      return result;
    }

    return [];
  };

  const retrieveMessagingData = async (...params) => {
    const siteId = (site && site.value) || selected_site_id || user_details.site_id || null;
    let clientId = (client && client.value) || user_details.client_id || null;
    const agencyId = user_details.agency_id || null;
    const type =
      selectedMessageType && selectedMessageType.value ? selectedMessageType.value : null;
    if (selectedType.value === 'SENT') {
      const result = await getMessages(type, siteId, clientId, agencyId, ...params);
      if (result.ok) {
        return result;
      }
    } else {
      const AutomatedMsgtype = 'SYSTEM_DEFAULT';
      clientId =
        user_details.user_type_id === USER_TYPE.AGENCY_REGION_ADMIN ||
        user_details.user_type_id === USER_TYPE.AGENCY_SITE_ADMIN ||
        user_details.user_type_id === USER_TYPE.AGENCY_ADMIN
          ? ''
          : (client && client.value) || user_details.client_id || null;
      const parameters = [...params];
      if (
        parameters[2] === 'sent_to' ||
        parameters[2] === 'dislikes' ||
        parameters[2] === 'likes'
      ) {
        parameters[2] = 'id';
      }
      const result = await getAutomatedMessages(
        AutomatedMsgtype,
        clientId,
        agencyId,
        ...parameters
      );
      if (result.ok) {
        return result;
      }
    }
    return [];
  };

  const onTemplateSelect = (template) => {
    if (template) {
      setIsNewTemplate(false);
    } else {
      setIsNewTemplate(true);
    }
  };

  const onMessageTypeChange = (e) => {
    const data = { ...INITIAL_MESSAGE_DATA, body: [...INITIAL_MESSAGE_DATA.body] };
    data.type = { ...e };
    setTemplateData({ ...data });
    setSelectedTemplate(null);
    setIsNewTemplate(false);
  };

  const generatePayloadForMessageTemplate = (template, templateCreate) => {
    const bodyItem = [];

    templateData.body.forEach((body) => {
      const item = [];
      if (body.text && body.text !== '') {
        item.push({
          type: 'text',
          data: body.text
        });
      }
      if (body.link && body.link !== '') {
        item.push({
          type: 'link',
          data: body.link
        });
      }
      if (body.media && body.media !== '') {
        item.push({
          type: 'media',
          data: body.media
        });
      }
      if (body.translate && body.translate !== '' && templateCreate !== 'CREATE_TEMPLATE') {
        item.push({
          type: 'translate',
          data: body.translate
        });
      }
      if (item.length > 0) {
        bodyItem.push(item);
      }
    });
    const payload = { ...templateData, type: templateData.type.value, body: [...bodyItem] };
    if (!template) {
      delete payload.languages;
    }
    return payload;
  };
  const onTranslateClicked = async (state, sendToType) => {
    const [validationResult, isFormValid] = validateForm(templateData, validationObject);
    setValidationObject({ ...validationResult });
    let isValidBody = true;
    let isValidLink = true;
    const body = [...templateData.body];

    body.forEach((item, index) => {
      if (isValidBody) {
        if (item.text.length < 1) {
          isValidBody = false;
        }
      }
      if (isValidLink) {
        if (item.link && item.link.length > 0) {
          isValidLink = validateUrl(item.link);
        }
      }
      body[index].isValid = item.text.length > 0;
      body[index].isValidUrl = item.link && item.link.length > 0 ? validateUrl(item.link) : true;
    });
    setTemplateData({ ...templateData, body: [...body] });
    if (isFormValid && isValidBody && isValidLink) {
      switch (state) {
        case 'create': {
          window.open(
            '/messaging-translate',
            'Popup',
            'toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=no, resizable=false, width=520, height=800, left=1200, top=100'
          );
          break;
        }
        case 'update': {
          window.open(
            '/messaging-translate',
            'Popup',
            'toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=no, resizable=false, width=520, height=800,left=1200, top=100'
          );
          break;
        }
        case 'send': {
          // sendToType is 1 check for worker if 2 check for others
          const send_to = [];
          if (sendToType === '1' && worker.length > 0) {
            const data = {
              type: 'workers'
            };
            data.data = worker.map((item) => item.value);
            send_to.push({ ...data });
          } else if (
            sendToType === '2' &&
            (role.length > 0 || department.length > 0 || shift.length > 0 || nationality.length > 0)
          ) {
            if (department.length > 0) {
              const data = {
                type: 'department'
              };
              data.data = department.map((item) => item.value);
              send_to.push({ ...data });
            }
            if (shift.length > 0) {
              const data = {
                type: 'shift'
              };
              data.data = shift.map((item) => item.value);
              send_to.push({ ...data });
            }
            if (role.length > 0) {
              const data = {
                type: 'job'
              };
              data.data = role.map((item) => item.value);
              send_to.push({ ...data });
            }
            if (nationality.length > 0) {
              const data = {
                type: 'nationality'
              };
              data.data = nationality.map((item) => item.value);
              send_to.push({ ...data });
            }
          } else if (sendToType === '3') {
            const data = {
              type: 'workers'
            };
            data.data = [];
            send_to.push({ ...data });
          } else {
            window.alert('Please select to whom you want to send.');
            return;
          }
          window.open(
            '/messaging-translate',
            'Popup',
            'toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=no, resizable=false, width=520, height=800, left=1200, top=100'
          );
          break;
        }

        default:
          break;
      }
    } else {
      setRefresh(!refresh);
    }
  };
  const [childWindow, setChildWindow] = useState(null);

  const openChildWindow = () => {
    const newWindow = window.open(
      '/design-template-translate',
      'Popup',
      'toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=no, resizable=false, width=520, height=800, left=1200, top=100'
    );
    setChildWindow(newWindow);
  };
  const retrieveSelectedTemplate = async () => {
    if (selectedTemplate && selectedTemplate.value) {
      const result = await getTemplate(selectedTemplate.value);
      if (result.ok) {
        const data = result.data.template;
        const body = [];
        data.body.forEach((item) => {
          const bodyData = {
            isValid: true,
            isValidUrl: true
          };
          item.forEach((bodyItem) => {
            if (bodyItem.type === 'text') {
              bodyData.text = bodyItem.data;
            }
            if (bodyItem.type === 'media') {
              bodyData.media = bodyItem.data;
            }
            if (bodyItem.type === 'link') {
              bodyData.link = bodyItem.data;
            }
            if (bodyItem.type === 'translate') {
              bodyData.translate = bodyItem.data;
            }
          });
          body.push({
            ...bodyData
          });
        });
        const newData = {
          name: data.name,
          title: data.title,
          from: data.from,
          type: templateData.type,
          body,
          languages: data.languages,
          id: data.id,
          title_translations: data.title_translations,
          is_master_template: data.is_master_template
        };
        setTemplateData({ ...newData });
      }
    }
  };

  const retrieveTemplates = async (templateId) => {
    const result = await getTemplateList(templateData.type.value);
    if (result && result.ok) {
      const item = result.data.templates.find((obj) => obj.id === templateId);
      setSelectedTemplate({ label: item.name, value: item.id });
    } else {
      toast({
        ...TOAST_SETTINGS.SETTINGS,
        status: TOAST_SETTINGS.ERROR,
        title: result.error
      });
    }
  };

  useEffect(() => {
    if (childWindow) {
      // Handle the case when the child window is closed
      const handleChildWindowClose = () => {
        const templateId = selectedTemplate.value;
        retrieveSelectedTemplate();
        retrieveTemplates(templateId);
      };
      childWindow.addEventListener('beforeunload', handleChildWindowClose);
    }
  }, [childWindow]);
  const onTranslateAllBtnClicked = (inDesignWindow) => {
    const [validationResult, isFormValid] = validateForm(templateData, validationObject);
    setValidationObject({ ...validationResult });
    let isValidBody = true;
    let isValidLink = true;
    const body = [...templateData.body];

    body.forEach((item, index) => {
      if (isValidBody) {
        if (item.text.length < 1) {
          isValidBody = false;
        }
      }
      if (isValidLink) {
        if (item.link && item.link.length > 0) {
          isValidLink = validateUrl(item.link);
        }
      }
      body[index].isValid = item.text.length > 0;
      body[index].isValidUrl = item.link && item.link.length > 0 ? validateUrl(item.link) : true;
    });
    setTemplateData({ ...templateData, body: [...body] });

    if (isFormValid && isValidBody && isValidLink && !inDesignWindow) {
      window.open(
        '/automated-messaging-translate',
        'Popup',
        'toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=no, resizable=false, width=520, height=800, left=1200, top=100'
      );
    } else if (isFormValid && isValidBody && isValidLink && inDesignWindow) {
      openChildWindow();
    } else {
      setRefresh(!refresh);
    }
  };

  const onDeleteBtnClicked = async () => {
    const { id } = templateData;
    const result = await deleteTemplate(id);
    if (result && result.ok) {
      toast({
        ...TOAST_SETTINGS.SETTINGS,
        duration: CUSTOM_TOAST_DURATION.duration,
        status: TOAST_SETTINGS.SUCCESS,
        title: result.message
      });
      setSelectedTemplate(null);
      setTemplateData({ ...INITIAL_MESSAGE_DATA, body: [...INITIAL_MESSAGE_DATA.body] });
      retrieveSelectedTemplate();
    } else {
      // setValidationObject({ ...deepClone(MESSAGE_CREATE_VALIDATIONS) });
      toast({
        ...TOAST_SETTINGS.SETTINGS,
        status: TOAST_SETTINGS.ERROR,
        title: result.error
      });
    }
  };

  const onSaveBtnClick = async (state, sendToType, selectedSite, selectedClient, commentToggle) => {
    if (edit) {
      const [validationResult, isFormValid] = validateForm(templateData, validationObject);
      setValidationObject({ ...validationResult });
      let isValidBody = true;
      let isValidLink = true;
      const body = [...templateData.body];
      body.forEach((item, index) => {
        if (isValidBody) {
          if (item.text.length < 1) {
            isValidBody = false;
          }
        }
        if (isValidLink) {
          if (item.link && item.link.length > 0) {
            isValidLink = validateUrl(item.link);
          }
        }
        body[index].isValid = item.text.length > 0;
        body[index].isValidUrl = item.link && item.link.length > 0 ? validateUrl(item.link) : true;
      });
      setTemplateData({ ...templateData, body: [...body] });

      if (isFormValid && isValidBody && isValidLink) {
        const payloadData = {
          ...generatePayloadForMessageTemplate(false, 'CREATE_TEMPLATE')
        };
        const payload = { body: [...payloadData.body], from: payloadData.from };
        const agencyId = user_details.agency_id || null;
        const clientId =
          user_details.user_type_id === USER_TYPE.AGENCY_REGION_ADMIN ||
          user_details.user_type_id === USER_TYPE.AGENCY_SITE_ADMIN ||
          user_details.user_type_id === USER_TYPE.AGENCY_ADMIN
            ? ''
            : (client && client.value) || user_details.client_id || null;
        const result = await UpdateMessage(clientId, agencyId, payloadData.id, payload);
        if (result && result.ok) {
          toast({
            ...TOAST_SETTINGS.SETTINGS,
            duration: CUSTOM_TOAST_DURATION.duration,
            status: TOAST_SETTINGS.SUCCESS,
            title: result.message
          });
          setValidationObject({ ...deepClone(MESSAGE_CREATE_VALIDATIONS) });
          changePageIndex(0);
          setRefresh(!refresh);
          setedit(false);
          setTemplateData({
            ...INITIAL_MESSAGE_DATA,
            body: [...INITIAL_MESSAGE_DATA.body]
          });
        } else {
          toast({
            ...TOAST_SETTINGS.SETTINGS,
            status: TOAST_SETTINGS.ERROR,
            title: result.error
          });
        }
      } else {
        setRefresh(!refresh);
      }
    } else {
      const [validationResult, isFormValid] = validateForm(templateData, validationObject);
      setValidationObject({ ...validationResult });
      let isValidBody = true;
      let isValidLink = true;
      const body = [...templateData.body];

      body.forEach((item, index) => {
        if (isValidBody) {
          if (item.text.length < 1) {
            isValidBody = false;
          }
        }
        if (isValidLink) {
          if (item.link && item.link.length > 0) {
            isValidLink = validateUrl(item.link);
          }
        }
        body[index].isValid = item.text.length > 0;
        body[index].isValidUrl = item.link && item.link.length > 0 ? validateUrl(item.link) : true;
      });

      setTemplateData({ ...templateData, body: [...body] });

      if (isFormValid && isValidBody && isValidLink) {
        switch (state) {
          case 'create': {
            const payload = { ...generatePayloadForMessageTemplate(true, 'CREATE_TEMPLATE') };
            delete payload.title_translations;
            delete payload.is_master_template;
            if (payload.id) {
              delete payload.id;
            }
            const result = await createTemplate(payload);
            if (result && result.ok) {
              toast({
                ...TOAST_SETTINGS.SETTINGS,
                duration: CUSTOM_TOAST_DURATION.duration,
                status: TOAST_SETTINGS.SUCCESS,
                title: result.message
              });
              // eslint-disable-next-line prefer-destructuring
              const id = result?.data?.id;
              // eslint-disable-next-line no-use-before-define
              const newTemplateData = await getTemplate(id);
              setSelectedTemplate({
                label: newTemplateData?.data?.template?.name,
                value: newTemplateData?.data?.template?.id
              });
            } else {
              toast({
                ...TOAST_SETTINGS.SETTINGS,
                status: TOAST_SETTINGS.ERROR,
                title: result.error
              });
            }
            break;
          }
          case 'update': {
            const payload = { ...generatePayloadForMessageTemplate(true) };
            const { id } = payload;
            delete payload.is_master_template;
            delete payload.id;
            const result = await updateTemplate(id, payload);
            if (result && result.ok) {
              toast({
                ...TOAST_SETTINGS.SETTINGS,
                duration: CUSTOM_TOAST_DURATION.duration,
                status: TOAST_SETTINGS.SUCCESS,
                title: result.message
              });
              retrieveTemplates(id);
            } else {
              toast({
                ...TOAST_SETTINGS.SETTINGS,
                status: TOAST_SETTINGS.ERROR,
                title: result.error
              });
            }
            break;
          }
          case 'send': {
            // sendToType is 1 check for worker if 2 check for others
            const send_to = [];
            if (sendToType === '1' && worker.length > 0) {
              const data = {
                type: 'workers'
              };
              data.data = worker.map((item) => item.value);
              send_to.push({ ...data });
            } else if (
              sendToType === '2' &&
              (role.length > 0 ||
                department.length > 0 ||
                shift.length > 0 ||
                nationality.length > 0)
            ) {
              if (department.length > 0) {
                const data = {
                  type: 'department'
                };
                data.data = department.map((item) => item.value);
                send_to.push({ ...data });
              }
              if (shift.length > 0) {
                const data = {
                  type: 'shift'
                };
                data.data = shift.map((item) => item.value);
                send_to.push({ ...data });
              }
              if (role.length > 0) {
                const data = {
                  type: 'job'
                };
                data.data = role.map((item) => item.value);
                send_to.push({ ...data });
              }
              if (nationality.length > 0) {
                const data = {
                  type: 'nationality'
                };
                data.data = nationality.map((item) => item.value);
                send_to.push({ ...data });
              }
            } else if (sendToType === '3') {
              const data = {
                type: 'workers'
              };
              data.data = [];
              send_to.push({ ...data });
            } else {
              window.alert('Please select to whom you want to send.');
              return;
            }

            const payload = {
              ...generatePayloadForMessageTemplate(),
              send_to,
              is_comment_allowed: commentToggle
            };
            delete payload.is_master_template;
            if (payload.id) {
              delete payload.id;
            }
            setloader(true);
            const result = await sendMessage(
              (selectedClient && selectedClient.value) || user_details.client_id,
              (selectedSite && selectedSite.value) || user_details.site_id || null,
              user_details.agency_id,
              workerType,
              payload
            );
            if (result && result.ok) {
              setloader(false);
              toast({
                ...TOAST_SETTINGS.SETTINGS,
                duration: CUSTOM_TOAST_DURATION.duration,
                status: TOAST_SETTINGS.SUCCESS,
                title: result.message
              });
              changePageIndex(0);
            } else {
              setloader(false);
              toast({
                ...TOAST_SETTINGS.SETTINGS,
                status: TOAST_SETTINGS.ERROR,
                title: result.error
              });
            }
            break;
          }

          default:
            break;
        }
      } else {
        setRefresh(!refresh);
      }
    }
  };

  const onInputChange = (field, e) => {
    const data = { ...templateData };
    data[field] = e;
    setTemplateData({ ...data });
    setIsNewTemplate(false);
  };

  const onBodyInputChange = (field, index, e) => {
    const data = { ...templateData };
    const bodyData = { ...templateData.body[index] };
    bodyData[field] = e;
    if (field === 'text') {
      bodyData.isValid = true;
    }
    if (field === 'link') {
      bodyData.isValidUrl = true;
    }
    data.body[index] = bodyData;
    setTemplateData({ ...data });
    setIsNewTemplate(false);
  };

  const onAddNewBody = () => {
    const data = { ...templateData };
    data.body.push({
      text: '',
      media: '',
      link: '',
      isValid: true,
      isValidUrl: true
    });
    setTemplateData({ ...data });
  };

  const removeBodyItem = (index) => {
    const data = templateData;
    data.body.splice(index, 1);
    setTemplateData({ ...data });
  };

  const resetFilters = () => {
    setWorker([]);
    setRole([]);
    setDepartment([]);
    setShift([]);
    setNationality([]);
  };

  useEffect(() => {
    setWorker([]);
    setRole([]);
    setDepartment([]);
    setShift([]);
    setNationality([]);
    setRefresh((currentState) => !currentState);
  }, [selectedMessageType, client, site, selectedType]);

  useEffect(() => {
    const currentSite = { ...site };
    if (!edit) {
      switch (currentPageIndex) {
        case 2:
          setSelectedTemplate(null);
          setTemplateData({ ...INITIAL_MESSAGE_DATA, body: [...INITIAL_MESSAGE_DATA.body] });
          break;
        case 1:
          setSelectedTemplate(null);
          setTemplateData({
            ...INITIAL_MESSAGE_DATA,
            body: [
              {
                text: '',
                media: '',
                link: '',
                isValid: true,
                isValidUrl: true
              }
            ]
          });
          break;
        default: {
          setValidationObject({ ...deepClone(MESSAGE_CREATE_VALIDATIONS) });
          const body = [...templateData.body];
          body.forEach((item, index) => {
            body[index].isValid = true;
            body[index].isValidUrl = true;
          });
          setTemplateData({ ...templateData, body: [...body] });
          setRefresh(!refresh);
          setSite({ ...currentSite });
          break;
        }
      }
    }
  }, [currentPageIndex]);

  useEffect(() => {
    retrieveSelectedTemplate();
  }, [selectedTemplate]);

  return currentPageIndex === 0 ? (
    <StyledMessagingContainer>
      {isPageLoader ? <Loader /> : null}
      <Card>
        <StyledMessageCardHeaderContainer>
          {/* {permission && permission.includes('create') && ( */}
          <Flex>
            <Button
              bg="main.semiPrimary"
              color="main.white"
              _hover={{ bg: 'main.primary' }}
              _active={{
                bg: 'main.semiPrimary',
                borderColor: 'main.primary'
              }}
              onClick={() => {
                changePageIndex(1);
              }}>
              {constants.MESSAGING.COMPOSE_BUTTON_TEXT}
            </Button>
            <Button
              bg="main.semiPrimary"
              color="main.white"
              _hover={{ bg: 'main.primary' }}
              _active={{
                bg: 'main.semiPrimary',
                borderColor: 'main.primary'
              }}
              onClick={() => {
                changePageIndex(2);
              }}
              ml={2}>
              {constants.MESSAGING.DESIGNS_BUTTON_TEXT}
            </Button>
          </Flex>
          {/* )} */}
          <Spacer />
          <Flex flexDir={isSmallerThan1980 && 'column-reverse'}>
            {user_details.user_type_id === USER_TYPE.AGENCY_ADMIN && (
              <Flex>
                <Box width="180px" mr="8px">
                  <CompanyDropdown
                    company={selectedType.value === 'AUTOMATED' ? '' : client}
                    setCompany={setClientData}
                    disabled={selectedType.value === 'AUTOMATED'}
                    setDefaultValue
                  />
                </Box>
                <Box width="180px" mr={isSmallerThan1980 ? '' : '8px'}>
                  <SiteDropdownWithDefault
                    label="Site"
                    site={selectedType.value === 'AUTOMATED' ? '' : site}
                    setSite={setSite}
                    disabled={selectedType.value === 'AUTOMATED'}
                    multiple={false}
                    setDefaultValue
                    clientId={client && client.value ? client.value : null}
                    edit={selectedType.value === 'AUTOMATED'}
                  />
                </Box>
              </Flex>
            )}
            {user_details.user_type_id === USER_TYPE.AGENCY_REGION_ADMIN && (
              <Box width="180px" mr="8px">
                <SiteDropdownWithDefault
                  label="Site"
                  site={selectedType.value === 'AUTOMATED' ? '' : site}
                  setSite={setSite}
                  disabled={selectedType.value === 'AUTOMATED'}
                  multiple={false}
                  setDefaultValue
                  clientId={client && client.value ? client.value : null}
                />
              </Box>
            )}

            {(user_details.user_type_id === USER_TYPE.CLIENT_ADMIN ||
              user_details.user_type_id === USER_TYPE.MESSAGE_ADMIN) && (
              <Box width="180px" mr="8px">
                <SiteDropdownWithDefault
                  label="Site"
                  site={selectedType.value === 'AUTOMATED' ? '' : site}
                  setSite={setSite}
                  disabled={selectedType.value === 'AUTOMATED'}
                  multiple={false}
                  setDefaultValue
                  clientId={client && client.value ? client.value : null}
                />
              </Box>
            )}
            <Flex>
              <Box width="180px" mr="8px">
                <TypeDropdown
                  messageType={selectedType}
                  refresh={refresh}
                  setMessageType={(e) => {
                    setSelectedType({ ...e });
                  }}
                  setDefaultValue
                  label="Message Status"
                />
              </Box>
              <Box width="180px">
                <MessageTypeDropdown
                  messageType={selectedType.value === 'AUTOMATED' ? '' : selectedMessageType}
                  refresh={refresh}
                  setMessageType={(e) => {
                    setSelectedMessageType({ ...e });
                  }}
                  label="Message Type"
                  disabled={selectedType.value === 'AUTOMATED'}
                />
              </Box>
            </Flex>
          </Flex>
        </StyledMessageCardHeaderContainer>

        <ServerSideDatatable
          pageSize={10}
          initialSortKey="id"
          column={columns}
          onPaginate={retrieveMessagingData}
          afterPaginateData={() => setIsPageLoader(false)}
          pagination
          pages={[10, 15]}
          dataKey="result"
          refresh={refresh}
          sortByIdDesc="true"
        />
      </Card>
    </StyledMessagingContainer>
  ) : (
    <MessagingView
      goBackOnClick={changePageIndex}
      isComposeType={currentPageIndex === 1}
      isNewTemplate={isNewTemplate}
      templateData={templateData}
      onMessageTypeChange={onMessageTypeChange}
      onTemplateSelect={onTemplateSelect}
      onSaveBtnClick={onSaveBtnClick}
      onInputChange={onInputChange}
      onBodyInputChange={onBodyInputChange}
      onAddNewBody={onAddNewBody}
      setSelectedTemplate={setSelectedTemplate}
      selectedTemplate={selectedTemplate}
      shift={shift}
      setShift={setShift}
      department={department}
      setDepartment={setDepartment}
      role={role}
      setRole={setRole}
      siteId={site.value || user_details.site_id || null}
      worker={worker}
      setWorker={setWorker}
      nationality={nationality}
      setNationality={setNationality}
      removeBodyItem={removeBodyItem}
      userType={user_details.user_type_id}
      validation={validationObject}
      refresh={refresh}
      resetFilters={resetFilters}
      getWorkerType={getWorkerType}
      onTranslateClicked={onTranslateClicked}
      translateclient={client}
      translateworkerType={workerType}
      edit={edit}
      setedit={setedit}
      setTemplateData={setTemplateData}
      loader={loader}
      onTranslateAllBtnClicked={onTranslateAllBtnClicked}
      onEditTranslateClicked={onEditTranslateClicked}
      onDeleteBtnClicked={onDeleteBtnClicked}
    />
  );
};

Messaging.propTypes = {};

export default Messaging;
