import React from 'react';

const RoleIcon = function () {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none" viewBox="0 0 22 26">
      <path
        fill="currentColor"
        d="M0 .48v25.231h21.75V.481H0zm15.366 18.315l-4.491 4.996-4.49-4.996 3.13-10.362-3.13-4.878h8.976l-3.127 4.878 3.132 10.362z"
      />
    </svg>
  );
};

export default RoleIcon;
