import { Box, Button, Flex, Spinner, Text, useToast } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
// import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import Card from '../../components/Card';
import InputComponent from '../../components/Input';
import NoData from '../../components/NoData';
import { CUSTOM_TOAST_DURATION, PATH, TOAST_SETTINGS } from '../../constants';
import {
  // getSurveyAnalysis,
  getSurveyAnalysisQuestions,
  updateGeneralSurveys
} from '../../redux/action/survey.action';

const UpdateSurvey = function () {
  const toast = useToast();
  const { user_details: userDetails } = useSelector((state) => state.authentication);
  const { selected_client_id: selectedClientId } = useSelector((state) => state.agency);
  const [generalSurveys, setGeneralSurveys] = useState([]);
  const [data, setData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [validations, setValidations] = useState([]);
  const [loader, setLoader] = useState(false);
  const getGeneralSurveyListing = async () => {
    const generalSurvey = '7';
    const result = await getSurveyAnalysisQuestions(generalSurvey);
    if (result && result.ok) {
      setGeneralSurveys(result.data.questions);
      setData(result.data.questions);
    }
  };

  useEffect(() => {
    const questionsLength = generalSurveys.length;
    for (let i = 0; i < questionsLength; i += 1) {
      validations[i] = {
        isvalid: true,
        errorMessage: '*Please Enter A Question',
        required: false
      };
    }
  }, [generalSurveys.length]);

  useEffect(() => {
    getGeneralSurveyListing();
  }, []);

  const updateParticularQuestion = (e, index) => {
    if (e.length === 0) {
      validations[index].isvalid = false;
    } else {
      validations[index].isvalid = true;
    }

    setValidations(validations);
    setRefresh(!refresh);
    const newData = [...data];
    newData[index].question_text = e;
    setData(newData);
  };

  const handleQuestionsUpdateBtn = async () => {
    setLoader(true);
    const newData = data.map((question) => {
      return { id: question.id, question_text: question.question_text };
    });
    const payload = { questions: newData };
    const generalSurvey = '7';
    const result = await updateGeneralSurveys(
      generalSurvey,
      selectedClientId || userDetails.client_id,
      payload
    );
    if (result) {
      setLoader(false);
    }
    if (result && result.ok) {
      toast({
        ...TOAST_SETTINGS.SETTINGS,
        duration: CUSTOM_TOAST_DURATION.duration,
        status: TOAST_SETTINGS.SUCCESS,
        title: result?.data?.message
      });
    } else {
      toast({
        ...TOAST_SETTINGS.SETTINGS,
        status: TOAST_SETTINGS.ERROR,
        title: result.error
      });
    }
  };

  return (
    <>
      <Card>
        <Flex flexDir="row" justifyContent="space-between">
          <Text m="0px 5px" fontSize="3xl" fontWeight="bold">
            General Feedback Surveys
          </Text>
          <NavLink to={PATH.SURVEYS.TO} color="main.primary" fontSize="sm">
            <Button
              m="7px 0px"
              bg="main.semiPrimary"
              color="main.white"
              _hover={{ bg: 'main.primary' }}
              _active={{
                bg: 'main.semiPrimary',
                borderColor: 'main.primary'
              }}>
              Back To Surveys{' '}
            </Button>
          </NavLink>
        </Flex>
      </Card>
      <Card style={{ marginTop: '30px', padding: '25px' }}>
        {generalSurveys.length ? (
          <>
            <Flex flexDir="column">
              {generalSurveys.map((survey, index) => {
                return (
                  <InputComponent
                    style={{ height: '45px', fontSize: '17px' }}
                    key={survey.id}
                    validationObj={
                      validations[index] || {
                        isvalid: true,
                        errorMessage: '*Please Enter A Question',
                        required: true
                      }
                    }
                    lable={`${index + 1}. ${data[index]?.label || `Question Title`}`}
                    value={data[index]?.question_text}
                    placeholder="Question Text"
                    onChange={(e) => updateParticularQuestion(e, index)}
                    refresh={refresh}
                  />
                );
              })}
            </Flex>
            <Flex flexDir="row" justifyContent="space-between" alignItems="center">
              <Box />
              {loader && (
                <Box>
                  <Spinner
                    thickness="4px"
                    speed="0.65s"
                    emptyColor="gray.200"
                    color="blue.500"
                    size="md"
                    position="absolute"
                    marginLeft="7px"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  />
                  <Text
                    color="main.semiPrimary"
                    marginLeft="40px"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    fontSize="16px"
                    fontWeight="600"
                    letterSpacing="1px">
                    Your updates are being processed, You&#39;ll see the changes in 8 to 10 seconds.
                  </Text>
                </Box>
              )}
              <Button
                onClick={handleQuestionsUpdateBtn}
                bg="main.semiPrimary"
                color="main.white"
                _hover={{ bg: 'main.primary' }}
                _active={{
                  bg: 'main.semiPrimary',
                  borderColor: 'main.primary'
                }}
                disabled={
                  // eslint-disable-next-line no-unneeded-ternary
                  loader || validations.filter((d) => d.isvalid === false).length > 0 ? true : false
                }
                width="100px"
                height="44px"
                margin="7px 0px">
                {loader ? <Text>Updating...</Text> : <Text>Update</Text>}
              </Button>
            </Flex>
          </>
        ) : (
          <Card>
            <NoData />
          </Card>
        )}
      </Card>
    </>
  );
};
export default UpdateSurvey;
