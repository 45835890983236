import styled from '@emotion/styled';
import { Flex } from '@chakra-ui/react';

const StyledComplianceContainer = styled.div``;

export const StyledComplianceHeaderContainer = styled(Flex)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export default StyledComplianceContainer;
