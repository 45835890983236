/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Text, useToast } from '@chakra-ui/react';
import { TOAST_SETTINGS } from '../../constants';
import { getAgency } from '../../redux/action/agency.action';
import Dropdown from '../Dropdown';

const AgencyDropdown = function ({
  agency,
  setAgency,
  validationObj,
  refresh,
  disabled = false,
  multiple = false,
  placeholder,
  removeErrors
}) {
  const toast = useToast();
  const [agencies, setAgencies] = useState([]);
  const [errorMsg, setErrorMsg] = useState(null);
  const [isValid, setValid] = useState(validationObj?.isvalid || true);

  useEffect(async () => {
    const result = await getAgency(1000, 1, 'name', 'ASC');
    if (result && result.ok) {
      setAgencies(result.data.agency_list);
    } else {
      toast({
        ...TOAST_SETTINGS.SETTINGS,
        status: TOAST_SETTINGS.ERROR,
        title: result.error
      });
    }
  }, []);

  useEffect(() => {
    if (validationObj && !validationObj.isvalid) {
      setErrorMsg(validationObj.errorMessage);
      setValid(validationObj.isvalid);
    }
  }, [refresh]);

  const setAgencyData = (e) => {
    setErrorMsg(null);
    setValid(true);
    setAgency(e);
  };

  return (
    <>
      <Dropdown
        label={`Agency${validationObj?.required ? '*' : ''}`}
        onSelect={setAgencyData}
        options={agencies.map((item) => {
          return { label: item.agency_name, value: item.agency_id };
        })}
        value={agency}
        placeholder={placeholder || 'All Agencies'}
        isError={!isValid}
        disabled={disabled}
        multiple={multiple}
      />
      {!removeErrors && (
        <Text
          visibility={isValid ? 'hidden' : 'visible'}
          color="main.error"
          mb="2px"
          fontSize="11px">
          {errorMsg || 'Error !'}
        </Text>
      )}
    </>
  );
};

export default AgencyDropdown;
