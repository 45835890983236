import { API_ENDPOINT } from '../../constants';

import axiosInstance from '../../config/axios.config';
import { prepareErrorResponse, prepareSuccessReponse } from '../../utils/responseHandler';

const getDepartments = async (clientId, siteId, regionId) => {
  let query = `client_id=${clientId}`;
  query += siteId ? `&site_id=${siteId}` : '';
  query += regionId ? `&region_id=${regionId}` : '';
  const result = await axiosInstance.get(`${API_ENDPOINT.GET_DEPARTMENT}?${query}`);
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const addDepartment = async (payload) => {
  const result = await axiosInstance.post(API_ENDPOINT.ADD_DEPARTMENT, payload);
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const updateDepartment = async (id, payload) => {
  const result = await axiosInstance.put(`${API_ENDPOINT.UPDATE_DEPARTMENT}/${id}`, payload);
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

export { getDepartments, addDepartment, updateDepartment };
