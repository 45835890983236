import { Box } from '@chakra-ui/react';
import styled from 'styled-components';

export const StyledRightPanel = styled(Box)`
  width: 100%;
  border-radius: 50px 0 0 50px;
  padding: 30px;
`;

export const StyledInputWrapper = styled(Box)`
  margin: 0 0 20px 0;
`;
