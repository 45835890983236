/* eslint-disable default-param-last */
/* eslint-disable radix */
import { API_ENDPOINT } from '../../constants';

import axiosInstance from '../../config/axios.config';
import { prepareErrorResponse, prepareSuccessReponse } from '../../utils/responseHandler';

const getMessages = async (
  type,
  siteId,
  clientId,
  agencyId,
  limit = 10,
  page = 1,
  sortKey,
  sortType
) => {
  let query = '?';
  query += type ? `type=${type}&` : '';
  query += clientId ? `client_id=${clientId}&` : '';
  query += siteId ? `site_id=${siteId}&` : '';
  query += agencyId ? `agency_id=${agencyId}&` : '';

  const result = await axiosInstance.get(
    `${API_ENDPOINT.GET_MESSAGES}${query}page=${page}&limit=${limit}&sort_by=${sortKey}&sort_type=${sortType}`
  );
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const getEditMessageTranslate = async (clientId, agencyId, messageId) => {
  let query = '?';
  query += clientId ? `client_id=${clientId}` : '';
  query += agencyId ? `agency_id=${agencyId}` : '';
  const result = await axiosInstance.get(
    `${API_ENDPOINT.GET_MESSAGES}${API_ENDPOINT.TRANSLATE_MESSAGES}/${messageId}${query}`
  );
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const updateEditMessageTranslate = async (clientId, agencyId, messageId, payload) => {
  let query = '?';
  query += clientId ? `client_id=${clientId}` : '';
  query += agencyId ? `agency_id=${agencyId}` : '';
  const result = await axiosInstance.post(
    `${API_ENDPOINT.GET_MESSAGES}${API_ENDPOINT.TRANSLATE_MESSAGES}/${messageId}${query}`,
    payload
  );
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const getAutomatedMessages = async (
  type,
  clientId,
  agencyId,
  limit = 10,
  page = 1,
  sortKey,
  sortType
) => {
  let query = '?';
  query += type ? `type=${type}&` : '';
  query += clientId ? `client_id=${clientId}&` : '';
  query += agencyId ? `agency_id=${agencyId}&` : '';
  const result = await axiosInstance.get(
    `${API_ENDPOINT.GET_MESSAGES}${query}page=${page}&limit=${limit}&sort_by=${sortKey}&sort_type=${sortType}`
  );
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const createTemplate = async (payload) => {
  const result = await axiosInstance.post(`${API_ENDPOINT.GET_TEMPLATES}`, payload);
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const updateTemplate = async (id, payload) => {
  const result = await axiosInstance.put(`${API_ENDPOINT.GET_TEMPLATES}/${id}`, payload);
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const deleteTemplate = async (id) => {
  const result = await axiosInstance.delete(`${API_ENDPOINT.GET_TEMPLATES}/${id}`);
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const getTemplateList = async (type) => {
  const result = await axiosInstance.get(`${API_ENDPOINT.GET_TEMPLATES}?type=${type}`);
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const getTemplate = async (id) => {
  const result = await axiosInstance.get(`${API_ENDPOINT.GET_TEMPLATES}/${id}`);
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const sendMessage = async (clientId, siteId, agencyId, workerType, payload) => {
  let query = '?';
  query += clientId ? `client_id=${clientId}` : '';
  query += siteId ? `&site_id=${siteId}` : '';
  query += agencyId ? `&agency_id=${agencyId}` : '';
  query += workerType ? `&type=${workerType}` : '';

  const result = await axiosInstance.post(`${API_ENDPOINT.GET_MESSAGES}${query}`, payload);
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};
const UpdateMessage = async (clientId, agencyId, id, payload) => {
  let query = '?';
  query += clientId ? `client_id=${clientId}` : '';
  query += agencyId ? `agency_id=${agencyId}` : '';
  const result = await axiosInstance.put(`${API_ENDPOINT.GET_MESSAGES}/${id}${query}`, payload);
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const TranslateMessage = async (payload) => {
  const result = await axiosInstance.post(
    `${API_ENDPOINT.GET_MESSAGES}${API_ENDPOINT.TRANSLATE_MESSAGES}`,
    payload
  );
  if (result && result.ok) {
    return result;
  }
  return result;
};
const TranslateAllMessage = async (payload) => {
  const result = await axiosInstance.post(
    `${API_ENDPOINT.GET_MESSAGES}${API_ENDPOINT.TRANSLATE_MESSAGES}${API_ENDPOINT.TRANSLATE_MULTIPLE}`,
    payload
  );
  if (result && result.ok) {
    return result;
  }
  return result;
};

const TranslateAllMessageDesign = async (payload) => {
  const result = await axiosInstance.post(
    `${API_ENDPOINT.TEMPLATE_MESSAGE}${API_ENDPOINT.TRANSLATE_MESSAGES}${API_ENDPOINT.TRANSLATE_MULTIPLE}`,
    payload
  );
  if (result && result.ok) {
    return result;
  }
  return result;
};
const getLanguage = async () => {
  const result = await axiosInstance.get('https://libretranslate.com/languages', {
    headers: { accept: 'application/json' }
  });
  if (result && result.ok) {
    return result;
  }
  return result;
};
export {
  getMessages,
  createTemplate,
  updateTemplate,
  getTemplateList,
  getTemplate,
  sendMessage,
  TranslateMessage,
  getLanguage,
  getAutomatedMessages,
  UpdateMessage,
  TranslateAllMessage,
  getEditMessageTranslate,
  updateEditMessageTranslate,
  TranslateAllMessageDesign,
  deleteTemplate
};
