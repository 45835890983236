import styled from 'styled-components';

export const StyledDiv = styled.div`
  background-color: ${(props) => (props.transparent ? 'transparent' : '#e9f4ff')};
  padding: 10px 8px;
  margin: 5px 0;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
`;

export const StyledItem = styled.div`
  font-size: 12px;
  font-weight: 500;
  display: flex;
  align-items: center;
  background: #426b9d;
  color: #ffffff;
  padding: 0 6px;
  border: 2px solid;
`;
