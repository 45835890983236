/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
/* eslint-disable array-callback-return */
import { Box, Button, Flex, Spinner, useToast } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import constants, {
  CUSTOM_TOAST_DURATION,
  LANGUAGE_OPTION,
  TOAST_SETTINGS,
  TRANSLATE_CUSTOM_TOAST_DURATION,
  TRANSLATE_LANGUAGE
} from '../../constants';
import StyledInputWrapper from '../../components/Forms/Forms.styled';
import InputComponent from '../../components/Input';
import { TranslateAllMessageDesign, updateTemplate } from '../../redux/action/messaging.action';
import { StyledMessagingFormContainer } from '../../components/MessagingView/MessagingView.styled';
import Card from '../../components/Card';

const INITIAL_DATA = {
  body: [
    {
      translate: {}
    }
  ]
};

const DesignMessageTranslate = function () {
  const toast = useToast();
  const { data } = useSelector((state) => state.translate);
  const [refresh, setRefresh] = useState(false);
  const [payloadData, setpayloadData] = useState();
  const [templateData, setTemplateData] = useState({
    ...data.templateData
  });
  const [loader, setLoader] = useState(true);

  const generatePayloadForMessageTemplate = (filterData) => {
    const bodyItem = [];
    filterData.body.forEach((body) => {
      const item = [];
      if (body.text && body.text !== '') {
        item.push({
          type: 'text',
          data: body.text
        });
      }
      if (body.link && body.link !== '') {
        item.push({
          type: 'link',
          data: body.link
        });
      }
      if (body.media && body.media !== '') {
        item.push({
          type: 'media',
          data: body.media
        });
      }
      if (item.length > 0) {
        bodyItem.push(item);
      }
    });
    const payload = { ...filterData, type: filterData.type.value, body: [...bodyItem] };
    return payload;
  };

  const filterTranlateData = (filterItem) => {
    const filterdata = { ...templateData };
    const finalData = generatePayloadForMessageTemplate(filterdata);
    setpayloadData(finalData);
    if (data?.editTranslate) {
      filterItem.forEach((item) => {
        Object.keys(item.translate).forEach((key) => {
          INITIAL_DATA.body[0].translate[key] = INITIAL_DATA.body[0].translate[key] || [];
          INITIAL_DATA.body[0].translate[key].push({
            text: item.translate[key],
            isValid: item.isValid.toString()
          });
        });
      });
    } else {
      filterItem.message.forEach((message) => {
        Object.keys(message.data).forEach((key) => {
          if (!INITIAL_DATA.body[0].translate[key]) {
            INITIAL_DATA.body[0].translate[key] = [];
          }
          INITIAL_DATA.body[0].translate[key].push({
            text: message.data[key],
            isValid: 'true'
          });
        });
      });
    }
    let filterTitle;
    if (data?.editTranslate) {
      filterTitle = data?.templateData?.title_translations;
    } else {
      filterTitle = filterItem.title_translate;
    }
    // Add title translations
    Object.keys(filterTitle).forEach((key) => {
      if (!INITIAL_DATA.body[0].translate[key]) {
        INITIAL_DATA.body[0].translate[key] = [];
      }
      INITIAL_DATA.body[0].translate[key].unshift({
        isTitle: true,
        text: filterTitle[key],
        isValid: 'true',
        errorMessage: '*Please Enter title'
      });
    });
    const filteredData = { ...templateData };
    filteredData.body = INITIAL_DATA.body;
    setTemplateData(filteredData);
    setLoader(false);
  };
  const fetchdata = async () => {
    setLoader(true);
    const translateData = templateData.body.map((item) => item.text);
    const payload = {
      message: translateData,
      title: templateData.title,
      from: 'en',
      to_languages: TRANSLATE_LANGUAGE
    };
    const res = await TranslateAllMessageDesign(payload);
    if (res && res.ok) {
      toast({
        ...TOAST_SETTINGS.SETTINGS,
        duration: TRANSLATE_CUSTOM_TOAST_DURATION.duration,
        status: TOAST_SETTINGS.SUCCESS,
        title: res.message
      });
    } else {
      toast({
        ...TOAST_SETTINGS.SETTINGS,
        status: TOAST_SETTINGS.ERROR,
        title: res.error
      });
    }
    filterTranlateData(res?.result);
  };

  useEffect(() => {
    if (data.translateAll) {
      fetchdata();
    } else {
      filterTranlateData(data?.templateData?.body);
    }
  }, []);

  const generatePayloadData = () => {
    const filterBodyData = { ...templateData.body[0].translate };

    // filter bodyData of Translated version
    const languages = Object.keys(filterBodyData);
    const filteredItems = languages.reduce((result, language) => {
      const filteredLanguageItems = filterBodyData[language].filter((item) => !item.isTitle);
      filteredLanguageItems.forEach((item, index) => {
        if (!result[index]) {
          result[index] = { data: {} };
        }
        result[index].data[language] = item.text;
      });
      return result;
    }, []);

    const outputArray = filteredItems.map((item) => ({
      data: item.data,
      type: 'translate'
    }));

    // append  filter bodyData to the previous body which we get when loading the window first time
    const originalTextData = [...payloadData.body];
    const finalBodyData = originalTextData.map((subArray, index) => [
      ...subArray,
      { ...outputArray[index] }
    ]);
    // create title_translation object
    const titleTranslations = {};
    Object.keys(filterBodyData).forEach((key) => {
      const titleTranslation = filterBodyData[key].find((translation) => translation.isTitle);
      if (titleTranslation) {
        titleTranslations[key] = titleTranslation.text;
      }
    });
    // Create final payload
    const finalPayload = { title_translations: titleTranslations, body: [...finalBodyData] };
    return finalPayload;
  };

  const saveBtnHandler = async () => {
    // const [validationResult, isFormValid] = validateForm(templateData, validation);
    // setValidation({ ...validationResult });
    let isValidData = true;
    const body = [...templateData.body];
    body.forEach((i) => {
      Object.entries(i.translate).map(([, value]) => {
        value.map((item) => {
          if (isValidData) {
            if (item.text.length < 1) {
              isValidData = false;
            }
          }
          item.isValid = item.text.length > 0;
        });
      });
    });
    setTemplateData({ ...templateData, body: [...body] });

    if (isValidData) {
      const payload = generatePayloadData();
      const result = await updateTemplate(data.templateData.id, payload);

      if (result && result.ok) {
        toast({
          ...TOAST_SETTINGS.SETTINGS,
          duration: CUSTOM_TOAST_DURATION.duration,
          status: TOAST_SETTINGS.SUCCESS,
          title: result.message
        });
        const closeWindow = () => {
          setTimeout(() => {
            window.close();
          }, 1000);
        };

        closeWindow();
      } else {
        setRefresh(!refresh);
        toast({
          ...TOAST_SETTINGS.SETTINGS,
          status: TOAST_SETTINGS.ERROR,
          title: result.error
        });
      }
    } else {
      setRefresh(!refresh);
    }
  };

  const onBodyInputChange = (index, key, codeIndex, e) => {
    const BodyData = { ...templateData };
    const bodyData = [...templateData.body[index].translate[key]];
    bodyData[codeIndex].text = e;
    bodyData[codeIndex].isValid = true;
    bodyData[codeIndex].isBodyChanged = true;
    BodyData.body[index].translate[key] = bodyData;
    setTemplateData({ ...BodyData });
  };
  const onInputChange = (index, key, codeIndex, e) => {
    const BodyData = { ...templateData };
    const bodyData = [...templateData.body[index].translate[key]];
    bodyData[codeIndex].text = e;
    bodyData[codeIndex].isValid = true;
    bodyData[codeIndex].isBodyChanged = true;
    BodyData.body[index].translate[key] = bodyData;
    setTemplateData({ ...BodyData });
  };

  return (
    <>
      {loader ? (
        <Box
          p="10px"
          style={{
            width: '450px',
            height: '700px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
          mb="30px">
          <Flex justifyContent="center">
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
              size="xl"
            />
          </Flex>
        </Box>
      ) : (
        <>
          <StyledMessagingFormContainer style={{ width: '450px' }}>
            <Card messagingView style={{ width: '450px' }}>
              <Box p="10px" style={{ width: '450px' }} mb="30px">
                {templateData?.body.map((message, index) => {
                  return Object.entries(message.translate).map(([key, value]) => {
                    return (
                      <>
                        <p
                          label="Language Name"
                          fontSize="md"
                          style={{ fontSize: '1rem', fontWeight: '500' }}
                          className={`input-label `}>
                          {LANGUAGE_OPTION.find((option) => option.code === key)?.name ||
                            'Language not found'}
                        </p>
                        {value.map((item, codeIndex) => {
                          return (
                            <div key={item}>
                              {item?.isTitle ? (
                                <Flex>
                                  <StyledInputWrapper style={{ marginBottom: '0px' }}>
                                    <InputComponent
                                      lable=""
                                      placeholder="Message Title"
                                      templatevalidation={item?.text}
                                      validationObj={item}
                                      isRequired
                                      value={item.text}
                                      refresh={refresh}
                                      onChange={(e) => onInputChange(index, key, codeIndex, e)}
                                      maxLength="150"
                                      labelFontSize="sm"
                                      isBodyChanged={item.isBodyChanged}
                                    />
                                  </StyledInputWrapper>
                                </Flex>
                              ) : (
                                <Flex style={{ alignItems: 'center' }}>
                                  <StyledInputWrapper style={{ marginBottom: '0px' }}>
                                    <p
                                      label="Body Text*"
                                      fontSize="sm"
                                      style={{ fontSize: '0.875rem' }}
                                      className={`input-label ${
                                        !item.isValid ? 'input-label-error' : ''
                                      }`}>
                                      Body Text*
                                    </p>
                                    <textarea
                                      className={
                                        item.isValid
                                          ? item.isBodyChanged
                                            ? 'custom-input input-change'
                                            : 'custom-input'
                                          : 'custom-input input-error'
                                      }
                                      rows="4"
                                      value={item.text}
                                      onChange={(e) =>
                                        onBodyInputChange(index, key, codeIndex, e.target.value)
                                      }
                                    />
                                    <p
                                      className="input-error-msg"
                                      style={{ visibility: item.isValid ? 'hidden' : 'visible' }}>
                                      *Body Text is required
                                    </p>
                                  </StyledInputWrapper>
                                </Flex>
                              )}
                            </div>
                          );
                        })}
                      </>
                    );
                  });
                })}
              </Box>
            </Card>
          </StyledMessagingFormContainer>
          <Flex
            justifyContent="flex-end"
            style={{
              height: '50px',
              position: 'fixed',
              bottom: '0%',
              width: '100%',
              opacity: '1',
              backgroundColor: '#ffffff'
            }}>
            <Button
              bg="main.semiPrimary"
              color="main.white"
              _hover={{ bg: 'main.primary' }}
              _active={{
                bg: 'main.semiPrimary',
                borderColor: 'main.primary'
              }}
              m="5px"
              onClick={saveBtnHandler}>
              {constants.MESSAGING.SAVE_BTN_LABEL}
            </Button>
          </Flex>
        </>
      )}
    </>
  );
};

export default DesignMessageTranslate;
