/* eslint-disable radix */
/* eslint-disable no-unused-vars */
import { API_ENDPOINT } from '../../constants';

import axiosInstance from '../../config/axios.config';
import { prepareErrorResponse, prepareSuccessReponse } from '../../utils/responseHandler';

const getSurveyCategories = async () => {
  const result = await axiosInstance.get(`${API_ENDPOINT.GET_SURVEY_CATEGORIES}`);
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const getSurveyAnalysis = async (
  surveyId,
  clientId,
  agencyId,
  siteId,
  regionId,
  workerType,
  startDate,
  endDate
) => {
  let query = '?';
  query += clientId ? `client_id=${parseInt(clientId)}` : '';
  query += agencyId ? `&agency_id=${parseInt(agencyId)}` : '';
  query += siteId ? `&site_id=${parseInt(siteId)}` : '';
  query += regionId ? `&region_id=${parseInt(regionId)}` : '';
  query += workerType ? `&type=${workerType}` : '';
  query += startDate ? `&start_date=${startDate}` : '';
  query += endDate ? `&end_date=${endDate}` : '';

  const result = await axiosInstance.get(`${API_ENDPOINT.GET_SURVEY_ANALYSIS}/${surveyId}${query}`);
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};
const getSurveyAnalysisQuestions = async (
  surveyId,
  clientId,
  agencyId,
  siteId,
  regionId,
  startDate,
  endDate
) => {
  let query = '?';
  query += clientId ? `client_id=${parseInt(clientId)}` : '';
  query += agencyId ? `&agency_id=${parseInt(agencyId)}` : '';
  query += siteId ? `&site_id=${parseInt(siteId)}` : '';
  query += regionId ? `&region_id=${parseInt(regionId)}` : '';
  query += startDate ? `&start_date=${startDate}` : '';
  query += endDate ? `&end_date=${endDate}` : '';

  const result = await axiosInstance.get(
    `${API_ENDPOINT.GET_SURVEY_ANALYSIS_QUESTIONS}/${surveyId}`
  );
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const getSurveyAnalysisCSV = async (
  surveyId,
  clientId,
  agencyId,
  siteId,
  regionId,
  workerType,
  startDate,
  endDate
) => {
  let query = '?';
  query += clientId ? `client_id=${parseInt(clientId)}` : '';
  query += agencyId ? `&agency_id=${parseInt(agencyId)}` : '';
  query += siteId ? `&site_id=${parseInt(siteId)}` : '';
  query += regionId ? `&region_id=${parseInt(regionId)}` : '';
  query += workerType ? `&type=${workerType}` : '';
  query += startDate ? `&start_date=${startDate}` : '';
  query += endDate ? `&end_date=${endDate}` : '';

  const result = await axiosInstance.get(
    `${API_ENDPOINT.GET_SURVEY_ANALYSIS_DOWNLOAD}/${surveyId}${query}`
  );
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const updateGeneralSurveys = async (surveyId, clientId, payload) => {
  const result = await axiosInstance.put(
    `${API_ENDPOINT.UPDATE_GENERAL_SURVEY_QUESTIONS}/${surveyId}?${
      clientId ? `client_id=${clientId}` : ''
    }`,
    payload
  );
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

export {
  getSurveyCategories,
  getSurveyAnalysis,
  getSurveyAnalysisCSV,
  updateGeneralSurveys,
  getSurveyAnalysisQuestions
};
