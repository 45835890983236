/* eslint-disable react/prop-types */
import React from 'react';
import { Box, Flex } from '@chakra-ui/react';
import constants from '../../constants';
import InputComponent from '../Input';
import StyledInputWrapper from './Forms.styled';
import { allowTwoDecimalNumbers } from '../../utils/helper';

const AddDynamicRateCard = function ({ data, setData, refresh, validations }) {
  return (
    <Box p="10px">
      <Flex>
        <StyledInputWrapper>
          <InputComponent
            lable={constants.PAY_PER_HOUR}
            placeholder={constants.PAY_PER_HOUR}
            onChange={(e) => setData({ ...data, pay_per_hour: allowTwoDecimalNumbers(e) })}
            value={data.pay_per_hour}
            validationObj={validations.pay_per_hour}
            refresh={refresh}
            type="number"
          />
        </StyledInputWrapper>
      </Flex>

      <Flex>
        <StyledInputWrapper style={{ marginRight: '8px' }}>
          <InputComponent
            lable={constants.NATIONAL_INSURANCE_RATE}
            placeholder={constants.NATIONAL_INSURANCE_RATE}
            onChange={(e) => setData({ ...data, insurance_rate: allowTwoDecimalNumbers(e) })}
            value={data.insurance_rate}
            validationObj={validations.insurance_rate}
            refresh={refresh}
            type="number"
            min={0}
          />
        </StyledInputWrapper>
        <StyledInputWrapper>
          <InputComponent
            lable={constants.HOLIDAY_PAY_RATE}
            placeholder={constants.HOLIDAY_PAY_RATE}
            onChange={(e) => setData({ ...data, holiday_pay_rate: allowTwoDecimalNumbers(e) })}
            value={data.holiday_pay_rate}
            validationObj={validations.holiday_pay_rate}
            refresh={refresh}
            type="number"
            min={0}
          />
        </StyledInputWrapper>
      </Flex>

      <Flex>
        <StyledInputWrapper style={{ marginRight: '8px' }}>
          <InputComponent
            lable={constants.APPRENTICESHIP_LEVY_RATE}
            placeholder={constants.APPRENTICESHIP_LEVY_RATE}
            onChange={(e) => setData({ ...data, apprenticeship_rate: allowTwoDecimalNumbers(e) })}
            value={data.apprenticeship_rate}
            validationObj={validations.apprenticeship_rate}
            refresh={refresh}
            type="number"
            min={0}
          />
        </StyledInputWrapper>
        <StyledInputWrapper>
          <InputComponent
            lable={constants.PENSION_RATE}
            placeholder={constants.PENSION_RATE}
            onChange={(e) => setData({ ...data, pension_rate: allowTwoDecimalNumbers(e) })}
            value={data.pension_rate}
            validationObj={validations.pension_rate}
            refresh={refresh}
            type="number"
            min={0}
          />
        </StyledInputWrapper>
      </Flex>

      <Flex>
        <StyledInputWrapper style={{ marginRight: '8px' }}>
          <InputComponent
            lable={constants.FULL_TIME_HOURS_VALUE}
            placeholder={constants.FULL_TIME_HOURS_VALUE}
            onChange={(e) => setData({ ...data, full_time_hours: allowTwoDecimalNumbers(e) })}
            value={data.full_time_hours}
            validationObj={validations.full_time_hours}
            refresh={refresh}
            type="number"
          />
        </StyledInputWrapper>
        <StyledInputWrapper>
          <InputComponent
            lable={constants.OVER_TIME_PAY}
            placeholder={constants.OVER_TIME_PAY}
            onChange={(e) => setData({ ...data, overtime_pay: allowTwoDecimalNumbers(e) })}
            value={data.overtime_pay}
            validationObj={validations.overtime_pay}
            type="number"
            refresh={refresh}
          />
        </StyledInputWrapper>
      </Flex>
    </Box>
  );
};

export default AddDynamicRateCard;
