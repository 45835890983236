import styled from 'styled-components';
import { Modal } from '@chakra-ui/react';

export const StyledModal = styled(Modal)``;

export const StyledDivider = styled.div`
  height: 1px;
  background-color: ${(props) => props.theme.color.main.black};
  margin-left: var(--chakra-space-4);
  margin-right: var(--chakra-space-4);
`;
