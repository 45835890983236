/* eslint-disable no-use-before-define */
/* eslint-disable react/self-closing-comp */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable camelcase */

import { API_ENDPOINT } from '../../constants';
import axiosInstance from '../../config/axios.config';
import { prepareErrorResponse, prepareSuccessReponse } from '../../utils/responseHandler';

const addRule = async (clientId, payload) => {
  // const params = new URLSearchParams();
  // if (clientId) params.append('client_id', clientId);

  const url = `${API_ENDPOINT.GET_CLIENT_PATH}${clientId}${API_ENDPOINT.GET_RULES}`;
  const result = await axiosInstance.post(url, payload);
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const updateRule = async (clientId, ruleId, payload) => {
  // const params = new URLSearchParams();
  // if (clientId) params.append('client_id', clientId);

  const url = `${API_ENDPOINT.GET_CLIENT_PATH}${clientId}${API_ENDPOINT.UPDATE_RULE}${ruleId}`;
  const result = await axiosInstance.put(url, payload);
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const getRules = async (clientId) => {
  // Construct the query parameters if clientId is provided
  const params = new URLSearchParams();
  if (clientId) params.append('client_id', clientId);

  // Construct the URL
  const url = `${API_ENDPOINT.GET_CLIENT_PATH}${clientId}${API_ENDPOINT.GET_RULES}`;

  try {
    // Make the GET request
    const result = await axiosInstance.get(url);

    // Handle the response
    if (result && result.ok) {
      return prepareSuccessReponse(result);
    }
    return prepareErrorResponse(result);
  } catch (error) {
    // Handle any errors
    return prepareErrorResponse(error.response || error);
  }
};

const deleteRule = async (clientId, ruleId) => {
  // const params = new URLSearchParams();
  // if (clientId) params.append('client_id', clientId);

  const url = `${API_ENDPOINT.GET_CLIENT_PATH}${clientId}${API_ENDPOINT.UPDATE_RULE}${ruleId}`;
  const result = await axiosInstance.delete(url);
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

export { addRule, getRules, updateRule, deleteRule };
