/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Text, useToast } from '@chakra-ui/react';
// eslint-disable-next-line import/named
import { TOAST_SETTINGS } from '../../constants';
// import { getSiteDropDownData } from '../../redux/action/nationality.action';
import Dropdown from '../Dropdown';
import { getWorkersNationality } from '../../redux/action/worker.action';

const WorkerNationalityDropdown = function ({
  setNationality,
  nationality,
  validationObj,
  refresh,
  multiple,
  label = 'Nationality',
  siteId = null,
  clientId = null,
  disabled = false
}) {
  const toast = useToast();
  const [nationalities, setNationalities] = useState([]);
  const { userData, user_details } = useSelector((state) => state.authentication);
  const { selected_client_id, selected_site_id } = useSelector((state) => state.agency);
  const [errorMsg, setErrorMsg] = useState(null);
  const [isValid, setValid] = useState(validationObj?.isvalid || true);

  useEffect(async () => {
    if (!!selected_client_id || !!userData.client_id || user_details) {
      const result = await getWorkersNationality(
        clientId || selected_client_id || userData.client_id || user_details.client_id || null,
        userData.agency_id ? userData.agency_id : undefined,
        siteId || selected_site_id || userData.site_id || undefined
      );
      if (result && result.ok) {
        setNationalities(result.data.nationalities);
      } else {
        toast({
          ...TOAST_SETTINGS.SETTINGS,
          status: TOAST_SETTINGS.ERROR,
          title: result.error
        });
      }
    }
  }, [
    clientId,
    siteId,
    selected_client_id,
    selected_site_id,
    userData.client_id,
    userData.agency_id,
    userData.site_id
  ]);

  useEffect(() => {
    if (validationObj && !validationObj.isvalid) {
      setErrorMsg(validationObj.errorMessage);
      setValid(validationObj.isvalid);
    }
  }, [refresh]);

  const setNationalityData = (e) => {
    setErrorMsg(null);
    setValid(true);
    setNationality(e);
  };

  return (
    <>
      <Dropdown
        label={label.length > 0 ? `${label}${validationObj?.required ? '*' : ''}` : ''}
        onSelect={(e) => {
          setNationalityData(e);
        }}
        options={nationalities.map((item) => {
          return { label: item, value: item };
        })}
        value={nationality}
        placeholder=""
        multiple={multiple}
        isError={!isValid}
        disabled={disabled}
      />
      <Text visibility={isValid ? 'hidden' : 'visible'} color="main.error" mb="2px" fontSize="11px">
        {errorMsg || 'Error !'}
      </Text>
    </>
  );
};

export default WorkerNationalityDropdown;
