/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React from 'react';

import { useMediaQuery } from '@chakra-ui/react';
import { StyledCard } from './Card.styled';
import Loader from '../Loader';

const Card = function ({
  messagingView,
  margin,
  children,
  className,
  isLoader,
  minHeight,
  minWidth,
  ...style
}) {
  const [isSmallerThan1440] = useMediaQuery('(max-width: 1440px)');
  const [isSmallerThan1240] = useMediaQuery('(max-width: 1240px)');

  return (
    <StyledCard
      className={className}
      style={{
        margin,
        minHeight: minHeight || 0,
        minWidth:
          isSmallerThan1440 && !messagingView
            ? '1190px'
            : messagingView
            ? isSmallerThan1240
              ? '500px'
              : '680px'
            : '0px',
        ...style.style
      }}>
      {isLoader ? <Loader /> : null}
      {children}
    </StyledCard>
  );
};

export default Card;
