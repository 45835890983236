/* eslint-disable no-case-declarations */
/* eslint-disable default-param-last */
const initialState = {
  userData: {},
  permissions: {},
  user_details: {},
  profile_details: {}
};

const authentication = (state = initialState, action) => {
  switch (action.type) {
    case 'USER_LOGIN':
      const { payload } = action;
      const { permissions } = payload;
      payload.permissions = undefined;
      return {
        ...state,
        userData: payload,
        permissions,
        menu: permissions.map((d) => d.feature_code)
      };
    case 'USER_DETAILS':
      return { ...state, user_details: action.payload.user_details };
    case 'USER_LOGOUT':
      return { userData: {} };
    case 'PROFILE_DETAIL':
      let data = {};
      if (action.payload.client_details) {
        data = action.payload.client_details;
      } else if (action.payload.agency) {
        data = action.payload.agency;
      }
      return { ...state, profile_details: { ...data } };
    default:
      return state;
  }
};

export default authentication;
