import React, { useEffect, useState } from 'react';
import { Alert, Box, Button, Flex, Link, useToast } from '@chakra-ui/react';

import { forgotPassword } from '../../redux/action/user.action';

import LoginBox from '../../components/LoginBox';

import constants, {
  CUSTOM_TOAST_DURATION,
  FORGOT_PASSWORD_VALIDATIONS,
  PAGE_TITLE,
  TOAST_SETTINGS
} from '../../constants';
import { deepClone, validateForm } from '../../utils/helper';
import { StyledContainer, StyledRoot } from './ForgotPassword.styled';
import CompanyLogoImage from '../../assets/images/New_Logo.png';

const ForgotPassword = function () {
  const toast = useToast();
  const [email, setEmail] = useState('');
  const [validations, setValidations] = useState(deepClone(FORGOT_PASSWORD_VALIDATIONS));
  const [refresh, setRefresh] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  // useEffect(() => {
  //   window.document.title = `${window.document.title} | Forgot Password`;
  // }, []);
  useEffect(() => {
    document.title = PAGE_TITLE.FORGOT_PASSWORD;
  }, []);

  const handleClick = async () => {
    const [validationObject, isFormValid] = validateForm(
      {
        email
      },
      validations
    );
    setValidations(validationObject);
    if (isFormValid) {
      const result = await forgotPassword({
        email
      });
      if (result && result.ok) {
        setEmailSent(true);
        toast({
          ...TOAST_SETTINGS.SETTINGS,
          duration: CUSTOM_TOAST_DURATION.duration,
          status: TOAST_SETTINGS.SUCCESS,
          title: result.message
        });
        // navigate(PATH.RESET_PASSWORD.TO);
      } else {
        toast({
          ...TOAST_SETTINGS.SETTINGS,
          status: TOAST_SETTINGS.ERROR,
          title: result.error
        });
      }
    } else {
      setRefresh(!refresh);
    }
  };
  return emailSent ? (
    <StyledRoot>
      <StyledContainer>
        <Flex w="100%" mb="8" align="center" justify="center">
          {/* <CompanyLogo /> */}
          <img alt="ClearVue" src={CompanyLogoImage} style={{ margin: '30px' }} />
        </Flex>
        <Flex w="100%" mb="8" align="center" justify="center">
          <Alert status="success">We have sent a password reset link in your email : {email}</Alert>
        </Flex>
        <Flex w="100%" mt="5" align="center">
          <Box>
            <Link color="main.primary" href="/" fontSize="sm">
              <Button
                bg="main.semiPrimary"
                color="main.white"
                _hover={{ bg: 'main.primary' }}
                _active={{
                  bg: 'main.semiPrimary',
                  borderColor: 'main.primary'
                }}>
                Back To Login
              </Button>
            </Link>
          </Box>
        </Flex>
      </StyledContainer>
    </StyledRoot>
  ) : (
    <LoginBox
      leftButtonText={constants.RESET}
      email={email}
      setEmail={setEmail}
      type="FORGOT-PASSWORD"
      leftButtonClick={handleClick}
      refresh={refresh}
      validations={validations}
      rightButtonText={constants.BACK_TO_LOGIN}
      rightLinkTo="/"
    />
  );
};

export default ForgotPassword;
