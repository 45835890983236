/* eslint-disable react/prop-types */
/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react';
import { Box, Flex, Switch } from '@chakra-ui/react';
import constants from '../../constants';
import InputComponent from '../Input';
import StyledInputWrapper, { StyledInfoLabel } from './Forms.styled';
import SectorDropdown from './SectorDropdown';
import { allowAlphaNumeric, allowOnlyAlphabate } from '../../utils/helper';
import NationalityDropdown from './NationalityDropdown';
import DaySelectionDropdown from './DaySelectionDropdown';
import CountryDropDownData from '../../utils/countryData.json';
import BookingFormatDropdown from './BookingFormatDropdown';
import Dropdown from '../Dropdown';

const AddClient = function ({
  countryValue,
  dayValue,
  setCountryData,
  setDayData,
  setBookingData,
  bookingValue,
  data,
  setData,
  refresh,
  validations,
  sectorValue,
  isClientEdit,
  isHeaderForm
}) {
  const [sector, setSector] = useState(sectorValue);
  const [workerPerformance, setWorkerPerformance] = useState(
    data.workerPerformance !== undefined && data.workerPerformance !== ''
      ? { value: data.workerPerformance, label: data.workerPerformance ? 'True' : 'False' }
      : { value: false, label: 'False' }
  );
  const [workerTraining, setWorkerTraining] = useState(
    data.workerTraining !== undefined && data.workerTraining !== ''
      ? { value: data.workerTraining, label: data.workerTraining ? 'True' : 'False' }
      : { value: false, label: 'False' }
  );

  const [rateCard, setRateCard] = useState(
    data.rateCardLookup !== undefined && data.rateCardLookup !== ''
      ? { value: data.rateCardLookup, label: data.rateCardLookup ? 'True' : 'False' }
      : { value: false, label: 'False' }
  );

  const initSwitch = data?.worker_invite_email;

  const setSectorData = (e) => {
    setSector(e);
    setData({ ...data, sectorId: e.value });
  };

  useEffect(() => {
    setSector(sectorValue);
  }, [sectorValue]);

  const onToggleChanged = (e) => {
    const isChecked = e.target.checked;
    setData({ ...data, worker_invite_email: isChecked });
  };

  const setWorkerPerformanceValue = (selectedOption) => {
    setWorkerPerformance(selectedOption);
    setData({ ...data, workerPerformance: selectedOption ? selectedOption.value : false });
  };
  const setWorkerTrainingValue = (selectedOption) => {
    setWorkerTraining(selectedOption);
    setData({ ...data, workerTraining: selectedOption ? selectedOption.value : false });
  };

  const setRateCardValue = (selectedOption) => {
    setRateCard(selectedOption);
    setData({ ...data, rateCardLookup: selectedOption ? selectedOption.value : false });
  };

  return (
    <Box p="10px">
      <Flex>
        <StyledInputWrapper>
          <InputComponent
            lable={constants.NAME}
            placeholder={constants.NAME}
            onChange={(e) => setData({ ...data, name: allowAlphaNumeric(e) })}
            value={data.name}
            validationObj={validations.name}
            refresh={refresh}
            maxLength="50"
          />
        </StyledInputWrapper>
      </Flex>
      <Flex>
        <StyledInputWrapper>
          <InputComponent
            lable={constants.ADDRESS1}
            placeholder={constants.ADDRESS1}
            onChange={(e) => setData({ ...data, address_line_1: e })}
            value={data.address_line_1}
            validationObj={validations.address_line_1}
            refresh={refresh}
            maxLength="50"
          />
        </StyledInputWrapper>
      </Flex>
      <Flex>
        <StyledInputWrapper style={{ marginRight: '8px' }}>
          <InputComponent
            lable={constants.ADDRESS2}
            placeholder={constants.ADDRESS2}
            onChange={(e) => setData({ ...data, address_line_2: e })}
            value={data.address_line_2}
            validationObj={validations.address_line_2}
            refresh={refresh}
            maxLength="50"
          />
        </StyledInputWrapper>
        <StyledInputWrapper>
          <InputComponent
            lable={constants.ADDRESS3}
            placeholder={constants.ADDRESS3}
            onChange={(e) => setData({ ...data, address_line_3: e })}
            value={data.address_line_3}
            validationObj={validations.address_line_3}
            refresh={refresh}
            maxLength="50"
          />
        </StyledInputWrapper>
      </Flex>
      <Flex>
        <StyledInputWrapper style={{ marginRight: '8px' }}>
          <InputComponent
            lable={constants.POST_CODE}
            placeholder={constants.POST_CODE}
            onChange={(e) => setData({ ...data, postCode: e })}
            value={data.postCode}
            validationObj={validations.postCode}
            refresh={refresh}
            maxLength="15"
          />
        </StyledInputWrapper>
        <StyledInputWrapper>
          <InputComponent
            lable={constants.CITY}
            placeholder={constants.CITY}
            onChange={(e) => setData({ ...data, city: allowOnlyAlphabate(e) })}
            value={data.city}
            validationObj={validations.city}
            refresh={refresh}
            maxLength="20"
          />
        </StyledInputWrapper>
      </Flex>
      <Flex>
        <StyledInputWrapper style={{ marginRight: '8px' }}>
          <NationalityDropdown
            label="Country"
            country={countryValue}
            setCountry={setCountryData}
            validationObj={validations.country}
            refresh={refresh}
            dropdownData={CountryDropDownData}
          />
        </StyledInputWrapper>
        <StyledInputWrapper>
          <DaySelectionDropdown
            disabled={isClientEdit}
            dayValue={dayValue}
            setDayData={setDayData}
            validationObj={validations.weekday_start}
            refresh={refresh}
          />
        </StyledInputWrapper>
      </Flex>
      <Flex>
        <StyledInputWrapper style={{ marginRight: '8px' }}>
          <BookingFormatDropdown
            disabled={isClientEdit}
            bookingValue={bookingValue}
            setBookingData={setBookingData}
            validationObj={validations.booking_format}
            refresh={refresh}
          />
        </StyledInputWrapper>
        <StyledInputWrapper>
          <Dropdown
            label="Worker Performance"
            options={[
              { value: true, label: 'True' },
              { value: false, label: 'False' }
            ]}
            onSelect={setWorkerPerformanceValue}
            value={workerPerformance}
            placeholder="Select"
            disabled={isHeaderForm}
          />
        </StyledInputWrapper>
      </Flex>
      <Flex>
        <StyledInputWrapper>
          <SectorDropdown
            sector={sector}
            validationObj={validations.sectorId}
            refresh={refresh}
            setSector={setSectorData}
          />
        </StyledInputWrapper>

        <StyledInputWrapper
          style={{
            paddingLeft: '10px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
          }}>
          <StyledInfoLabel style={{ color: '#426b9d', fontWeight: 400 }}>
            Restrict Worker Invite
          </StyledInfoLabel>

          <Switch
            id="user-switch-block"
            float="left"
            isChecked={data.worker_invite_email}
            isDisabled={isClientEdit && initSwitch}
            onChange={(e) => onToggleChanged(e)}
          />
        </StyledInputWrapper>
      </Flex>
      <Flex>
        <StyledInputWrapper style={{ marginRight: '8px' }}>
          <Dropdown
            label="Worker Training"
            options={[
              { value: true, label: 'True' },
              { value: false, label: 'False' }
            ]}
            onSelect={setWorkerTrainingValue}
            value={workerTraining}
            placeholder="Select"
            disabled={isHeaderForm}
          />
        </StyledInputWrapper>
        <StyledInputWrapper>
          <Dropdown
            label="Rate Card Lookup"
            options={[
              { value: true, label: 'True' },
              { value: false, label: 'False' }
            ]}
            onSelect={setRateCardValue}
            value={rateCard}
            placeholder="Rate Card Lookup"
            disabled={isHeaderForm}
          />
        </StyledInputWrapper>
      </Flex>
    </Box>
  );
};

export default AddClient;
