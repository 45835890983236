/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable camelcase */
/* eslint-disable import/named */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Flex, useToast } from '@chakra-ui/react';
import DatePicker from 'react-multi-date-picker';
import moment from 'moment';
import DropDownMenu from '../DropdownMenu';
import BellIcon from '../../icons/BellIcon';
import {
  StyledHeader,
  StyledHeaderNav,
  StyledHeaderLogo,
  StyledHeaderLeftSideMenu,
  StyledDropDownMenu,
  StyledUserRole,
  StyledProfileImageContainer,
  StyledHeaderRightSideMenu,
  StyledUserInfo,
  StyledUserLogo,
  StyledUserName
} from './Header.styled';
import constants, {
  ADD_AGENCY_VALIDATIONS,
  ADD_CLIENT_VALIDATIONS,
  ADD_USER_PROFILE_VALIDATIONS,
  CUSTOM_TOAST_DURATION,
  DATE_SAVE_TO_DB,
  DaySelectionList,
  PATH,
  REDUX_SELECTOR,
  TOAST_SETTINGS,
  TOKENS,
  USER_TYPE
} from '../../constants';
import { deleteCookie } from '../../utils/cookie';
import ModalComponent from '../Modal';
import StyledInputWrapper from '../Forms/Forms.styled';
import InputComponent from '../Input';
import { deepClone, toFormData } from '../../utils/helper';
import { getUserData, updateUser } from '../../redux/action/user.action';
import USERPROFILE from '../../assets/images/user-profile.png';
import ProfileImageUploader from '../ProfileImageUploader';
import CompanyDropdown from '../Forms/CompanyDropdown';
import { getClientById, getClientData, updateClient } from '../../redux/action/client.action';
import { getAgencyData, updateAgency } from '../../redux/action/agency.action';
import AddClient from '../Forms/AddClients';
import AddAgency from '../Forms/AddAgency';
import SiteDropdownWithDefault from '../Forms/SiteDropdownWithDefault';
import { colors } from '../../theme';

const Header = function () {
  const dispatch = useDispatch();
  const toast = useToast();
  const { user_details, profile_details } = useSelector((state) => state.authentication);
  const { weekday_start } = useSelector((state) => state.agency);
  const [isProfileSetupModalOpen, setIsProfileSetupModalOpen] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [validations, setValidations] = useState(deepClone(ADD_USER_PROFILE_VALIDATIONS));
  const [data, setData] = useState({
    profile: null,
    name: '',
    email: '',
    mobile: ''
  });
  const [profileUrl, setProfileUrl] = useState(USERPROFILE);
  const [isClientProfileSetupModalOpen, setIsClientProfileSetupModalOpen] = useState(false);
  const [isAgencyProfileSetupModalOpen, setIsAgencyProfileSetupModalOpen] = useState(false);
  const [clientProfileData, setClientProfileData] = useState({
    name: '',
    address_line_1: '',
    address_line_2: '',
    address_line_3: '',
    city: '',
    country: '',
    sectorId: '',
    postCode: '',
    weekday_start: '',
    worker_training: '',
    rate_card_lookup: ''
  });
  const [agencyProfileData, setAgencyProfileData] = useState({
    name: '',
    address_line_1: '',
    address_line_2: '',
    address_line_3: '',
    city: '',
    country: '',
    postCode: ''
  });
  const [clientValidations, setClientValidations] = useState(deepClone(ADD_CLIENT_VALIDATIONS));
  const [agencyValidations, setAgencyValidations] = useState(deepClone(ADD_AGENCY_VALIDATIONS));
  const [sector, setSectorData] = useState({});
  const [agencyCountry, setAgencyCountry] = useState({});
  const [clientCountry, setClientCountry] = useState({});
  const [day, setDay] = useState({});
  const [profileDataImageUrl, setProfileDataImageUrl] = useState(null);
  const [currentPage, setCurrentPage] = useState('');
  const imageRef = useRef(null);
  const logoRef = useRef(null);
  const [restrictAgency, setrestrictAgency] = useState();
  const dayIndex = DaySelectionList.indexOf(weekday_start);
  const [selectweekDay, setselectweekDay] = useState();

  const setAgencyCountryData = (e) => {
    setAgencyCountry(e);
    setAgencyProfileData({ ...agencyProfileData, country: e.value });
  };

  const setClientCountryData = (e) => {
    setClientCountry(e);
    setClientProfileData({ ...clientProfileData, country: e.value });
  };
  const setDayData = (e) => {
    setDay(e);
    setClientProfileData({ ...clientProfileData, weekday_start: e.value });
  };

  useEffect(() => {
    const url = window.location.href;
    const page = url.split('/')[url.split('/').length - 1];
    setCurrentPage(page);
  }, [window.location.href]);

  const [selectedSite, setSelectedSite] = useState({});
  const [reload, setReload] = useState(false);
  const selectSite = (e) => {
    setSelectedSite(e);
    dispatch({
      type: 'SELECT_SITE',
      payload: { selected_site_id: e.value, selected_site_name: e.label }
    });
  };

  const setUserProfileData = () => {
    setData({
      profile: null,
      name: user_details.name,
      email: user_details.email,
      mobile: `${user_details.country_code} ${user_details.mobile}`
    });

    if (user_details.profile_url) {
      setProfileUrl(user_details.profile_url);
    }
  };

  const toggleProfileSetupModal = () => {
    setUserProfileData();
    if (isProfileSetupModalOpen) {
      setIsProfileSetupModalOpen(false);
    } else {
      setIsProfileSetupModalOpen(true);
    }
  };

  const toggleClientProfileSetupModal = async () => {
    if (!isClientProfileSetupModalOpen) {
      let responseData = await dispatch(await getClientData(user_details.client_id));

      responseData = { ...responseData.data.client_details };

      setClientProfileData({
        name: responseData.name,
        address_line_1: responseData.address.address_line_1,
        address_line_2: responseData.address.address_line_2,
        address_line_3: responseData.address.address_line_3,
        city: responseData.city,
        country: responseData.country,
        sectorId: responseData.sector_id,
        postCode: responseData.post_code,
        url: responseData.profile_url,
        id: responseData.id,
        weekday_start: responseData.weekday_start,
        workerPerformance: responseData.worker_performance,
        workerTraining: responseData.worker_training,
        rateCardLookup: responseData.rate_card_lookup
      });
      setIsClientProfileSetupModalOpen(true);
      setSectorData({ label: responseData.sector_name, value: responseData.sector_id.toString() });
      setClientCountry({ label: responseData.country, value: responseData.country });
      setDay({ label: responseData.weekday_start, value: responseData.weekday_start });
    } else {
      setClientProfileData({
        name: '',
        address_line_1: '',
        address_line_2: '',
        address_line_3: '',
        city: '',
        country: '',
        sectorId: '',
        postCode: '',
        weekday_start: ''
      });
      setClientValidations(deepClone(ADD_CLIENT_VALIDATIONS));
      setSectorData({});
      setClientCountry({});
      setIsClientProfileSetupModalOpen(false);
    }
  };

  const toggleAgencyProfileSetupModal = async () => {
    if (!isAgencyProfileSetupModalOpen) {
      setIsAgencyProfileSetupModalOpen(true);
      let responseData = await dispatch(await getAgencyData(user_details.agency_id));

      responseData = { ...responseData.data.agency };

      setAgencyProfileData({
        name: responseData.name,
        address_line_1: responseData.address.address_line_1,
        address_line_2: responseData.address.address_line_2,
        address_line_3: responseData.address.address_line_3,
        city: responseData.city,
        country: responseData.country,
        postCode: responseData.post_code,
        url: responseData.profile_url,
        id: responseData.id
      });
      setAgencyCountry({ label: responseData.country, value: responseData.country });
    } else {
      setAgencyProfileData({
        name: '',
        address_line_1: '',
        address_line_2: '',
        address_line_3: '',
        city: '',
        country: '',
        postCode: ''
      });
      setAgencyCountry({});
      setAgencyValidations(deepClone(ADD_AGENCY_VALIDATIONS));
      setIsAgencyProfileSetupModalOpen(false);
    }
  };

  useEffect(() => {
    setUserProfileData();
    if (user_details.user_type_id === USER_TYPE.AGENCY_SITE_ADMIN) {
      dispatch({
        type: 'SELECT_SITE',
        payload: {
          selected_site_id: user_details.site_id,
          selected_site_name: user_details.name
        }
      });
    }
  }, []);

  useEffect(() => {
    setUserProfileData();
  }, [user_details]);

  const updateUserData = async () => {
    const payload = { ...data };
    delete payload.email;
    delete payload.mobile;
    const result = await updateUser(toFormData(payload));

    if (result && result.ok) {
      toggleProfileSetupModal();
      await dispatch(await getUserData());
      imageRef.current.src += `?${new Date().getTime()}`;
      toast({
        ...TOAST_SETTINGS.SETTINGS,
        duration: CUSTOM_TOAST_DURATION.duration,
        status: TOAST_SETTINGS.SUCCESS,
        title: result.message
      });
    } else {
      toast({
        ...TOAST_SETTINGS.SETTINGS,
        status: TOAST_SETTINGS.ERROR,
        title: result.error
      });
    }
  };

  const getDropDownLabel = (userData) => {
    return (
      <StyledUserInfo>
        <StyledUserLogo>
          <img alt="User Profile" src={profileUrl} ref={imageRef} />
        </StyledUserLogo>
        <div style={{ width: '104px' }}>
          <StyledUserName>{userData.name}</StyledUserName>
          <StyledUserRole>{userData.user_type_name}</StyledUserRole>
        </div>
      </StyledUserInfo>
    );
  };

  const logout = () => {
    dispatch({ type: REDUX_SELECTOR.USER.LOGOUT });
    deleteCookie(TOKENS.ACCESS);
    deleteCookie(TOKENS.REFRESH);
    setTimeout(() => {
      window.location.href = '/';
    }, 100);
  };

  const getDropDownItems = () => {
    return (
      <StyledDropDownMenu>
        <li onClick={toggleProfileSetupModal}>Profile Setup </li>
        {user_details.user_type_id === USER_TYPE.CLIENT_ADMIN ? (
          <li onClick={toggleClientProfileSetupModal}>Client Profile</li>
        ) : null}
        {user_details.user_type_id === USER_TYPE.AGENCY_ADMIN ? (
          <li onClick={toggleAgencyProfileSetupModal}>Agency Profile</li>
        ) : null}
        <li onClick={logout}>Logout </li>
      </StyledDropDownMenu>
    );
  };

  const getNotificationLabelIcon = () => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: colors.main.white,
          width: '28px',
          height: '28px',
          borderRadius: '50%',
          position: 'relative'
        }}>
        <BellIcon />
        <div
          style={{
            position: 'absolute',
            top: '-8px',
            right: '-4px',
            width: '16px',
            height: '16px',
            borderRadius: '50%',
            textAlign: 'center',
            fontSize: '12px',
            color: colors.main.white,
            backgroundColor: '#18BE6B'
          }}>
          4
        </div>
      </div>
    );
  };

  const getNotificationItems = () => {
    return (
      <StyledDropDownMenu>
        <li>Notification 1</li>
        <li>Notification 2</li>
        <li>Notification 3</li>
        <li>Notification 4</li>
      </StyledDropDownMenu>
    );
  };

  const profileImageOnChangeHandler = (event) => {
    event.preventDefault();
    const reader = new FileReader();
    const file = event.target.files[0];
    reader.onloadend = () => {
      setData((previousData) => ({ ...previousData, profile: event.target.files[0] }));
      setProfileUrl(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const onClientProfileUpload = (event) => {
    event.stopPropagation();
    event.preventDefault();

    const reader = new FileReader();
    const file = event.target.files[0];
    reader.onloadend = () => {
      setClientProfileData((previousData) => ({
        ...previousData,
        profile: event.target.files[0],
        url: reader.result
      }));
    };
    reader.readAsDataURL(file);
  };

  const onAgencyProfileUpload = (event) => {
    event.stopPropagation();
    event.preventDefault();

    const reader = new FileReader();
    const file = event.target.files[0];
    reader.onloadend = () => {
      setAgencyProfileData((previousData) => ({
        ...previousData,
        profile: event.target.files[0],
        url: reader.result
      }));
    };
    reader.readAsDataURL(file);
  };

  const [client, setClient] = useState({});
  const setClientData = (clientData) => {
    setClient(clientData);
    // dispatch({
    //   type: 'SELECT_SITE',
    //   payload: { selected_site_id: '', selected_site_name: '' }
    // });
    dispatch({
      type: 'SELECT_CLIENT',
      payload: {
        selected_client_id: clientData.value,
        selected_client_name: clientData.label,
        is_restricted: clientData.is_restricted,
        weekday_start: clientData.weekday_start,
        total_assignment_pay: clientData.total_assignment_pay
      }
    });
    setSelectedSite({});
    setReload(!reload);
  };

  const updateClientData = async () => {
    const clientId = clientProfileData.id;
    const payload = { ...clientProfileData };
    delete payload.url;
    delete payload.id;
    delete payload.weekday_start;
    delete payload.rateCardLookup;
    delete payload.workerPerformance;
    delete payload.workerTraining;
    // delete payload.name;
    const result = await updateClient(clientId, toFormData(payload));
    if (result && result.ok) {
      toast({
        ...TOAST_SETTINGS.SETTINGS,
        duration: CUSTOM_TOAST_DURATION.duration,
        status: TOAST_SETTINGS.SUCCESS,
        title: result.message
      });
      dispatch(await getClientData(user_details.client_id));
      logoRef.current.src += `?${new Date().getTime()}`;
      toggleClientProfileSetupModal();
    } else {
      toast({
        ...TOAST_SETTINGS.SETTINGS,
        status: TOAST_SETTINGS.ERROR,
        title: result.error
      });
    }
  };
  const updateAgencyData = async () => {
    const agencyId = agencyProfileData.id;
    const payload = { ...agencyProfileData };
    // payload.postCode = payload.postCode;
    delete payload.url;
    delete payload.id;
    // delete payload.postCode;
    const result = await updateAgency(agencyId, toFormData(payload));
    if (result && result.ok) {
      toast({
        ...TOAST_SETTINGS.SETTINGS,
        duration: CUSTOM_TOAST_DURATION.duration,
        status: TOAST_SETTINGS.SUCCESS,
        title: result.message
      });
      toggleAgencyProfileSetupModal();
      dispatch(await getAgencyData(user_details.agency_id));
      logoRef.current.src += `?${new Date().getTime()}`;
    } else {
      toast({
        ...TOAST_SETTINGS.SETTINGS,
        status: TOAST_SETTINGS.ERROR,
        title: result.error
      });
    }
  };

  const getClientByIdFunction = async () => {
    const result = await getClientById(user_details.client_id);
    if (result && result.ok) {
      const weekDayIndex = DaySelectionList.indexOf(result.data.client_details.weekday_start);
      setselectweekDay(weekDayIndex);
    }
  };
  useEffect(() => {
    if (
      user_details.user_type_id !== USER_TYPE.SUPER_ADMIN &&
      user_details.user_type_id !== USER_TYPE.AGENCY_ADMIN
    ) {
      getClientByIdFunction();
    }
  }, []);

  const calendarRef = useRef();

  const [selectedDate, setSelectedData] = useState([
    moment(new Date(), 'DD/MM/YYYY').startOf('week').format('DD/MM/YYYY'),
    moment(new Date(), 'DD/MM/YYYY').endOf('week').format('DD/MM/YYYY')
  ]);
  const handelDateChange = (event) => {
    if (event.length === 1) {
      const client_index = typeof selectweekDay === 'number' ? selectweekDay : dayIndex;
      const current_index = event[0].weekDay.index;
      let start;
      let end;
      if (client_index > current_index) {
        const forward_index = client_index - current_index - 1;
        const index = 7 - (client_index - current_index);

        start = moment(event[0].toDate(), 'YYYY-MM-DD').subtract(index, 'days').toDate();
        end = moment(event[0].toDate(), 'YYYY-MM-DD').add(forward_index, 'days').toDate();
      } else {
        const index = current_index - client_index;
        const forward_index = 7 - index - 1;
        start = moment(event[0].toDate(), 'YYYY-MM-DD').subtract(index, 'days').toDate();
        end = moment(event[0].toDate(), 'YYYY-MM-DD').add(forward_index, 'days').toDate();
      }
      const dateArray = [];
      if (selectedDate.length > 0) {
        if (moment(selectedDate[0], 'DD/MM/YYYY').diff(moment(start, 'DD/MM/YYYY')) > 0) {
          dateArray.push(start);
        } else dateArray.push(selectedDate[0]);

        if (moment(selectedDate[1], 'DD/MM/YYYY').diff(moment(end, 'DD/MM/YYYY')) < 0) {
          dateArray.push(end);
        } else dateArray.push(selectedDate[1]);
      } else {
        dateArray.push(start);
        dateArray.push(end);
      }

      setSelectedData(dateArray);
    }
  };

  const onCloseCalender = () => {
    setSelectedData([]);
  };

  const onCalenderFilter = () => {
    if (selectedDate.length > 0) {
      dispatch({
        type: 'SELECT_DATE_RANGE',
        payload: {
          start_date: DATE_SAVE_TO_DB(selectedDate[0]),
          end_date: DATE_SAVE_TO_DB(selectedDate[1])
        }
      });
    } else {
      setSelectedData([
        moment(new Date(), 'DD/MM/YYYY').startOf('week').format('DD/MM/YYYY'),
        moment(new Date(), 'DD/MM/YYYY').endOf('week').format('DD/MM/YYYY')
      ]);
      dispatch({
        type: 'SELECT_DATE_RANGE',
        payload: {
          start_date: moment(new Date(), 'YYYY-MM-DD').startOf('week').format('YYYY-MM-DD'),
          end_date: moment(new Date(), 'YYYY-MM-DD').endOf('week').format('YYYY-MM-DD')
        }
      });
    }
    calendarRef.current.closeCalendar();
  };

  const { selected_site_id, selected_site_name, selected_client_id, selected_client_name } =
    useSelector((state) => state.agency);

  useEffect(() => {
    setClient({ value: selected_client_id, label: selected_client_name });
    setSelectedSite({ value: selected_site_id, label: selected_site_name });
  }, [selected_site_id, selected_site_name, selected_client_id, selected_client_name]);

  useEffect(() => {
    dispatch({
      type: 'SELECT_DATE_RANGE',
      payload: {
        start_date: DATE_SAVE_TO_DB(selectedDate[0]),
        end_date: DATE_SAVE_TO_DB(selectedDate[1])
      }
    });
  }, []);

  return (
    <StyledHeader>
      {user_details.user_type_id === USER_TYPE.CLIENT_ADMIN ? (
        <ModalComponent
          isOpen={isClientProfileSetupModalOpen}
          onClose={toggleClientProfileSetupModal}
          modalTitle={constants.CLIENT.EDIT_HEADER_TEXT}
          modalContent={
            <>
              <StyledProfileImageContainer>
                <ProfileImageUploader
                  src={clientProfileData.url}
                  onChange={onClientProfileUpload}
                  width={80}
                />
              </StyledProfileImageContainer>
              <AddClient
                isHeaderForm
                countryValue={clientCountry}
                setCountryData={setClientCountryData}
                setData={setClientProfileData}
                data={clientProfileData}
                validations={clientValidations}
                refresh={refresh}
                sectorValue={sector}
                setDayData={setDayData}
                dayValue={day}
                isClientEdit="true"
              />
            </>
          }
          negative={
            <Button
              onClick={toggleClientProfileSetupModal}
              bg="main.secondary"
              mr={3}
              _hover={{ bg: 'main.secondary' }}
              color="main.black">
              {constants.CLIENT.CANCEL_BUTTON_TEXT}
            </Button>
          }
          positive={
            <Button
              bg="main.semiPrimary"
              color="main.white"
              _hover={{ bg: 'main.primary' }}
              _active={{
                bg: 'main.semiPrimary',
                borderColor: 'main.primary'
              }}
              onClick={() => {
                updateClientData();
              }}>
              {constants.CLIENT.EDIT_UPDATE_BUTTON_TEXT}
            </Button>
          }
        />
      ) : null}
      {user_details.user_type_id === USER_TYPE.AGENCY_ADMIN ? (
        <ModalComponent
          isOpen={isAgencyProfileSetupModalOpen}
          onClose={toggleAgencyProfileSetupModal}
          modalTitle={constants.AGENCY.EDIT_HEADER_TEXT}
          modalContent={
            <>
              <StyledProfileImageContainer>
                <ProfileImageUploader
                  src={agencyProfileData.url}
                  onChange={onAgencyProfileUpload}
                  width={80}
                />
              </StyledProfileImageContainer>
              <AddAgency
                countryValue={agencyCountry}
                setCountryData={setAgencyCountryData}
                setData={setAgencyProfileData}
                data={agencyProfileData}
                validations={agencyValidations}
                refresh={refresh}
              />
            </>
          }
          negative={
            <Button
              onClick={toggleAgencyProfileSetupModal}
              mr={3}
              _hover={{ bg: 'main.secondary' }}
              color="main.black">
              {constants.AGENCY.CANCEL_BUTTON_TEXT}
            </Button>
          }
          positive={
            <Button
              bg="main.semiPrimary"
              color="main.white"
              _hover={{ bg: 'main.primary' }}
              _active={{
                bg: 'main.semiPrimary',
                borderColor: 'main.primary'
              }}
              onClick={() => {
                updateAgencyData();
              }}>
              {constants.AGENCY.EDIT_UPDATE_BUTTON_TEXT}
            </Button>
          }
        />
      ) : null}
      <ModalComponent
        isOpen={isProfileSetupModalOpen}
        onClose={toggleProfileSetupModal}
        modalTitle="Update User Information"
        modalContent={
          <Box p="10px">
            <StyledProfileImageContainer>
              <ProfileImageUploader
                src={profileUrl}
                onChange={profileImageOnChangeHandler}
                width={80}
              />
            </StyledProfileImageContainer>

            <StyledInputWrapper>
              <InputComponent
                isRequired
                lable={constants.NAME}
                placeholder={constants.NAME}
                onChange={(e) => setData({ ...data, name: e })}
                value={data.name}
                validationObj={validations.name}
                refresh={refresh}
              />
            </StyledInputWrapper>
            <StyledInputWrapper>
              <InputComponent
                isRequired
                lable={constants.EMAIL}
                placeholder={constants.EMAIL}
                onChange={(e) => setData({ ...data, email: e })}
                value={data.email}
                validationObj={{ isvalid: true }}
                refresh={refresh}
                disabled
              />
            </StyledInputWrapper>
            <StyledInputWrapper>
              <InputComponent
                isRequired
                lable={constants.NUMBER}
                placeholder={constants.NUMBER}
                onChange={(e) => setData({ ...data, mobile: e })}
                value={data.mobile}
                validationObj={{ isvalid: true }}
                refresh={refresh}
                disabled
              />
            </StyledInputWrapper>
          </Box>
        }
        negative={
          <Button
            bg="main.secondary"
            mr={3}
            _hover={{ bg: 'main.secondary' }}
            color="main.black"
            onClick={toggleProfileSetupModal}>
            Cancel
          </Button>
        }
        positive={
          <Button
            onClick={() => updateUserData()}
            bg="main.semiPrimary"
            color="main.white"
            _hover={{ bg: 'main.primary' }}
            _active={{
              bg: 'main.semiPrimary',
              borderColor: 'main.primary'
            }}
            disabled={data.name.length === 0}>
            Update
          </Button>
        }
      />
      <StyledHeaderNav>
        <StyledHeaderLeftSideMenu>
          {`/${currentPage}` !== PATH.DEMOGRAPHICS.TO &&
            `/${currentPage}` !== PATH.TREND_ANALYSIS.TO &&
            // `/${currentPage}` !== PATH.SURVEYS.TO &&
            `/${currentPage}` !== PATH.MESSAGING.TO &&
            `/${currentPage}` !== PATH.PAYROLL.TO &&
            `/${currentPage}` !== PATH.SURVEY_UPDATE.TO &&
            user_details.user_type_id === USER_TYPE.AGENCY_ADMIN && (
              <Box width="200px" mr="15px">
                <CompanyDropdown
                  company={client}
                  setCompany={setClientData}
                  setrestrictAgency={setrestrictAgency}
                  disabled={false}
                  setDefaultValue
                />
              </Box>
            )}
          {(user_details.user_type_id === USER_TYPE.AGENCY_ADMIN ||
            user_details.user_type_id === USER_TYPE.AGENCY_REGION_ADMIN) &&
            (`/${currentPage}` === PATH.WORKER.TO ||
              `/${currentPage}` === PATH.TIME_AND_ATTENDANCE.TO) && (
              <Box width="250px" mr="15px">
                <SiteDropdownWithDefault
                  label="Site"
                  site={selectedSite}
                  setSite={selectSite}
                  disabled={false}
                  multiple={false}
                  reloadData={reload}
                  setDefaultValue
                />
              </Box>
            )}
          {user_details.user_type_id !== USER_TYPE.SUPER_ADMIN &&
            [
              PATH.DASHBOARD.TO,
              PATH.REGION.TO,
              PATH.SITES.TO,
              PATH.DEPARTMENT.TO,
              PATH.SHIFT.TO,
              PATH.TIME_AND_ATTENDANCE.TO,
              PATH.COMPLIANCE.TO,
              PATH.AGENCY_MANAGEMENT.TO,
              PATH.TRAINING.TO
            ].includes(`/${currentPage}`) && (
              <Box width="250px">
                <p className="input-label">Select Date</p>
                <DatePicker
                  format="DD/MM/YYYY"
                  inputClass="date-picker"
                  range
                  onChange={handelDateChange}
                  value={selectedDate}
                  ref={calendarRef}
                  editable={false}
                  maxDate={new Date()}
                  weekStartDayIndex={typeof selectweekDay === 'number' ? selectweekDay : dayIndex}>
                  <Button
                    bg="main.semiPrimary"
                    color="main.white"
                    _hover={{ bg: 'main.primary' }}
                    _active={{
                      bg: 'main.semiPrimary',
                      borderColor: 'main.primary'
                    }}
                    onClick={() => onCloseCalender()}
                    size="xs"
                    mb={4}>
                    {constants.DASHBOARD_VIEW.CANCEL_BUTTON_TEXT}
                  </Button>
                  &nbsp;
                  <Button
                    bg="main.semiPrimary"
                    color="main.white"
                    _hover={{ bg: 'main.primary' }}
                    _active={{
                      bg: 'main.semiPrimary',
                      borderColor: 'main.primary'
                    }}
                    onClick={() => onCalenderFilter()}
                    size="xs"
                    mb={4}>
                    {constants.DASHBOARD_VIEW.OK_BUTTON_TEXT}
                  </Button>
                </DatePicker>
              </Box>
            )}
        </StyledHeaderLeftSideMenu>

        <StyledHeaderRightSideMenu>
          <Flex>
            <DropDownMenu
              LabelComponent={getDropDownLabel(user_details)}
              DropDownItems={getDropDownItems()}
            />
          </Flex>
        </StyledHeaderRightSideMenu>
      </StyledHeaderNav>
      {user_details.user_type !== 'clear_vue_admin' &&
      profile_details &&
      PATH.COMPLIANCE.TO !== `/${currentPage}` &&
      profile_details.profile_url ? (
        <StyledHeaderLogo bg="main.white">
          <img alt="Profile" src={profile_details.profile_url} ref={logoRef} />
        </StyledHeaderLogo>
      ) : (
        <StyledHeaderLogo />
      )}
    </StyledHeader>
  );
};

export default Header;
