import { Global } from '@emotion/react';

const Fonts = function () {
  return (
    <Global
      styles={`
    @font-face {
      font-family: 'NimbusSanLBold';
      src: local('NimbusSanLBold'), url('../assets/fonts/NimbusSanL-Bol.ttf') format('truetype');
      font-weight: bold;
    }
    @font-face {
      font-family: 'NimbusSanLRegular';
      src: local('NimbusSanLRegular'), url('../assets/fonts/NimbusSanL-Reg.ttf') format('truetype');
      font-weight: normal;
    }
      `}
    />
  );
};

export default Fonts;
