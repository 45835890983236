/* eslint-disable react/prop-types */
import React from 'react';
import ArrowLeft from '../../icons/ArrowLeft';
import ArrowRight from '../../icons/ArrowRight';
import {
  StyledCarouselBtnContainer,
  StyledCarouselContainer,
  StyledCarouselItemContainer,
  StyledCarouselNextBtn,
  StyledCarouselPreviousBtn
} from './CustomCarousel.styled';

const CustomCarousel = function ({
  children,
  onNextButtonClick,
  onPreviousButtonClick,
  disablePreviousButtton,
  disableNextButtton
}) {
  return (
    <StyledCarouselContainer>
      <StyledCarouselBtnContainer>
        <StyledCarouselPreviousBtn
          disabled={disablePreviousButtton}
          onClick={onPreviousButtonClick}>
          <ArrowLeft />
        </StyledCarouselPreviousBtn>
        <StyledCarouselNextBtn disabled={disableNextButtton} onClick={onNextButtonClick}>
          <ArrowRight />
        </StyledCarouselNextBtn>
      </StyledCarouselBtnContainer>
      <StyledCarouselItemContainer>{children}</StyledCarouselItemContainer>
    </StyledCarouselContainer>
  );
};

export default CustomCarousel;
