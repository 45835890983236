/* eslint-disable react/prop-types */
import React from 'react';
import { Center, Image } from '@chakra-ui/react';
import { colors } from '../../theme';

import greenArrowUp from '../../assets/images/greenArrowUp.png';
import redArrowDown from '../../assets/images/redArrowDown.png';

const DonutChartImageContainer = function ({
  isIndicatorsVisible = false,
  positive,
  difference,
  children
}) {
  // const [isSmallerThan2150] = useMediaQuery('(max-width: 2150px)');
  return (
    <>
      <Center top={2} display="flex" flexDir="column" pos="absolute" right={1}>
        {isIndicatorsVisible && positive && (
          //   {true && (
          <>
            <Center>
              <Image src={greenArrowUp} height={6} />
            </Center>
            <Center mt={1} color={positive ? colors.main.positiveGreen : colors.main.negativeRed}>
              {difference}%
            </Center>
          </>
        )}
      </Center>
      {children}
      <Center top={2} display="flex" flexDir="column" position="absolute" right={1}>
        {isIndicatorsVisible && !positive && (
          <>
            <Center>
              <Image src={redArrowDown} height={6} />
            </Center>
            <Center mt={1} color={positive ? colors.main.positiveGreen : colors.main.negativeRed}>
              {difference}%
            </Center>
          </>
        )}
      </Center>
    </>
  );
};

export default DonutChartImageContainer;
