import { Flex, Text } from '@chakra-ui/react';
import styled from 'styled-components';
import { colors } from '../../theme';

const StyledAttendanceContainer = styled.div``;

export const StyledAttendanceHeaderContainer = styled(Flex)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const StyledUploadLabel = styled(Text)`
  font-weight: 800;
  font-size: 14px;
  display: flex;
`;

export const StyledUploadFileButton = styled.label`
  display: inline-flex;
  appearance: none;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  user-select: none;
  position: relative;
  white-space: nowrap;
  vertical-align: middle;
  outline: transparent solid 2px;
  outline-offset: 2px;
  width: auto;
  line-height: 1.2;
  border-radius: var(--chakra-radii-md);
  font-weight: var(--chakra-fontWeights-semibold);
  transition-property: var(--chakra-transition-property-common);
  transition-duration: var(--chakra-transition-duration-normal);
  height: var(--chakra-sizes-10);
  min-width: var(--chakra-sizes-10);
  font-size: var(--chakra-fontSizes-md);
  padding-inline-start: var(--chakra-space-4);
  padding-inline-end: var(--chakra-space-4);
  color: ${colors.main.white};
  background: ${colors.main.semiPrimary};
  cursor: pointer;
  &:hover {
    background: ${(props) => (props.disabled ? colors.main.semiPrimary : colors.main.primary)};
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  }
`;

export const StyledWeekText = styled.label`
  color: ${colors.main.semiPrimary};
  cursor: pointer;
  font-weight: 600;
`;

export const StyledDownloadSample = styled.span`
  display: inline-block;
  color: ${colors.main.semiPrimary};
  cursor: pointer;
`;

export default StyledAttendanceContainer;
