/* eslint-disable react/prop-types */
import React from 'react';
import { Box } from '@chakra-ui/react';
import { colors } from '../../theme';

const DashboardContainer = function ({ header, color, children, minW }) {
  return (
    <Box
      borderColor={color}
      borderWidth="2px"
      borderRadius="lg"
      p={header ? '25px 5px 5px 5px' : '5px 5px 5px 5px'}
      minW={minW || '396px'}
      w="100%"
      m={0.5}
      position="relative">
      <Box
        bg={colors.main.blueBackground}
        transform="translateX(-50%)"
        position="absolute"
        top="-3%"
        left="50%"
        color={color}
        fontSize="22px"
        fontWeight="bold"
        p="0px 15px">
        {header}
      </Box>
      {children}
    </Box>
  );
};

export default DashboardContainer;
