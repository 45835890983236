/* eslint-disable no-nested-ternary */
/* eslint-disable  react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import Select, { createFilter } from 'react-select';
import Label from '../Label';
import theme from '../../theme/index';

const Dropdown = function (props) {
  const customStyles = {
    option: (
      provided,
      {
        selectProps: {
          selectedTheme: { colors, fontSizes }
        }
      }
    ) => ({
      padding: '7px 12px',
      cursor: 'pointer',
      color: colors.main.black,
      fontSize: fontSizes.md,
      lineHeight: '1.2',
      display: 'flex',
      alignItems: 'center',
      height: '40px',
      webkitBoxSizing: 'border-box',
      mozBoxSizing: 'border-box',
      boxSizing: 'border-box',
      zIndex: 1500000000000000000000000000000000,
      '&:hover': {
        backgroundColor: colors.main.secondary
      },
      '&:last-child': {
        borderBottomLeftRadius: '4px',
        borderBottomRightRadius: '4px'
      },
      '&:first-of-type': {
        borderTopLeftRadius: '4px',
        borderTopRightRadius: '4px'
      }
    }),
    singleValue: (
      provided,
      {
        selectProps: {
          selectedTheme: { colors, fontSizes }
        }
      }
    ) => ({
      ...provided,
      fontSize: fontSizes.md,
      color: colors.main.black
    }),
    placeholder:
      (
        provided,
        {
          selectProps: {
            selectedTheme: { colors, fontSizes }
          }
        }
      ) =>
      () => ({
        ...provided,
        fontSize: fontSizes.md,
        color: colors.main.gray
      }),
    valueContainer: (provided, state) => ({
      ...provided,
      padding: `0 ${state.selectProps.selectedTheme.space['4']}`
    }),
    control: (
      provided,
      {
        selectProps: {
          selectedTheme: { colors },
          isError
        },
        isFocused
      }
    ) => ({
      ...provided,
      boxShadow: isFocused
        ? isError
          ? `0 0 0 1px ${colors.main.error}`
          : `0 0 0 1px ${colors.main.semiPrimary}`
        : 'none',
      borderColor: isError ? colors.main.error : colors.main.semiPrimary,
      '&:hover': {
        borderColor: isError ? colors.main.error : colors.main.semiPrimary
      }
    })
  };

  const {
    label,
    options,
    onSelect,
    value,
    placeholder,
    multiple,
    isError,
    disabled,
    labelFontSize,
    isSearchable
  } = props;
  return (
    <>
      {label && <Label isError={isError} label={label} fontSize={labelFontSize} />}
      <Select
        styles={customStyles}
        options={options}
        onChange={onSelect}
        value={value && Object.keys(value).length ? value : ''}
        placeholder={placeholder}
        selectedTheme={theme}
        isMulti={multiple}
        isError={isError}
        isDisabled={disabled}
        menuPosition="fixed"
        isSearchable={isSearchable}
        filterOption={createFilter({ matchFrom: 'start' })}
      />
    </>
  );
};

Dropdown.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onSelect: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.object),
  value: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  multiple: PropTypes.bool,
  isError: PropTypes.bool,
  disabled: PropTypes.bool,
  labelFontSize: PropTypes.string
};

Dropdown.defaultProps = {
  label: 'Label',
  placeholder: 'Placeholder',
  onSelect: () => {},
  options: [
    { value: 1, label: 'me' },
    { value: 2, label: 'you' }
  ],
  value: {},
  multiple: false,
  isError: false,
  disabled: false,
  labelFontSize: 'lg'
};

export default Dropdown;
