/* eslint-disable camelcase */
/* eslint-disable react/jsx-no-useless-fragment */
import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Center, Flex, useToast } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import ClientSideDatatable from '../../components/ClientSideDatatable';
import constants, {
  ADD_JOB_VALIDATIONS,
  PATH,
  TOAST_SETTINGS,
  USER_TYPE,
  PAGE_TITLE,
  CUSTOM_TOAST_DURATION,
  RoleTypeList
} from '../../constants';
import {
  addRoleType,
  getRoleType,
  updateRoleType,
  uploadBulkRoleType
} from '../../redux/action/role.action';
import { getModulePermissions } from '../../utils/hooks';
import { StyledRolesContainer } from './Role.styled';
import Card from '../../components/Card';
import Loader from '../../components/Loader';
import ModalComponent from '../../components/Modal';
import AddRole from '../../components/Forms/AddRole';
import { deepClone, validateForm } from '../../utils/helper';

const INITIAL_JOB = {
  name: '',
  departmentId: [],
  siteId: '',
  type: '',
  shiftId: '',
  hoursPerWeek: ''
};

const INITIAL_LISTING_COLUMNS = [
  { label: 'Name', field: 'job_name', sort: false },
  { label: 'Type', field: 'job_type', sort: false },
  { label: 'Shift', field: 'job_shift', sort: false },
  { label: 'Site', field: 'site_name', sort: false },
  // { label: 'Rate Card', field: 'rate_card_name', sort: false },
  {
    label: 'Department',
    field: 'department',
    sort: false,
    customRow: (cell) => {
      return cell.map((d) => d.label).join(', ');
    }
  },
  { label: 'Job Hours Per Week', field: 'job_hours_per_week', sort: false }
];

const Role = function () {
  useEffect(() => {
    document.title = PAGE_TITLE.ROLE_TYPE;
  }, []);

  const { user_details: userDetails } = useSelector((state) => state.authentication);
  const { selected_client_id, is_restricted } = useSelector((state) => state.agency);
  const permission = getModulePermissions(PATH.ROLE.CODE);
  const toast = useToast();
  const [isRoleModalOpen, setIsRoleModalOpen] = useState(false);
  const [jobData, setJobData] = useState({ ...INITIAL_JOB });
  const [jobList, setJobList] = useState([]);
  const [isPageLoader, setIsPageLoader] = useState(false);
  const [reload, setReload] = useState(false);
  const [validations, setValidations] = useState(deepClone(ADD_JOB_VALIDATIONS));
  const [refresh, setRefresh] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [roleTypeListingColumns, setRoleTypeListingColumns] = useState([
    ...INITIAL_LISTING_COLUMNS
  ]);

  const [selectedDepartment, setSelectedDepartment] = useState({});
  const [selectedRateCard, setSelectedRateCard] = useState({});
  const [selectedShift, setSelectedShift] = useState({});
  const [selectedRoleType, setSelectedRoleType] = useState({});
  const [selectedSite, setSelectedSite] = useState({});
  const [isBulkUploadRoleType, setIsBulkUploadRoleType] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const roleTypeFileInputRef = useRef(null);

  const resetRoleTypeFileInput = () => {
    roleTypeFileInputRef.current.value = null;
  };
  const retrieveListOfRoleTypes = async () => {
    const result = await getRoleType(
      1,
      10,
      'id',
      'ASC',
      selected_client_id || userDetails.client_id,
      (userDetails.user_type_id === USER_TYPE.AGENCY_SITE_ADMIN && userDetails.site_id) ||
        (userDetails.user_type_id === USER_TYPE.SITE_ADMIN && userDetails.site_id),

      (userDetails.user_type_id === USER_TYPE.AGENCY_REGION_ADMIN && userDetails.region_id) ||
        (userDetails.user_type_id === USER_TYPE.REGION_ADMIN && userDetails.region_id)
    );
    // const result = await getRoleType(1, 10, 'id', 'ASC', 15);
    setIsPageLoader(false);
    if (result && result.ok) {
      setJobList(result.data.job_list);
    } else {
      toast({
        ...TOAST_SETTINGS.SETTINGS,
        status: TOAST_SETTINGS.ERROR,
        title: result.error
      });
    }
  };

  const closeRoleModal = () => {
    setIsRoleModalOpen(false);
    setIsEdit(false);
    setJobData({ ...INITIAL_JOB });
    setSelectedDepartment({});
    setSelectedRateCard({});
    setSelectedShift({});
    setSelectedRoleType({});
    setSelectedSite({});
    setValidations(deepClone(ADD_JOB_VALIDATIONS));
  };
  const onCloseBulkRoleType = () => {
    setIsBulkUploadRoleType(false);
    setSelectedFile(null);
  };
  const selectFile = async (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const cancelBtnClickHandler = () => {
    closeRoleModal();
  };

  const saveRoleType = async (payload) => {
    const result = await addRoleType(payload);
    if (result && result.ok) {
      setReload(!reload);
      toast({
        ...TOAST_SETTINGS.SETTINGS,
        duration: CUSTOM_TOAST_DURATION.duration,
        status: TOAST_SETTINGS.SUCCESS,
        title: result.message
      });
      retrieveListOfRoleTypes();
      closeRoleModal();
    } else {
      toast({
        ...TOAST_SETTINGS.SETTINGS,
        status: TOAST_SETTINGS.ERROR,
        title: result.error
      });
    }
  };

  const saveBulkRoleData = async () => {
    const result = await uploadBulkRoleType(selected_client_id || userDetails.client_id, {
      jobs: selectedFile
    });
    if (result && result.ok) {
      // retrieveWorkerData(userDetails.agency_id, selected_site_id);
      setReload(!reload);
      toast({
        ...TOAST_SETTINGS.SETTINGS,
        duration: CUSTOM_TOAST_DURATION.duration,
        status: TOAST_SETTINGS.SUCCESS,
        title: result.message
      });
      retrieveListOfRoleTypes();
      onCloseBulkRoleType();
    } else {
      resetRoleTypeFileInput();
      toast({
        ...TOAST_SETTINGS.SETTINGS,
        status: TOAST_SETTINGS.ERROR,
        title: result.error
      });
    }
  };

  const updateSelectedRoleType = async (id, payload) => {
    const result = await updateRoleType(id, payload);
    if (result && result.ok) {
      setReload(!reload);
      toast({
        ...TOAST_SETTINGS.SETTINGS,
        duration: CUSTOM_TOAST_DURATION.duration,
        status: TOAST_SETTINGS.SUCCESS,
        title: result.message
      });
      retrieveListOfRoleTypes();
      closeRoleModal();
    } else {
      toast({
        ...TOAST_SETTINGS.SETTINGS,
        status: TOAST_SETTINGS.ERROR,
        title: result.error
      });
    }
  };

  const saveBtnHandler = () => {
    const [validationObject, isFormValid] = validateForm(jobData, validations);
    setValidations(validationObject);
    if (isFormValid) {
      const payload = { ...jobData, clientId: userDetails.client_id.toString() };
      if (!isEdit) {
        saveRoleType(payload);
      } else {
        const { id } = payload;
        delete payload.id;
        updateSelectedRoleType(id, payload);
      }
    } else {
      setRefresh(!refresh);
    }
  };

  const editRoleTypeData = (cell, rowData) => {
    setIsEdit(true);

    setSelectedDepartment(rowData.department);
    const departmentIds = rowData.department.map((d) => d.value);
    setSelectedSite({ label: rowData.site_name, value: rowData.site_id });
    setSelectedShift({ label: rowData.job_shift, value: rowData.job_shift_id });
    setSelectedRateCard({ value: rowData.rate_card_id, label: rowData.rate_card_name });

    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < RoleTypeList.length; index++) {
      const role = RoleTypeList[index];
      // eslint-disable-next-line eqeqeq
      if (role.value == rowData.job_type_id) {
        setSelectedRoleType({ label: role.label, value: role.value });
        break;
      }
    }

    setJobData({
      name: rowData.job_name,
      departmentId: departmentIds,
      siteId: rowData.site_id,
      type: rowData.job_type_id,
      shiftId: rowData.job_shift_id,
      hoursPerWeek: rowData.job_hours_per_week.toString(),
      id: rowData.job_id
    });

    setIsRoleModalOpen(true);
  };

  useEffect(() => {
    if (permission && permission.includes('edit')) {
      setRoleTypeListingColumns([
        ...INITIAL_LISTING_COLUMNS,
        {
          label: 'Action',
          field: 'id',
          sort: false,
          // eslint-disable-next-line react/no-unstable-nested-components
          customRow: (cell, row) => {
            // eslint-disable-next-line no-plusplus
            return (
              <Button
                bg="main.semiPrimary"
                color="main.white"
                _hover={{ bg: 'main.primary' }}
                _active={{
                  bg: 'main.semiPrimary',
                  borderColor: 'main.primary'
                }}
                onClick={() => editRoleTypeData(cell, row)}>
                {constants.ROLE_TYPE.EDIT_BUTTON_TEXT}
              </Button>
            );
          }
        }
      ]);
    }
  }, [permission]);

  useEffect(async () => {
    setIsPageLoader(true);
    if (userDetails.user_type_id !== USER_TYPE.AGENCY_ADMIN) retrieveListOfRoleTypes();
  }, []);

  useEffect(() => {
    if (userDetails.user_type_id === USER_TYPE.AGENCY_ADMIN && !is_restricted)
      retrieveListOfRoleTypes();
    setReload(!reload);
  }, [selected_client_id]);

  return (
    <>
      {is_restricted && userDetails.user_type_id === USER_TYPE.AGENCY_ADMIN ? (
        <Box>
          <Card margin="5px" minHeight="58px">
            {/* <Text fontSize={25}>No Data Found!</Text> */}
          </Card>
          <Box h="600px" display="flex" justifyContent="center">
            <Center fontSize={35}>Client Has Restricted View</Center>
          </Box>
        </Box>
      ) : (
        <>
          <StyledRolesContainer>
            {isPageLoader ? <Loader /> : null}
            <Card>
              <>
                {permission && permission.includes('create') && (
                  <Button
                    bg="main.semiPrimary"
                    color="main.white"
                    _hover={{ bg: 'main.primary' }}
                    _active={{
                      bg: 'main.semiPrimary',
                      borderColor: 'main.primary'
                    }}
                    onClick={() => setIsRoleModalOpen(true)}>
                    {constants.ROLE_TYPE.CREATE_BUTTON_TEXT}
                  </Button>
                )}
                {permission && permission.includes('create') && (
                  <Button
                    bg="main.semiPrimary"
                    color="main.white"
                    _hover={{ bg: 'main.primary' }}
                    _active={{
                      bg: 'main.semiPrimary',
                      borderColor: 'main.primary'
                    }}
                    float="right"
                    // mr={30}
                    onClick={() => setIsBulkUploadRoleType(true)}>
                    {constants.ROLE_TYPE.BULK_UPLOAD_BUTTON_TEXT}
                  </Button>
                )}
              </>

              <ModalComponent
                isOpen={isRoleModalOpen}
                onClose={cancelBtnClickHandler}
                modalTitle={
                  isEdit ? constants.ROLE_TYPE.EDIT_HEADER_TEXT : constants.ROLE_TYPE.HEADER_TEXT
                }
                modalContent={
                  <AddRole
                    data={jobData}
                    setData={setJobData}
                    validations={validations}
                    refresh={refresh}
                    siteValue={selectedSite}
                    roleTypeValue={selectedRoleType}
                    departmentValue={selectedDepartment}
                    shiftValue={selectedShift}
                    rateCardValue={selectedRateCard}
                    isEdit={isEdit}
                  />
                }
                negative={
                  <Button
                    bg="main.secondary"
                    mr={3}
                    _hover={{ bg: 'main.secondary' }}
                    color="main.black"
                    onClick={cancelBtnClickHandler}>
                    {constants.WORKER.CANCEL_BUTTON_TEXT}
                  </Button>
                }
                positive={
                  <Button
                    bg="main.semiPrimary"
                    color="main.white"
                    _hover={{ bg: 'main.primary' }}
                    _active={{
                      bg: 'main.semiPrimary',
                      borderColor: 'main.primary'
                    }}
                    onClick={saveBtnHandler}>
                    {isEdit
                      ? constants.WORKER.EDIT_UPDATE_BUTTON_TEXT
                      : constants.WORKER.SAVE_BUTTON_TEXT}
                  </Button>
                }
              />

              <ModalComponent
                isOpen={isBulkUploadRoleType}
                onClose={() => onCloseBulkRoleType()}
                modalTitle={constants.ROLE_TYPE.HEADER_TEXT}
                modalContent={
                  <Flex flexDir="column" alignItems="center" justifyContent="center">
                    <input ref={roleTypeFileInputRef} type="file" onChange={selectFile} />
                  </Flex>
                }
                negative={
                  <Button
                    bg="main.secondary"
                    mr={3}
                    _hover={{ bg: 'main.secondary' }}
                    color="main.black"
                    onClick={() => onCloseBulkRoleType()}>
                    {constants.ROLE_TYPE.CANCEL_BUTTON_TEXT}
                  </Button>
                }
                positive={
                  <Button
                    bg="main.semiPrimary"
                    color="main.white"
                    _hover={{ bg: 'main.primary' }}
                    _active={{
                      bg: 'main.semiPrimary',
                      borderColor: 'main.primary'
                    }}
                    disabled={!selectedFile}
                    onClick={() => saveBulkRoleData()}>
                    {constants.ROLE_TYPE.SAVE_BUTTON_TEXT}
                  </Button>
                }
              />

              <ClientSideDatatable
                pageSize={10}
                initialSortKey="id"
                column={roleTypeListingColumns}
                row={jobList}
                pagination
                pages={[10, 15, 25, 30, 40, 50]}
                refresh={reload}
              />
            </Card>
          </StyledRolesContainer>{' '}
        </>
      )}
    </>
  );
};

Role.propTypes = {};

export default Role;
