import { Box, Table, Td, Th, Thead, Tr } from '@chakra-ui/react';
import styled from 'styled-components';

export const StyledContainer = styled(Box)`
  margin: 20px 0;
`;

export const StyledThead = styled(Thead)`
  height: 60px;
`;

export const StyledTable = styled(Table)`
  border-collapse: separate;
  border-spacing: 0 20px;
`;

export const StyledTh = styled(Th)`
  border: none;
  &.active {
    color: ${(props) => props.theme.color.main.semiPrimary};
  }
  font-family: 'NimbusSanLRegular' !important;
`;

export const StyledTd = styled(Td)`
  border: none;
  width: calc(100% / ${(props) => props.length});
  background: ${(props) =>
    props.columnName === 'worked_hours_only' ||
    props.columnName === 'worked_hours_charge' ||
    props.columnName === 'actual_employment_costs' ||
    props.columnName === 'total_agency_margin' ||
    props.columnName === 'actual_margin' ||
    props.columnName === 'rate_card_margin' ||
    props.columnName === 'clearvue_savings' ||
    props.columnName === 'other_assignment_pay_value'
      ? '#E5F9FF'
      : 'inherit'};
`;

export const StyledTr = styled(Tr)`
  &:hover {
    background: ${(props) => props.theme.color.main.blueBackground};
  }
  font-size: 14px;
  color: ${(props) => props.theme.color.main.black};
  border-radius: 30px;
`;

export const StyledHeadTr = styled(Tr)`
  th {
    font-size: 16px;
    text-transform: none;
    color: ${(props) => props.theme.color.main.semiPrimary};
    cursor: pointer;
  }
`;
