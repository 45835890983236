/* eslint-disable no-underscore-dangle */
/* eslint-disable radix */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable prettier/prettier */
/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Text,
  // Grid,
  // GridItem,
  Box,
  Flex,
  Skeleton,
  SkeletonCircle,
  // Tooltip,
  Progress,
  Image,
  Tooltip,
  useMediaQuery,
  FormControl,
  FormLabel,
  Switch
} from '@chakra-ui/react';
import moment from 'moment';
// import { colors } from '../../theme';
// import Card from '../Card';
// import PieChart from '../Charts/PieChart';
import RatingComponent from '../RatingComponent';
import greenArrowUp from '../../assets/images/greenArrowUp.png';
import redArrowDown from '../../assets/images/redArrowDown.png';
import DonuteChart from '../Charts/DonuteChart';
// import CircularChart from '../Charts/CircularChart';
// import { StyledContainer } from '../../pages/ClientDashboard/ClientDashboard.stgyled';
import HeaderCard from '../HeaderCard';
import Nodata from '../NoData';
import ModalComponent from '../Modal';
import {
  API_ENDPOINT,
  BLUE_COLOR_SHADES,
  PATH,
  RATING_STAR_COLOR,
  USER_TYPE
} from '../../constants';
import { prepareErrorResponse, prepareSuccessReponse } from '../../utils/responseHandler';
import axiosInstance from '../../config/axios.config';

const DashboardRatingCard = function ({
  siteRatingData,
  agencyRatingData,
  clientRatingData,
  ratingData,
  leaversData,
  headerData,
  regionId
}) {
  const { userData, user_details } = useSelector((state) => state.authentication);
  const { selected_client_id } = useSelector((state) => state.agency);

  const [isAgencyModalOpen, setIsAgencyModalOpen] = useState(false);
  const [isSiteModalOpen, setIsSiteModalOpen] = useState(false);
  const [isClientModalOpen, setIsClientModalOpen] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [siteRatingDataDetails, setSiteRatingDataDetails] = useState(null);
  const [agencyRatingDataDetails, setAgencyRatingDataDetails] = useState(null);
  const [clientRatingDataDetails, setClientRatingDataDetails] = useState(null);
  const [currPage, setCurrPage] = useState('');
  const [users] = useState([USER_TYPE.CLIENT_ADMIN, USER_TYPE.REGION_ADMIN, USER_TYPE.SITE_ADMIN]);
  const [workerType, setWorkerType] = useState('PERMANENT');

  useEffect(() => {
    const url = window.location.href;
    const page = url.split('/')[url.split('/').length - 1];
    setCurrPage(page);
  }, [window.location.href]);

  const getSiteRatingDataDetails = async (clientId, workerTypeId) => {
    let query = `client_id=${clientId}`;
    if (regionId) {
      query += `&region_id=${regionId}`;
    }
    if (workerTypeId) {
      query += `&type=${workerTypeId}`;
    }
    const result = await axiosInstance.get(`${API_ENDPOINT.GET_SITE_RATING_DATA_DETAILS}?${query}`);
    if (result && result.ok) {
      return prepareSuccessReponse(result);
    }
    return prepareErrorResponse(result);
  };

  const getAgencyRatingDataDetails = async (clientId, agencyId, siteId) => {
    const result = await axiosInstance.get(
      `${API_ENDPOINT.GET_AGENCY_RATING_DATA_DETAILS}?client_id=${clientId}${
        agencyId ? `&agency_id=${agencyId}` : ''
      }${siteId ? `&site_id=${siteId}` : ''}`
    );
    if (result && result.ok) {
      return prepareSuccessReponse(result);
    }
    return prepareErrorResponse(result);
  };

  const getClientRatingDataDetails = async (clientId) => {
    const query = `client_id=${clientId}`;
    const result = await axiosInstance.get(
      `${API_ENDPOINT.GET_CLIENT_RATING_DATA_DETAILS}?${query}`
    );
    if (result && result.ok) {
      return prepareSuccessReponse(result);
    }
    return prepareErrorResponse(result);
  };

  const getSiteRatingDataDashboardDetails = async (clientId) => {
    let query = `client_id=${clientId}`;
    if (user_details.user_type_id === USER_TYPE.SITE_ADMIN) {
      query += `&site_id=${user_details.site_id}`;
    }
    if (user_details.user_type_id === USER_TYPE.REGION_ADMIN) {
      query += `&region_id=${user_details.region_id}`;
    }
    const result = await axiosInstance.get(
      `${API_ENDPOINT.GET_SITE_RATING_DASHBOARD_DATA_DETAILS}?${query}`
    );
    if (result && result.ok) {
      return prepareSuccessReponse(result);
    }
    return prepareErrorResponse(result);
  };

  const getSiteRatingDataDetailsFunction = async () => {
    const rating = await getSiteRatingDataDetails(
      userData.client_id || selected_client_id,
      user_details.user_type_id === USER_TYPE.CLIENT_ADMIN && workerType
    );
    if (rating && rating.ok) {
      setSiteRatingDataDetails(rating.data);
    }
  };

  const getSiteRatingDataDetailsDashboardFunction = async () => {
    const rating = await getSiteRatingDataDashboardDetails(
      userData.client_id || selected_client_id
    );
    if (rating && rating.ok) {
      setSiteRatingDataDetails(rating.data);
    }
  };

  const getAgencyRatingDataDetailsFunction = async () => {
    const rating = await getAgencyRatingDataDetails(
      userData.client_id || selected_client_id || user_details.client_id
    );
    if (rating && rating.ok) {
      setAgencyRatingDataDetails(rating.data);
    }
  };

  const getClientRatingDataDetailsFunction = async () => {
    const rating = await getClientRatingDataDetails(
      userData.client_id || selected_client_id || user_details.client_id
    );
    if (rating && rating.ok) {
      setClientRatingDataDetails(rating.data);
    }
  };

  useEffect(() => {
    if (isSiteModalOpen) {
      if (`/${currPage}` === PATH.DASHBOARD.TO) getSiteRatingDataDetailsDashboardFunction();
      else if (`/${currPage}` === PATH.REGION.TO) getSiteRatingDataDetailsFunction();
    }
    if (isAgencyModalOpen) getAgencyRatingDataDetailsFunction();
    if (isClientModalOpen) getClientRatingDataDetailsFunction();
  }, [isSiteModalOpen, isAgencyModalOpen, isClientModalOpen, workerType]);

  const onOpenAgencyModal = () => {
    if (
      (user_details.user_type_id === USER_TYPE.CLIENT_ADMIN ||
        user_details.user_type_id === USER_TYPE.REGION_ADMIN ||
        user_details.user_type_id === USER_TYPE.SITE_ADMIN) &&
      `/${currPage}` === PATH.DASHBOARD.TO
    )
      setIsAgencyModalOpen(true);
  };

  const onCloseAgencyModal = () => {
    setIsAgencyModalOpen(false);
    setAgencyRatingDataDetails(null);
  };

  const onOpenSiteModal = () => {
    if (
      (users.includes(user_details.user_type_id) &&
        `/${currPage}` !== PATH.SITES.TO &&
        `/${currPage}` !== PATH.SHIFT.TO &&
        `/${currPage}` !== PATH.DEPARTMENT.TO) ||
      (user_details.user_type_id === USER_TYPE.AGENCY_ADMIN && `/${currPage}` === PATH.REGION.TO)
    )
      setIsSiteModalOpen(true);
  };

  const onCloseSiteModal = () => {
    setIsSiteModalOpen(false);
    setSiteRatingDataDetails(null);
    setWorkerType('PERMANENT');
  };

  const onOpenClientModal = () => {
    if (
      user_details.user_type_id === USER_TYPE.CLIENT_ADMIN &&
      `/${currPage}` === PATH.DASHBOARD.TO
    )
      setIsClientModalOpen(true);
  };

  const onCloseClientModal = () => {
    setIsClientModalOpen(false);
    setClientRatingDataDetails(null);
  };

  const from = moment(headerData?.result?.clearvue_savings?.last_week).format('DD/MM/YYYY');
  const obj = moment(from, 'DD/MM/YYYY').add(6, 'days')._d;
  const to = moment(obj).format('DD/MM/YYYY');

  const [isSmallerThan1441] = useMediaQuery('(max-width: 1801px)');

  return (
    <>
      <ModalComponent
        specifySize="5xl"
        isOpen={isAgencyModalOpen}
        onClose={() => {
          onCloseAgencyModal();
        }}
        modalTitle=""
        specifyDivider="notRequired"
        modalContent={
          <Flex flexWrap="wrap">
            {agencyRatingDataDetails && agencyRatingDataDetails?.ratings?.length > 0 ? (
              agencyRatingDataDetails?.ratings?.map((item) => {
                return (
                  <Flex key={item?.name} flexDirection="column" margin="7px" width="31.8%">
                    {/* <Tooltip placement="bottom-start" label="Agency Name"> */}
                    <Text alignSelf="" fontSize="l" fontWeight="semibold">
                      {item?.name}
                    </Text>
                    {/* </Tooltip> */}
                    <HeaderCard boxShadow="rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;">
                      {/* <Tooltip label="Agency Rating"> */}
                      <Text textAlign="center" fontSize="l" fontWeight="semibold">
                        Agency Rating
                      </Text>
                      {/* </Tooltip> */}
                      <Flex>
                        <Flex
                          flexDirection="column"
                          pt="35px"
                          pb="50px"
                          alignItems="center"
                          justifyContent="center"
                          w="50%">
                          <Text fontSize="38px" textAlign="center">
                            {item?.average_rating}
                          </Text>
                          <RatingComponent alignSelf="flex-end" value={item?.average_rating} />
                          <Text fontWeight="600" mt="6px">{`${item?.reviews_count} reviews`}</Text>
                        </Flex>
                        <Box mt="14px" w="50%">
                          {item?.label_wise_ratings?.map((nestedItem) => {
                            return (
                              <Box key={nestedItem?.label}>
                                <Text
                                  textOverflow="ellipsis"
                                  whiteSpace="nowrap"
                                  overflow="hidden"
                                  fontSize="14px">
                                  {nestedItem?.label}
                                </Text>
                                <Progress
                                  mb="4px"
                                  colorScheme="yellow"
                                  height="10px"
                                  borderRadius="5px"
                                  max={5}
                                  value={nestedItem?.ratings}
                                />{' '}
                              </Box>
                            );
                          })}
                        </Box>
                      </Flex>
                    </HeaderCard>
                  </Flex>
                );
              })
            ) : (
              <Nodata />
            )}
          </Flex>
          // );
        }
      />
      <ModalComponent
        specifySize="5xl"
        isOpen={isSiteModalOpen}
        onClose={() => {
          onCloseSiteModal();
        }}
        modalTitle=""
        specifyDivider="notRequired"
        modalContent={
          <Flex
            flexWrap="wrap"
            justifyContent={`/${currPage}` === `${PATH.DASHBOARD.TO}` ? 'space-around' : ''}>
            {`/${currPage}` === `${PATH.REGION.TO}` &&
            user_details.user_type_id === USER_TYPE.CLIENT_ADMIN ? (
              <FormControl
                pos="absolute"
                top="10px"
                right="55px"
                display="flex"
                alignItems="center"
                width="286px">
                <FormLabel htmlFor="email-alerts" mb="0">
                  Temporary/Permanent Workers:
                </FormLabel>
                <Switch
                  isChecked={workerType === 'PERMANENT'}
                  onChange={() => {
                    setWorkerType(workerType === 'PERMANENT' ? 'TEMPORARY' : 'PERMANENT');
                  }}
                  id="worker-activity"
                />
              </FormControl>
            ) : (
              ''
            )}
            {siteRatingDataDetails && siteRatingDataDetails?.ratings?.length > 0 ? (
              siteRatingDataDetails?.ratings?.map((item) => {
                return (
                  <Flex
                    key={
                      `/${currPage}` === `${PATH.DASHBOARD.TO}`
                        ? `${item?.type} WORKERS`
                        : item?.name
                    }
                    flexDirection="column"
                    margin="7px"
                    width="31.8%">
                    <Text alignSelf="" fontSize="l" fontWeight="semibold">
                      {`/${currPage}` === `${PATH.DASHBOARD.TO}`
                        ? `${item?.type} WORKERS`
                        : item?.name}
                    </Text>
                    <HeaderCard boxShadow="rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;">
                      <Text textAlign="center" fontSize="l" fontWeight="semibold">
                        Site Rating
                      </Text>
                      <Flex>
                        <Flex
                          flexDirection="column"
                          pt="35px"
                          pb="50px"
                          alignItems="center"
                          justifyContent="center"
                          w="50%">
                          <Text fontSize="38px" textAlign="center">
                            {item?.average_rating}
                          </Text>
                          <RatingComponent alignSelf="flex-end" value={item?.average_rating} />
                          <Text fontWeight="600" mt="6px">{`${item?.reviews_count} reviews`}</Text>
                        </Flex>
                        <Box mt="14px" w="50%">
                          {item?.label_wise_ratings?.map((nestedItem) => {
                            return (
                              <Box key={nestedItem?.label}>
                                <Text
                                  textOverflow="ellipsis"
                                  whiteSpace="nowrap"
                                  overflow="hidden"
                                  fontSize="14px">
                                  {nestedItem?.label}
                                </Text>
                                <Progress
                                  mb="4px"
                                  colorScheme="yellow"
                                  height="10px"
                                  borderRadius="5px"
                                  max={5}
                                  value={nestedItem?.ratings}
                                />
                              </Box>
                            );
                          })}
                        </Box>
                      </Flex>
                    </HeaderCard>
                  </Flex>
                );
              })
            ) : (
              <Nodata />
            )}
          </Flex>
        }
      />
      <ModalComponent
        specifySize="5xl"
        isOpen={isClientModalOpen}
        onClose={() => {
          onCloseClientModal();
        }}
        modalTitle=""
        specifyDivider="notRequired"
        modalContent={
          clientRatingDataDetails && clientRatingDataDetails.ok ? (
            <Flex flexWrap="wrap" justifyContent="space-around">
              {clientRatingDataDetails.ratings.map((rating) => {
                return (
                  <Flex key={rating.type} flexDirection="column" margin="7px" width="31.8%">
                    <Text
                      alignSelf=""
                      textTransform="capitalize"
                      fontSize="l"
                      fontWeight="semibold">
                      {`${rating?.type} WORKERS`}
                    </Text>
                    <HeaderCard boxShadow="rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;">
                      <Text textAlign="center" fontSize="l" fontWeight="semibold">
                        Site Rating
                      </Text>
                      <Flex>
                        <Flex
                          flexDirection="column"
                          pt="35px"
                          pb="50px"
                          alignItems="center"
                          justifyContent="center"
                          w="50%">
                          <Text fontSize="38px" textAlign="center">
                            {rating?.average_rating}
                          </Text>
                          <RatingComponent alignSelf="flex-end" value={rating?.average_rating} />
                          <Text fontWeight="600" mt="6px">
                            {`${rating?.reviews_count} reviews`}
                          </Text>
                        </Flex>
                        <Box mt="14px" w="50%">
                          {rating.label_wise_ratings.map((nestedRating) => {
                            return (
                              <Box key={nestedRating?.label}>
                                <Text
                                  textOverflow="ellipsis"
                                  whiteSpace="nowrap"
                                  overflow="hidden"
                                  fontSize="14px">
                                  {nestedRating?.label}
                                </Text>
                                <Progress
                                  mb="4px"
                                  colorScheme="yellow"
                                  height="10px"
                                  borderRadius="5px"
                                  max={5}
                                  value={nestedRating?.ratings}
                                />
                              </Box>
                            );
                          })}
                        </Box>
                      </Flex>
                    </HeaderCard>
                  </Flex>
                );
              })}
            </Flex>
          ) : (
            <Nodata />
          )
        }
      />
      <Flex mt="5px">
        <HeaderCard
          boxShadow="rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;"
          onClick={() => {
            onOpenSiteModal();
          }}
          cursor={
            (users.includes(user_details.user_type_id) &&
              `/${currPage}` !== PATH.SITES.TO &&
              `/${currPage}` !== PATH.SHIFT.TO &&
              `/${currPage}` !== PATH.DEPARTMENT.TO) ||
            (user_details.user_type_id === USER_TYPE.AGENCY_ADMIN &&
              `/${currPage}` === PATH.REGION.TO)
              ? 'pointer'
              : ''
          }
          minWidth="239.5px"
          width="100%"
          margin="5px">
          {/* <Tooltip label="Site Rating"> */}
          <Text textAlign="center" fontSize="l" fontWeight="semibold">
            Site Rating
          </Text>
          {/* </Tooltip> */}
          {siteRatingData ? (
            <>
              {siteRatingData && siteRatingData.ok ? (
                <Flex>
                  <Flex
                    flexDirection="column"
                    pt="35px"
                    pb="50px"
                    alignItems="center"
                    justifyContent="center"
                    width="50%">
                    <Text fontSize="38px" textAlign="center">
                      {siteRatingData?.average_rating}
                    </Text>
                    <RatingComponent value={siteRatingData?.average_rating} />
                    <Text
                      fontWeight="600"
                      mt="6px">{`${siteRatingData?.reviews_count} reviews`}</Text>
                  </Flex>
                  <Box mt="14px" w="50%">
                    {siteRatingData?.label_wise_ratings?.map((item) => {
                      return (
                        <Box key={item?.label}>
                          <Text fontSize="14px">{item?.label}</Text>
                          <Progress
                            fontWeight="600"
                            style={{ color: RATING_STAR_COLOR }}
                            mb="4px"
                            // colorScheme="yellow"
                            height="10px"
                            borderRadius="5px"
                            max={5}
                            value={item?.ratings}
                          />
                        </Box>
                      );
                    })}
                  </Box>
                </Flex>
              ) : (
                <Skeleton h="90%" w="100%" />
              )}
            </>
          ) : (
            <Nodata />
          )}
        </HeaderCard>
        <HeaderCard
          boxShadow="rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;"
          cursor={
            (user_details.user_type_id === USER_TYPE.CLIENT_ADMIN ||
              user_details.user_type_id === USER_TYPE.SITE_ADMIN ||
              user_details.user_type_id === USER_TYPE.REGION_ADMIN) &&
            `/${currPage}` === PATH.DASHBOARD.TO
              ? 'pointer'
              : ''
          }
          onClick={() => {
            onOpenAgencyModal();
          }}
          width="100%"
          minWidth="239.5px"
          margin="5px">
          {/* <Tooltip label="Agency Rating"> */}
          <Text textAlign="center" fontSize="l" fontWeight="semibold">
            Agency Rating
          </Text>
          {/* </Tooltip> */}
          {agencyRatingData ? (
            <>
              {agencyRatingData && agencyRatingData.ok ? (
                <Flex>
                  <Flex
                    flexDirection="column"
                    pt="35px"
                    pb="50px"
                    alignItems="center"
                    justifyContent="center"
                    w="50%">
                    <Text fontSize="38px" textAlign="center">
                      {agencyRatingData?.average_rating}
                    </Text>
                    <RatingComponent
                      alignSelf="flex-end"
                      value={agencyRatingData?.average_rating}
                    />
                    <Text
                      fontWeight="600"
                      mt="6px">{`${agencyRatingData?.reviews_count} reviews`}</Text>
                  </Flex>
                  <Box mt="14px" w="50%">
                    {agencyRatingData?.label_wise_ratings?.map((item) => {
                      return (
                        <Box key={item?.label}>
                          <Text
                            textOverflow="ellipsis"
                            whiteSpace="nowrap"
                            overflow="hidden"
                            fontSize="14px">
                            {item?.label}
                          </Text>
                          <Progress
                            mb="4px"
                            colorScheme="yellow"
                            height="10px"
                            borderRadius="5px"
                            value={item?.ratings}
                            max={5}
                          />
                        </Box>
                      );
                    })}
                  </Box>
                </Flex>
              ) : (
                <Skeleton h="90%" w="100%" />
              )}
            </>
          ) : (
            <Nodata />
          )}
        </HeaderCard>
        <HeaderCard
          boxShadow="rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;"
          width="70%"
          margin="5px"
          minWidth="189.5px">
          {/* <Tooltip label="Average Worker Hours"> */}
          <Text
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            overflow="hidden"
            textAlign="center"
            fontSize="l"
            fontWeight="semibold">
            AVE HRS
          </Text>
          {/* </Tooltip> */}
          <Flex justifyContent="center" alignItems="center" flexDirection="column" h="198px">
            <Box m="10px 0px">
              <Box w="111px" textAlign="center" fontSize="4xl">
                {headerData?.result?.last_week_average_hours?.average_hours_per_worker || 0}
              </Box>

              <Flex position="relative" justifyContent="center">
                {headerData?.result?.last_week_average_hours?.average_hours_per_worker >
                  headerData?.result?.last_week_average_hours?.past_average_hours_per_worker && (
                  <Image
                    position="absolute"
                    src={greenArrowUp}
                    height={6}
                    top="-37px"
                    left={isSmallerThan1441 ? '-28px' : '-50px'}
                  />
                )}
                <Tooltip
                  borderRadius="7px"
                  label={
                    from && from !== '' && from !== 'Invalid date' ? `From: ${from} To: ${to}` : ''
                  }>
                  <Text textAlign="center">Last week</Text>
                </Tooltip>
                {headerData?.result?.last_week_average_hours?.average_hours_per_worker <
                  headerData?.result?.last_week_average_hours?.past_average_hours_per_worker && (
                  <Image
                    position="absolute"
                    right={isSmallerThan1441 ? '-28px' : '-50px'}
                    top="-40px"
                    src={redArrowDown}
                    height={6}
                  />
                )}
              </Flex>
            </Box>
            <Box m="10px 0px">
              <Box w="111px" textAlign="center" fontSize="4xl">
                {headerData?.result?.eight_week_average_worker_working_hours || 0}
              </Box>
              <Flex position="relative" justifyContent="center">
                {headerData?.result?.eight_week_average_worker_working_hours >
                  headerData?.result?.past_eight_week_average_worker_working_hours && (
                  <Image
                    position="absolute"
                    src={greenArrowUp}
                    height={6}
                    top="-37px"
                    left={isSmallerThan1441 ? '-28px' : '-50px'}
                  />
                )}
                <Text textAlign="center">Last 8 weeks</Text>
                {headerData?.result?.eight_week_average_worker_working_hours <
                  headerData?.result?.past_eight_week_average_worker_working_hours && (
                  <Image
                    position="absolute"
                    right={isSmallerThan1441 ? '-28px' : '-50px'}
                    top="-40px"
                    src={redArrowDown}
                    height={6}
                  />
                )}
              </Flex>
            </Box>
            {/* /* {activityTopCardData ? (
            <CircularChart
              value={activityTopCardData.current_range_average_hours}
              max={activityTopCardData.current_range_average_hours}
              strokeWidth={2}
              styles={{
                pathColor: colors.main.gray
              }}
              minW="80px"
              maxW="110px"
            />
          ) : (
            <SkeletonCircle size="80px" margin="auto" />
          )} */}
            {/* <Box textOverflow="ellipsis" whiteSpace="nowrap" overflow="hidden" w="110px">
                  <Tooltip label="Average Hours Per Worker (4 wk Avg.)">
                    <Box>Average Hours Per Worker (4 wk Avg.)</Box>
                  </Tooltip>
                  {activityTopCardData ? (
                    <CircularChart
                      value={activityTopCardData.past_average_hours}
                      max={activityTopCardData.past_average_hours}
                      strokeWidth={2}
                      styles={{
                        pathColor: colors.main.gray
                      }}
                      minW="80px"
                      maxW="110px"
                    />
                  ) : (
                    <SkeletonCircle size="80px" margin="auto" />
                  )}
                  </Box> */}
          </Flex>
        </HeaderCard>
        <HeaderCard
          boxShadow="rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;"
          width="100%"
          minWidth="239.5px"
          margin="5px"
          cursor={
            user_details.user_type_id === USER_TYPE.CLIENT_ADMIN &&
            `/${currPage}` === PATH.DASHBOARD.TO
              ? 'pointer'
              : ''
          }
          onClick={() => {
            onOpenClientModal();
          }}>
          {/* <Tooltip label="Client Rating"> */}
          <Text textAlign="center" fontSize="l" fontWeight="semibold">
            Company Rating
          </Text>
          {/* </Tooltip> */}
          {clientRatingData ? (
            <>
              {clientRatingData && clientRatingData.ok ? (
                <Flex>
                  <Flex
                    flexDirection="column"
                    pt="35px"
                    pb="50px"
                    alignItems="center"
                    justifyContent="center"
                    w="50%">
                    <Text fontSize="38px" textAlign="center">
                      {clientRatingData?.average_rating}
                    </Text>
                    <RatingComponent
                      alignSelf="flex-end"
                      value={clientRatingData?.average_rating}
                    />
                    <Text
                      fontWeight="600"
                      mt="6px">{`${clientRatingData?.reviews_count} reviews`}</Text>
                  </Flex>
                  <Box mt="14px" w="50%">
                    {clientRatingData?.label_wise_ratings?.map((item) => {
                      return (
                        <Box key={item?.label}>
                          <Text fontSize="14px">{item?.label}</Text>
                          <Progress
                            mb="4px"
                            colorScheme="yellow"
                            height="10px"
                            borderRadius="5px"
                            max={5}
                            value={item?.ratings}
                          />
                        </Box>
                      );
                    })}
                  </Box>
                </Flex>
              ) : (
                <Skeleton h="90%" w="100%" />
              )}
            </>
          ) : (
            <Nodata />
          )}
          {/* <Box minW="250px" w="100%">
          <Text textAlign="center" fontSize="l" fontWeight="semibold">
            Lost Shift
          </Text>
          {headerData && headerData.shift_fulfilled && (
            <Box>
              {headerData.shift_fulfilled.current_range_count >=
                headerData.shift_fulfilled.past_range_count && (
                <Box>
                  <Image src={greenArrowUp} height={8} ml="5%" />
                </Box>
              )}
              <Text textAlign="center" fontSize="6xl">
                {headerData.shift_fulfilled && headerData.shift_fulfilled.current_range_count}
              </Text>
              {headerData.shift_fulfilled.current_range_count <
                headerData.shift_fulfilled.past_range_count && (
                <Box>
                  <Image src={redArrowDown} height={8} ml="80%" />
                </Box>
              )}
            </Box>
          )}
                </Box> */}
          {/* </Box> */}
        </HeaderCard>
        <HeaderCard
          boxShadow="rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;"
          width="100%"
          minWidth="239.5px"
          margin="5px">
          <Tooltip borderRadius="7px" label="Total Cumulative Leavers">
            <Text textAlign="center" fontSize="l" fontWeight="semibold">
              Exit Interview Analysis
            </Text>
          </Tooltip>
          {/* <Box>
            <Image src={greenArrowUp} height={8} ml="5%" />
          </Box> */}
          <Flex h="198px" justifyContent="center" alignItems="center">
            {leaversData?.is_data_available ? (
              <>
                {leaversData &&
                leaversData?.result?.rows &&
                leaversData?.result?.rows?.length > 0 ? (
                  <DonuteChart
                    // legend
                    height="220px"
                    series={leaversData?.result?.rows?.map((d) => parseInt(d.value))}
                    labels={leaversData?.result?.rows?.map((d) => d.label)}
                    otherColors={BLUE_COLOR_SHADES}
                  />
                ) : (
                  <SkeletonCircle size="200px" />
                )}
              </>
            ) : (
              <Nodata />
            )}
          </Flex>
          {/* <Box>
            <Image src={redArrowDown} height={8} ml="80%" />
          </Box> */}
        </HeaderCard>
      </Flex>
    </>
  );
};

export default DashboardRatingCard;
