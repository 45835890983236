/* eslint-disable react/prop-types */
import React from 'react';
import { Box, Flex } from '@chakra-ui/react';
import constants from '../../constants';
import InputComponent from '../Input';
import StyledInputWrapper from './Forms.styled';
import { allowOnlyAlphabate } from '../../utils/helper';
import NationalityDropdown from './NationalityDropdown';
import CountryDropDownData from '../../utils/countryData.json';

const AddAgency = function ({ countryValue, setCountryData, data, setData, refresh, validations }) {
  return (
    <Box p="10px">
      <Flex>
        <StyledInputWrapper>
          <InputComponent
            lable={constants.NAME}
            placeholder={constants.NAME}
            onChange={(e) => setData({ ...data, name: e })}
            value={data.name}
            refresh={refresh}
            validationObj={validations.name}
            isRequired
            maxLength="50"
          />
        </StyledInputWrapper>
      </Flex>

      <Flex>
        <StyledInputWrapper>
          <InputComponent
            lable={constants.ADDRESS1}
            placeholder={constants.ADDRESS1}
            onChange={(e) => setData({ ...data, address_line_1: e })}
            value={data.address_line_1}
            refresh={refresh}
            validationObj={validations.address_line_1}
            isRequired
            maxLength="50"
          />
        </StyledInputWrapper>
      </Flex>

      <Flex>
        <StyledInputWrapper style={{ marginRight: '8px' }}>
          <InputComponent
            lable={constants.ADDRESS2}
            placeholder={constants.ADDRESS2}
            onChange={(e) => setData({ ...data, address_line_2: e })}
            value={data.address_line_2}
            refresh={refresh}
            validationObj={validations.address_line_2}
            maxLength="50"
          />
        </StyledInputWrapper>
        <StyledInputWrapper>
          <InputComponent
            lable={constants.ADDRESS3}
            placeholder={constants.ADDRESS3}
            onChange={(e) => setData({ ...data, address_line_3: e })}
            value={data.address_line_3}
            refresh={refresh}
            validationObj={validations.address_line_3}
            maxLength="50"
          />
        </StyledInputWrapper>
      </Flex>

      <Flex>
        <StyledInputWrapper style={{ marginRight: '8px' }}>
          <InputComponent
            lable={constants.POST_CODE}
            placeholder={constants.POST_CODE}
            onChange={(e) => setData({ ...data, postCode: e })}
            value={data.postCode}
            refresh={refresh}
            validationObj={validations.postCode}
            isRequired
            maxLength="15"
          />
        </StyledInputWrapper>
        <StyledInputWrapper>
          <InputComponent
            lable={constants.CITY}
            placeholder={constants.CITY}
            onChange={(e) => setData({ ...data, city: allowOnlyAlphabate(e) })}
            value={data.city}
            refresh={refresh}
            validationObj={validations.city}
            isRequired
            maxLength="20"
          />
        </StyledInputWrapper>
      </Flex>
      <Flex>
        <StyledInputWrapper>
          {/* <InputComponent
            lable={constants.COUNTRY}
            placeholder={constants.COUNTRY}
            onChange={(e) => setData({ ...data, country: e })}
            value={data.country}
            refresh={refresh}
            validationObj={validations.country}
            isRequired
            maxLength="20"
          /> */}
          <NationalityDropdown
            label="Country"
            country={countryValue}
            setCountry={setCountryData}
            validationObj={validations.country}
            refresh={refresh}
            dropdownData={CountryDropDownData}
          />
        </StyledInputWrapper>
      </Flex>
    </Box>
  );
};

export default AddAgency;
