/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Text, useToast } from '@chakra-ui/react';
import { TOAST_SETTINGS, USER_TYPE } from '../../constants';
import { getDepartments } from '../../redux/action/department.action';
import Dropdown from '../Dropdown';

const DepartmentDropdown = function ({
  department,
  setDepartment,
  validationObj,
  refresh,
  multiple,
  disabled = false,
  siteId = null,
  clientId = null
}) {
  const toast = useToast();
  const { userData, user_details } = useSelector((state) => state.authentication);
  const [departments, setDepartments] = useState([]);
  const [errorMsg, setErrorMsg] = useState(null);
  const [isValid, setValid] = useState(validationObj?.isvalid || true);
  const { selected_client_id, selected_site_id } = useSelector((state) => state.agency);

  useEffect(async () => {
    const result = await getDepartments(
      clientId || selected_client_id || userData.client_id || user_details.client_id || null,
      user_details.user_type_id === USER_TYPE.AGENCY_REGION_ADMIN
        ? ''
        : siteId || selected_site_id || userData.site_id || null,
      user_details.user_type_id === USER_TYPE.AGENCY_REGION_ADMIN && user_details.region_id
    );
    if (result && result.ok) {
      setDepartments(result.data.department_list);
    } else {
      toast({
        ...TOAST_SETTINGS.SETTINGS,
        status: TOAST_SETTINGS.ERROR,
        title: result.error
      });
    }
  }, [
    clientId,
    siteId,
    selected_client_id,
    selected_site_id,
    userData.client_id,
    userData.site_id
  ]);

  useEffect(() => {
    if (validationObj && !validationObj.isvalid) {
      setErrorMsg(validationObj.errorMessage);
      setValid(validationObj.isvalid);
    }
  }, [refresh]);

  const setDepartmentData = (e) => {
    setErrorMsg(null);
    setValid(true);
    setDepartment(e);
  };

  return (
    <>
      <Dropdown
        label={`Department${validationObj?.required ? '*' : ''}`}
        onSelect={setDepartmentData}
        options={departments.map((dept) => {
          return { label: dept.name, value: dept.id };
        })}
        value={department}
        placeholder=""
        isError={!isValid}
        multiple={multiple}
        disabled={disabled}
      />
      <Text visibility={isValid ? 'hidden' : 'visible'} color="main.error" mb="2px" fontSize="11px">
        {errorMsg || 'Error !'}
      </Text>
    </>
  );
};

export default DepartmentDropdown;
