/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
/* eslint-disable array-callback-return */

import { Box, Button, Flex, Spinner, useToast } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Card from '../../components/Card';
import StyledInputWrapper from '../../components/Forms/Forms.styled';
import { StyledMessagingFormContainer } from '../../components/MessagingView/MessagingView.styled';
import constants, {
  CUSTOM_TOAST_DURATION,
  LANGUAGE_OPTION,
  TOAST_SETTINGS,
  TRANSLATE_LANGUAGE,
  USER_TYPE
} from '../../constants';
import {
  TranslateAllMessage,
  getEditMessageTranslate,
  updateEditMessageTranslate
} from '../../redux/action/messaging.action';

const AutomatedMessageTranslate = function () {
  const { user_details } = useSelector((state) => state.authentication);
  const { data } = useSelector((state) => state.translate);
  const [refresh, setRefresh] = useState(false);
  const toast = useToast();
  const [templateData, setTemplateData] = useState({
    ...data.templateData
  });
  const [loader, setLoader] = useState(true);

  const onBodyInputChange = (field, index, key, e) => {
    const BodyData = { ...templateData };
    const bodyData = { ...templateData.body[index].translate };
    bodyData[key].text = e;
    bodyData[key].isValid = true;
    BodyData.body[index].translate = bodyData;
    setTemplateData({ ...BodyData });
  };
  const filterTranlateData = (filterItem) => {
    const finalData = {
      body: [{ translate: {} }]
    };
    const translate = {};
    const translatedMessage = filterItem;

    Object.keys(translatedMessage).forEach((key) => {
      translate[key] = { text: translatedMessage[key], isValid: true };
    });

    finalData.body[0].translate = translate;
    setTemplateData(finalData);
    setLoader(false);
  };

  const fetchdata = async () => {
    setLoader(true);
    const translateData = templateData.body[0].text;
    const payload = {
      message: translateData,
      from: 'en',
      to_languages: TRANSLATE_LANGUAGE
    };
    const res = await TranslateAllMessage(payload);
    filterTranlateData(res?.translatedMessage);
  };

  const getEditMessageData = async () => {
    setLoader(true);
    const clientId =
      user_details.user_type_id === USER_TYPE.AGENCY_REGION_ADMIN ||
      user_details.user_type_id === USER_TYPE.AGENCY_SITE_ADMIN ||
      user_details.user_type_id === USER_TYPE.AGENCY_ADMIN
        ? ''
        : (data.client && data.client.value) || user_details.client_id || null;
    const agencyId = user_details.agency_id || null;
    const result = await getEditMessageTranslate(clientId, agencyId, data.templateData.id);
    filterTranlateData(result.data.result.data);
  };

  useEffect(() => {
    if (data.translateAll) {
      fetchdata();
    } else {
      getEditMessageData();
    }
  }, []);

  const generatePayloadForMessageTemplate = () => {
    const translateBodyData = { ...templateData };
    translateBodyData.body[0].translate = Object.fromEntries(
      Object.entries(translateBodyData.body[0].translate).map(([key, value]) => [key, value.text])
    );
    const payload = {
      ...translateBodyData
    };
    return payload;
  };

  const saveBtnHandler = async () => {
    let isValidBody = true;
    const body = [...templateData.body];
    body.forEach((item) => {
      Object.entries(item.translate).map(([, value]) => {
        if (isValidBody) {
          if (value.text.length < 1) {
            isValidBody = false;
          }
        }
        value.isValid = value.text.length > 0;
      });
    });
    setTemplateData({ ...templateData, body: [...body] });

    if (isValidBody) {
      const payloadData = {
        ...generatePayloadForMessageTemplate()
      };
      const payload = { data: payloadData.body[0].translate };

      const translate = {};
      const translatedMessage = templateData.body[0].translate;
      Object.keys(translatedMessage).forEach((key) => {
        translate[key] = { text: translatedMessage[key], isValid: true };
      });
      const finalData = { ...templateData };
      finalData.body[0].translate = translate;
      setTemplateData({ ...finalData });

      const clientId =
        user_details.user_type_id === USER_TYPE.AGENCY_REGION_ADMIN ||
        user_details.user_type_id === USER_TYPE.AGENCY_SITE_ADMIN ||
        user_details.user_type_id === USER_TYPE.AGENCY_ADMIN
          ? ''
          : (data.client && data.client.value) || user_details.client_id || null;
      const agencyId = user_details.agency_id || null;
      const result = await updateEditMessageTranslate(
        clientId,
        agencyId,
        data.templateData.id,
        payload
      );

      if (result && result.ok) {
        toast({
          ...TOAST_SETTINGS.SETTINGS,
          duration: CUSTOM_TOAST_DURATION.duration,
          status: TOAST_SETTINGS.SUCCESS,
          title: result.message
        });
        window.close();
      } else {
        setRefresh(!refresh);
        toast({
          ...TOAST_SETTINGS.SETTINGS,
          status: TOAST_SETTINGS.ERROR,
          title: result.error
        });
      }
    }
  };

  return (
    <>
      <StyledMessagingFormContainer style={{ width: '450px' }}>
        <Card messagingView style={{ width: '450px' }}>
          {loader ? (
            <Box
              p="10px"
              style={{
                width: '450px',
                height: '700px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
              mb="30px">
              <Flex justifyContent="center">
                <Spinner
                  thickness="4px"
                  speed="0.65s"
                  emptyColor="gray.200"
                  color="blue.500"
                  size="xl"
                />
              </Flex>
            </Box>
          ) : (
            <Box p="10px" style={{ width: '450px' }}>
              {/* Object.keys(obj).forEach */}
              {templateData.body.map((message, index) => {
                return Object.entries(message.translate).map(([key, value], i) => {
                  return (
                    <Flex style={{ alignItems: 'center' }} key={key}>
                      <StyledInputWrapper>
                        <p
                          label="Body Text*"
                          fontSize="md"
                          style={{ fontSize: '1rem', fontWeight: '500' }}
                          className={`input-label ${!value.isValid ? 'input-label-error' : ''}`}>
                          {LANGUAGE_OPTION.find((option) => option.code === key)?.name ||
                            'Language not found'}
                        </p>
                        <textarea
                          className={value.isValid ? 'custom-input' : 'custom-input input-error'}
                          rows="4"
                          style={{
                            padding: '10px',
                            marginBottom:
                              i === Object.keys(templateData.body[0].translate).length - 1
                                ? '30px'
                                : ''
                          }}
                          value={value.text}
                          onChange={(e) =>
                            onBodyInputChange('translate', index, key, e.target.value)
                          }
                        />
                        <p
                          className="input-error-msg"
                          style={{ visibility: value.isValid ? 'hidden' : 'visible' }}>
                          Body Text is required
                        </p>
                      </StyledInputWrapper>
                    </Flex>
                  );
                });
              })}
            </Box>
          )}
        </Card>
      </StyledMessagingFormContainer>
      <Flex
        justifyContent="flex-end"
        style={{
          height: '50px',
          position: 'fixed',
          bottom: '0%',
          width: '100%',
          opacity: '1',
          backgroundColor: '#ffffff'
        }}>
        <Button
          bg="main.semiPrimary"
          color="main.white"
          _hover={{ bg: 'main.primary' }}
          _active={{
            bg: 'main.semiPrimary',
            borderColor: 'main.primary'
          }}
          m="5px"
          onClick={saveBtnHandler}>
          {constants.MESSAGING.SAVE_BTN_LABEL}
        </Button>
      </Flex>
    </>
  );
};

export default AutomatedMessageTranslate;
