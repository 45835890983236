import styled from 'styled-components';
import { colors } from '../../theme';

export const StyledContainer = styled.div``;

export const StyledHeaderTitleCard = styled.div`
  font-weight: normal;
`;

export const StyledHeaderValue = styled.div`
  font-size: 40px;
  font-weight: normal;
  color: ${colors.main.semiPrimary};
`;

export const StyledTableTitle = styled.div`
  color: ${colors.main.primary};
  font-weight: 800;
`;

export const StyledTableContainer = styled.table`
  width: 100%;
  margin-top: 8px;
  margin-bottom: 16px;
`;
export const StyledTableRow = styled.tr`
  border-bottom: 1px solid ${colors.main.secondary};
  padding: 4px;
  &:hover {
    background-color: ${colors.main.blueBackground};
  }
`;
export const StyledTableHeader = styled.th`
  padding: 8px 16px;
  text-align: start;
  font-size: 14px;
`;
export const StyledTableData = styled.td`
  padding: 8px 16px;
  text-align: start;
  font-size: 14px;
`;
