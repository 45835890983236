/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { BLUE_COLOR_SHADES, CHART_COLORS_FOR_AGENCY, NEW_CHART_COLOR_ARRAY } from '../../constants';
import { colors } from '../../theme';
import { numberFormatter } from '../../utils/helper';

const StackChart = function ({
  height,
  header,
  horizontal,
  series,
  Xcategories,
  Ycategories,
  label = []
}) {
  const colorArray = label?.map((item) => colors.main.blueBackground);
  const weeks = ['1-2', '3-4', '5-8', '9-12', '13-16', '17-26', '27-52', '52+'];
  const [options] = useState({
    chart: {
      type: 'bar',
      stacked: true,
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        horizontal,
        borderRadius: 7,
        columnWidth: 50,
        rangeBarOverlap: false,
        colors: {
          backgroundBarColors: colorArray,
          backgroundBarRadius: 7
        }
      }
    },
    title: {
      text: header,
      align: 'center',
      style: {
        fontSize: '16px',
        fontWeight: 500,
        fontFamily:
          '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif'
      }
    },
    dataLabels: {
      enabled: false
    },
    colors: NEW_CHART_COLOR_ARRAY,
    xaxis: {
      Xcategories,
      labels: {
        show: !!Xcategories
      },
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      }
    },
    yaxis: {
      Ycategories,
      labels: {
        show: !!Ycategories
      },
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      }
    },
    tooltip: {
      custom({ series, seriesIndex, dataPointIndex, w }) {
        const total = Array(series.length).fill(0);
        for (let i = 0; i < series.length; i += 1) {
          let index = 0;
          for (let j = 0; j < series[i].length; j += 1) {
            total[index] += series[i][j];
            index += 1;
          }
        }
        return label
          ? `<div class="tooltip"> <div class="tooltip-inside"> <span class="tooltip-circle" style="background-color: ${
              NEW_CHART_COLOR_ARRAY[seriesIndex]
            }"></span> <p style="max-width: 110px; word-break: break-word;
            white-space: normal;">${
              label[dataPointIndex] ? label[dataPointIndex] : ''
            }</p> </div> <p>Weeks: ${weeks[seriesIndex]}</p> <p>${
              series[seriesIndex][dataPointIndex]
            } & ${numberFormatter(
              (series[seriesIndex][dataPointIndex] / total[dataPointIndex]) * 100
            )}%</p> </div>`
          : '';
      }
    },
    fill: {
      opacity: 1
    },
    legend: {
      position: 'bottom',
      horizontalAlign: 'center',
      itemMargin: {
        horizontal: 3,
        vertical: 0
      }
    },
    grid: {
      xaxis: {
        lines: {
          show: false
        }
      },
      yaxis: {
        lines: {
          show: false
        }
      }
    }
  });
  // const [series] = useState([
  //   {
  //     name: '1-2',
  //     data: [44]
  //   },
  //   {
  //     name: '3-4',
  //     data: [53]
  //   },
  //   {
  //     name: '5-8',
  //     data: [12]
  //   },
  //   {
  //     name: '9-12',
  //     data: [9]
  //   },
  //   {
  //     name: '13-16',
  //     data: [25]
  //   }
  // ]);

  return <ReactApexChart height={height} options={options} series={series} type="bar" />;
};

export default StackChart;
