/* eslint-disable react/prop-types */
import React from 'react';
import { Box } from '@chakra-ui/react';
import InputComponent from '../Input';
import StyledInputWrapper from './Forms.styled';
import { allowOnlyAlphabate } from '../../utils/helper';
import constants from '../../constants';
import MobileInput from '../MobileInput';

const AddUser = function ({ data, setData, validations, refresh, disabled }) {
  return (
    <Box p="10px">
      <StyledInputWrapper>
        <InputComponent
          isRequired
          lable={constants.NAME}
          placeholder={constants.NAME}
          onChange={(e) => setData({ ...data, name: allowOnlyAlphabate(e) })}
          value={data.name}
          validationObj={validations.name}
          refresh={refresh}
          disabled={disabled}
        />
      </StyledInputWrapper>
      <StyledInputWrapper>
        <MobileInput
          data={data}
          setData={setData}
          validationObj={validations}
          refresh={refresh}
          disabled={disabled}
        />
        {/* <InputComponent
          isRequired
          lable={constants.NUMBER}
          placeholder={constants.NUMBER}
          onChange={(e) => setNumber(allowOnlyNumber(e))}
          value={number}
        /> */}
      </StyledInputWrapper>
      <StyledInputWrapper>
        <InputComponent
          isRequired
          type="email"
          lable={constants.EMAIL}
          placeholder={constants.EMAIL}
          onChange={(e) => {
            setData({ ...data, email: e });
          }}
          value={data.email}
          validationObj={validations.email}
          refresh={refresh}
          disabled={disabled}
        />
      </StyledInputWrapper>
    </Box>
  );
};

export default AddUser;
