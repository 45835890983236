import { NavLink } from 'react-router-dom';
import { Box, Flex } from '@chakra-ui/react';
import styled from 'styled-components';

const mixin = {
  styledCurves: `
    height: 10px;
    width: 10px;
    position: absolute;
    right: 0; `
};

export const StyledItemLabel = styled(Box)`
  font-style: normal;
  font-size: 14px;
  padding: 0 17px;
  color: ${(props) =>
    props['data-active'] ? props.theme.color.main.primary : props.theme.color.main.blueBackground};
`;

export const StyledNavItem = styled(Flex)`
  margin: 18px 0 0 18px;
  position: relative;
  color: ${(props) =>
    props['data-active'] ? props.theme.color.main.primary : props.theme.color.main.blueBackground};
  border-radius: 30px 0 0 30px;
  background: ${(props) =>
    props['data-active'] ? props.theme.color.main.blueBackground : props.theme.color.main.primary};

  ::before {
    background: ${(props) =>
      props['data-active']
        ? props.theme.color.main.blueBackground
        : props.theme.color.main.primary};
    content: '';
    z-index: 1;
    top: -10px;
    ${mixin.styledCurves}
  }
  ::after {
    background: ${(props) =>
      props['data-active']
        ? props.theme.color.main.blueBackground
        : props.theme.color.main.primary};
    content: '';
    z-index: 1;
    bottom: -10px;
    ${mixin.styledCurves}
  }

  ${StyledItemLabel} {
    color: ${(props) =>
      props['data-active']
        ? props.theme.color.main.primary
        : props.theme.color.main.blueBackground};
  }

  &:hover {
    background: ${(props) => props.theme.color.main.blueBackground};
    color: ${(props) => props.theme.color.main.primary};
    ::before,
    ::after {
      background: ${(props) => props.theme.color.main.blueBackground};
    }
    ${StyledItemLabel} {
      color: ${(props) => props.theme.color.main.primary};
    }
  }
`;

export const StyledNavLink = styled(NavLink)`
  height: 100%;
  width: 100%;
  padding: 6px 0 6px 10px;
  border-radius: 30px 0 0 30px;

  ::before {
    background: ${(props) => props.theme.color.main.primary};
    content: '';
    z-index: 10;
    top: -10px;
    border-radius: 0 0 30px 0;
    ${mixin.styledCurves}
  }
  ::after {
    background: ${(props) => props.theme.color.main.primary};
    content: '';
    z-index: 10;
    bottom: -10px;
    border-radius: 0 30px 0 0;
    ${mixin.styledCurves}
  }
`;

export const StyledNavContainer = styled(Flex)`
  flex-direction: column;
  overflow-y: auto;
  height: calc(100vh - 116px);
  ::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background: #ff0000;
  }
`;

export const StyledCompanyLogo = styled.div`
  width: 160px;
  height: 36px;
  margin: 8px auto 24px;
  object-fit: cover;
`;
