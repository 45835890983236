import React from 'react';

const ArrowUp = function (props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={11}
      height={11}
      viewBox="0 0 17.828 22"
      {...props}>
      <defs>
        <style>
          {
            '.cls-1{fill:none;stroke:currentColor;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px}'
          }
        </style>
      </defs>
      <g id="Arrow_Up" transform="translate(1.414 1)">
        <path id="Line_4" d="M0 20V0" className="cls-1" transform="translate(7.5)" />
        <path
          id="Path_4"
          d="M4.5 9.5L12 2l7.5 7.5"
          className="cls-1"
          transform="translate(-4.5 -2)"
        />
      </g>
    </svg>
  );
};

export default ArrowUp;
