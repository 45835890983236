import { API_ENDPOINT } from '../../constants';

import axiosInstance from '../../config/axios.config';
import { prepareErrorResponse, prepareSuccessReponse } from '../../utils/responseHandler';
import { toFormData } from '../../utils/helper';

const getSites = async (clientId, regionId) => {
  const result = await axiosInstance.get(
    `${API_ENDPOINT.GET_SITE}?client_id=${clientId}${regionId ? `&region_id=${regionId}` : ''}`
  );
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const addSite = async (payload) => {
  const result = await axiosInstance.post(API_ENDPOINT.ADD_SITE, payload);
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const updateSite = async (id, payload) => {
  const result = await axiosInstance.put(`${API_ENDPOINT.UPDATE_SITE}/${id}`, payload);
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const getSiteDropDownData = async (clientId, regionId) => {
  const result = await axiosInstance.get(
    `${API_ENDPOINT.GET_SITE}?client_id=${clientId}${regionId ? `&region_id=${regionId}` : ''}`
  );
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const getSiteRateCards = async (siteId) => {
  const result = await axiosInstance.get(`${API_ENDPOINT.RATE_CARD}?&site_id=${siteId}`);
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const addRateCard = async (payload) => {
  const result = await axiosInstance.post(API_ENDPOINT.RATE_CARD, await toFormData(payload));
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const downloadRateCardSample = async () => {
  const result = await axiosInstance.get(`${API_ENDPOINT.RATE_CARD_DOWNLOAD_SAMPLE}`);
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const deleteRateCard = async (id) => {
  const result = await axiosInstance.delete(`${API_ENDPOINT.RATE_CARD}/${id}`);
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

export {
  getSites,
  addSite,
  updateSite,
  getSiteDropDownData,
  getSiteRateCards,
  addRateCard,
  deleteRateCard,
  downloadRateCardSample
};
