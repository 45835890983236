import { API_ENDPOINT } from '../../constants';

import axiosInstance from '../../config/axios.config';
import { prepareErrorResponse, prepareSuccessReponse } from '../../utils/responseHandler';

const getSectors = async () => {
  const result = await axiosInstance.get(`${API_ENDPOINT.GET_SECTOR}?page=1&limit=10`);
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const addSector = async () => {};

export { getSectors, addSector };
