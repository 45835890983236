/* eslint-disable no-promise-executor-return */
/* eslint-disable no-unreachable */
/* eslint-disable no-underscore-dangle */
import axios from 'axios';
import { TOKENS } from '../constants';
import { deleteCookie, getCookie, setCookie } from '../utils/cookie';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    Authorization: getCookie(TOKENS.ACCESS) ? `Bearer ${getCookie(TOKENS.ACCESS)}` : null
  }
});

axiosInstance.interceptors.request.use((config) => {
  const con = {
    ...config,
    headers: { ...config.headers, Authorization: `Bearer ${getCookie(TOKENS.ACCESS)}` }
  };
  return con;
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    if (error?.response?.data?.error === 'UNAUTHORIZED') {
      return new Promise((resolve) => {
        const originalRequest = error.config;
        const refreshToken = getCookie(TOKENS.REFRESH);
        if (
          error.response &&
          error.response.status === 401 &&
          error.config &&
          !error.config.__isRetryRequest &&
          refreshToken
        ) {
          originalRequest._retry = true;

          const response = fetch(`${process.env.REACT_APP_API_BASE_URL}/token`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              refresh_token: refreshToken
            })
          })
            .then((res) => res.json())
            .then((res) => {
              setCookie(TOKENS.ACCESS, res.access_token);

              return axiosInstance(originalRequest);
            });
          resolve(response);
        } else if (error.response.status === 401) {
          deleteCookie(TOKENS.ACCESS);
          deleteCookie(TOKENS.REFRESH);
          window.location.href = '/';
        }
        return Promise.reject(error.response);
      });
    }
    return error?.response?.data;
  }
);

export default axiosInstance;
