/* eslint-disable react/prop-types */
import React from 'react';
import { Box } from '@chakra-ui/react';
import { colors } from '../../theme';

const DashboardCard = function ({ children, fullWidth, height, minW, ...props }) {
  return (
    <Box
      h={height || '260px'}
      bg={colors.main.white}
      width="100%"
      {...props}
      minW={minW || '180px'}>
      {children}
    </Box>
  );
};

export default DashboardCard;
