/* eslint-disable default-param-last */
import { API_ENDPOINT } from '../../constants';

import axiosInstance from '../../config/axios.config';
import { prepareErrorResponse, prepareSuccessReponse } from '../../utils/responseHandler';
import { toFormData } from '../../utils/helper';

const getBookings = async (queryString, limit = 10, page = 1, sortKey, sortType) => {
  let qs = `${queryString}&page=${page}&limit=${limit}`;
  if (sortKey) {
    qs += `&sort_by=${sortKey}&sort_type=${sortType}`;
  }
  const result = await axiosInstance.get(`${API_ENDPOINT.GET_BOOKING}${qs}`);
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const getBookingsDetail = async (queryString) => {
  const result = await axiosInstance.get(`${API_ENDPOINT.GET_BOOKING}${queryString}`);
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const AddBookingData = async (payload) => {
  const result = await axiosInstance.post(API_ENDPOINT.ADD_BOOKING, payload);
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const updateBooking = async (id, payload) => {
  const result = await axiosInstance.put(`${API_ENDPOINT.ADD_BOOKING}/${id}`, payload);
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const updateRequestedHeadsInBooking = async (payload) => {
  const result = await axiosInstance.put(`${API_ENDPOINT.UPDATE_BOOKING}`, payload);
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const cancelBooking = async (id) => {
  const result = await axiosInstance.put(`${API_ENDPOINT.CANCEL_BOOKING}/${id}`, { status: '2' });
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const downloadUploadedFullfillSheet = async (clientId, agencyId, startDate, endDate) => {
  let queryString = `client_id=${clientId}`;
  if (agencyId) {
    queryString += `&agency_id=${agencyId}`;
  }
  if (startDate) {
    queryString += `&start_date=${startDate}`;
  }
  if (endDate) {
    queryString += `&end_date=${endDate}`;
  }
  const result = await axiosInstance.get(
    `${API_ENDPOINT.UPDATE_BOOKING}${API_ENDPOINT.OPEN}?${queryString}`
  );
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const updateCreateBookingCsvData = async (clientId, agencyId, startDate, endDate, payload) => {
  let queryString = `client_id=${clientId}`;
  if (agencyId) {
    queryString += `&agency_id=${agencyId}`;
  }
  if (startDate) {
    queryString += `&start_date=${startDate}`;
  }
  if (endDate) {
    queryString += `&end_date=${endDate}`;
  }
  const result = await axiosInstance.put(
    `${API_ENDPOINT.SHIFT_BOOKING}${API_ENDPOINT.SHIFT_FULLFILLMENT}?${queryString}`,
    await toFormData(payload)
  );
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const uploadCreateBookingCsvData = async (payload) => {
  const result = await axiosInstance.post(
    `${API_ENDPOINT.BULK_BOOKING}${API_ENDPOINT.BULK}`,
    await toFormData(payload)
  );
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const downloadCreateBookingSampleSheet = async () => {
  const result = await axiosInstance.get(
    `${API_ENDPOINT.BULK_BOOKING}${API_ENDPOINT.BOOKING_DOWNLOAD}${API_ENDPOINT.DYNAMIC_SAMPLE_SHEET}`
  );
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

const deleteBooking = async (id) => {
  const result = await axiosInstance.delete(`${API_ENDPOINT.BULK_BOOKING}/${id}`);
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

export {
  getBookings,
  getBookingsDetail,
  AddBookingData,
  updateBooking,
  cancelBooking,
  updateRequestedHeadsInBooking,
  downloadUploadedFullfillSheet,
  uploadCreateBookingCsvData,
  downloadCreateBookingSampleSheet,
  deleteBooking,
  updateCreateBookingCsvData
};
