/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Text, useToast } from '@chakra-ui/react';
import { TOAST_SETTINGS } from '../../constants';
import { getSectors } from '../../redux/action/sector.action';
import Dropdown from '../Dropdown';

const SectorDropdown = function ({ setSector, sector, validationObj, refresh }) {
  const toast = useToast();
  const [sectors, setSectors] = useState([]);
  const [errorMsg, setErrorMsg] = useState(null);
  const [isValid, setValid] = useState(validationObj?.isvalid || true);

  useEffect(async () => {
    const result = await getSectors();
    if (result && result.ok) {
      setSectors(result.data.sector_list);
    } else {
      toast({
        ...TOAST_SETTINGS.SETTINGS,
        status: TOAST_SETTINGS.ERROR,
        title: result.error
      });
    }
  }, []);

  useEffect(() => {
    if (validationObj && !validationObj.isvalid) {
      setErrorMsg(validationObj.errorMessage);
      setValid(validationObj.isvalid);
    }
  }, [refresh]);

  const setSectorData = (e) => {
    setErrorMsg(null);
    setValid(true);
    setSector(e);
  };

  return (
    <>
      <Dropdown
        label={`Sectors${validationObj?.required ? '*' : ''}`}
        onSelect={setSectorData}
        options={sectors.map((sec) => {
          return { label: sec.sector_value, value: sec.sector_id };
        })}
        value={sector}
        placeholder=""
        isError={!isValid}
      />
      <Text visibility={isValid ? 'hidden' : 'visible'} color="main.error" mb="2px" fontSize="11px">
        {errorMsg || 'Error !'}
      </Text>
    </>
  );
};

export default SectorDropdown;
