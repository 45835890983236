/* eslint-disable import/prefer-default-export */
import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { PERMISSIONS } from '../constants';

export const useQuery = () => {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
};

export const getModulePermissions = (featureCode) => {
  const { permissions } = useSelector((state) => state.authentication);
  const permission = permissions.filter((d) => d.feature_code === featureCode);
  return PERMISSIONS[permission[0].access_type];
};
