import React from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import {
  StyledItemLabel,
  StyledNavItem,
  StyledNavLink,
  StyledCompanyLogo,
  StyledNavContainer
} from './LeftPanel.styled';
import { DIMENSIONS, PATH } from '../../constants';
import { checkActiveItem } from '../../utils/helper';
import CompanyLogo from '../../assets/images/Logo_White.png';

const getLeftPanelLabel = (el) => {
  return (
    <Flex alignItems="center" pl="10px">
      {el.ICON}
      <StyledItemLabel pl="20px">{el.LABEL}</StyledItemLabel>
    </Flex>
  );
};

const LeftPanel = function () {
  const { menu } = useSelector((state) => state.authentication);
  return (
    menu &&
    menu.length > 0 && (
      <Box color="main.secondary" w={DIMENSIONS.LEFT_PANEL_WIDTH}>
        <StyledCompanyLogo>
          <img alt="Clear Vue" src={CompanyLogo} />
        </StyledCompanyLogo>
        <StyledNavContainer>
          {Object.keys(PATH).map((el) => {
            return (
              PATH[el].LABEL &&
              (PATH[el].CODE === 'all' || menu.includes(PATH[el].CODE)) && (
                <StyledNavItem key={PATH[el].TO} data-active={checkActiveItem(PATH[el].TO)}>
                  <StyledNavLink to={PATH[el].TO}>{getLeftPanelLabel(PATH[el])}</StyledNavLink>
                </StyledNavItem>
              )
            );
          })}
        </StyledNavContainer>
      </Box>
    )
  );
};

LeftPanel.propTypes = {};

export default LeftPanel;
