import React from 'react';
import { Spinner } from '@chakra-ui/react';
import { LoaderContainer } from './Loader.styled';

const Loader = function () {
  return (
    <LoaderContainer>
      <Spinner size="lg" color="main.primary" />
    </LoaderContainer>
  );
};

export default Loader;
