/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Text, useToast } from '@chakra-ui/react';
// eslint-disable-next-line import/named
import { TOAST_SETTINGS, USER_TYPE } from '../../constants';
import { getSiteDropDownData } from '../../redux/action/site.action';
import Dropdown from '../Dropdown';

const SiteDropdownWithDefault = function ({
  setSite,
  site,
  validationObj,
  refresh,
  multiple,
  label = 'Site',
  reloadData,
  setDefaultValue,
  clientId = null,
  disabled,
  edit
}) {
  const toast = useToast();
  const dispatch = useDispatch();
  const [sites, setSites] = useState([]);
  const { userData, user_details } = useSelector((state) => state.authentication);
  const { selected_client_id } = useSelector((state) => state.agency);
  const [errorMsg, setErrorMsg] = useState(null);
  const [isValid, setValid] = useState(validationObj?.isvalid || true);

  useEffect(async () => {
    if (!edit) {
      if (!!selected_client_id || !!userData.client_id || !!user_details.client_id) {
        const result = await getSiteDropDownData(
          clientId || selected_client_id || userData.client_id || user_details.client_id,
          (user_details.user_type_id === USER_TYPE.AGENCY_REGION_ADMIN ||
            user_details.user_type_id === USER_TYPE.REGION_ADMIN) &&
            user_details.region_id
        );
        if (result && result.ok) {
          setSites(result.data.sites);
          if (setDefaultValue && result.data.sites.length > 0) {
            setSite({
              label: result.data.sites[0].name,
              value: result.data.sites[0].id
            });
            dispatch({
              type: 'SELECT_SITE',
              payload: {
                selected_site_id: result.data.sites[0].id,
                selected_site_name: result.data.sites[0].name
              }
            });
          } else {
            setSite({});
            // toast({
            //   ...TOAST_SETTINGS.SETTINGS,
            //   status: TOAST_SETTINGS.WARNING,
            //   title: 'No sites available'
            // });
          }
        } else {
          toast({
            ...TOAST_SETTINGS.SETTINGS,
            status: TOAST_SETTINGS.ERROR,
            title: result.error
          });
        }
      }
    }
  }, [clientId, selected_client_id, userData.client_id, user_details.client_id]);

  useEffect(() => {
    if (validationObj && !validationObj.isvalid) {
      setErrorMsg(validationObj.errorMessage);
      setValid(validationObj.isvalid);
    }
  }, [refresh]);

  useEffect(async () => {
    if (!!selected_client_id || !!userData.client_id || !!user_details.client_id) {
      const result = await getSiteDropDownData(
        selected_client_id || userData.client_id || user_details.client_id,
        (user_details.user_type_id === USER_TYPE.AGENCY_REGION_ADMIN ||
          user_details.user_type_id === USER_TYPE.REGION_ADMIN) &&
          user_details.region_id
      );
      if (result && result.ok) {
        setSites(result.data.sites);
        if (setDefaultValue && result.data.sites.length > 0) {
          setSite({
            label: result.data.sites[0].name,
            value: result.data.sites[0].id
          });
          dispatch({
            type: 'SELECT_SITE',
            payload: {
              selected_site_id: result.data.sites[0].id,
              selected_site_name: result.data.sites[0].name
            }
          });
        } else {
          setSite({});
          // toast({
          //   ...TOAST_SETTINGS.SETTINGS,
          //   status: TOAST_SETTINGS.WARNING,
          //   title: 'No sites available'
          // });
        }
      } else {
        toast({
          ...TOAST_SETTINGS.SETTINGS,
          status: TOAST_SETTINGS.ERROR,
          title: result.error
        });
      }
    }
  }, [reloadData]);

  const setSectorData = (e) => {
    setErrorMsg(null);
    setValid(true);
    setSite(e);
  };

  return (
    <>
      <Dropdown
        label={label.length > 0 ? `${label}${validationObj?.required ? '*' : ''}` : ''}
        onSelect={(e) => {
          setSectorData(e);
        }}
        options={sites.map((sec) => {
          return { label: sec.name, value: sec.id };
        })}
        value={site}
        disabled={disabled}
        placeholder=""
        multiple={multiple}
        isError={!isValid}
      />
      <Text visibility={isValid ? 'hidden' : 'visible'} color="main.error" mb="2px" fontSize="11px">
        {errorMsg || 'Error !'}
      </Text>
    </>
  );
};

export default SiteDropdownWithDefault;
