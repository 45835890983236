import { API_ENDPOINT, REDUX_SELECTOR, TOKENS } from '../../constants';

import axiosInstance from '../../config/axios.config';
import { setCookie } from '../../utils/cookie';
import { prepareErrorResponse, prepareSuccessReponse } from '../../utils/responseHandler';

export const userSignIn = (payload) => async (dispatch) => {
  const result = await axiosInstance.post(API_ENDPOINT.LOGIN, payload);
  if (result && result.ok) {
    setCookie(TOKENS.ACCESS, result.access_token);
    setCookie(TOKENS.REFRESH, result.refresh_token);
    result.access_token = undefined;
    result.refresh_token = undefined;
    dispatch({
      type: REDUX_SELECTOR.USER.LOGIN,
      payload: result
    });
    return prepareSuccessReponse(result);
  }
  dispatch({
    type: REDUX_SELECTOR.USER.LOGOUT
  });
  return prepareErrorResponse(result);
};

export const forgotPassword = async (payload) => {
  const result = await axiosInstance.post(API_ENDPOINT.FORGOT_PASSWORD, payload);
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

export const resetPassword = async (payload) => {
  const result = await axiosInstance.post(API_ENDPOINT.RESET_PASSWORD, payload);
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

export const getUserData = (payload) => async (dispatch) => {
  const result = await axiosInstance.get(API_ENDPOINT.GET_USER_DATA, payload);
  if (result && result.ok) {
    dispatch({
      type: REDUX_SELECTOR.USER.USER_DETAILS,
      payload: result
    });
    return prepareSuccessReponse(result);
  }
  dispatch({
    type: REDUX_SELECTOR.USER.LOGOUT
  });
  return prepareErrorResponse(result);
};

export const addUser = async (payload) => {
  const result = await axiosInstance.post(API_ENDPOINT.ADD_USER_DATA, payload);
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

export const updateUser = async (payload) => {
  const result = await axiosInstance.put(API_ENDPOINT.UPDATE_USER_DATA, payload);
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

export const reInviteAdminUser = async (id) => {
  const result = await axiosInstance.post(`${API_ENDPOINT.RE_INVITE}/${id}`, {});
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

export const InActiveUser = async (id, payload) => {
  const result = await axiosInstance.put(`${API_ENDPOINT.IN_ACTIVE}/${id}`, payload);
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

export const revokeUser = async (id) => {
  const result = await axiosInstance.put(
    `${API_ENDPOINT.GET_USER_DATA}/${id}/${API_ENDPOINT.REVOKE}`,
    {}
  );
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};

export const blockWorkerInvite = async (id, payload) => {
  const result = await axiosInstance.put(
    `${API_ENDPOINT.BLOCK_WORKER_INVITE_EMAIL}/${id}`,
    payload
  );
  if (result && result.ok) {
    return prepareSuccessReponse(result);
  }
  return prepareErrorResponse(result);
};
