/* eslint-disable react/prop-types */
import { Box, Flex, Button, keyframes } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { PATH } from '../../constants';
// import LoginPage from '../Login';

const animationKeyFrames = keyframes`
    0% {height: 0.8rem;}
    50% {height: 0.8rem;}
    52% {height: 0.1rem;}
    54% {height: 0.8rem;}
    100% {height: 0.8rem;}
`;

const animation = `${animationKeyFrames} 3s infinite ease-in-out`;

const NotFoundPage = function ({ loggedIn }) {
  const navigate = useNavigate();

  return (
    <Flex
      backgroundColor="#E9F4FF"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      h="100vh">
      <Flex justifyContent="space-between" alignContent="center">
        <Box fontSize="15rem" color="#426b9d" fontWeight="900" lineHeight="1">
          4
        </Box>
        <Box pos="relative" w="12.2rem" m="20px 2.1rem">
          <Box
            pos="absolute"
            bottom="0"
            left="0"
            w="12.2rem"
            h="11.4rem"
            borderRadius="50%"
            backgroundColor="#426b9d"
          />
          <Box
            pos="absolute"
            bottom="0.3rem"
            left="50%"
            transform="translateX(-50%)"
            overflow="hidden"
            w="12.5rem"
            h="13rem"
            borderRadius="0 0 50% 50%">
            <Box
              pos="absolute"
              bottom="-0.3rem"
              left="50%"
              transform="translateX(-50%)"
              w="9.2rem"
              h="12.4rem"
              borderWidth="0.3rem"
              borderColor="#426b9d"
              borderStyle="solid"
              backgroundColor="white"
              borderRadius="0.8rem">
              <Box pos="relative" mt="2.3rem">
                <Box pos="absolute" top="0" left="2.4rem" w="4.6rem" h="0.8rem">
                  <Box
                    animation={animation}
                    pos="absolute"
                    bottom="0"
                    w="0.8rem"
                    h="0.8rem"
                    borderRadius="50%"
                    backgroundColor="#426b9d"
                    left="0"
                  />
                  <Box
                    animation={animation}
                    pos="absolute"
                    bottom="0"
                    w="0.8rem"
                    h="0.8rem"
                    borderRadius="50%"
                    backgroundColor="#426b9d"
                    right="10px"
                  />
                </Box>
                <Box
                  pos="absolute"
                  top="1.6rem"
                  w="1rem"
                  h="0.2rem"
                  borderRadius="50%"
                  backgroundColor="#EF7448"
                  left="1.4rem"
                />
                <Box
                  pos="absolute"
                  top="1.6rem"
                  w="1rem"
                  h="0.2rem"
                  borderRadius="50%"
                  backgroundColor="#EF7448"
                  right="1.4rem"
                />
                <Box
                  pos="absolute"
                  top="3.1rem"
                  w="1.6rem"
                  h="0.2rem"
                  borderRadius="0.1rem"
                  backgroundColor="#426b9d"
                  transform="translateX(-50%)"
                  left="50%"
                />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box fontSize="15rem" color="#426b9d" fontWeight="900" lineHeight="1">
          4
        </Box>
      </Flex>
      <Box fontWeight="500" color="#426b9d" fontSize="2xl">
        Oops! You found a glitch...
      </Box>
      <Button
        onClick={() => {
          navigate(loggedIn ? PATH.DASHBOARD.TO : PATH.LOGIN.TO);
        }}
        opacity="0.7"
        textDecoration="none"
        backgroundColor="#426b9d"
        _hover={{ backgroundColor: '#164684' }}
        color="white"
        m="40px">
        {' '}
        Back Home
      </Button>
    </Flex>
  );
};

export default NotFoundPage;
